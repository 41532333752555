import styled from 'styled-components'
import produce from 'immer'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import SwapMainItemToken from '../atoms/SwapMainItemToken'
import SwapMainItemInput from '../atoms/SwapMainItemInput'
import SwapMainItemBalance from '../atoms/SwapMainItemBalance'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useTokenQuery from '../../../api/query/useTokenQuery'

const SwapMainToWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  margin-top: 4px;
  padding: 20px 30px 20px 19px;
  border-radius: 24px;
  background-color: #f8f8f8;

  @media (max-width: 768px) {
    padding: 24px 16px;
    border-radius: 16px;
  }
`

const SwapMainToRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 7px;
  }
`

const TokenPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
  font-size: 16px;
  color: #9d9999;

  @media (max-width: 768px) {
    position: relative;
    margin: 0;
    font-size: 11px;
  }
`

const Difference = styled.span<{ plus: string }>`
  margin-left: 6px;
  color: ${({ plus }) => (plus === 'true' ? '#00e1c2' : '#ff6d68')};

  @media (max-width: 768px) {
    position: absolute;
    top: 12px;
    right: 0;
    margin: 0;
  }
`

const SwapMainTo = () => {
  const {
    swapFormState: {
      form: { from, to },
      error,
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const {
    swapInfoState: { diff },
  } = useManageSwapInfoState()
  const { data } = useTokenQuery(to.address)

  const handleChange = (value: string) =>
    setSwapFormState(prev =>
      produce(prev, draft => {
        draft.form.to.value = value
        draft.form.lastModified = 'to'

        return draft
      }),
    )

  return (
    <SwapMainToWrapper>
      <SwapMainToRow>
        <SwapMainItemToken token={data} position="to" />
        <SwapMainItemInput
          value={to.value ?? 0}
          handleChange={handleChange}
          color={error === 'INSUFFICIENT_LIQUIDITY' ? '#9d9999' : '#000000'}
        />
      </SwapMainToRow>

      <SwapMainToRow>
        <SwapMainItemBalance
          balance={data?.balance ?? '0'}
          isVisibleMaxButton={false}
        />
        <TokenPrice>
          {to.value !== '' && to.address !== '' ? `~ $ ` : ''}
          <SlicedDecimal maxIntLength={12} prefix="$ " isNotDecimal>
            {convertNumber(
              (data?.price ?? 0) * parseFloat(to.value !== '' ? to.value : '0'),
              {
                maxDigit: 6,
              },
            )}
          </SlicedDecimal>
          {from.value !== '' &&
          to.value !== '' &&
          diff !== '' &&
          error !== 'INSUFFICIENT_LIQUIDITY' ? (
            <Difference plus={String(parseFloat(diff) >= 0)}>
              ({parseFloat(diff).toFixed(2)}%)
            </Difference>
          ) : null}
        </TokenPrice>
      </SwapMainToRow>
    </SwapMainToWrapper>
  )
}

export default SwapMainTo

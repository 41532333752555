import caver from '../modules/network/caver'
import { TOKEN_ADDRESS } from '../contract/address'
import {
  NodeEnvironmentType,
  NftItemPartType,
  NftCardRarityType,
  NftItemRarityType,
} from './types'

// Custom Node Environment
// 테스트 환경에서 실제 프로덕션에 배포된 컨트랙트를 사용할 경우 'production'으로 설정, production 배포 시에는 undefined으로 변경
export const CUSTOM_NODE_ENV: NodeEnvironmentType | undefined = 'production'

// Wallet Install Link
export const WALLET_INSTALL_LINK = {
  kaikas:
    'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
  bitkeep: {
    pc: 'https://chrome.google.com/webstore/detail/bitkeep-bitcoin-crypto-wa/jiidiaalihmmhddjgbnbgdfflelocpak',
    android: 'https://play.google.com/store/apps/details?id=com.bitkeep.wallet',
    ios: 'https://apps.apple.com/app/bitkeep/id1395301115',
  },
}

// Transaction Deadline
export const DEADLINE = Math.floor(+new Date() / 1000 + 5000)

// uint256 Maximum Value
export const MAX_U_INT = caver.utils.toBN(
  '115792089237316195423570985008687907853269984665640564039457584007913129639935',
)

// Approve Max Allowance
export const MAX_ALLOWANCE = caver.utils.convertToPeb(MAX_U_INT, 'peb')

// Neuron Symbol By Node Environment
export const NR_SYMBOL = {
  production: 'NR' as keyof typeof TOKEN_ADDRESS,
  development: 'NT' as keyof typeof TOKEN_ADDRESS,
}[CUSTOM_NODE_ENV ?? (process.env.NODE_ENV as NodeEnvironmentType)]

// Swap / Deposit Input Regex
export const INPUT_REGEX = RegExp(/^([0-9]*)(\.[0-9]{0,6})?$/i)

// Deposit LP Input Regex
export const LP_INPUT_REGEX = RegExp(/^([0-9]*)(\.[0-9]{0,8})?$/i)

// My Asset Page Chart Color
export const COLOR_LIST = [
  '#ff6d68',
  '#65dfb9',
  '#6d5b97',
  '#ff7619',
  '#587aff',
  '#ffa208',
  '#97b04d',
  '#f072d2',
]

// Scroll Delta Value
export const SCROLL_DELTA = 5

// Referral Event Deadline
export const REFERRAL_EVENT_DEADLINE = '2022-02-27T18:00:00'

// zIndex Constant
export const CSS_Z_INDEX = {
  token: 1,
  tooltip: 2,
  widget: 3,
  header: 4,
  sidebar: 5,
  modal: 8,
  popup: 9,
  messageBox: 10,
  videoController: 12,
  video: 15,
}

// Regex For Checking Mobile Device
export const MOBILE_REG_EXP = RegExp(
  'Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini',
  'i',
)

// Staking Term By Index
export const STAKING_TERM = {
  index: [0, 1, 2, 3, 4],
  month: [2, 4, 8, 12, 24],
  color: ['#d1cbcb', '#d1cbcb', '#9d9999', '#65dfb9', '#ff6d68'],
  multiple: [1, 2, 4, 16, 128],
}

// Neuron Staking Pool PID
export const NEURON_STAKING_PID = 0

// A.K.A Check Regex
export const AKA_CHECK_REGEX = /^[a-zA-Z0-9]{4,15}$/

// Skin Colors For Creating New Avatar
export const SKIN_COLOR_LIST = ['#e4d6c1', '#69fda0', '#acd8fa', '#8b6545']

// Contract Parameter By Skin Hex Color
export const SKIN_KEY_BY_HEX_COLOR = {
  '#e4d6c1': 'Light_Beige',
  '#69fda0': 'Bright_Green',
  '#acd8fa': 'Sky_Blue',
  '#8b6545': 'Coffee',
}

// Unity Parameter By Skin Hex Color
export const SKIN_INDEX_BY_HEX_COLOR = {
  '#e4d6c1': 0,
  '#69fda0': 1,
  '#acd8fa': 2,
  '#8b6545': 3,
}

// Background Colors For Creating New Avatar
export const BACKGROUND_COLOR_LIST = [
  '#ff6d68',
  '#4fccb4',
  '#7f69f7',
  '#e0862d',
  '#292d33',
]

// Contract Parameter By Background Hex Color
export const BACKGROUND_KEY_BY_HEX_COLOR = {
  '#ff6d68': 'Coral_Red',
  '#4fccb4': 'Emerald_Green',
  '#7f69f7': 'Royal_Blue',
  '#e0862d': 'Tiger_Orange',
  '#292d33': 'Midnight_Gray',
}

// Unity Parameter By Background Hex Color
export const BACKGROUND_INDEX_BY_HEX_COLOR = {
  '#292d33': 0,
  '#4fccb4': 1,
  '#e0862d': 2,
  '#7f69f7': 3,
  '#ff6d68': 4,
}

export const NFT_ITEM_PARTS: NftItemPartType[] = [
  'head',
  'top',
  'bottom',
  'shoes',
  'face',
  'hand',
  'acc1',
  'acc2',
]

export const NFT_CARD_RARITIES: NftCardRarityType[] = [
  'fantasticFrank',
  'legend',
  'epic',
  'rare',
  'normal',
]

export const NFT_ITEM_SORT_KEY: { [key in NftItemRarityType]: number } = {
  mythic: 5,
  legend: 4,
  epic: 3,
  rare: 2,
  normal: 1,
}

export const NFT_CARD_SORT_KEY: { [key in NftCardRarityType]: number } = {
  fantasticFrank: 5,
  legend: 4,
  epic: 3,
  rare: 2,
  normal: 1,
}

export const NFT_ITEM_FONT_COLOR_BY_RARITIES: {
  [key in NftItemRarityType]: string
} = {
  mythic: '#ff6d68',
  legend: '#00e899',
  epic: '#ffffff',
  rare: '#ffffff',
  normal: '#ffffff',
}

export const NFT_CARD_FONT_COLOR_BY_RARITIES: {
  [key in NftCardRarityType]: string
} = {
  fantasticFrank: '#ff6d68',
  legend: '#00e899',
  epic: '#ffffff',
  rare: '#ffffff',
  normal: '#ffffff',
}

export const HALL_OF_FAME_ITEM_COLOR: {
  [key in Exclude<NftCardRarityType, 'fantasticFrank' | 'legend'>]: {
    background: string
    border: string
  }
} = {
  epic: {
    background: '#7f68ff',
    border: '#d2c4ff',
  },
  rare: {
    background: '#65b495',
    border: '#c4ffdd',
  },
  normal: {
    background: '#4f5b62',
    border: '#909da5',
  },
}

export const FONT_COLOR_BY_QUARTER: { [key: string]: string } = {
  '1': '#b545e3',
  '2': '#ff6d68',
  '3': '#00e899',
  '4': '#4fccb4',
}

export const RONY_CLUB_MUSIC_LIST = [
  new Audio(`${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/music_1.mp3`),
  new Audio(`${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/music_2.mp3`),
  new Audio(`${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/music_3.mp3`),
]

export const SOUND_EFFECTS = {
  setItem: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/set_nft.wav`,
  ),
  unsetItem: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/unset_nft.mp3`,
  ),
  enterMenu: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/enter_menu.mp3`,
  ),
  hallOfFame: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/hall_of_fame.mp3`,
  ),
  mintingSuccess: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/minting_success.wav`,
  ),
  confirm: new Audio(
    `${process.env.REACT_APP_S3_RONYCLUB_NFT_URL}/Assets/confirm.wav`,
  ),
}

export const CONTRACT_GAS_LIMIT = 3000000

export const MINTING_CARD_GAS_LIMIT = 15000000

export const PALA_MARKET_LINK =
  'https://pala.world/square/project/1A5b19b69266Bf47c642E5A9256fE87e38283A56'

export const OPENSEA_LINK = 'https://opensea.io/collection/ronyclub-parts-nft'

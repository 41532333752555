import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

export type ConnectedWalletStateType = {
  type: 'klip' | 'kaikas' | 'bitkeep' | undefined
  address: string | undefined
}

export const connectedWalletAtom = atom<ConnectedWalletStateType>({
  key: 'connectedWallet',
  default: {
    type: undefined,
    address: undefined,
  },
})

export const useManageConnectedWalletState = (): {
  connectedWalletState: ConnectedWalletStateType
  setConnectedWalletState: SetterOrUpdater<ConnectedWalletStateType>
  resetConnectedWalletState: Resetter
} => {
  const [connectedWalletState, setConnectedWalletState] =
    useRecoilState(connectedWalletAtom)
  const resetConnectedWalletState = useResetRecoilState(connectedWalletAtom)

  return {
    connectedWalletState,
    setConnectedWalletState,
    resetConnectedWalletState,
  }
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const WidgetWrapper = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`

const CommunityMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  width: 240px;
  margin-top: 11px;
  padding: 8px 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);

  img {
    width: 22px;
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: calc(100% - 11px);
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
  }
`

const HeaderCommunityMenuWrapper = styled.div<{ language: string }>`
  display: grid;
  place-items: center;
  position: relative;
  height: 100%;
  margin-left: ${({ language }) => (language === 'ko' ? '30' : '25')}px;
  font-size: 14px;
  color: #2e2e2e;
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }

  &:hover ${WidgetWrapper} {
    display: grid;
  }
`

const HeaderCommunityMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Header',
  })

  return (
    <HeaderCommunityMenuWrapper language={language}>
      {t('menu.7')}
      <WidgetWrapper>
        <CommunityMenu>
          <a
            href="https://documents.neuronswap.com/neuronswap/"
            target="_blank"
          >
            <img
              src="/dexAssets/header_icons/community_icons/docs.png"
              alt="Community Icon"
            />
          </a>
          <a href="https://medium.com/@neuronswap" target="_blank">
            <img
              src="/dexAssets/header_icons/community_icons/medium.png"
              alt="Community Icon"
            />
          </a>
          <a href="https://twitter.com/neuronswap" target="_blank">
            <img
              src="/dexAssets/header_icons/community_icons/twitter.png"
              alt="Community Icon"
            />
          </a>
          <a href="https://t.me/NEURONswap" target="_blank">
            <img
              src="/dexAssets/header_icons/community_icons/telegram.png"
              alt="Community Icon"
            />
          </a>
          <a href="mailto:hello@neuronswap.com">
            <img
              src="/dexAssets/header_icons/community_icons/mail.png"
              alt="Community Icon"
            />
          </a>
        </CommunityMenu>
      </WidgetWrapper>
    </HeaderCommunityMenuWrapper>
  )
}

export default HeaderCommunityMenu

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

type ReferralRankItemType = {
  address: string
  inviteeCount: number
  totalSpendingByInvitees: number
}

type ReferralsRankType = {
  data: {
    nWallets: ReferralRankItemType[]
  }
}

const loadReferralsTop = async () =>
  await AxiosClient.getAxiosInstance(false).get(
    `/api/wallets/referrals/rank/top/10`,
  )

export default function useReferralsTopQuery() {
  return useQuery<ReferralsRankType['data']>(
    'referralsTop',
    async () => {
      const {
        data: { data },
      } = await loadReferralsTop()

      return data
    },
    {
      enabled: AxiosClient.isInitialized(),
      staleTime: 1000 * 60,
    },
  )
}

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

type WalletType = {
  data: {
    wallet: {
      address: string
      inviterAddress: string | null
    }
  }
}

const loadWallet = async (address: string) =>
  await AxiosClient.getAxiosInstance(false).get(`/api/wallets/${address}`)

export default function useWalletQuery(address?: string) {
  return useQuery<WalletType['data']>(
    'wallet',
    async () => {
      const {
        data: { data },
      } = await loadWallet(address as string)

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )
}

import { useState, MouseEvent } from 'react'
import styled from 'styled-components'
import SlicedDecimalTooltip, {
  SlicedDecimalTooltipWrapper,
  SlicedDecimalTooltipProps,
} from './SlicedDecimalTooltip'
import MobilePopup from '../molecules/MobilePopup'
import convertNumber from '../../../utils/convertNumber'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type SlicedDecimalProps = {
  maxIntLength?: number
  isNotDecimal?: boolean
  showTooltip?: boolean
  prefix?: string
  suffix?: string
  customTooltipPosition?: SlicedDecimalTooltipProps['customTooltipPosition']
  children: string
}

const SlicedDecimalWrapper = styled.div`
  position: relative;
  text-align: left;

  &:hover ${SlicedDecimalTooltipWrapper} {
    display: block;
  }
`

const MobilePopupItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
`

const SlicedDecimal = ({
  maxIntLength = 10,
  isNotDecimal,
  showTooltip = true,
  prefix = '',
  suffix = '',
  customTooltipPosition,
  children,
}: SlicedDecimalProps) => {
  const { width } = useGetWindowSize()
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowPopup = () => setIsVisiblePopup(true)
  const handleClosePopup = (event: MouseEvent) => {
    event.stopPropagation()
    setIsVisiblePopup(false)
  }

  if (children.split('.')[0].length > maxIntLength) {
    const integer = children.split('.')[0]

    const difference = integer.length - maxIntLength

    const slicedInteger = convertNumber(
      integer.substring(0, isNotDecimal ? maxIntLength : maxIntLength + 1) +
        '0'.repeat(difference - 1),
      { comma: true },
    )

    const convertedDecimal = slicedInteger.substring(
      0,
      slicedInteger.length -
        difference -
        Math.floor((difference - 1) / 3.0) +
        1,
    )

    if (width <= 768)
      return (
        <SlicedDecimalWrapper onClick={handleShowPopup}>
          {`${convertedDecimal}..`}

          <MobilePopup
            type="normal"
            isVisible={isVisiblePopup}
            handleClose={handleClosePopup}
          >
            <MobilePopupItem>
              {prefix}
              {convertNumber(children, { comma: true })}
              {suffix}
            </MobilePopupItem>
          </MobilePopup>
        </SlicedDecimalWrapper>
      )

    return (
      <SlicedDecimalWrapper>
        {`${convertedDecimal}..`}
        {showTooltip ? (
          <SlicedDecimalTooltip customTooltipPosition={customTooltipPosition}>
            {prefix}
            {convertNumber(children, { comma: true })}
            {suffix}
          </SlicedDecimalTooltip>
        ) : null}
      </SlicedDecimalWrapper>
    )
  }

  return (
    <SlicedDecimalWrapper>
      {convertNumber(children, { comma: true })}
    </SlicedDecimalWrapper>
  )
}

export default SlicedDecimal

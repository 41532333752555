import * as React from 'react'
import { motion } from 'framer-motion'

type BouncyProps = {
  children: React.ReactNode
  variation: number
  duration: number
  delay?: number
  style?: React.CSSProperties
  className?: string
  opacity?: number[]
}

export default function Bouncy({
  children,
  variation,
  duration = 1.5,
  delay = 0,
  style,
  className,
  opacity = [],
}: BouncyProps) {
  return (
    <motion.div
      {...{ style }}
      animate={{
        y: [variation, -variation],
        x: [variation, -variation],
        opacity,
      }}
      transition={{
        delay,
        duration,
        type: 'spring',
        repeat: Infinity,
        repeatType: 'mirror',
      }}
      className={className}
    >
      {children}
    </motion.div>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import produce from 'immer'
import { useManageTokenSelectModalState } from '../../../state/modal/tokenSelectModalState'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import TokenModalSectionListItem from '../atoms/TokenModalSectionListItem'
import useTokenListQuery from '../../../api/query/useTokenListQuery'

const TokenModalSectionListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`

const TokenModalSectionFavoriteList = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const {
    tokenSelectModalState: { position },
    resetTokenSelectModalState,
  } = useManageTokenSelectModalState()
  const {
    swapFormState: {
      settings: { isMax },
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const { status, data } = useTokenListQuery()

  const favoriteTokenList = useMemo(
    () =>
      status === 'success' && data !== undefined ? data.tokens.slice(0, 3) : [],
    [status, data],
  )

  const selectToken = (tokenAddress: string) => {
    if (status !== 'success' || data === undefined) return

    setSwapFormState(prev =>
      produce(prev, draft => {
        const isAlreadySelect =
          prev.form[position === 'from' ? 'to' : 'from'].address ===
          tokenAddress

        if (isAlreadySelect) {
          const { from, to } = prev.form

          const toToken = data.tokens.find(
            ({ address }) => address === to.address,
          )

          if (toToken === undefined) return draft

          if (isMax) {
            draft.form.from = {
              address: to.address,
              value: toToken.balance,
            }
            draft.form.to = from
            draft.form.lastModified = 'from'
          } else {
            draft.form.from = to
            draft.form.to = from
            draft.form.lastModified =
              prev.form.lastModified === 'from' ? 'to' : 'from'
          }
        } else {
          draft.form[position].address = tokenAddress

          if (position === 'from' && isMax) {
            const token = data.tokens.find(
              ({ address }) => address === tokenAddress,
            )

            if (token === undefined) return draft

            draft.form.from.value = token.balance
            draft.form.lastModified = 'from'
          }
        }

        return draft
      }),
    )
    resetTokenSelectModalState()
  }

  return (
    <TokenModalSectionListWrapper>
      {favoriteTokenList.map(({ address, symbol, korName, engName }) => (
        <TokenModalSectionListItem
          address={address}
          symbol={symbol}
          name={language === 'ko' ? korName : engName}
          onClick={() => selectToken(address)}
          key={address}
        />
      ))}
    </TokenModalSectionListWrapper>
  )
}

export default TokenModalSectionFavoriteList

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import { useManageDepositRequestModalState } from '../../state/modal/depositRequestModalState'
import { useManageDepositFormState } from '../../state/pool/depositFormState'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import { CONTRACT_ADDRESS, WKLAY_TOKEN_ADDRESS } from '../../contract/address'
import { TOKEN_ABI } from '../../contract/abi'
import convertNumber from '../../utils/convertNumber'
import { CSS_Z_INDEX } from '../../utils/constants'
import useGetWindowSize from '../../hooks/useGetWindowSize'
import useTokenApprove from '../../hooks/useTokenApprove'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useAddPoolLiquidity from '../../hooks/useAddPoolLiquidity'
import { PoolType } from '../../api/query/usePoolQuery'
import caver from '../../modules/network/caver'

type TokenApproveKeyType = 'token0' | 'token1'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 580px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 520px;
    padding: 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  margin-bottom: auto;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    padding: 19px 0 17px;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const TokenApproveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

const TransactionButton = styled.button<{ $isActive: boolean }>`
  width: 100%;
  margin-top: 14px;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #${({ $isActive }) => ($isActive ? '65dfb9' : '9d9999')};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  transition: 0.15s all;

  @media (max-width: 768px) {
    margin-top: 8px;
  }
`

const DepositRequestModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.DepositRequestModal',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    depositRequestModalState: { pairAddress },
    resetDepositRequestModalState,
  } = useManageDepositRequestModalState()
  const {
    depositFormState: { deposit },
    resetDepositFormState,
  } = useManageDepositFormState()
  const handleTokenApprove = useTokenApprove()
  const handleAddLiquidity = useAddPoolLiquidity(pairAddress)
  const pushMessage = useCallMessageBox()
  const { width } = useGetWindowSize()
  const [tokensApprove, setTokensApprove] = useState<{
    [key in TokenApproveKeyType]: boolean
  }>({ token0: false, token1: false })
  const queryClient = useQueryClient()

  const { tokens } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const approveSuccessCallback = (target: TokenApproveKeyType) => {
    setTokensApprove(prev => ({ ...prev, [target]: true }))
    pushMessage('request', 'success')
  }

  const approveFailureCallback = () => {
    resetDepositRequestModalState()
    pushMessage('request', 'failure')
  }

  const addLiquiditySuccessCallback = () => {
    queryClient.invalidateQueries(['token', tokens[0].address])
    queryClient.invalidateQueries(['token', tokens[1].address])
    queryClient.invalidateQueries(['pool', pairAddress])
    resetDepositRequestModalState()
    resetDepositFormState()
    pushMessage('request', 'success')
  }

  const addLiquidityFailureCallback = () => pushMessage('request', 'failure')

  const handleLoadTokenApproved = async () => {
    const token0Contract = new caver.klay.Contract(TOKEN_ABI, tokens[0].address)
    const token1Contract = new caver.klay.Contract(TOKEN_ABI, tokens[1].address)

    const [token0, token1] = await Promise.all([
      token0Contract.methods
        .allowance(address, CONTRACT_ADDRESS.router)
        .call() as string,
      token1Contract.methods
        .allowance(address, CONTRACT_ADDRESS.router)
        .call() as string,
    ])

    setTokensApprove({
      token0: parseInt(token0) > 0 || tokens[0].address === WKLAY_TOKEN_ADDRESS,
      token1: parseInt(token1) > 0 || tokens[1].address === WKLAY_TOKEN_ADDRESS,
    })
  }

  useEffect(() => {
    handleLoadTokenApproved()
  }, [])

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={resetDepositRequestModalState}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div>Token 1</div>
            <div>
              {width > 768 ? (
                convertNumber(deposit.from, { maxDigit: 6, comma: true })
              ) : (
                <SlicedDecimal maxIntLength={12} showTooltip={false}>
                  {convertNumber(deposit.from, { maxDigit: 6 })}
                </SlicedDecimal>
              )}
              <span>{tokens[0].symbol}</span>
            </div>
          </TransactionInfoItem>
          <TransactionInfoItem>
            <div>Token 2</div>
            <div>
              {width > 768 ? (
                convertNumber(deposit.to, { maxDigit: 6, comma: true })
              ) : (
                <SlicedDecimal maxIntLength={12} showTooltip={false}>
                  {convertNumber(deposit.to, { maxDigit: 6 })}
                </SlicedDecimal>
              )}{' '}
              <span>{tokens[1].symbol}</span>
            </div>
          </TransactionInfoItem>
        </TransactionInfo>

        <TokenApproveButtonWrapper>
          <TransactionButton
            $isActive={!tokensApprove.token0}
            onClick={
              !tokensApprove.token0
                ? () =>
                    handleTokenApprove(
                      tokens[0].address,
                      CONTRACT_ADDRESS.router,
                      () => approveSuccessCallback('token0'),
                      approveFailureCallback,
                    )
                : undefined
            }
          >
            {t(`button.token1.${String(tokensApprove.token0)}`)}
          </TransactionButton>
          <TransactionButton
            $isActive={tokensApprove.token0 && !tokensApprove.token1}
            onClick={
              tokensApprove.token0 && !tokensApprove.token1
                ? () =>
                    handleTokenApprove(
                      tokens[1].address,
                      CONTRACT_ADDRESS.router,
                      () => approveSuccessCallback('token1'),
                      approveFailureCallback,
                    )
                : undefined
            }
          >
            {t(`button.token2.${String(tokensApprove.token1)}`)}
          </TransactionButton>
        </TokenApproveButtonWrapper>

        <TransactionButton
          $isActive={tokensApprove.token0 && tokensApprove.token1}
          onClick={
            tokensApprove.token0 && tokensApprove.token1
              ? () =>
                  handleAddLiquidity(
                    addLiquiditySuccessCallback,
                    addLiquidityFailureCallback,
                  )
              : undefined
          }
        >
          {t(`button.deposit`)}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default DepositRequestModal

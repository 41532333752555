import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import MobileHeaderMenu from '../molecules/MobileHeaderMenu'
import HeaderConnectedWallet from '../molecules/HeaderConnectedWallet'
import { useManageMobileMenuState } from '../../../state/modal/mobileMenuState'
import { SCROLL_DELTA, CSS_Z_INDEX } from '../../../utils/constants'
import { PoolType } from '../../../api/query/usePoolQuery'

const HeaderWrapper = styled.div<{
  isScrollDown: string
  isDepositPage: string
}>`
  position: fixed;
  top: ${({ isScrollDown, isDepositPage }) =>
    isScrollDown === 'true' && isDepositPage !== 'true' ? '-60px' : '0'};
  left: 0;
  z-index: ${CSS_Z_INDEX.header};
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  transition: 0.2s all;

  @media (max-width: 768px) {
    padding: ${({ isDepositPage }) =>
      isDepositPage === 'true' ? '0 20px' : '0'};
  }
`

const HeaderContents = styled.div<{ isDepositPage?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;

  &:first-child {
    padding: ${({ isDepositPage }) =>
      isDepositPage === 'true' ? '0 20px' : '20px 20px 11px'};
  }

  @media (max-width: 768px) {
    overflow-x: auto;

    &:last-child {
      padding: 0;
    }
  }
`

const HeaderLogo = styled(Link)`
  display: grid;
  place-items: center;
  width: 166px;
  margin-right: auto;
  cursor: pointer;

  img {
    width: 100%;
  }
`

const MobileDepositInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;

  img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-bottom: 1.5px;
  }
`

const MobileHeaderMenuButton = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
`

const MobileHeader = () => {
  const { pairAddress } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setMobileMenuState } = useManageMobileMenuState()
  const [{ currentScrollTop, lastScrollTop }, setScrollTop] = useState<{
    currentScrollTop: number
    lastScrollTop: number
  }>({ currentScrollTop: window.scrollY, lastScrollTop: window.scrollY })
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false)
  const data = queryClient.getQueryData<PoolType['data']>(['pool', pairAddress])

  const handleShowMobileMenu = () => setMobileMenuState(true)

  const animateHeaderByScroll = () => {
    const currentScrollTop = window.scrollY
    setScrollTop(prev => ({ ...prev, currentScrollTop }))
  }

  useEffect(() => {
    window.addEventListener('scroll', animateHeaderByScroll)

    return () => window.removeEventListener('scroll', animateHeaderByScroll)
  }, [])

  useEffect(() => {
    if (Math.abs(currentScrollTop - lastScrollTop) <= SCROLL_DELTA) return

    if (currentScrollTop < lastScrollTop || currentScrollTop <= 56)
      setIsScrollDown(false)
    else setIsScrollDown(true)

    setScrollTop(prev => ({ ...prev, lastScrollTop: currentScrollTop }))
  }, [currentScrollTop, lastScrollTop])

  return (
    <HeaderWrapper
      isScrollDown={String(isScrollDown)}
      isDepositPage={String(pairAddress !== undefined)}
    >
      <HeaderContents isDepositPage={String(pairAddress !== undefined)}>
        {pairAddress === undefined ? (
          <HeaderLogo to="/">
            <img src="/dexAssets/neuron_logo.png" alt="Neuron Logo" />
          </HeaderLogo>
        ) : (
          <MobileDepositInfo>
            <img
              onClick={() => navigate(-1)}
              src="/dexAssets/header_icons/mobile_deposit_header_icon.png"
              alt="Prev Icon"
            />
            {data !== undefined
              ? `${data.tokens[0].symbol} + ${data.tokens[1].symbol}`
              : ''}
          </MobileDepositInfo>
        )}

        <HeaderConnectedWallet />
        <MobileHeaderMenuButton
          src="/dexAssets/header_icons/mobile_header_button_icon.png"
          alt="Mobile Header Button Icon"
          onClick={handleShowMobileMenu}
        />
      </HeaderContents>

      {pairAddress === undefined ? (
        <HeaderContents>
          <MobileHeaderMenu />
        </HeaderContents>
      ) : null}
    </HeaderWrapper>
  )
}

export default MobileHeader

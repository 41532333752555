import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const API_KEY = process.env.REACT_APP_API_KEY as string

export default class AxiosClient {
  private static axiosInstance: AxiosInstance | undefined
  private static accessToken: string

  public static initialize(
    throttlingCallback: () => void,
    expiredCallback: () => void,
  ) {
    AxiosClient.axiosInstance = axios.create({
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
      },
    })

    AxiosClient.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const {
          config,
          response: {
            status,
            data: { message },
          },
        } = error

        if (status === 429 && message === 'Too many requests') {
          throttlingCallback()
          return Promise.resolve()
        }

        if (status === 401 && message === 'Access token has expired') {
          const axiosInstance = AxiosClient.getAxiosInstance(false)

          if (axiosInstance === undefined) return Promise.reject(error)

          const originalRequest = config
          const {
            data: {
              data: { accessToken },
            },
          } = await axiosInstance.post('/api/sessions/refresh', {})

          AxiosClient.setAccessToken(accessToken as string)

          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          return axios(originalRequest as AxiosRequestConfig)
        }

        if (status === 401 && message === 'Refresh token has expired') {
          AxiosClient.setAccessToken('')
          expiredCallback()
          return Promise.resolve()
        }

        return Promise.reject(error)
      },
    )
  }

  public static getAxiosInstance(
    isJwt: boolean,
    isJwtRequired = true,
  ): AxiosInstance {
    if (this.axiosInstance === undefined) return axios

    const authorizationValue =
      isJwt && (isJwtRequired || AxiosClient.accessToken)
        ? `Bearer ${AxiosClient.accessToken}`
        : `Basic ${Buffer.from(API_KEY).toString('base64')}`

    this.axiosInstance.defaults.headers.common['Authorization'] =
      authorizationValue

    return this.axiosInstance
  }

  public static setAccessToken(accessToken: string) {
    AxiosClient.accessToken = accessToken
  }

  public static isInitialized() {
    return this.axiosInstance !== undefined
  }
}

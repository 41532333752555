import { ChangeEvent, useMemo, WheelEvent } from 'react'
import styled from 'styled-components'
import produce from 'immer'
import { useManageDepositFormState } from '../../../state/pool/depositFormState'
import { InputPositionType } from '../../../utils/types'
import convertNumber from '../../../utils/convertNumber'
import { INPUT_REGEX, LP_INPUT_REGEX } from '../../../utils/constants'

type AppendPoolItemInputProps = {
  type: 'deposit' | 'withdraw'
  position: InputPositionType
}

const SwapMainItemInputWrapper = styled.input<{ isError: string }>`
  width: 200px;
  border: 0;
  background: transparent;
  font-size: 22px;
  font-weight: 700;
  text-align: right;
  color: #${({ isError }) => (isError === 'true' ? 'b95955' : '000000')};
  outline: none;
  line-height: 1.27;

  &::placeholder {
    color: #000000;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 155px;
    font-size: 18px;
  }
`

const DepositMainItemInput = ({ type, position }: AppendPoolItemInputProps) => {
  const {
    depositFormState: {
      deposit,
      withdraw,
      settings: { firstIsMaxAmount, secondIsMaxAmount, withdrawIsMaxAmount },
      error,
    },
    setDepositFormState,
  } = useManageDepositFormState()

  const inputValue = useMemo(() => {
    if (type === 'deposit')
      return INPUT_REGEX.exec(deposit[position])
        ? deposit[position]
        : convertNumber(deposit[position], { maxDigit: 6 })
    else
      return LP_INPUT_REGEX.exec(withdraw)
        ? withdraw
        : convertNumber(withdraw, { maxDigit: 8 })
  }, [type, position, deposit, withdraw])

  const handleChangeAutoScroll = (event: ChangeEvent<HTMLInputElement>) => {
    const validator = type === 'deposit' ? INPUT_REGEX : LP_INPUT_REGEX

    if (event.target.value !== '' && !validator.exec(event.target.value)) return

    setDepositFormState(prev =>
      produce(prev, draft => {
        if (type === 'deposit') {
          draft.deposit.lastModified = position
          draft.deposit[position] = event.target.value
        } else draft.withdraw = event.target.value

        draft.isUserEntered = true

        return draft
      }),
    )
    event.currentTarget.scrollTo(event.currentTarget.scrollWidth, 0)
  }

  const blockScrollInput = (event: WheelEvent<HTMLInputElement>) =>
    event.currentTarget.blur()

  return (
    <SwapMainItemInputWrapper
      type="number"
      value={inputValue}
      readOnly={firstIsMaxAmount || secondIsMaxAmount || withdrawIsMaxAmount}
      isError={String(type === 'deposit' ? error[position] : error.withdraw)}
      placeholder="0"
      onChange={handleChangeAutoScroll}
      onWheel={blockScrollInput}
    />
  )
}

export default DepositMainItemInput

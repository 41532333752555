export default function getAppliedSlippageValue(
  value: string | number,
  slippage: string,
  type: 'min' | 'max',
) {
  if (value === '') return '0'

  const slippageByType =
    type === 'min'
      ? 1 - parseFloat(slippage) / 100
      : 1 + parseFloat(slippage) / 100

  return (
    (typeof value === 'string' ? parseFloat(value) : value) * slippageByType
  ).toFixed(18)
}

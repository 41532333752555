import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import convertNumber from '../../../utils/convertNumber'

type DashboardFiguresCardProps = {
  circulatingSupply: number
  totalStakedNeuron: number
  buybackFund: number
}

const DashboardFiguresCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 162px;
  padding: 22px 30px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    padding: 16px 17px;
  }
`

const DashboardFiguresCardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
`

const TotalStakingNeuron = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #656565;
`

const Contents = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #9d9999;

  span {
    margin-left: 4px;
    font-weight: 400;
  }
`

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background: #edeaea;
`

const DashboardFiguresCard = ({
  circulatingSupply,
  totalStakedNeuron,
  buybackFund,
}: DashboardFiguresCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardCardTemplate',
  })

  return (
    <DashboardFiguresCardWrapper>
      <DashboardFiguresCardItem>
        <Title>{t('totalNRStakedDividedByCirculatingSupply')}</Title>
        <Contents>
          <TotalStakingNeuron>
            {convertNumber(totalStakedNeuron, { comma: true, maxDigit: 0 })}
            &nbsp;/&nbsp;
          </TotalStakingNeuron>
          {convertNumber(circulatingSupply, { comma: true, maxDigit: 0 })}
          <span>NR</span>
        </Contents>
      </DashboardFiguresCardItem>

      <StyledLine />

      <DashboardFiguresCardItem>
        <Title>{t('stakingRatio')}</Title>
        <Contents>
          {convertNumber(
            circulatingSupply !== 0
              ? (totalStakedNeuron / circulatingSupply) * 100
              : 0,
            {
              isPercentage: true,
            },
          )}{' '}
          %
        </Contents>
      </DashboardFiguresCardItem>

      <StyledLine />

      <DashboardFiguresCardItem>
        <Title>{t('buybackFund')}</Title>
        <Contents>
          $ {convertNumber(buybackFund, { comma: true, maxDigit: 0 })}
        </Contents>
      </DashboardFiguresCardItem>
    </DashboardFiguresCardWrapper>
  )
}

export default DashboardFiguresCard

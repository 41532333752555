import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import produce from 'immer'
import { SwapFormStateType } from '../../../state/swap/swapFormState'
import { DepositFormStateType } from '../../../state/pool/depositFormState'
import { SetterOrUpdater } from 'recoil'

type SettingSlippageProps = {
  type: 'swap' | 'deposit'
  slippage: string
  setter:
    | SetterOrUpdater<SwapFormStateType>
    | SetterOrUpdater<DepositFormStateType>
  mobileSetter?: Dispatch<SetStateAction<string>>
}

const SettingSlippageWrapper = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: ${({ language }) =>
      language === 'ko' ? '40px 40px auto' : '48px 48px auto'} 14px;
  grid-gap: 4px;
  align-items: stretch;
  width: 180px;
  height: 30px;
  padding: 5px ${({ language }) => (language === 'ko' ? '14' : '8')}px 5px 5px;
  border: 1px solid #d1cbcb;
  border-radius: 15px;
  background: #ffffff;
  font-weight: 700;

  @media (max-width: 768px) {
    grid-template-columns: 72px 72px auto 18px;
    grid-gap: 8px;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 9px 16px 9px 9px;
    border-radius: 23px;

    span {
      font-size: 18px;
    }
  }
`

const SlippageSettingButton = styled.div<{
  $isActive: boolean
  language: string
}>`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  width: ${({ language }) => (language === 'ko' ? '40' : '48')}px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #9d9999;
  background: ${({ $isActive }) => ($isActive ? '#9d9999' : 'transparent')};
  font-size: 11px;
  color: #${({ $isActive }) => ($isActive ? 'ffffff' : '9d9999')};
  cursor: pointer;
  transition: 0.15s all;

  @media (max-width: 768px) {
    width: 72px;
    height: 28px;
    border-radius: 14px;
    font-size: 14px;
    font-weight: 700;
  }
`

const SlippageInput = styled.input`
  display: flex;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: right;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const SettingSlippage = ({
  type,
  slippage,
  setter,
  mobileSetter,
}: SettingSlippageProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapMain.setting.button',
  })

  const handleChangeSlippage = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      (event.target.value !== '' &&
        !RegExp(/^([0-9]{1,2})(\.[0-9]{0,2})?$/i).exec(event.target.value)) ||
      parseFloat(event.target.value) > 50
    )
      return

    if (mobileSetter !== undefined) {
      mobileSetter(event.target.value)
      return
    }

    if (type === 'swap') {
      ;(setter as SetterOrUpdater<SwapFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = event.target.value
          return draft
        }),
      )
    } else if (type === 'deposit') {
      ;(setter as SetterOrUpdater<DepositFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = event.target.value
          return draft
        }),
      )
    }
  }

  const handleBlurSlippageInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') handleToggleDefaultSlippage()
  }

  const handleToggleDefaultSlippage = () => {
    if (mobileSetter !== undefined) {
      mobileSetter('0.50')
      return
    }

    if (type === 'swap') {
      ;(setter as SetterOrUpdater<SwapFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = '0.50'
          return draft
        }),
      )
    } else if (type === 'deposit') {
      ;(setter as SetterOrUpdater<DepositFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = '0.50'
          return draft
        }),
      )
    }
  }

  const handleToggleMaxSlippage = () => {
    if (mobileSetter !== undefined) {
      mobileSetter('50.00')
      return
    }

    if (type === 'swap') {
      ;(setter as SetterOrUpdater<SwapFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = '50.00'
          return draft
        }),
      )
    } else if (type === 'deposit') {
      ;(setter as SetterOrUpdater<DepositFormStateType>)(prev =>
        produce(prev, draft => {
          draft.settings.slippage = '50.00'
          return draft
        }),
      )
    }
  }

  return (
    <SettingSlippageWrapper language={language}>
      <SlippageSettingButton
        $isActive={slippage !== '' && parseFloat(slippage) === 0.5}
        language={language}
        onClick={handleToggleDefaultSlippage}
      >
        {t('0')}
      </SlippageSettingButton>
      <SlippageSettingButton
        $isActive={slippage !== '' && parseFloat(slippage) === 50}
        language={language}
        onClick={handleToggleMaxSlippage}
      >
        {t('1')}
      </SlippageSettingButton>
      <SlippageInput
        type="number"
        value={slippage}
        onChange={handleChangeSlippage}
        onBlur={handleBlurSlippageInput}
      />
      <span>%</span>
    </SettingSlippageWrapper>
  )
}

export default SettingSlippage

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'
import { TokenType } from './useTokenQuery'

type PoolItemType = {
  address: string
  pid: number
  totalLP: number
  depositRate: [number, number]
  exchangeRate: [number, number]
  totalLiquidity: number
  rewardsDetail: {
    distributedNRPerDay: number
    nrAirdrop: number
    tradeFeeApr: number
    apr: number
    apy: number
  }
  userData: {
    depositedLpBalance: string
    stakedLpBalance: string
    claimableNeuron: number
    claimableNeuronPrice: number
    sharedOf: string
  }
  tokens: TokenType[]
}

export type PoolType = {
  data: PoolItemType
}

const loadPool = async (address: string) =>
  await AxiosClient.getAxiosInstance(true, false).get(`/api/pools/${address}`)

export default function usePoolQuery(address?: string) {
  return useQuery<PoolType['data']>(
    ['pool', address],
    async () => {
      const {
        data: { data },
      } = await loadPool(address as string)

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      retry: false,
      staleTime: 1000 * 30,
      refetchOnWindowFocus: true,
    },
  )
}

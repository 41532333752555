import { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import useHandleClickOutside from '../../../hooks/useHandleClickOutSide'
import { CSS_Z_INDEX } from '../../../utils/constants'

type SelectBoxProps = {
  value: number
  handleSelect: (sortedBy: number) => void
}

const SelectBoxWrapper = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  margin-left: auto;
  background: transparent;
`

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 30px;
  padding: 0 10px;
  border-radius: 15px;
  border: 0.5px solid #d1cbcb;
  background: #ffffff;
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
  cursor: pointer;
  outline: none;

  img {
    width: 16px;
    height: 16px;
  }
`

const MobileDimmed = styled.div`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${CSS_Z_INDEX.popup};
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
`

const DropdownMenu = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1;
  overflow: hidden;
  width: 160px;
  border-radius: 15px;
  border: 0.5px solid #d1cbcb;
  background: #ffffff;

  @media (max-width: 768px) {
    top: unset;
    bottom: 0;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
`

const DropdownMenuItem = styled.div<{ highlight: string }>`
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
  color: #9d9999;
  cursor: pointer;

  & + & {
    border-top: 1px solid #d1cbcb;
  }

  &:hover {
    background: #edeaea;
  }

  @media (max-width: 768px) {
    display: grid;
    place-items: center;
    height: 50px;
    padding: 0;
    font-size: 14px;
    text-align: center;

    ${({ highlight }) =>
      highlight === 'true'
        ? `
      font-weight: 700;
      color: #ff6d68;
    `
        : ''}
  }
`

const StakingListSelectBox = ({ value, handleSelect }: SelectBoxProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingList.sortBy',
  })
  const { ref, buttonRef, isVisible, toggleComponent } = useHandleClickOutside()
  const { width } = useGetWindowSize()

  const selectItem = (sortedBy: number) => {
    handleSelect(sortedBy)
    toggleComponent()
  }

  useEffect(() => {
    if (!isVisible || width > 768) return

    document.body.style.cssText = `overflow: hidden; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `overflow: unset; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [isVisible, width])

  return (
    <SelectBoxWrapper>
      <Select ref={buttonRef} onClick={toggleComponent}>
        {t(value.toString())}
        <img src="/dexAssets/select_box_icon.png" alt="Select Icon" />
      </Select>

      {isVisible ? (
        <MobileDimmed>
          <DropdownMenu ref={ref}>
            <DropdownMenuItem
              highlight={String(value === 1)}
              onClick={() => selectItem(value !== 1 ? 1 : 0)}
            >
              {t('1')}
            </DropdownMenuItem>
            <DropdownMenuItem
              highlight={String(value === 2)}
              onClick={() => selectItem(value !== 2 ? 2 : 0)}
            >
              {t('2')}
            </DropdownMenuItem>
            <DropdownMenuItem
              highlight={String(value === 3)}
              onClick={() => selectItem(value !== 3 ? 3 : 0)}
            >
              {t('3')}
            </DropdownMenuItem>
          </DropdownMenu>
        </MobileDimmed>
      ) : null}
    </SelectBoxWrapper>
  )
}

export default StakingListSelectBox

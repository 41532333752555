import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReferralClaimRewards from '../organisms/ReferralClaimRewards'
import ReferralClaimPolicy from '../organisms/ReferralClaimPolicy'

const ReferralRewardsTemplateWrapper = styled.div`
  margin-top: 100px;
`

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  color: #ff6d68;

  @media (max-width: 768px) {
    margin-bottom: 23px;
  }
`

const RewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 645px;
  padding: 27px 40px 40px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    height: 563px;
    padding: 27px 24px 30px;
  }
`

const ReferralRewardsTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralRewards',
  })

  return (
    <ReferralRewardsTemplateWrapper>
      <Title>{t('title')}</Title>

      <RewardsWrapper>
        <ReferralClaimRewards />
        <ReferralClaimPolicy />
      </RewardsWrapper>
    </ReferralRewardsTemplateWrapper>
  )
}

export default ReferralRewardsTemplate

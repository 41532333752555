import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DepositPoolInfoToken from '../molecules/DepositPoolInfoToken'
import DepositPoolInfoDetail from '../molecules/DepositPoolInfoDetail'
import DepositPoolInfoEstimatedReward from '../atoms/DepositPoolInfoEstimatedReward'
import usePoolQuery from '../../../api/query/usePoolQuery'

type DepositPoolInfoProps = {
  pairAddress: string
}

const PoolDepositTokenWrapper = styled.div<{ language: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 83px;
  padding: 0 30px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-gap: 18px;
    width: 100%;
    padding: 15px;
  }
`

const PoolDepositTokenLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const DepositPoolInfo = ({ pairAddress }: DepositPoolInfoProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const { data } = usePoolQuery(pairAddress)

  if (data === undefined) return null

  return (
    <PoolDepositTokenWrapper language={language}>
      <PoolDepositTokenLeftSide>
        <DepositPoolInfoToken tokens={data.tokens} />
        <DepositPoolInfoDetail
          totalLiquidity={data.totalLiquidity}
          rewardsDetail={data.rewardsDetail}
        />
      </PoolDepositTokenLeftSide>

      <DepositPoolInfoEstimatedReward apr={data.rewardsDetail.apr} />
    </PoolDepositTokenWrapper>
  )
}

export default DepositPoolInfo

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import { useManageReceiveRewardsModalState } from '../../state/modal/receiveRewardsModalState'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import { NR_SYMBOL } from '../../utils/constants'
import useWithdraw from '../../hooks/useWithdraw'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import convertNumber from '../../utils/convertNumber'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { masterchefContract } from '../../contract/contract'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 380px;
    padding: 24px;
  }
`

const RewardList = styled.div`
  margin-top: 59px;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-top: 30px;
    padding: 16px 0;
    border-color: #edeaea;
  }
`

const RewardsListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
    white-space: pre-line;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;
    line-height: 1.56;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const NoticeContent = styled.div`
  margin-top: 30px;
  font-size: 14px;
  color: #9d9999;
  line-height: 1.57;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const ReceiveRewardsButton = styled.button`
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: auto;
  padding: 20px;
  border-radius: 16px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const ReceiveRewardsModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.ReceiveRewardModal',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    receiveRewardsModalState: { pairAddress, pid },
    resetReceiveRewardsModalState,
  } = useManageReceiveRewardsModalState()
  const handleWithdraw = useWithdraw(pid)
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()
  const [claimableNeuron, setClaimableNeuron] = useState<string>('0')

  const handleCloseReceiveRewardModal = () => resetReceiveRewardsModalState()

  const withdrawSuccessCallback = () => {
    queryClient.invalidateQueries('tokenList')
    queryClient.invalidateQueries(['pool', pairAddress])
    handleCloseReceiveRewardModal()
    pushMessage('request', 'success')
  }

  const withdrawFailureCallback = () => pushMessage('request', 'failure')

  const loadClaimableNeuron = async () => {
    const claimableNeuron = (await masterchefContract.methods
      .pendingNeuron(pid, address)
      .call()) as string
    const convertedClaimableNeuron = getConvertFromPebValue(claimableNeuron, 18)

    setClaimableNeuron(convertedClaimableNeuron)
  }

  useEffect(() => {
    if (pid !== undefined) loadClaimableNeuron()
  }, [pid])

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={handleCloseReceiveRewardModal}>
          {t('title')}
        </ModalHead>

        <RewardList>
          <RewardsListItem>
            <div>{t('nrTokenReward')}</div>
            <div>
              <SlicedDecimal showTooltip={false}>
                {convertNumber(claimableNeuron, { comma: true, maxDigit: 6 })}
              </SlicedDecimal>
              <span>{NR_SYMBOL}</span>
            </div>
          </RewardsListItem>
        </RewardList>

        <NoticeContent>{t('description')}</NoticeContent>

        <ReceiveRewardsButton
          onClick={() =>
            handleWithdraw(
              'reward',
              withdrawSuccessCallback,
              withdrawFailureCallback,
            )
          }
        >
          {t('button')}
        </ReceiveRewardsButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default ReceiveRewardsModal

import styled from 'styled-components'
import SwapMain from '../organisms/SwapMain'
import SwapInfo from '../organisms/SwapInfo'

const SwapSectionWrapper = styled.div`
  max-width: 626px;
  width: 100%;
  margin: 110px auto 60px;

  @media (max-width: 768px) {
    margin: 0 auto 56px;
  }
`

const SwapTemplate = () => {
  return (
    <SwapSectionWrapper>
      <SwapMain />
      <SwapInfo />
    </SwapSectionWrapper>
  )
}

export default SwapTemplate

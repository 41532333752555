import styled from 'styled-components'

export type MyAssetsPortfolioListItemProps = {
  color: string
  symbol: string
  proportion: number
  balance: number
  value: number
}

const MyAssetsPortfolioListItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ColorIcon = styled.div<{ color: string }>`
  width: 18px;
  height: 18px;
  margin-right: 20px;
  border-radius: 50%;
  background: ${({ color }) => color};

  @media (max-width: 768px) {
    width: 17px;
    height: 17px;
    margin-right: 17px;
  }
`

const TokenName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const Percentage = styled.div`
  margin-left: auto;
  font-size: 14px;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const MyAssetsPortfolioListItem = ({
  color,
  symbol,
  proportion,
}: MyAssetsPortfolioListItemProps) => {
  return (
    <MyAssetsPortfolioListItemWrapper>
      <ColorIcon color={color} />
      <TokenName>{symbol}</TokenName>
      <Percentage>{proportion.toFixed(2)} %</Percentage>
    </MyAssetsPortfolioListItemWrapper>
  )
}

export default MyAssetsPortfolioListItem

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'
import { StakingTermType } from '../../utils/types'

export type StakingFormStateType = {
  isMax: boolean
  value: string
  term: StakingTermType
}

const stakingFormAtom = atom<StakingFormStateType>({
  key: 'stakingFormState',
  default: {
    isMax: false,
    value: '',
    term: -1,
  },
})

export const useManageStakingFormState = (): {
  stakingFormState: StakingFormStateType
  setStakingFormState: SetterOrUpdater<StakingFormStateType>
  resetStakingFormState: Resetter
} => {
  const [stakingFormState, setStakingFormState] =
    useRecoilState(stakingFormAtom)
  const resetStakingFormState = useResetRecoilState(stakingFormAtom)

  return { stakingFormState, setStakingFormState, resetStakingFormState }
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import HeaderChangeWalletStatus, {
  HeaderChangeWalletStatusWrapper,
} from '../atoms/HeaderChangeWalletStatus'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const HeaderConnectedWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 30px;
  height: 35px;
  border-radius: 17.5px;
  border: 1px solid #65dfb9;

  &:hover ${HeaderChangeWalletStatusWrapper} {
    display: flex;
  }

  @media (max-width: 768px) {
    width: 94px;
    height: 28px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
`

const WalletAddressText = styled.div`
  padding: 0 9px 0 10px;
  font-size: 13px;
  font-weight: 700;
  color: #9d9999;
  line-height: 1;

  @media (max-width: 768px) {
    padding: 0 0 0 7px;
    font-size: 10px;
    line-height: 1.5;
  }
`

const ConnectedWalletIcon = styled.div`
  display: grid;
  place-items: center;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 50%;
  border: solid 0.5px rgba(112, 112, 112, 0.1);

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    margin-left: auto;

    img {
      width: 16px;
      height: 16px;
    }
  }
`

const LoginButton = styled.button`
  margin-left: 30px;
  padding: 8px 44px;
  border: 0;
  border-radius: 15px;
  background: #ff6d68;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 94px;
    height: 28px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
`

const HeaderConnectedWallet = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Header',
  })
  const { width } = useGetWindowSize()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()

  const handleShowLoginModal = () => setConnectWalletModalState(true)

  if (type === undefined || address === undefined)
    return (
      <LoginButton onClick={handleShowLoginModal}>{t('login')}</LoginButton>
    )

  return (
    <HeaderConnectedWalletWrapper>
      <WalletAddressText>
        {`${address.substring(0, width > 768 ? 5 : 4)}...${address.substring(
          width > 768 ? address.length - 5 : address.length - 4,
        )}`}
      </WalletAddressText>

      <ConnectedWalletIcon>
        <img
          src={`/dexAssets/header_icons/${type}.png`}
          alt="Connected Wallet Logo"
        />
      </ConnectedWalletIcon>

      <HeaderChangeWalletStatus />
    </HeaderConnectedWalletWrapper>
  )
}

export default HeaderConnectedWallet

import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SearchInput from '../atoms/SearchInput'
import AvailableDepositItemSelectBox from '../atoms/AvailableDepositItemSelectBox'
import PoolListHead from '../molecules/PoolListHead'
import AvailableDepositList from '../organisms/AvailableDepositList'

const DepositableListSectionWrapper = styled.div`
  margin-top: 77px;

  @media (max-width: 768px) {
    margin: 0 0 56px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Title = styled.div`
  margin-right: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`

const RightSideWrapper = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: ${({ language }) =>
      language === 'ko' ? '180' : '216'}px auto;
  grid-gap: 20px;
  align-items: center;
  font-size: 12px;

  @media (max-width: 768px) {
    width: 100%;
    grid-gap: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
  }
`

const AppendFluidityPool = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 64px;
  margin: 65px auto 0;
  border-radius: 24px;
  background: #e8e8e8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  font-size: 16px;
  color: #9d9999;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;

  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }
`

const DepositableListSection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit',
  })

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [sortedBy, setSortedBy] = useState<string>('none')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value)

  const handleSelect = (sortedBy: string) => setSortedBy(sortedBy)

  return (
    <DepositableListSectionWrapper>
      <TitleWrapper>
        <Title>{t('title')}</Title>
        <RightSideWrapper language={language}>
          <SearchInput
            placeholder={t('search')}
            value={searchQuery}
            onChange={handleChange}
          />
          <AvailableDepositItemSelectBox
            value={sortedBy}
            handleSelect={handleSelect}
          />
        </RightSideWrapper>
      </TitleWrapper>

      <PoolListHead type="AvailableDeposit" />
      <AvailableDepositList searchQuery={searchQuery} sortedBy={sortedBy} />

      <AppendFluidityPool>
        <img
          src="/dexAssets/append_button_icon.png"
          alt="Append Fluidity Pool Button"
        />
        Coming Soon
      </AppendFluidityPool>
    </DepositableListSectionWrapper>
  )
}

export default DepositableListSection

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'
import { TokenType } from './useTokenQuery'

export type AvailablePoolItemType = {
  address: string
  pid: number
  totalLP: number
  depositRate: [number, number]
  totalLiquidity: number
  rewardsDetail: {
    distributedNRPerDay: number
    nrAirdrop: number
    tradeFeeApr: number
    apr: number
    apy: number
  }
  tokens: Pick<TokenType, 'address' | 'symbol' | 'engName' | 'korName'>[]
}

export type AvailableDepositPoolsType = {
  data: {
    pools: AvailablePoolItemType[]
  }
}

const loadAvailableDepositPools = async (
  searchQuery: string,
  sortBy: string,
) => {
  const query: { [key in string]: string } = {}

  if (searchQuery !== '') query['search'] = searchQuery
  if (sortBy !== 'none') query['sortBy'] = sortBy

  const stringifiedQuery =
    Object.keys(query).length !== 0
      ? '?' +
        Object.entries(query)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      : ''

  return await AxiosClient.getAxiosInstance(true, false).get(
    `/api/pools/available${stringifiedQuery}`,
  )
}

export default function useAvailableDepositPoolsQuery(
  searchQuery: string,
  sortBy: string,
) {
  return useQuery<AvailableDepositPoolsType['data']>(
    ['availableDepositPools', searchQuery, sortBy],
    async () => {
      const {
        data: { data },
      } = await loadAvailableDepositPools(searchQuery, sortBy)

      return data
    },
    {
      enabled: AxiosClient.isInitialized(),
      retry: false,
      staleTime: 1000 * 30,
      refetchOnWindowFocus: true,
    },
  )
}

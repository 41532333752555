import styled from 'styled-components'
import DepositMainWithdrawItemToken from '../atoms/DepositMainWithdrawItemToken'
import DepositMainItemInput from '../atoms/DepositMainItemInput'
import DepositMainItemBalance from '../atoms/DepositMainItemBalance'
import { TokenType } from '../../../api/query/useTokenQuery'
import { getConvertFromPebValue } from '../../../utils/convertTokenNumberData'

type DepositMainWithdrawItemProps = {
  tokens: TokenType[]
  stakedLpBalance: string
}

const DepositMainItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 30px;
  border-radius: 24px;
  background-color: #f8f8f8;

  & + & {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    height: 100px;
    padding: 0 16px;
  }
`

const DepositMainItemRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 17px 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0;
  }
`

const DepositMainWithdrawItem = ({
  tokens,
  stakedLpBalance,
}: DepositMainWithdrawItemProps) => {
  return (
    <DepositMainItemWrapper>
      <DepositMainWithdrawItemToken tokens={tokens} />

      <DepositMainItemRightSide>
        <DepositMainItemInput type="withdraw" position="from" />
        <DepositMainItemBalance
          balance={getConvertFromPebValue(stakedLpBalance, 18)}
          type="withdraw"
          position="from"
        />
      </DepositMainItemRightSide>
    </DepositMainItemWrapper>
  )
}

export default DepositMainWithdrawItem

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { Link } from 'react-router-dom'
import { newLineToBr } from '../../../utils/newLineToBr'

const Section = styled.section`
  background-color: #222232;
  padding: 134px 0 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 55px 0 52px;
  }
`

const Title = styled.img`
  height: inherit;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const Line = styled.div`
  height: 8px;
  background-color: #ff6d68;
  width: 100vw;
  position: absolute;
  transform: translateX(-50%);
  margin-left: 50%;
  z-index: 1;
  bottom: 30px;

  @media (max-width: 768px) {
    bottom: 20px;
    height: 6px;
  }
`

const ShortDesc = styled.img`
  margin: 62px 0 37px;
  height: 64px;
`

const MobileReferralDesc = styled.div`
  margin: 50px 0 30px;

  & > div + div {
    margin-top: 30px;
  }
`

const MobileReferralDescTitle = styled.div<{ color: string }>`
  font-family: 'itc-avant-garde-gothic-pro';
  font-size: 30px;
  font-weight: 700;
  color: ${({ color }) => color};
  text-align: center;
`

const MobileReferralDescContent = styled.div<{ color: string }>`
  margin-top: 10px;
  font-family: 'itc-avant-garde-gothic-pro';
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 1.5;

  span {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`

const MobileReferralImage = styled.img`
  width: 264px;
  margin: 43px 0 38px;
`

const LongDesc = styled.div`
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  opacity: 0.7;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 14px;
    color: #bdbdc2;
  }
`

const ReferralLinkButton = styled(Link)`
  width: 480px;
  margin-top: 70px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.32);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding: 12px 0;
  text-align: center;
  box-shadow: 0 0 24px 0 rgba(255, 109, 104, 0.4);
  background-color: #ff6d68;
  border-radius: 999px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  > img {
    margin-left: 10px;
    height: 47px;
  }

  @media (max-width: 768px) {
    width: 320px;
    margin-top: 38px;
    padding: 8px 0;
    font-size: 21px;

    img {
      height: 40px;
    }
  }
`

const MainReferralSection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Main.ReferralSection',
  })
  const { width } = useGetWindowSize()

  return (
    <Section>
      <div style={{ position: 'relative', height: width > 768 ? 182 : 111 }}>
        <Line />
        <Title src="/dexAssets/main_page/referral/title.png" />
      </div>

      {width > 768 ? (
        <ShortDesc
          src={`/dexAssets/main_page/referral/short-desc${
            language == 'en' ? '-en' : ''
          }.png`}
        />
      ) : language === 'en' ? (
        <MobileReferralDesc>
          <div>
            <MobileReferralDescTitle color="#ff6d68">
              INVITER
            </MobileReferralDescTitle>
            <MobileReferralDescContent color="#ff6d68">
              will be{' '}
              <span>
                rewarded 10% of
                <br />
                transaction fee
              </span>{' '}
              of invitee's wallet.
            </MobileReferralDescContent>
          </div>

          <div>
            <MobileReferralDescTitle color="#65dfb9">
              INVITEE
            </MobileReferralDescTitle>
            <MobileReferralDescContent color="#65dfb9">
              if accept invitation, will be receive
              <br />
              <span>lifetime 20% discount on transaction fee.</span>
            </MobileReferralDescContent>
          </div>
        </MobileReferralDesc>
      ) : (
        <MobileReferralImage
          src="/dexAssets/main_page/referral/ko_mobile_referral_desc.png"
          alt="Korean Referral Description"
        />
      )}

      <LongDesc
        dangerouslySetInnerHTML={{
          __html: newLineToBr(
            t(width > 768 ? 'description' : 'mobileDescription'),
          ),
        }}
      ></LongDesc>
      <ReferralLinkButton to="/referral">
        {t('button')}
        <img src="/dexAssets/main_page/referral/icon.png" />
      </ReferralLinkButton>
    </Section>
  )
}
export default MainReferralSection

import { useRef } from 'react'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { getResult } from 'klip-sdk'

export default function useCheckKlipConnected() {
  const {
    klipRequestKeyState: { requestKey, requestCallback },
    resetKlipRequestKeyState,
  } = useManageKlipRequestKeyState()
  const klipCallbackFlag = useRef<boolean>(false)

  const checkKlipWalletConnected = async () => {
    if (requestKey === undefined) return

    const { status, result } = await getResult(requestKey)

    if (status === 'completed' && !klipCallbackFlag.current) {
      klipCallbackFlag.current = true
      resetKlipRequestKeyState()

      if (requestCallback !== undefined) requestCallback(result)
    }
  }

  return checkKlipWalletConnected
}

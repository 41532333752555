import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CSS_Z_INDEX } from '../../../utils/constants'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { TokenType } from '../../../api/query/useTokenQuery'

type DepositMainWithdrawItemTokenProps = {
  tokens: TokenType[]
}

const DepositMainItemTokenWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    height: 48px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    & > div:nth-child(1) {
      height: unset;
    }
  }
`

const TokenIcon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);
  background: #ffffff;

  &:nth-child(1) {
    position: relative;
    z-index: ${CSS_Z_INDEX.token};
    margin-right: -5px;
    background: #ffffff;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

const SelectedTokenInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  & > div:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    color: #9d9999;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 8px;

    & > div:nth-child(1) {
      font-size: 12px;
    }

    & > div:nth-child(2) {
      font-size: 10px;
    }
  }
`

const DepositMainWithdrawItemToken = ({
  tokens,
}: DepositMainWithdrawItemTokenProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const { width } = useGetWindowSize()

  return (
    <DepositMainItemTokenWrapper>
      <div>
        <TokenIcon
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[0].address}.png`}
          alt="Token Icon"
        />
        <TokenIcon
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[1].address}.png`}
          alt="Token Icon"
        />
      </div>

      <SelectedTokenInfo>
        <div>
          {tokens[0].symbol}-{tokens[1].symbol}
        </div>
        <div>
          {language === 'en' ? tokens[0].engName : tokens[0].korName}
          {width > 768 ? '-' : ' + '}
          {language === 'en' ? tokens[1].engName : tokens[1].korName}
        </div>
      </SelectedTokenInfo>
    </DepositMainItemTokenWrapper>
  )
}

export default DepositMainWithdrawItemToken

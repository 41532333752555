import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import checkMobileDevice from '../utils/checkMobileDevice'
import { CONTRACT_GAS_LIMIT, MAX_ALLOWANCE } from '../utils/constants'
import { TOKEN_APPROVE_ABI } from '../contract/abi'

export default function useTokenApprove() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()

  const klipTokenApproveSuccessCallback = (
    result: any,
    successCallback: () => void,
  ) => {
    if (result.status === 'success') successCallback()
  }

  const klipTokenApprove = async (
    tokenAddress: string,
    targetAddress: string,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: tokenAddress,
      value: '0',
      abi: JSON.stringify(TOKEN_APPROVE_ABI),
      params: JSON.stringify([targetAddress, MAX_ALLOWANCE]),
    })

    if (err) {
      failureCallback()
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipTokenApproveSuccessCallback(result, successCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasTokenApprove = (
    tokenAddress: string,
    targetAddress: string,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(TOKEN_APPROVE_ABI, [
      targetAddress,
      MAX_ALLOWANCE,
    ])

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: tokenAddress,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const bitkeepTokenApprove = (
    tokenAddress: string,
    targetAddress: string,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = web3.eth.abi.encodeFunctionCall(TOKEN_APPROVE_ABI, [
      targetAddress,
      MAX_ALLOWANCE,
    ] as string[])

    web3.eth
      .sendTransaction({
        from: address,
        to: tokenAddress,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const handleTokenApprove = async (
    tokenAddress: string,
    targetAddress: string,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipTokenApprove(
        tokenAddress,
        targetAddress,
        successCallback,
        failureCallback,
      )
    else if (type === 'kaikas')
      kaikasTokenApprove(
        tokenAddress,
        targetAddress,
        successCallback,
        failureCallback,
      )
    else if (type === 'bitkeep')
      bitkeepTokenApprove(
        tokenAddress,
        targetAddress,
        successCallback,
        failureCallback,
      )
  }

  return handleTokenApprove
}

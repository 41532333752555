import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageStakingFormState } from '../../../state/staking/stakingFormState'
import convertNumber from '../../../utils/convertNumber'
import TemplateLayout from '../templates/TemplateLayout'
import StakingTemplate from '../templates/StakingTemplate'
import StakingRewardsTemplate from '../templates/StakingRewardsTemplate'
import useTotalStakedNeuronQuery from '../../../api/query/useTotalStakedNeuronQuery'

type StakingPageType = 'staking' | 'rewards'

const Title = styled.div`
  margin-top: 77px;
  font-size: 32px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: center;

  span {
    color: #ff6d68;
  }
`

const Contents = styled.div`
  width: 754px;
  margin: 80px auto 0;
`

const StakingMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 48px;
  padding: 1px;
  border-radius: 24px;
  background: #9d9999;
`

const SelectItem = styled.div<{ isSelected: boolean; background: string }>`
  position: relative;
  z-index: ${({ isSelected }) => (isSelected ? '1' : '0')};
  display: grid;
  place-items: center;
  width: 125px;
  height: 46px;
  border-radius: 23px;
  background: ${({ isSelected, background }) =>
    isSelected ? background : 'transparent'};
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ isSelected }) => (isSelected ? '1' : '0.4')});
  cursor: ${({ isSelected }) => (isSelected ? 'initial' : 'pointer')};

  & + & {
    margin-left: -12px;
  }
`

const TotalStakedNeuron = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 377px;
  height: 48px;
  padding: 0 30px;
  border-radius: 24px;
  background: #e8e8e8;
  color: #9d9999;

  & > div:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    font-size: 18px;
  }
`

const StakingPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { data } = useTotalStakedNeuronQuery()
  const { resetStakingFormState } = useManageStakingFormState()
  const [page, setPage] = useState<StakingPageType>('staking')

  const handleChangePage = (page: StakingPageType) => setPage(page)

  useEffect(() => {
    if (address === undefined) resetStakingFormState()
  }, [address])

  return (
    <TemplateLayout>
      <Title>
        {t('title.0')}
        <span>{t('title.1')}</span>
        {t('title.2')}
      </Title>

      <Contents>
        <StakingMenuWrapper>
          <SelectWrapper>
            <SelectItem
              isSelected={page === 'staking'}
              background="#ff6d68"
              onClick={() => handleChangePage('staking')}
            >
              {t('menu.item.0')}
            </SelectItem>
            <SelectItem
              isSelected={page === 'rewards'}
              background="#65dfb9"
              onClick={() => handleChangePage('rewards')}
            >
              {t('menu.item.1')}
            </SelectItem>
          </SelectWrapper>

          <TotalStakedNeuron>
            <div>{t('menu.totalNRStaked')}</div>
            <div>{convertNumber(data ?? 0, { comma: true, maxDigit: 6 })}</div>
          </TotalStakedNeuron>
        </StakingMenuWrapper>

        {page === 'staking' ? <StakingTemplate /> : null}
        {page === 'rewards' ? <StakingRewardsTemplate /> : null}
      </Contents>
    </TemplateLayout>
  )
}

export default StakingPage

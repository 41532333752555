import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import multipleByDecimal from '../../../utils/multipleByDecimal'
import { TokenType } from '../../../api/query/useTokenQuery'
import { getConvertFromPebValue } from '../../../utils/convertTokenNumberData'

type DepositPoolBalanceMyAssetProps = {
  tokens: TokenType[]
  depositRate: [number, number]
  sharedOf: string
  userLpBalance: string
}

const DepositPoolBalanceMyAssetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 8px 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0 16px 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
`

const Title = styled.div`
  font-size: 12px;
  color: #2e2e2e;

  @media (max-width: 768px) {
    color: #9d9999;
  }
`

const ShareRate = styled.div`
  margin-top: 4px;
  font-size: 10px;
  color: #9d9999;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0;
    font-size: 12px;
  }
`

const DepositPoolBalanceTokenAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

const TokenWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  @media (max-width: 768px) {
    & + & {
      margin-top: 4px;
    }
  }
`

const TokenAmount = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #656565;

  span {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const TokenSymbol = styled.div`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const DepositPoolBalanceMyAsset = ({
  tokens,
  depositRate,
  sharedOf,
  userLpBalance,
}: DepositPoolBalanceMyAssetProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositPoolBalance.DepositPoolBalanceMyAsset',
  })

  return (
    <DepositPoolBalanceMyAssetWrapper>
      <TitleWrapper>
        <Title>{t('title')}</Title>
        <ShareRate>
          {t('shared')} {sharedOf}%
        </ShareRate>
      </TitleWrapper>

      <DepositPoolBalanceTokenAmountWrapper>
        <TokenWrapper>
          <TokenAmount>
            <SlicedDecimal maxIntLength={12} suffix={` ${tokens[0].symbol}`}>
              {convertNumber(
                multipleByDecimal(
                  depositRate[0] *
                    parseFloat(getConvertFromPebValue(userLpBalance, 18)),
                  tokens[0].decimal,
                ),
                { maxDigit: 6 },
              )}
            </SlicedDecimal>
          </TokenAmount>
          <TokenSymbol>{tokens[0].symbol}</TokenSymbol>
        </TokenWrapper>

        <TokenWrapper>
          <TokenAmount>
            <span>+</span>
            <SlicedDecimal maxIntLength={12} suffix={` ${tokens[1].symbol}`}>
              {convertNumber(
                multipleByDecimal(
                  depositRate[1] *
                    parseFloat(getConvertFromPebValue(userLpBalance, 18)),
                  tokens[1].decimal,
                ),
                { maxDigit: 6 },
              )}
            </SlicedDecimal>
          </TokenAmount>
          <TokenSymbol>{tokens[1].symbol}</TokenSymbol>
        </TokenWrapper>
      </DepositPoolBalanceTokenAmountWrapper>
    </DepositPoolBalanceMyAssetWrapper>
  )
}

export default DepositPoolBalanceMyAsset

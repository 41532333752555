import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const ReferralEventInformationWrapper = styled.div`
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #707070;
`

const DetailItem = styled.div`
  display: flex;
  font-size: 12px;
  color: #707070;
  line-height: 1.67;
  white-space: pre-line;

  img {
    margin-top: 3px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }

  span {
    font-size: 10.5px;
    font-weight: 500;
  }

  & + & {
    margin-top: 8px;
  }
`

const ReferralEventInformation = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralEventDashboard.ReferralEventDashboard',
  })
  const { width } = useGetWindowSize()

  return (
    <ReferralEventInformationWrapper>
      <DetailItem>
        <img
          src="/dexAssets/referral_policy_item_icon.png"
          alt="Referral Claim Policy Icon"
        />
        <div style={{ letterSpacing: language === 'ko' ? '0.1px' : '0' }}>
          {t(`${width > 768 ? 'info' : 'mobileInfo'}.0`)}
        </div>
      </DetailItem>

      <DetailItem>
        <img
          src="/dexAssets/referral_policy_item_icon.png"
          alt="Referral Claim Policy Icon"
        />
        <div style={{ letterSpacing: language === 'ko' ? '-0.12px' : '0' }}>
          {t(`${width > 768 ? 'info' : 'mobileInfo'}.1`)}
        </div>
      </DetailItem>
    </ReferralEventInformationWrapper>
  )
}

export default ReferralEventInformation

import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import produce from 'immer'
import styled from 'styled-components'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import convertNumber from '../../../utils/convertNumber'

type DashboardChartCardProps = {
  index: number
  title: string
  price: number
  chartData: [number, number][]
  updatedDate: string
}

const DashboardChartCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 272px;
  padding: 20px 30px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    height: unset;
    padding: 16px 17px;
  }
`

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const UpdatedDate = styled.div`
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: 10px;
    color: #d1cbcb;
  }
`

const Price = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #9d9999;

  span {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    margin-top: 1px;
    font-size: 14px;

    span {
      margin-left: 2px;
      font-size: 14px;
    }
  }
`

const ChartWrapper = styled.div`
  height: 170px;
  margin: auto -10px 0;
`

const DashboardChartCard = ({
  index,
  title,
  price,
  chartData,
  updatedDate,
}: DashboardChartCardProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      id: 'apexchart',
      animations: {
        enabled: true,
      },
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1.5,
      colors: [index === 1 ? '#ff6d68' : '#65dfb9'],
    },
    fill: {
      type: 'solid',
      colors: ['transparent'],
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: language === 'ko' ? 'M월 D일' : 'MMM dd',
        style: {
          fontSize: '12px',
          colors: '#9d9999',
        },
      },
      axisBorder: {
        color: '#edeaea',
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: value => {
          const units = ['k', 'm', 'b']
          let converted = value
          let unitIndex = -1

          while (converted >= 1000) {
            converted /= 1000
            unitIndex += 1
          }

          if (unitIndex === -1 || unitIndex > 2)
            return `$ ${convertNumber(converted, { comma: true, maxDigit: 6 })}`
          else
            return `$ ${convertNumber(converted, {
              comma: true,
              maxDigit: 2,
            })}${units[unitIndex]}`
        },
        style: {
          fontSize: '9px',
          colors: '#9d9999',
        },
      },
      opposite: true,
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => title,
        },
      },
      marker: {
        show: false,
      },
    },
    markers: {
      colors: ['#ffffff'],
      strokeWidth: 2,
      strokeColors: index === 1 ? '#ff6d68' : '#65dfb9',
      hover: {
        size: undefined,
        sizeOffset: 4,
      },
    },
  })

  useEffect(() => {
    setChartOptions(prev =>
      produce(prev, draft => {
        if (draft.xaxis?.labels === undefined || draft.tooltip?.y === undefined)
          return draft

        draft.xaxis.labels.format = language === 'ko' ? 'M월 d일' : 'MMM dd'
        draft.tooltip.y = {
          title: {
            formatter: () => title,
          },
        }

        return draft
      }),
    )
  }, [language, title])

  return (
    <DashboardChartCardWrapper>
      <CardHead>
        <Title>{title}</Title>
        <UpdatedDate>{updatedDate}</UpdatedDate>
      </CardHead>
      <Price>$ {convertNumber(price ?? 0, { maxDigit: 0, comma: true })}</Price>

      <ChartWrapper>
        <Chart
          options={chartOptions}
          series={[{ name: 'Chart', data: chartData }]}
          type="area"
          height="170"
        />
      </ChartWrapper>
    </DashboardChartCardWrapper>
  )
}

export default DashboardChartCard

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import SwapInfoExchangeRateItem from '../atoms/SwapInfoExchangeRateItem'
import SwapInfoTransactionPath from '../atoms/SwapInfoTransactionPath'
import MobilePopup from './MobilePopup'
import useHandleClickOutSide from '../../../hooks/useHandleClickOutSide'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import getAppliedSlippageValue from '../../../utils/getAppliedSlippageValue'
import convertNumber from '../../../utils/convertNumber'
import { CSS_Z_INDEX } from '../../../utils/constants'
import { TokenType } from '../../../api/query/useTokenQuery'

const SwapInfoExchangeRateDetailWidgetWrapper = styled.div`
  position: relative;
  z-index: ${CSS_Z_INDEX.widget};
  width: 20px;
  height: 20px;

  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`

const ToggleIcon = styled.img`
  width: 100%;
  height: 100%;
  margin-left: 4px;
  cursor: pointer;
`

const SwapInfoExchangeRateWidget = styled.div<{ isVisible: string }>`
  display: ${({ isVisible }) => (isVisible === 'true' ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  transform: translateX(calc(50% - 10px));
  width: 400px;
  padding: 20px;
  border: 1px solid #9d9999;
  border-radius: 24px;
  background: #f8f8f8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    width: 305px;
    padding: 16px;
    transform: none;
  }
`

const Difference = styled.span<{ isDanger: string }>`
  color: #${({ isDanger }) => (isDanger === 'true' ? 'ff6d68' : '00e1c2')};
`

const MobilePopupTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
`

const SwapInfoExchangeRateDetailWidget = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapInfo.tooltip',
  })
  const {
    swapFormState: {
      form: { from, to, lastModified },
      settings: { slippage },
    },
  } = useManageSwapFormState()
  const {
    swapInfoState: { exchangeRatePerToValue, bestTradeAddress },
  } = useManageSwapInfoState()
  const { ref, buttonRef, isVisible, toggleComponent } = useHandleClickOutSide()
  const { width } = useGetWindowSize()
  const [rateDifference, setRateDifference] = useState<string>('')
  const queryClient = useQueryClient()

  const fromToken = queryClient.getQueryData<TokenType>(['token', from.address])
  const toToken = queryClient.getQueryData<TokenType>(['token', to.address])

  useEffect(() => {
    if (from.value === '' || to.value === '') {
      setRateDifference('')
      return
    }

    const swapRate = parseFloat(from.value) / parseFloat(to.value)

    setRateDifference(
      Math.abs((1 - swapRate / exchangeRatePerToValue) * 100).toString(),
    )
  }, [from, to, exchangeRatePerToValue])

  if (fromToken === undefined || toToken === undefined) return <>-</>

  return (
    <>
      <SwapInfoExchangeRateDetailWidgetWrapper>
        <ToggleIcon
          ref={buttonRef}
          src="/dexAssets/info_icon.png"
          onClick={toggleComponent}
          alt="Info Icon"
        />

        {width > 768 ? (
          <SwapInfoExchangeRateWidget ref={ref} isVisible={String(isVisible)}>
            <SwapInfoExchangeRateItem title={t('menu.0')}>
              {rateDifference !== '' ? (
                <Difference
                  isDanger={String(Math.abs(parseFloat(rateDifference)) >= 10)}
                >
                  {Math.abs(parseFloat(rateDifference)) < 0.01
                    ? '< 0.01'
                    : Math.abs(parseFloat(rateDifference)).toFixed(2)}{' '}
                  %
                </Difference>
              ) : (
                '-'
              )}
            </SwapInfoExchangeRateItem>
            <SwapInfoExchangeRateItem title={t('menu.1')} highlight={true}>
              {slippage}%
            </SwapInfoExchangeRateItem>
            <SwapInfoExchangeRateItem title={t(`menu.2.${lastModified}`)}>
              {from.value && to.value ? (
                <>
                  {convertNumber(
                    getAppliedSlippageValue(
                      lastModified === 'from' ? to.value : from.value,
                      slippage,
                      lastModified === 'from' ? 'min' : 'max',
                    ),
                    { maxDigit: 6 },
                  )}{' '}
                  {lastModified === 'from' ? toToken.symbol : fromToken.symbol}
                </>
              ) : (
                '-'
              )}
            </SwapInfoExchangeRateItem>
            <SwapInfoExchangeRateItem title={t('menu.3')}>
              <SwapInfoTransactionPath bestTradeAddress={bestTradeAddress} />
            </SwapInfoExchangeRateItem>
          </SwapInfoExchangeRateWidget>
        ) : null}
      </SwapInfoExchangeRateDetailWidgetWrapper>

      {width <= 768 ? (
        <MobilePopup
          type="normal"
          isVisible={isVisible}
          handleClose={toggleComponent}
        >
          <MobilePopupTitle>{t('mobileTitle')}</MobilePopupTitle>

          <SwapInfoExchangeRateItem title={t('menu.0')}>
            {rateDifference !== '' ? (
              <Difference
                isDanger={String(Math.abs(parseFloat(rateDifference)) >= 10)}
              >
                {Math.abs(parseFloat(rateDifference)) < 0.01
                  ? '< 0.01'
                  : Math.abs(parseFloat(rateDifference)).toFixed(2)}{' '}
                %
              </Difference>
            ) : (
              '-'
            )}
          </SwapInfoExchangeRateItem>
          <SwapInfoExchangeRateItem title={t('menu.1')} highlight={true}>
            {slippage}%
          </SwapInfoExchangeRateItem>
          <SwapInfoExchangeRateItem title={t(`menu.2.${lastModified}`)}>
            {from.value && to.value ? (
              <>
                {convertNumber(
                  getAppliedSlippageValue(
                    lastModified === 'from' ? to.value : from.value,
                    slippage,
                    lastModified === 'from' ? 'min' : 'max',
                  ),
                  { maxDigit: 6 },
                )}{' '}
                {lastModified === 'from' ? toToken.symbol : fromToken.symbol}
              </>
            ) : (
              '-'
            )}
          </SwapInfoExchangeRateItem>
          <SwapInfoExchangeRateItem title={t('menu.3')}>
            <SwapInfoTransactionPath bestTradeAddress={bestTradeAddress} />
          </SwapInfoExchangeRateItem>
        </MobilePopup>
      ) : null}
    </>
  )
}

export default SwapInfoExchangeRateDetailWidget

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageConnectWalletModalState } from '../../state/modal/connectWalletModalState'
import ModalPortal from '../ModalPortal'
import ConnectWalletButton from '../../components/NeuronSwap/atoms/ConnectWalletButton'
import useHandleConnectWallet from '../../hooks/useHandleConnectWallet'
import useGetWindowSize from '../../hooks/useGetWindowSize'
import checkMobileDevice from '../../utils/checkMobileDevice'
import { CSS_Z_INDEX } from '../../utils/constants'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  width: 420px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 380px;
    padding: 16px;
  }
`

const ModalHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.4;

  img {
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 8px 8px 32px 8px;
  }
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin: 0 8px;
  }
`

const LoginInfo = styled.div`
  margin: 20px 0 29.5px;
  padding: 18.5px 0 20px;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.63;
  white-space: pre-line;

  @media (max-width: 768px) {
    margin: 16px 8px auto;
    padding: 12px 0;
  }
`

const ConnectWalletModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.ConnectWalletModal',
  })
  const { width } = useGetWindowSize()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const handleConnectWallet = useHandleConnectWallet()

  const handleCloseLoginModal = () => setConnectWalletModalState(false)

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead>
          <div>{t('title')}</div>
          <img
            src="/dexAssets/modal_icons/modal_close_icon.png"
            alt="Modal Close Icon"
            onClick={handleCloseLoginModal}
          />
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <LoginInfo>
          {t(width > 768 ? 'description' : 'mobileDescription')}
        </LoginInfo>

        <ConnectWalletButton
          type="klip"
          handleConnectWallet={handleConnectWallet}
        />
        {!checkMobileDevice() ? (
          <ConnectWalletButton
            type="kaikas"
            handleConnectWallet={handleConnectWallet}
          />
        ) : null}
        <ConnectWalletButton
          type="bitkeep"
          handleConnectWallet={handleConnectWallet}
        />
      </ModalWrapper>
    </ModalPortal>
  )
}

export default ConnectWalletModal

import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import useCreateSessionMutation from '../api/mutation/useCreateSessionMutation'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import useCallMessageBox from './useCallMessageBox'
import AxiosClient from '../api/axiosConfig'
import checkMobileDevice from '../utils/checkMobileDevice'

export default function useDetectConnectedWallet() {
  const {
    connectedWalletState: { type },
    setConnectedWalletState,
  } = useManageConnectedWalletState()
  const createSessionMutation = useCreateSessionMutation()
  const queryClient = useQueryClient()
  const pushMessage = useCallMessageBox()

  const detectKlipWallet = () => {
    const address = window.sessionStorage.getItem(
      'neuronSwapKlipAddress',
    ) as string

    if (address === null)
      window.sessionStorage.removeItem('neuronSwapWalletType')
    else {
      createSessionMutation.mutate(address, {
        onSuccess: ({
          data: {
            data: { accessToken },
          },
        }) => {
          AxiosClient.setAccessToken(accessToken as string)
          queryClient.removeQueries()
          setConnectedWalletState({ type: 'klip', address })
          pushMessage('wallet', 'success')
        },
      })
    }
  }

  const detectKaikasWallet = async () => {
    const { isUnlocked, isApproved } = window.klaytn._kaikas

    const [locked, approved] = await Promise.all([isUnlocked(), isApproved()])

    if (locked && approved) {
      const address = await window.klaytn.enable()

      createSessionMutation.mutate(address[0] as string, {
        onSuccess: ({
          data: {
            data: { accessToken },
          },
        }) => {
          AxiosClient.setAccessToken(accessToken as string)
          queryClient.removeQueries()
          setConnectedWalletState({ type: 'kaikas', address: address[0] })
          pushMessage('wallet', 'success')
        },
      })
    } else window.sessionStorage.removeItem('neuronSwapWalletType')
  }

  const detectBitkeepWallet = async () => {
    if (checkMobileDevice()) {
      window.BitKeepInvoke.getAddress((err: any, data: any) => {
        if (data.klay === undefined) pushMessage('wallet', 'failure')

        createSessionMutation.mutate(data.klay as string, {
          onSuccess: ({
            data: {
              data: { accessToken },
            },
          }) => {
            AxiosClient.setAccessToken(accessToken as string)
            queryClient.removeQueries()
            setConnectedWalletState({
              type: 'bitkeep',
              address: data.klay as string,
            })
            pushMessage('wallet', 'success')
            window.sessionStorage.setItem('neuronSwapWalletType', 'bitkeep')
          },
        })
      })
      return
    }

    const address = await window.ethereum.request({
      method: 'eth_requestAccounts',
    })

    createSessionMutation.mutate(address[0] as string, {
      onSuccess: ({
        data: {
          data: { accessToken },
        },
      }) => {
        AxiosClient.setAccessToken(accessToken as string)
        queryClient.removeQueries()
        setConnectedWalletState({ type: 'bitkeep', address: address[0] })
        pushMessage('wallet', 'success')
        window.sessionStorage.setItem('neuronSwapWalletType', 'bitkeep')
      },
    })
  }

  useEffect(() => {
    const detectedWalletType = window.sessionStorage.getItem(
      'neuronSwapWalletType',
    )

    if (detectedWalletType === 'kaikas') detectKaikasWallet()
    else if (detectedWalletType === 'klip') detectKlipWallet()
    else if (detectedWalletType === 'bitkeep') detectBitkeepWallet()
  }, [])

  useEffect(() => {
    if (type === 'kaikas') {
      window.klaytn.on('networkChanged', (networkId: number) => {
        if (networkId !== 8217) pushMessage('wallet', 'failure')
      })
      window.klaytn.on('accountsChanged', (accounts: string[]) =>
        createSessionMutation.mutate(accounts[0], {
          onSuccess: ({
            data: {
              data: { accessToken },
            },
          }) => {
            AxiosClient.setAccessToken(accessToken as string)
            queryClient.removeQueries()
            setConnectedWalletState(prev => ({ ...prev, address: accounts[0] }))
            pushMessage('wallet', 'success')
          },
        }),
      )
    } else if (type === 'bitkeep') {
      window.ethereum.on('chainChanged', (chainId: string) => {
        if (chainId !== '0x2019') pushMessage('wallet', 'failure')
      })
      window.ethereum.on('accountsChanged', (accounts: string[]) =>
        createSessionMutation.mutate(accounts[0], {
          onSuccess: ({
            data: {
              data: { accessToken },
            },
          }) => {
            AxiosClient.setAccessToken(accessToken as string)
            queryClient.removeQueries()
            setConnectedWalletState(prev => ({ ...prev, address: accounts[0] }))
            pushMessage('wallet', 'success')
          },
        }),
      )
    }

    return () => {
      window.klaytn?.removeAllListeners()
      window.ethereum?.removeAllListeners()
    }
  }, [type])
}

import { useEffect } from 'react'
import produce from 'immer'
import { useQueryClient } from 'react-query'
import { useManageDepositFormState } from '../state/pool/depositFormState'
import convertNumber from '../utils/convertNumber'
import { PoolType } from '../api/query/usePoolQuery'
import { TokenListType } from '../api/query/useTokenListQuery'
import { getConvertFromPebValue } from '../utils/convertTokenNumberData'

export default function useConvertDepositTokenAmount(pairAddress: string) {
  const {
    depositFormState: { currentMode, isUserEntered, deposit, withdraw },
    setDepositFormState,
  } = useManageDepositFormState()
  const queryClient = useQueryClient()
  const poolData = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ])
  const tokenListData = queryClient.getQueryData<TokenListType['data']>([
    'tokenList',
    '',
  ])

  const convertDepositAmount = () => {
    if (poolData === undefined || tokenListData === undefined) return

    const convertedTokenAmount = [
      parseFloat(deposit.from || '0') * poolData.exchangeRate[0],
      parseFloat(deposit.to || '0') * poolData.exchangeRate[1],
    ]

    const token0Balance =
      tokenListData.tokens.find(
        ({ address }) => address === poolData.tokens[0].address,
      )?.balance ?? 0
    const token1Balance =
      tokenListData.tokens.find(
        ({ address }) => address === poolData.tokens[1].address,
      )?.balance ?? 0

    setDepositFormState(prev =>
      produce(prev, draft => {
        const convertPosition = deposit.lastModified === 'from' ? 'to' : 'from'

        draft.isUserEntered = false
        draft.deposit[convertPosition] = convertNumber(
          convertedTokenAmount[deposit.lastModified === 'from' ? 0 : 1],
          { maxDigit: 6 },
        )

        draft.error.from = token0Balance < parseFloat(draft.deposit.from)
        draft.error.to = token1Balance < parseFloat(draft.deposit.to)

        return draft
      }),
    )
  }

  useEffect(() => {
    if (poolData === undefined) return

    if (currentMode === 'withdraw') {
      const isErrorWithdraw =
        withdraw !== '' &&
        parseFloat(withdraw) !== 0 &&
        parseFloat(withdraw) >
          parseFloat(
            getConvertFromPebValue(poolData.userData?.stakedLpBalance ?? 0, 18),
          )

      setDepositFormState(prev =>
        produce(prev, draft => {
          draft.isUserEntered = false
          draft.error.withdraw = isErrorWithdraw

          return draft
        }),
      )
    }

    if (currentMode !== 'deposit' || deposit[deposit.lastModified] === '') {
      setDepositFormState(prev =>
        produce(prev, draft => {
          draft.isUserEntered = false
          draft.deposit[deposit.lastModified === 'from' ? 'to' : 'from'] = ''
          draft.error.from = false
          draft.error.to = false
          return draft
        }),
      )
      return
    }

    convertDepositAmount()
  }, [isUserEntered, poolData])
}

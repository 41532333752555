import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

export type PoolItemType = {
  address: string
  depositRate: [number, number]
  totalDepositedTokens: {
    [key in string]: number
  }
  tokens: {
    address: string
    symbol: string
    engName: string
    korName: string
    price: number
    decimal: number
  }[]
  rewardsDetail: {
    apr: number
    apy: number
    distributedNRPerDay: number
    nrAirdrop: number
    tradeFeeApr: number
  }
  totalLP: number
  totalLiquidity: number
  user: null
  volume: {
    total: number
    since: Date
  }
  fee: {
    total: number
    since: Date
  }
}

type DashboardPoolsType = {
  count: number
  hasNext: boolean
  page: number
  pageSize: number
  poolTotalCount: number
  tokenTotalCount: number
  pools: PoolItemType[]
}

const loadDashboardPools = async (page: string, searchQuery: string) =>
  await AxiosClient.getAxiosInstance(false).get(
    `/api/dashboard/pools?time=1&unit=day&pageSize=10&pageNum=${page}${
      searchQuery !== '' ? `&search=${searchQuery}` : ''
    }`,
  )

export default function useDashboardPoolsQuery(
  page: string,
  searchQuery: string,
) {
  const pageParam = (parseInt(page) - 1).toString()

  return useQuery<DashboardPoolsType>(
    ['dashboard', pageParam, searchQuery],
    async () => {
      const { data } = await loadDashboardPools(pageParam, searchQuery)

      return data.data
    },
    {
      enabled: AxiosClient.isInitialized(),
      staleTime: 1000 * 10,
      refetchOnWindowFocus: true,
    },
  )
}

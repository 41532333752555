import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { newLineToBr } from '../../../utils/newLineToBr'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

SwiperCore.use([Pagination, Autoplay])

const Section = styled.section`
  padding: 198px 0 148px;
  background-color: #f5f2e7;
  background-image: url(/dexAssets/main_page/rony/background.webp);
  background-size: 100% 55%;
  background-repeat: no-repeat;

  .swiper {
    padding: 100px 0 0;
    max-width: 1920px;
    height: 595px;
    &::before {
      content: '';
      background-image: linear-gradient(
        to left,
        rgba(245, 242, 231, 0),
        rgba(245, 242, 231, 0.93) 90%,
        #f5f2e7
      );
      position: absolute;
      left: -2px;
      bottom: -100px;
      width: 616px;
      top: 90px;
      z-index: 45;
    }
    &::after {
      content: '';
      background-image: linear-gradient(
        to right,
        rgba(245, 242, 231, 0),
        rgba(245, 242, 231, 0.93) 90%,
        #f5f2e7
      );
      position: absolute;
      right: -2px;
      bottom: -100px;
      width: 616px;
      top: 90px;
      z-index: 45;
    }
  }
  .swiper-wrapper {
    align-items: center;
  }
  // &::after {
  //   content: '';
  //   width: 547px;
  //   background: red;
  //   height: 50px;
  //   left: 50vw;
  //   margin-left: -273px;
  //   position: fixed;
  //   top: 50vh;
  //   z-index: 999;
  // }
  .swiper-slide {
    // margin-left: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 547px;
    transition: padding 0.3s;
    // padding: 0 28px;
    // transform: scale(0.83);
    padding: 0 46px;
    &.swiper-slide-$isActive {
      // margin: 0 50px;
      padding: 0 0;
    }
  }
  .swiper-slide img {
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    border-radius: 30px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #d1cbcb;
    vertical-align: middle;
    opacity: 1;
    margin: 0 10px !important;
  }
  .swiper-pagination-bullet-$isActive {
    width: 10px;
    height: 10px;
    background-color: #ff6d68;
    vertical-align: middle;
  }

  @media (max-width: 768px) {
    padding: 100px 0 107px;

    .swiper {
      margin-top: 75px;
      padding-top: 0px;
      padding-bottom: 60px;
      height: unset;

      &::before,
      &::after {
        display: none;
      }
    }

    .swiper-slide {
      width: calc(100% - 76px);
      margin: 0 9px;
      padding: 0;

      img {
        box-shadow: none;
      }
    }

    .swiper-pagination-bullets {
      bottom: 5px;
    }
  }
`

const TitleArea = styled.div`
  margin: 0 auto 0;
  max-width: 1266px;
  display: flex;
  > .left {
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    .beta-logo {
      height: 63px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    .comming-soon {
      font-family: itc-avant-garde-gothic-pro;
      font-size: 46px;
      font-weight: bold;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #2e2e2e;
    }
    .short-desc {
      margin: 37px 0 18px;
      font-size: 24px;
      font-weight: bold;
      line-height: 2;
      color: #000;
    }
    .desc {
      opacity: 0.7;
      font-size: 16px;
      line-height: 1.75;
      text-align: left;
      color: #000;
      margin-right: -20px;
    }
  }

  > img {
    height: 640px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    & > .left {
      width: 100%;
      align-items: center;

      .beta-logo {
        width: 208px;
        height: unset;
        margin-bottom: 10px;
      }

      .comming-soon {
        font-size: 22px;
      }

      .short-desc {
        font-size: 18px;
        margin: 20px 0 22px;
      }

      .desc {
        margin-right: 0;
        font-size: 12px;
        text-align: center;
      }

      > img {
        width: 360px;
        height: unset;
      }
    }
  }
`

const Description = styled.div`
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    text-align: center;
  }
  > .title {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.15;
    color: #2e2e2e;
    > span {
      margin-left: 8px;
      display: inline-block;
      opacity: 0.7;
      font-size: 16px;
      line-height: 1.88;
      color: #2e2e2e;
    }
    margin-bottom: 25px;
  }
  > .content {
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    color: #2e2e2e;
  }

  @media (max-width: 768px) {
    margin-top: 40px;

    > .title {
      margin-bottom: 15px;
      font-size: 14px;
    }

    > .content {
      font-size: 12px;
    }
  }
`

const MainRonyClubSection = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Main.RonySection',
  })
  const { width } = useGetWindowSize()

  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Section>
      <TitleArea>
        <div className="left">
          <img
            className="beta-logo"
            src={`/dexAssets/main_page/rony/logo-beta${
              width > 768 ? '' : '-mobile'
            }.svg`}
          />
          <span className="comming-soon">
            {width > 768 ? (
              <>
                2022 Q1
                <br />
                COMING SOON!
              </>
            ) : (
              '2022 Q1 COMING SOON!'
            )}
          </span>

          {width <= 768 ? (
            <img src="/dexAssets/main_page/rony/side-image.png" />
          ) : null}

          <span
            className="short-desc"
            dangerouslySetInnerHTML={{ __html: t(`main.subtitle`) }}
          ></span>
          <span
            className="desc"
            dangerouslySetInnerHTML={{
              __html: newLineToBr(
                t(width > 768 ? `main.description` : 'main.mobileDescription'),
              ),
            }}
          ></span>
        </div>

        {width > 768 ? (
          <img src="/dexAssets/main_page/rony/side-image.png" />
        ) : null}
      </TitleArea>

      <Swiper
        spaceBetween={0}
        slidesPerView={'auto'}
        centeredSlides={true}
        pagination={{
          clickable: true,
          type: 'bullets',
        }}
        onSlideChange={swiper => {
          setActiveIndex(swiper.activeIndex)
        }}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        simulateTouch
      >
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/1.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/2.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/3.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/4.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/5.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/dexAssets/main_page/rony/6.png" />
        </SwiperSlide>
      </Swiper>

      <Description>
        <span className="title">
          {t(`carousel.${activeIndex % 6}.title`)}
          {width > 768 ? (
            <span>{t(`carousel.${activeIndex % 6}.subtitle`)}</span>
          ) : null}
        </span>
        <span
          className="content"
          dangerouslySetInnerHTML={{
            __html: newLineToBr(
              t(
                `carousel.${activeIndex % 6}.${
                  width > 768 ? 'description' : 'mobileDescription'
                }`,
              ),
            ),
          }}
        ></span>
      </Description>
    </Section>
  )
}
export default MainRonyClubSection

import styled from 'styled-components'

type TokenSelectModalListItemProps = {
  address: string
  symbol: string
  name: string
  onClick?: () => void
}

const TokenSelectModalListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`

const TokenIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);
`

const TokenSymbol = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
`

const TokenName = styled.div`
  font-size: 10px;
  color: #9d9999;
`

const TokenSelectModalListItem = ({
  address,
  symbol,
  name,
  onClick,
}: TokenSelectModalListItemProps) => {
  return (
    <TokenSelectModalListItemWrapper onClick={onClick}>
      <TokenIcon
        src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
        alt="Token Icon"
      />

      <div>
        <TokenSymbol>{symbol}</TokenSymbol>
        <TokenName>{name}</TokenName>
      </div>
    </TokenSelectModalListItemWrapper>
  )
}

export default TokenSelectModalListItem

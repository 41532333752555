import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import SlicedDecimal from '../atoms/SlicedDecimal'
import SwapInfoItem from '../molecules/SwapInfoItem'
import SwapInfoExchangeRate from '../molecules/SwapInfoExchangeRate'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import convertNumber from '../../../utils/convertNumber'
import { TokenType } from '../../../api/query/useTokenQuery'

const SwapInfoWrapper = styled.div`
  margin-top: 20px;
  padding: 30px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    margin-top: 25px;
    padding: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
`

const SwapInfo = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapInfo.items',
  })
  const {
    swapFormState: {
      form: { from, to },
      error,
    },
  } = useManageSwapFormState()
  const {
    swapInfoState: { fee, transactionRewards },
  } = useManageSwapInfoState()
  const queryClient = useQueryClient()

  const fromToken = queryClient.getQueryData<TokenType>(['token', from.address])

  if (from.address === '' || to.address === '' || fromToken === undefined)
    return null

  return (
    <SwapInfoWrapper>
      <SwapInfoItem title={t('0')}>
        {from.value !== '' &&
        to.value !== '' &&
        error !== 'INSUFFICIENT_LIQUIDITY' ? (
          <SwapInfoExchangeRate />
        ) : (
          '-'
        )}
      </SwapInfoItem>

      <SwapInfoItem title={t('1')}>
        {from.value !== '' &&
        to.value !== '' &&
        error !== 'INSUFFICIENT_LIQUIDITY' ? (
          <>
            <span>
              <SlicedDecimal maxIntLength={9} suffix={` ${fromToken.symbol}`}>
                {convertNumber(fee, { maxDigit: 6 })}
              </SlicedDecimal>
            </span>
            {fromToken.symbol}
          </>
        ) : (
          '-'
        )}
      </SwapInfoItem>

      <SwapInfoItem title={t('2')} highlight>
        {from.value !== '' &&
        to.value !== '' &&
        error !== 'INSUFFICIENT_LIQUIDITY' ? (
          <>
            <span>
              <SlicedDecimal maxIntLength={9} suffix=" NR">
                {convertNumber(transactionRewards, { maxDigit: 6 })}
              </SlicedDecimal>
            </span>
            NR
          </>
        ) : (
          '-'
        )}
      </SwapInfoItem>
    </SwapInfoWrapper>
  )
}

export default SwapInfo

import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import produce from 'immer'
import TemplateLayout from '../templates/TemplateLayout'
import SwapTemplate from '../templates/SwapTemplate'
import TransactionHistoryTemplate from '../templates/TransactionHistoryTemplate'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import useSwapDetailQuery from '../../../api/query/useSwapDetailQuery'
import useDebounceInput from '../../../hooks/useDebounceInput'

const SwapPage = () => {
  const {
    swapFormState: {
      form: { from, to, lastModified },
    },
    setSwapFormState,
    resetSwapFormState,
  } = useManageSwapFormState()
  const { resetSwapInfoState } = useManageSwapInfoState()
  const debouncedValue = useDebounceInput(
    lastModified === 'from' ? from.value : to.value,
  )
  const { refetch } = useSwapDetailQuery({
    from: from.address,
    to: to.address,
    lastModified,
    value: debouncedValue,
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (from.address !== '' && to.address !== '' && debouncedValue !== '')
      refetch()

    if (lastModified === 'from' ? from.value === '' : to.value === '')
      setSwapFormState(prev =>
        produce(prev, draft => {
          draft.form.from.value = ''
          draft.form.to.value = ''
          draft.error = undefined

          return draft
        }),
      )
  }, [debouncedValue, from, to])

  useEffect(() => {
    return () => {
      resetSwapFormState()
      resetSwapInfoState()
      queryClient.removeQueries('swapDetail')
    }
  }, [])

  return (
    <TemplateLayout>
      <SwapTemplate />
      <TransactionHistoryTemplate />
    </TemplateLayout>
  )
}

export default SwapPage

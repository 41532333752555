import { ReactNode } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

type HeaderMenuItemProps = {
  to: string
  color?: string
  highlight?: boolean
  children: ReactNode
}

const HeaderMenuItemWrapper = styled(NavLink)<{
  color?: string
  highlight?: string
}>`
  flex-shrink: 0;
  font-size: 14px;
  color: ${({ color }) => (color !== undefined ? color : '#2e2e2e')};
  text-decoration: none;

  ${({ highlight }) => (highlight === 'true' ? 'color: #cdad7a;' : '')}

  &.$isActive {
    font-weight: 700;
  }

  &:hover {
    font-weight: 700;
  }

  @media (min-width: 769px) {
    &:not(:first-child) {
      margin-left: 30px;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    color: #9d9999;

    & + & {
      margin-left: 20px;
    }
  }
`

const HeaderMenuItem = ({
  to,
  color,
  highlight,
  children,
}: HeaderMenuItemProps) => {
  return (
    <HeaderMenuItemWrapper
      to={to}
      color={color}
      highlight={String(highlight)}
      className={({ isActive }) => (isActive ? '$isActive' : '')}
      end
    >
      {children}
    </HeaderMenuItemWrapper>
  )
}

export default HeaderMenuItem

import { useQuery } from 'react-query'
import { CONTRACT_ADDRESS } from '../../contract/address'
import { neuronContract } from '../../contract/contract'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'

const loadTotalStakedNeuron = async () => {
  const totalStakedNeuron = await neuronContract.methods
    .balanceOf(CONTRACT_ADDRESS.gnr)
    .call()

  return parseFloat(getConvertFromPebValue(totalStakedNeuron as string, 18))
}

export default function useTotalStakedNeuronQuery() {
  return useQuery<number>('totalStakedNeuron', loadTotalStakedNeuron, {
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
  })
}

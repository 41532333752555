import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MobileMenuListItem from '../atoms/MobileMenuListItem'
import MobileMenuCommunity from '../atoms/MobileMenuCommunity'
import MobilePopup from './MobilePopup'

const MobileMenuListWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 0 -20px;
  padding: 0 20px;
`

const MobileBlockDescription = styled.div`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
`

const MobileMenuList = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'Modal.MobileMenu' })
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowMobilePopup = () => setIsVisiblePopup(true)
  const handleCloseMobilePopup = () => setIsVisiblePopup(false)

  return (
    <MobileMenuListWrapper>
      <MobileMenuListItem link="/swap" type="internal">
        {t('menu.0')}
      </MobileMenuListItem>
      <MobileMenuListItem link="/pool" type="internal">
        {t('menu.1')}
      </MobileMenuListItem>
      <MobileMenuListItem
        link="/staking"
        type="dummy"
        dummyClickHandler={handleShowMobilePopup}
      >
        {t('menu.2')}
      </MobileMenuListItem>
      <MobileMenuListItem link="/asset" type="internal">
        {t('menu.3')}
      </MobileMenuListItem>
      <MobileMenuListItem link="/dashboard" type="internal">
        {t('menu.4')}
      </MobileMenuListItem>
      <MobileMenuListItem link="/referral" type="internal">
        {t('menu.5')}
      </MobileMenuListItem>
      <MobileMenuListItem link="https://bridge.orbitchain.io/" type="external">
        {t('menu.6')}
      </MobileMenuListItem>
      <MobileMenuCommunity />

      <MobilePopup
        type="normal"
        isVisible={isVisiblePopup}
        handleClose={handleCloseMobilePopup}
      >
        <MobileBlockDescription>
          {language === 'ko'
            ? 'NR 스테이킹 서비스는 PC 환경으로 접속 가능합니다.'
            : 'The NR staking service can be accessed\nthrough a PC environment.'}
        </MobileBlockDescription>
      </MobilePopup>
    </MobileMenuListWrapper>
  )
}

export default MobileMenuList

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'
import { TokenType } from './useTokenQuery'

export type TokenListType = {
  data: {
    tokens: TokenType[]
  }
}

const loadTokenList = async (searchQuery: string) =>
  await AxiosClient.getAxiosInstance(true, false).get(
    `/api/tokens${searchQuery !== '' ? `?search=${searchQuery}` : ''}`,
  )

export default function useTokenListQuery(searchQuery = '') {
  return useQuery<TokenListType['data']>(
    ['tokenList', searchQuery],
    async () => {
      const {
        data: { data },
      } = await loadTokenList(searchQuery)

      return data
    },
    {
      enabled: AxiosClient.isInitialized(),
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  )
}

import { useManageMessageBoxState } from '../state/modal/messageBoxState'
import produce from 'immer'
import { useTranslation } from 'react-i18next'

type useCallMessageBoxType = (
  type: 'wallet' | 'request',
  result: 'success' | 'failure',
  customMessage?: string,
) => void

export default function useCallMessageBox(): useCallMessageBoxType {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.MessageBox',
  })
  const { setMessageBoxState } = useManageMessageBoxState()

  const pushMessage = (
    type: 'wallet' | 'request',
    result: 'success' | 'failure',
    customMessage?: string,
  ) => {
    const message =
      customMessage === undefined
        ? t(`${type}${result.charAt(0).toUpperCase()}${result.slice(1)}`)
        : customMessage

    setMessageBoxState(prev =>
      produce(prev, draft => {
        draft.isVisible = true
        draft.messages.push({
          type: result,
          message,
        })

        return draft
      }),
    )
  }

  return pushMessage
}

import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CSS_Z_INDEX } from '../../../utils/constants'

export type SlicedDecimalTooltipProps = {
  customTooltipPosition?: {
    top?: number
    left?: number
  }
  children: ReactNode
}

export const SlicedDecimalTooltipWrapper = styled.div`
  display: none;
  position: fixed;
  transform: translate(-50%);
  z-index: ${CSS_Z_INDEX.tooltip};
  width: 231px;
  padding: 10px 13px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.67;
  word-break: break-all;

  &::after {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
  }
`

const SlicedDecimalTooltip = ({
  customTooltipPosition,
  children,
}: SlicedDecimalTooltipProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const calculatePosition = () => {
    const parentElement = ref.current?.parentElement

    if (!parentElement || ref.current === null) return

    const { width, height, top, left } = parentElement.getBoundingClientRect()

    ref.current.style.top = `${
      customTooltipPosition?.top
        ? top + customTooltipPosition.top
        : top + height + 14
    }px`
    ref.current.style.left = `${
      customTooltipPosition?.left
        ? left + customTooltipPosition.left
        : left + width / 2
    }px`
  }

  useEffect(() => {
    calculatePosition()
    window.addEventListener('scroll', calculatePosition)

    return () => window.removeEventListener('scroll', calculatePosition)
  }, [])

  return (
    <SlicedDecimalTooltipWrapper ref={ref}>
      {children}
    </SlicedDecimalTooltipWrapper>
  )
}

export default SlicedDecimalTooltip

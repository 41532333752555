// import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  font-size: 14px;
  color: #9d9999;

  @media (max-width: 1200px) {
    position: sticky;
    width: 100vw;
    top: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    left: 20px;
    width: calc(100vw - 40px);
  }
`

const PaginationPageButton = styled.div<{ $isActive?: boolean }>`
  ${({ $isActive }) =>
    $isActive
      ? `
    font-weight: 700;
    color: #2e2e2e;
  `
      : ''}

  cursor: pointer;

  & + & {
    margin-left: 10px;
  }
`

const PaginationSideIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;

  img {
    width: 14px;
    height: 14px;
    margin: 0 2px;
    cursor: pointer;
  }
`

const Pagination = () => {
  // const { page } = useParams()

  // console.log(page)

  return (
    <PaginationWrapper>
      <PaginationSideIcon>
        <img src="/dexAssets/first_arrow_button.png" alt="First Page Button" />
        <img src="/dexAssets/prev_arrow_button.png" alt="Prev Page Button" />
      </PaginationSideIcon>

      <PaginationPageButton $isActive>1</PaginationPageButton>
      {/* <PaginationPageButton>2</PaginationPageButton>
      <PaginationPageButton>3</PaginationPageButton>
      <PaginationPageButton $isActive="true">4</PaginationPageButton>
      <PaginationPageButton>5</PaginationPageButton>
      <PaginationPageButton>6</PaginationPageButton>
      <PaginationPageButton>7</PaginationPageButton>
      <PaginationPageButton>8</PaginationPageButton>
      <PaginationPageButton>9</PaginationPageButton>
      <PaginationPageButton>10</PaginationPageButton> */}

      <PaginationSideIcon>
        <img src="/dexAssets/next_arrow_button.png" alt="Prev Page Button" />
        <img src="/dexAssets/last_arrow_button.png" alt="First Page Button" />
      </PaginationSideIcon>
    </PaginationWrapper>
  )
}

export default Pagination

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type StakingRequestModalStateType = {
  visible: boolean
  pairAddress: string | undefined
  pid: number | undefined
}

const stakingRequestModalAtom = atom<StakingRequestModalStateType>({
  key: 'stakingRequestModal',
  default: {
    visible: false,
    pairAddress: undefined,
    pid: undefined,
  },
})

export const useManageStakingRequestModalState = (): {
  stakingRequestModalState: StakingRequestModalStateType
  setStakingRequestModalState: SetterOrUpdater<StakingRequestModalStateType>
  resetStakingRequestModalState: Resetter
} => {
  const [stakingRequestModalState, setStakingRequestModalState] =
    useRecoilState(stakingRequestModalAtom)
  const resetStakingRequestModalState = useResetRecoilState(
    stakingRequestModalAtom,
  )

  return {
    stakingRequestModalState,
    setStakingRequestModalState,
    resetStakingRequestModalState,
  }
}

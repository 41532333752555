import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import SearchInput from '../atoms/SearchInput'
import MyAssetListFilterCheckbox from '../atoms/MyAssetListFilterCheckbox'
import MyAssetsList from '../organisms/MyAssetsList'

const AssetListSectionWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 75px auto 0;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
`

const Title = styled.div`
  margin-right: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
`

const RightSideWrapper = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: ${({ language }) =>
      language === 'ko' ? '180' : '216'}px auto;
  grid-gap: 20px;
  align-items: center;
  font-size: 12px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`

const MyAssetsListTemplate = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'MyAssets.MyAssetsListTemplate',
  })
  const { width } = useGetWindowSize()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isFiltered, setIsFiltered] = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value)

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) =>
    setIsFiltered(event.target.checked)

  return (
    <AssetListSectionWrapper>
      <TitleWrapper>
        <Title>{t('title')}</Title>
        <RightSideWrapper language={language}>
          {width > 768 ? (
            <SearchInput
              placeholder={t('search')}
              value={searchQuery}
              onChange={handleChange}
            />
          ) : null}
          <MyAssetListFilterCheckbox
            checked={isFiltered}
            onChange={handleCheck}
          >
            {t('checkbox')}
          </MyAssetListFilterCheckbox>
        </RightSideWrapper>
      </TitleWrapper>

      {width !== 0 && width <= 768 ? (
        <SearchInput
          placeholder={t('search')}
          value={searchQuery}
          onChange={handleChange}
        />
      ) : null}

      <MyAssetsList searchQuery={searchQuery} isFiltered={isFiltered} />
    </AssetListSectionWrapper>
  )
}

export default MyAssetsListTemplate

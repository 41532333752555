import { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { CSS_Z_INDEX } from '../../../utils/constants'

type NoticeTooltipProps = {
  children: ReactNode
  iconCustomStyle?: CSSProperties
  widgetCustomStyle?: CSSProperties
}

const TooltipIcon = styled.img`
  width: 100%;
  cursor: pointer;
`

const NoticeTooltipWidget = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translate(-50%);
  z-index: ${CSS_Z_INDEX.tooltip};
  padding: 29px 21px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  color: #ffffff;
  line-height: 1.43;
  white-space: pre-line;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
  }
`

const NoticeTooltipWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  &:hover ${NoticeTooltipWidget} {
    display: block;
  }
`

const NoticeTooltip = ({
  children,
  iconCustomStyle,
  widgetCustomStyle,
}: NoticeTooltipProps) => {
  return (
    <NoticeTooltipWrapper style={iconCustomStyle}>
      <TooltipIcon src="/dexAssets/tooltip_icon.png" alt="Tooltip Icon" />

      <NoticeTooltipWidget style={widgetCustomStyle}>
        {children}
      </NoticeTooltipWidget>
    </NoticeTooltipWrapper>
  )
}

export default NoticeTooltip

import { NftItemType, NftCardType } from '../api/query/useInventoryItemsQuery'
import { StakingListItemType } from '../api/query/useStakingListQuery'
import { NftCardRarityType, NftDataType, NftItemRarityType } from './types'
import { NFT_ITEM_SORT_KEY, NFT_CARD_SORT_KEY } from './constants'

export const sortNeuronStakingList = [
  (prev: StakingListItemType, next: StakingListItemType) =>
    next.startDate - prev.startDate,
  (prev: StakingListItemType, next: StakingListItemType) =>
    prev.startDate - next.startDate,
  (prev: StakingListItemType, next: StakingListItemType) =>
    parseInt(next.amount) - parseInt(prev.amount),
]

export const sortNftItemsAndCards = [
  () => 0,
  (prev: NftItemType | NftCardType, next: NftItemType | NftCardType) =>
    new Date(next.createdAt).getTime() - new Date(prev.createdAt).getTime(),
  (prev: NftItemType | NftCardType, next: NftItemType | NftCardType) =>
    new Date(prev.createdAt).getTime() - new Date(next.createdAt).getTime(),
  (
    prev: (NftItemType | NftCardType) & { type: NftDataType },
    next: (NftItemType | NftCardType) & { type: NftDataType },
  ) => {
    if (prev.type !== next.type) return -1
    else if (prev.type === 'item' && next.type === 'item')
      return (
        NFT_ITEM_SORT_KEY[next.rarity as NftItemRarityType] -
        NFT_ITEM_SORT_KEY[prev.rarity as NftItemRarityType]
      )
    else if (prev.type === 'card' && next.type === 'card')
      return (
        NFT_CARD_SORT_KEY[next.rarity as NftCardRarityType] -
        NFT_CARD_SORT_KEY[prev.rarity as NftCardRarityType]
      )
    else return 0
  },
]

import {
  atom,
  useRecoilState,
  SetterOrUpdater,
  useResetRecoilState,
  Resetter,
} from 'recoil'

type TransactionHistoryItemType = {
  type: 'swap' | 'deposit' | 'withdraw'
  hash: string
  status: boolean
  date: string
}

type TransactionHistoryStateType = TransactionHistoryItemType[]

const transactionHistoryAtom = atom<TransactionHistoryStateType>({
  key: 'transactionHistory',
  default: [],
})

export const useManageTransactionHistoryState = (): {
  transactionHistoryState: TransactionHistoryStateType
  setTransactionHistoryState: SetterOrUpdater<TransactionHistoryStateType>
  resetTransactionHistoryState: Resetter
} => {
  const [transactionHistoryState, setTransactionHistoryState] = useRecoilState(
    transactionHistoryAtom,
  )
  const resetTransactionHistoryState = useResetRecoilState(
    transactionHistoryAtom,
  )

  return {
    transactionHistoryState,
    setTransactionHistoryState,
    resetTransactionHistoryState,
  }
}

import { useQuery } from 'react-query'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import AxiosClient from '../axiosConfig'

export type StakingRewardsType = {
  data: {
    rewards: number
    accumulatedRewards: number
  }
}

const loadStakingRewards = async () =>
  await AxiosClient.getAxiosInstance(true).get('/api/staking/rewards')

export default function useStakingRewardsQuery() {
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()

  return useQuery<StakingRewardsType['data']>(
    'stakingRewards',
    async () => {
      const {
        data: { data },
      } = await loadStakingRewards()

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 10,
    },
  )
}

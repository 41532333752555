import styled from 'styled-components'
import convertNumber from '../../../utils/convertNumber'
import SlicedDecimal from './SlicedDecimal'

type DepositedItemClaimableTokenProps = {
  claimableNeuron: number
}

const ClaimableTokenItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #9d9999;

  & + & {
    margin-top: 2px;
  }

  span {
    margin-left: 3px;
    font-weight: 400;
  }
`

const DepositedItemClaimableToken = ({
  claimableNeuron,
}: DepositedItemClaimableTokenProps) => {
  return (
    <div>
      <ClaimableTokenItem>
        <SlicedDecimal maxIntLength={7} suffix=" NR">
          {convertNumber(claimableNeuron, { comma: true, maxDigit: 6 })}
        </SlicedDecimal>{' '}
        <span>NR</span>
      </ClaimableTokenItem>
    </div>
  )
}

export default DepositedItemClaimableToken

import styled from 'styled-components'
import TokenModalListSectionHead from '../molecules/TokenModalListSectionHead'
import TokenModalSectionFavoriteList from '../molecules/TokenModalSectionFavoriteList'
import TokenModalSectionList from '../molecules/TokenModalSectionList'

type TokenModalListSectionProps = {
  type: 'favorite' | 'normal'
  title: string
}

const TokenModalListSectionWrapper = styled.div`
  & + & {
    margin-top: 30px;
  }
`

const TokenModalListSection = ({ type, title }: TokenModalListSectionProps) => {
  return (
    <TokenModalListSectionWrapper>
      <TokenModalListSectionHead isVisibleSelectBox={type === 'normal'}>
        {title}
      </TokenModalListSectionHead>

      {type === 'favorite' ? (
        <TokenModalSectionFavoriteList />
      ) : (
        <TokenModalSectionList />
      )}
    </TokenModalListSectionWrapper>
  )
}

export default TokenModalListSection

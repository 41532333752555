import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import Header from '../organisms/Header'
import MobileHeader from '../organisms/MobileHeader'
import Footer from '../organisms/Footer'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type TemplateLayoutProps = {
  children: ReactNode
  fullWidth?: boolean
  background?: string
}

const TemplateLayoutWrapper = styled.div<
  Pick<TemplateLayoutProps, 'background'>
>`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  background: ${props => props.background};

  @media (min-width: 769px) {
    padding-top: 70px;
  }
`

const Contents = styled.div<{
  fullWidth?: boolean
  isDepositPage: string
}>`
  ${props =>
    !props.fullWidth &&
    `
  max-width: 1200px;
  `}

  flex: 1;
  width: 100%;
  margin: 0 auto 125px;

  @media (max-width: 768px) {
    width: 100%;
    padding: ${({ fullWidth }) => (fullWidth ? '0' : '0 20px')};
    margin: ${({ isDepositPage }) =>
        isDepositPage === 'true' ? '84' : '176'}px
      0 56px;
  }
`

const TemplateLayout = ({
  children,
  fullWidth,
  background = '#F8F8F8',
}: TemplateLayoutProps) => {
  const { width } = useGetWindowSize()
  const { pairAddress } = useParams()

  useEffect(() => {
    return () => window.scrollTo(0, 0)
  }, [])

  return (
    <TemplateLayoutWrapper background={background}>
      {width > 768 ? <Header /> : <MobileHeader />}
      <Contents
        fullWidth={fullWidth}
        isDepositPage={String(pairAddress !== undefined)}
      >
        {children}
      </Contents>
      <Footer />
    </TemplateLayoutWrapper>
  )
}

export default TemplateLayout

import { prepare, request } from 'klip-sdk'
import { useQueryClient } from 'react-query'
import useCallMessageBox from './useCallMessageBox'
import {
  ConnectedWalletStateType,
  useManageConnectedWalletState,
} from '../state/shared/connectedWalletState'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectWalletModalState } from '../state/modal/connectWalletModalState'
import checkMobileDevice from '../utils/checkMobileDevice'
import { WALLET_INSTALL_LINK } from '../utils/constants'
import useCreateSessionMutation from '../api/mutation/useCreateSessionMutation'
import AxiosClient from '../api/axiosConfig'

export default function useHandleConnectWallet() {
  const { setConnectedWalletState } = useManageConnectedWalletState()
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const createSessionMutation = useCreateSessionMutation()
  const queryClient = useQueryClient()
  const pushMessage = useCallMessageBox()

  const klipConnectSuccessCallback = (result: any) => {
    const address = result.klaytn_address as string

    if (!address) {
      pushMessage('wallet', 'failure')
      return
    }

    createSessionMutation.mutate(address, {
      onSuccess: ({
        data: {
          data: { accessToken },
        },
      }) => {
        AxiosClient.setAccessToken(accessToken as string)
        queryClient.removeQueries()
        setConnectedWalletState({ type: 'klip', address })
        pushMessage('wallet', 'success')
        window.sessionStorage.setItem('neuronSwapWalletType', 'klip')
        window.sessionStorage.setItem('neuronSwapKlipAddress', address)
      },
    })
  }

  const handleKlipConnect = async () => {
    const { err, request_key } = await prepare.auth({
      bappName: 'NEURONswap',
    })

    if (err) throw new Error()

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: klipConnectSuccessCallback,
    })

    if (checkMobileDevice()) request(request_key)
  }

  const handleKaikasConnect = async () => {
    if (window.klaytn === undefined) {
      pushMessage('wallet', 'failure', '카이카스 설치 후 이용해주세요')
      setTimeout(() => window.open(WALLET_INSTALL_LINK.kaikas), 1500)
      return
    }

    const address = await window.klaytn.enable()

    if (window.klaytn.networkVersion.toString() !== '8217') {
      pushMessage('wallet', 'failure')
      return
    }

    createSessionMutation.mutate(address[0] as string, {
      onSuccess: ({
        data: {
          data: { accessToken },
        },
      }) => {
        AxiosClient.setAccessToken(accessToken as string)
        queryClient.removeQueries()
        setConnectedWalletState({ type: 'kaikas', address: address[0] })
        pushMessage('wallet', 'success')
        window.sessionStorage.setItem('neuronSwapWalletType', 'kaikas')
      },
    })
  }

  const handleBitkeepConnect = async () => {
    if (checkMobileDevice()) {
      window.BitKeepInvoke.getAddress((err: any, data: any) => {
        if (data === undefined)
          window.open(
            WALLET_INSTALL_LINK.bitkeep[
              navigator.userAgent.toLowerCase().indexOf('android') > -1
                ? 'android'
                : 'ios'
            ],
          )
        else if (data.klay === undefined) pushMessage('wallet', 'failure')
        else
          createSessionMutation.mutate(data.klay as string, {
            onSuccess: ({
              data: {
                data: { accessToken },
              },
            }) => {
              AxiosClient.setAccessToken(accessToken as string)
              queryClient.removeQueries()
              setConnectedWalletState({
                type: 'bitkeep',
                address: data.klay as string,
              })
              pushMessage('wallet', 'success')
              window.sessionStorage.setItem('neuronSwapWalletType', 'bitkeep')
            },
          })
      })
      return
    }

    if (!window.ethereum || !window.ethereum.isBitKeep) {
      pushMessage('wallet', 'failure', 'BitKeep 설치 후 이용해주세요')
      setTimeout(() => window.open(WALLET_INSTALL_LINK.bitkeep.pc), 1500)
      return
    }

    const address = await window.ethereum.request({
      method: 'eth_requestAccounts',
    })

    if (window.ethereum.networkVersion.toString() !== '8217') {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x2019' }],
        })
      } catch (e) {
        console.log(e)
        pushMessage('wallet', 'failure')
      }
    }

    createSessionMutation.mutate(address[0] as string, {
      onSuccess: ({
        data: {
          data: { accessToken },
        },
      }) => {
        AxiosClient.setAccessToken(accessToken as string)
        queryClient.removeQueries()
        setConnectedWalletState({ type: 'bitkeep', address: address[0] })
        pushMessage('wallet', 'success')
        window.sessionStorage.setItem('neuronSwapWalletType', 'bitkeep')
      },
    })
  }

  const handleConnectWallet = (
    type: NonNullable<ConnectedWalletStateType['type']>,
  ) => {
    setConnectWalletModalState(false)

    try {
      if (type === 'klip') handleKlipConnect()
      else if (type === 'kaikas') handleKaikasConnect()
      else if (type === 'bitkeep') handleBitkeepConnect()
    } catch (error) {
      pushMessage('wallet', 'failure')
    }
  }

  return handleConnectWallet
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const ReferralClaimPolicyWrapper = styled.div`
  margin-top: auto;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #707070;

  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  @media (max-width: 768px) {
    img {
      width: 16px;
      height: 16px;
      margin-left: -2px;
      margin-right: 3px;
    }
  }
`

const StyledLine = styled.div`
  margin: 16px 0 14px;
  width: 100%;
  height: 1px;
  background: #707070;
`

const DetailItem = styled.div`
  display: flex;
  font-size: 12px;
  color: #707070;
  line-height: 1.67;
  white-space: pre-line;

  img {
    margin-top: 3px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }

  span {
    font-size: 10.5px;
    font-weight: 500;
  }

  & + & {
    margin-top: 10px;
  }
`

const ReferralClaimPolicy = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralRewards.ReferralClaimPolicy',
  })
  const { width } = useGetWindowSize()

  return (
    <ReferralClaimPolicyWrapper>
      <Title>
        <img src="/dexAssets/info_icon.png" alt="Referral Claim Policy Icon" />
        {t('title')}
      </Title>

      <StyledLine />

      {width > 768 ? (
        <>
          <DetailItem>
            <img
              src="/dexAssets/referral_policy_item_icon.png"
              alt="Referral Claim Policy Icon"
            />
            <div style={{ letterSpacing: language === 'ko' ? '0.1px' : '0' }}>
              {`${t('policy1.0')} `}
              <span>{t('policy1.1')}</span>
            </div>
          </DetailItem>
          <DetailItem>
            <img
              src="/dexAssets/referral_policy_item_icon.png"
              alt="Referral Claim Policy Icon"
            />
            <div style={{ letterSpacing: language === 'ko' ? '-0.12px' : '0' }}>
              {t('policy2')}
            </div>
          </DetailItem>
        </>
      ) : (
        <DetailItem>
          <img
            src="/dexAssets/referral_policy_item_icon.png"
            alt="Referral Claim Policy Icon"
          />
          <div style={{ letterSpacing: language === 'ko' ? '-0.12px' : '0' }}>
            {t('mobilePolicy')}
          </div>
        </DetailItem>
      )}
    </ReferralClaimPolicyWrapper>
  )
}

export default ReferralClaimPolicy

import styled from 'styled-components'

const FooterMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FooterMenuItem = styled.a`
  img {
    width: 40px;
    height: 40px;
  }

  & + & {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    img {
      width: 24px;
      height: 24px;
    }

    & + & {
      margin-left: 16px;
    }
  }
`

const FooterMenu = () => {
  return (
    <FooterMenuWrapper>
      <FooterMenuItem
        href="https://documents.neuronswap.com/neuronswap/"
        target="_blank"
      >
        <img src="/dexAssets/footer_icons/docs.png" alt="Footer Link" />
      </FooterMenuItem>
      <FooterMenuItem href="https://medium.com/@neuronswap" target="_blank">
        <img src="/dexAssets/footer_icons/medium.png" alt="Footer Link" />
      </FooterMenuItem>
      <FooterMenuItem href="https://twitter.com/neuronswap" target="_blank">
        <img src="/dexAssets/footer_icons/twitter.png" alt="Footer Link" />
      </FooterMenuItem>
      <FooterMenuItem href="https://t.me/NEURONswap">
        <img src="/dexAssets/footer_icons/telegram.png" alt="Footer Link" />
      </FooterMenuItem>
      <FooterMenuItem href="mailto:hello@neuronswap.com">
        <img src="/dexAssets/footer_icons/mail.png" alt="Footer Link" />
      </FooterMenuItem>
    </FooterMenuWrapper>
  )
}

export default FooterMenu

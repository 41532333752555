import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

export const MobileMenuChangeWalletStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`

const ChangeWalletItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9d9999;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

const StyledLine = styled.div`
  width: 1px;
  height: 16px;
  margin: 0 12px;
  background: #edeaea;
`

const MobileMenuChangeWalletStatus = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.MobileMenu',
  })
  const {
    connectedWalletState: { type, address },
    resetConnectedWalletState,
  } = useManageConnectedWalletState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()

  const handleLogout = () => {
    resetConnectedWalletState()
    window.localStorage.removeItem('neuronSwapWalletType')

    if (type === 'klip') window.localStorage.removeItem('neuronSwapKlipAddress')
  }

  const handleChangeWallet = () => {
    handleLogout()
    setConnectWalletModalState(true)
  }

  if (type === undefined || address === undefined) return null

  return (
    <MobileMenuChangeWalletStatusWrapper>
      <CopyToClipboard text={address}>
        <ChangeWalletItem>
          <img
            src="/dexAssets/modal_icons/mobile_menu/copy_address_icon.png"
            alt="Logout Icon"
          />
          {t('copyAddress')}
        </ChangeWalletItem>
      </CopyToClipboard>
      <StyledLine />
      <ChangeWalletItem onClick={handleChangeWallet}>
        <img
          src="/dexAssets/modal_icons/mobile_menu/change_wallet_icon.png"
          alt="Change Wallet Icon"
        />
        {t('walletChange')}
      </ChangeWalletItem>
    </MobileMenuChangeWalletStatusWrapper>
  )
}

export default MobileMenuChangeWalletStatus

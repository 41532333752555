import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type SwapInfoStateType = {
  exchangeRatePerToValue: number
  fee: number
  transactionRewards: number
  bestTradeAddress: string[]
  diff: string
}

const swapInfoAtom = atom<SwapInfoStateType>({
  key: 'swapInfo',
  default: {
    exchangeRatePerToValue: 0,
    fee: 0,
    transactionRewards: 0,
    bestTradeAddress: [],
    diff: '',
  },
})

export const useManageSwapInfoState = (): {
  swapInfoState: SwapInfoStateType
  setSwapInfoState: SetterOrUpdater<SwapInfoStateType>
  resetSwapInfoState: Resetter
} => {
  const [swapInfoState, setSwapInfoState] = useRecoilState(swapInfoAtom)
  const resetSwapInfoState = useResetRecoilState(swapInfoAtom)

  return { swapInfoState, setSwapInfoState, resetSwapInfoState }
}

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type DepositMainDetailItemProps = {
  title: string
  children: ReactNode
  type: 'exchangeRate' | 'notice'
  onClick?: () => void
}

const DepositMainDetailItemWrapper = styled.div<{
  type: DepositMainDetailItemProps['type']
}>`
  &:not(:last-child) {
    padding-bottom: 21.5px;
  }

  & + & {
    padding-top: 23px;
    border-top: 0.5px solid #9d9999;
  }

  @media (max-width: 768px) {
    ${({ type }) =>
      type === 'exchangeRate'
        ? `
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    `
        : ''}

    &:not(:last-child) {
      padding-bottom: 16px;
    }

    & + & {
      padding-top: 0;
      border-top: 0;
    }
  }
`

const DetailItemTitle = styled.div<{
  type: DepositMainDetailItemProps['type']
}>`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-size: 10px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    ${({ type }) =>
      type === 'exchangeRate'
        ? `
    margin-bottom: 0;
    margin-top: 3px;
    `
        : ''}
    font-size: 12px;
  }
`

const DetailItemContent = styled.div<{
  type: DepositMainDetailItemProps['type']
  language: string
}>`
  display: flex;
  flex-wrap: wrap;
  width: 183px;
  font-size: 10px;
  color: #9d9999;
  line-height: 1.6;
  white-space: pre-line;

  @media (max-width: 768px) {
    width: unset;

    ${({ type }) =>
      type === 'exchangeRate'
        ? `
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
      `
        : ''}
  }
`

const DepositTitleIcon = styled.img<{
  type: DepositMainDetailItemProps['type']
}>`
  width: 12px;
  height: 12px;
  margin-${({ type }) => (type === 'exchangeRate' ? 'left' : 'right')}: 4px;
`

const DepositMainDetailItem = ({
  title,
  children,
  type,
  onClick,
}: DepositMainDetailItemProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const { width } = useGetWindowSize()

  return (
    <DepositMainDetailItemWrapper type={type}>
      <DetailItemTitle type={type}>
        {type === 'exchangeRate' ? (
          <>
            {title}
            {width > 768 ? (
              <DepositTitleIcon
                src="/dexAssets/deposit_exchange_rate_icon.png"
                alt="Exchange Rate Icon"
                type={type}
                onClick={onClick}
              />
            ) : null}
          </>
        ) : (
          <>
            <DepositTitleIcon
              src="/dexAssets/deposit_notice_icon.png"
              alt="Deposit Notice Icon"
              type={type}
            />
            {title}
          </>
        )}
      </DetailItemTitle>
      <DetailItemContent type={type} language={language}>
        {children}
      </DetailItemContent>
    </DepositMainDetailItemWrapper>
  )
}

export default DepositMainDetailItem

type ParsedDateType = {
  year: string
  month: string
  day: string
  hour: string
  minute: string
}

export default function parseDate(timestamp: number): ParsedDateType {
  const date = new Date(timestamp)

  const year = date.getFullYear().toString()
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : String(date.getMonth() + 1)
  const day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString()

  const hour =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString()
  const minute =
    date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes().toString()

  return { year, month, day, hour, minute }
}

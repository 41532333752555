import TemplateLayout from '../templates/TemplateLayout'
import MyAssetsPortfolioTemplate from '../templates/MyAssetsPortfolioTemplate'
import MyAssetsListTemplate from '../templates/MyAssetsListTemplate'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

const MyAssetsPage = () => {
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()

  return (
    <TemplateLayout>
      {address !== undefined ? <MyAssetsPortfolioTemplate /> : null}
      <MyAssetsListTemplate />
    </TemplateLayout>
  )
}

export default MyAssetsPage

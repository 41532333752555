import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'
import { InputPositionType } from '../../utils/types'

type TokenSelectModalStateType = {
  isVisible: boolean
  position: InputPositionType
  searchInput: string
  isFiltered: boolean
}

const tokenSelectModalAtom = atom<TokenSelectModalStateType>({
  key: 'tokenSelectModal',
  default: {
    isVisible: false,
    position: 'from',
    searchInput: '',
    isFiltered: false,
  },
})

export const useManageTokenSelectModalState = (): {
  tokenSelectModalState: TokenSelectModalStateType
  setTokenSelectModalState: SetterOrUpdater<TokenSelectModalStateType>
  resetTokenSelectModalState: Resetter
} => {
  const [tokenSelectModalState, setTokenSelectModalState] =
    useRecoilState(tokenSelectModalAtom)
  const resetTokenSelectModalState = useResetRecoilState(tokenSelectModalAtom)

  return {
    tokenSelectModalState,
    setTokenSelectModalState,
    resetTokenSelectModalState,
  }
}

import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const Section = styled.section`
  padding: 140px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 45px 0 0;
  }
`

const Title = styled.img`
  margin-bottom: 70px;
  height: 71px;

  @media (max-width: 768px) {
    width: 210px;
    height: unset;
    margin-bottom: 40px;
  }
`

const LogosRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  > img {
    height: 75px;
    margin-right: 100px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;

    > img {
      width: 110px;
      height: unset;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const MainInfrastructureSection = () => {
  const { width } = useGetWindowSize()

  return (
    <Section>
      <Title
        src={`/dexAssets/main_page/infrastructure/${
          width > 768 ? 'title.svg' : 'title_mobile.png'
        }`}
      />

      {width > 768 ? (
        <>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/klayton.png" />
            <img src="/dexAssets/main_page/infrastructure/open_sea.png" />
          </LogosRow>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/meta_mask.png" />
            <img src="/dexAssets/main_page/infrastructure/neuron_swap.png" />
            <img src="/dexAssets/main_page/infrastructure/kaikas.png" />
          </LogosRow>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/rbit.png" />
            <img src="/dexAssets/main_page/infrastructure/klip.png" />
            <img src="/dexAssets/main_page/infrastructure/uniswap.png" />
          </LogosRow>
        </>
      ) : (
        <>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/klayton.png" />
            <img src="/dexAssets/main_page/infrastructure/open_sea.png" />
          </LogosRow>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/meta_mask.png" />
            <img src="/dexAssets/main_page/infrastructure/neuron_swap.png" />
          </LogosRow>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/kaikas.png" />
            <img src="/dexAssets/main_page/infrastructure/rbit.png" />
          </LogosRow>
          <LogosRow>
            <img src="/dexAssets/main_page/infrastructure/klip.png" />
            <img src="/dexAssets/main_page/infrastructure/uniswap.png" />
          </LogosRow>
        </>
      )}
    </Section>
  )
}
export default MainInfrastructureSection

type DashboardTimeType = {
  year: string
  month: string
  day: string
  hour: string
  minute: string
}

export function getCurrentTimeForHistory(): string {
  const date = new Date()

  const year = date.getFullYear().toString().substring(2)
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const second =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  return `${year}-${month}-${day}\n${hour}:${minute}:${second}`
}

export function getCurrentTimeForDashboard(): DashboardTimeType {
  const date = new Date()

  const year = date.getFullYear().toString()
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : String(date.getMonth() + 1)
  const day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString()

  const hour =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString()
  const minute =
    date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes().toString()

  return { year, month, day, hour, minute }
}

export function convertTimeForDashboard(
  language: string,
  { year, month, day, hour, minute }: DashboardTimeType,
) {
  return language === 'ko'
    ? `${year}.${month}.${day} ${hour}:${minute} 기준`
    : `At ${hour}:${minute} ${year}-${month}-${day}`
}

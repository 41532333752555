import styled from 'styled-components'
import produce from 'immer'
import { useTranslation } from 'react-i18next'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type SwapMainItemBalanceProps = {
  balance: string
  isVisibleMaxButton: boolean
}

const SwapMainItemBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 -7px 29px;
  font-size: 12px;
  color: #9d9999;
  line-height: 1.33;

  @media (max-width: 768px) {
    margin: 0;
    font-size: 11px;
  }
`

const BalanceText = styled.span`
  margin-right: 3px;
`

const TokenMaxAmount = styled.div<{
  isVisible: string
  isMax: string
}>`
  margin-left: 6px;
  display: ${({ isVisible }) => (isVisible === 'true' ? 'grid' : 'none')};
  place-items: center;
  width: 40px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #9d9999;
  background: ${({ isMax }) => (isMax === 'true' ? '#9d9999' : 'transparent')};
  font-size: 11px;
  font-weight: 700;
  color: ${({ isMax }) => (isMax === 'true' ? '#ffffff' : '#9d9999')};
  line-height: 1.55;
  transition: 0.15s all;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 4px;
    width: 30px;
    font-weight: 400;
  }
`

const SwapMainItemBalance = ({
  balance,
  isVisibleMaxButton,
}: SwapMainItemBalanceProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapMain',
  })
  const {
    swapFormState: {
      settings: { isMax },
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const { width } = useGetWindowSize()

  const handleToggleAmountMax = () => {
    if (balance === '0' || balance === '') return

    if (!isMax)
      setSwapFormState(prev =>
        produce(prev, draft => {
          draft.form.from.value = balance.toString()
          draft.form.lastModified = 'from'
          draft.settings.isMax = true

          return draft
        }),
      )
    else
      setSwapFormState(prev =>
        produce(prev, draft => {
          draft.settings.isMax = false

          return draft
        }),
      )
  }

  return (
    <SwapMainItemBalanceWrapper>
      <BalanceText>{t(width > 768 ? 'balance' : 'mobileBalance')}</BalanceText>
      <SlicedDecimal
        maxIntLength={width > 768 ? 12 : 9}
        prefix={`${t('balance')} `}
        customTooltipPosition={{ left: -12 }}
      >
        {convertNumber(balance, { maxDigit: 6 })}
      </SlicedDecimal>
      <TokenMaxAmount
        isVisible={String(isVisibleMaxButton)}
        isMax={String(isMax)}
        onClick={handleToggleAmountMax}
      >
        {t('max')}
      </TokenMaxAmount>
    </SwapMainItemBalanceWrapper>
  )
}

export default SwapMainItemBalance

import styled from 'styled-components'

type TransactionHistoryItemHashProps = {
  hash: string
  status: boolean
}

const TransactionHistoryItemHashWrapper = styled.div<{ status: string }>`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  font-size: 14px;
  color: ${({ status }) => (status === 'true' ? '#65dfb9' : '#9d9999')};
  fill: ${({ status }) => (status === 'true' ? '#65dfb9' : '#9d9999')};

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const HashText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const LinkIcon = styled.a`
  display: grid;
  place-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    svg {
      transform: scale(0.75);
    }
  }
`

const TransactionHistoryItemHash = ({
  hash,
  status,
}: TransactionHistoryItemHashProps) => {
  return (
    <TransactionHistoryItemHashWrapper status={String(status)}>
      <HashText>{hash}</HashText>

      <LinkIcon
        href={`https://scope.klaytn.com/tx/${hash}?tabId=tokenTransfer`}
        target="_blank"
      >
        <svg
          data-name="icon / launch / 16px"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: status ? '#65dfb9' : '#9d9999' }}
        >
          <path
            data-name="패스 332"
            d="M0 0h16v16H0z"
            style={{ fill: 'none' }}
          />
          <path
            data-name="패스 333"
            d="M15.444 15.444H4.556V4.556H10V3H4.556A1.555 1.555 0 0 0 3 4.556v10.888A1.555 1.555 0 0 0 4.556 17h10.888A1.56 1.56 0 0 0 17 15.444V10h-1.556zM11.556 3v1.556h2.792L6.7 12.2l1.1 1.1 7.646-7.646v2.79H17V3z"
            transform="translate(-2 -2)"
          />
        </svg>
      </LinkIcon>
    </TransactionHistoryItemHashWrapper>
  )
}

export default TransactionHistoryItemHash

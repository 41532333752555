import {
  atom,
  useRecoilState,
  SetterOrUpdater,
  useResetRecoilState,
  Resetter,
} from 'recoil'

type ApproveModalStateType = {
  visible: boolean
  type: 'token' | 'staking' | 'minting' | 'exchange' | 'participate' | undefined
}

const approveModalAtom = atom<ApproveModalStateType>({
  key: 'approveModal',
  default: {
    visible: false,
    type: undefined,
  },
})

export const useManageApproveModalState = (): {
  approveModalState: ApproveModalStateType
  setApproveModalState: SetterOrUpdater<ApproveModalStateType>
  resetApproveModalState: Resetter
} => {
  const [approveModalState, setApproveModalState] =
    useRecoilState(approveModalAtom)
  const resetApproveModalState = useResetRecoilState(approveModalAtom)

  return { approveModalState, setApproveModalState, resetApproveModalState }
}

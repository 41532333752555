import { Fragment, useMemo } from 'react'
import styled from 'styled-components'
import MyAssetsListHead from '../molecules/MyAssetsListHead'
import MyAssetsListItem, {
  MyAssetsListItemProps,
} from '../molecules/MyAssetsListItem'
import useTokenListQuery from '../../../api/query/useTokenListQuery'
import useDebounceInput from '../../../hooks/useDebounceInput'

type MyAssetsListProps = {
  searchQuery: string
  isFiltered: boolean
}

const AssetListWrapper = styled.div`
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  background: #ffffff;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`

const MyAssetsList = ({ searchQuery, isFiltered }: MyAssetsListProps) => {
  const debouncedSearchQuery = useDebounceInput(searchQuery)
  const { data } = useTokenListQuery(debouncedSearchQuery)

  const manufacturedTokenList = useMemo(() => {
    if (data === undefined) return []

    const totalValue = data.tokens.reduce(
      (totalPrice, { balance, price }) =>
        totalPrice + parseFloat(balance) * price,
      0,
    )

    return data.tokens
      .filter(({ balance }) => !isFiltered || balance !== '0')
      .map(({ address, symbol, korName, engName, balance, price }) => {
        const convertedBalance = parseFloat(balance)

        return {
          address,
          symbol,
          korName,
          engName,
          balance: convertedBalance,
          price,
          value: convertedBalance * price,
          proportion:
            totalValue !== 0
              ? ((convertedBalance * price) / totalValue) * 100
              : 0,
        } as MyAssetsListItemProps
      })
      .sort((a, b) => {
        if (a.balance * a.price < b.balance * b.price) return 1
        else return -1
      })
  }, [data, searchQuery, isFiltered])

  return (
    <Fragment>
      <MyAssetsListHead />

      <AssetListWrapper>
        {manufacturedTokenList.map(
          (tokenItem: MyAssetsListItemProps, index: number) => {
            return <MyAssetsListItem {...tokenItem} key={index} />
          },
        )}
      </AssetListWrapper>
    </Fragment>
  )
}

export default MyAssetsList

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { MainFeatureCard } from '../molecules/MainFeatureCard'

const Wrapper = styled.div`
  background: #ffeee6;
  display: flex;
  justify-content: center;
  padding-top: 125px;
  padding-bottom: 125px;
  position: relative;

  @media (max-width: 768px) {
    padding: 95px 0 82px;
    overflow: hidden;
  }
`

const VisualWrapper = styled.div`
  position: relative;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`

const VisualBackground = styled.img`
  width: 470px;
  height: 390px;

  @media (max-width: 768px) {
    width: 286px;
    height: unset;
  }
`
const VisualLight = styled.img`
  position: absolute;
  width: 236px;
  height: 204px;
  left: 50%;
  transform: translateX(-50%);
  top: 17.3%;

  @media (max-width: 768px) {
    width: 143px;
    height: unset;
  }
`

const Token03 = styled.img`
  width: 70.9px;
  height: 70.9px;
  position: absolute;
  bottom: 26.5%;
  right: 15.8%;

  @media (max-width: 768px) {
    width: 51px;
    height: 51px;
    bottom: 14%;
    right: calc(50% - 200px);
  }
`
const Token04 = styled.img`
  position: absolute;
  width: 137.3px;
  height: 137.3px;
  left: 46.9%;
  top: 7.8%;

  @media (max-width: 768px) {
    width: 86px;
    height: 86px;
    left: calc(50% - 200px);
    top: 38%;
  }
`
const Token05 = styled.img`
  position: absolute;
  width: 143.1px;
  height: 142.8px;
  bottom: 8.5%;
  right: 18.3%;

  @media (max-width: 768px) {
    width: 78.8px;
    height: 78.8px;
    bottom: 19.4px;
    right: calc(50% - 170px);
  }
`

const MainDefiSection = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Main.DefiSection',
  })
  const { width } = useGetWindowSize()

  const Visual = () => {
    return (
      <VisualWrapper>
        <VisualBackground srcSet="/dexAssets/main_page/defi/visual-bg.png 1x, /dexAssets/main_page/defi/visual-bg@2x.png 2x, /dexAssets/main_page/defi/visual-bg@3x.png 3x" />
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{
            type: 'spring',
            repeat: Infinity,
            duration: 0.75,
            repeatType: 'mirror',
          }}
        >
          <VisualLight srcSet="/dexAssets/main_page/defi/visual-light.png 1x, /dexAssets/main_page/defi/visual-light@2x.png 2x, /dexAssets/main_page/defi/visual-light@3x.png 3x" />
        </motion.div>
      </VisualWrapper>
    )
  }

  return (
    <Wrapper>
      <MainFeatureCard
        section="defi"
        heading={t('heading')}
        subHeading={t(width > 768 ? 'subHeading' : 'mobileSubHeading')}
        desc={t(width > 768 ? 'desc' : 'mobileDesc')}
        visual={<Visual />}
        spacing="190px"
        stackWidth={543}
      />
      <Token03 srcSet="/dexAssets/main_page/defi/token-03.png 1x, /dexAssets/main_page/defi/token-03@2x.png 2x, /dexAssets/main_page/defi/token-03@3x.png 3x" />
      <Token04 srcSet="/dexAssets/main_page/defi/token-04.png 1x, /dexAssets/main_page/defi/token-04@2x.png 2x, /dexAssets/main_page/defi/token-04@3x.png 3x" />
      <Token05 srcSet="/dexAssets/main_page/defi/token-05.png 1x, /dexAssets/main_page/defi/token-05@2x.png 2x, /dexAssets/main_page/defi/token-05@3x.png 3x" />
    </Wrapper>
  )
}
export default MainDefiSection

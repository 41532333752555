import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ko, en } from './locale'

i18n.use(initReactI18next).init({
  resources: { ko, en },
  fallbackLng: ['ko'],
  lng: 'ko',
  debug: false,
})

export default i18n

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PoolListHead from '../molecules/PoolListHead'
import DepositedList from '../organisms/DepositedList'

const DepositedTemplateWrapper = styled.div`
  margin-top: 77px;

  @media (max-width: 768px) {
    margin: 0 0 56px;
  }
`

const Title = styled.div`
  margin-bottom: 19px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
`

const DepositedTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.Deposited',
  })

  return (
    <DepositedTemplateWrapper>
      <Title>{t('title')}</Title>

      <PoolListHead type="Deposited" />
      <DepositedList />
    </DepositedTemplateWrapper>
  )
}

export default DepositedTemplate

import { useQuery, useQueryClient } from 'react-query'
import produce from 'immer'
import AxiosClient from '../axiosConfig'
import { InputPositionType } from '../../utils/types'
import { useManageSwapFormState } from '../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../state/swap/swapInfoState'
import { TokenType } from './useTokenQuery'

type SwapDetailQueryStringType = {
  from: string
  to: string
  lastModified: InputPositionType
  value: string
}

type SwapDetailType = {
  data: {
    from: {
      address: string
      value: string
    }
    to: {
      address: string
      value: string
    }
    detail: {
      exchangeRatePerToValue: number
      fee: number
      transactionRewards: number
      bestTradeAddress: string[]
    }
    error: boolean
  }
}

const loadSwapDetail = async (query: SwapDetailQueryStringType) =>
  await AxiosClient.getAxiosInstance(true, false).get(
    `/api/tokens/swap/${query.lastModified}?from=${query.from}&to=${query.to}&value=${query.value}`,
  )

export default function useSwapDetailQuery(query: SwapDetailQueryStringType) {
  const { setSwapFormState } = useManageSwapFormState()
  const { setSwapInfoState } = useManageSwapInfoState()
  const queryClient = useQueryClient()

  return useQuery<SwapDetailType>(
    'swapDetail',
    async () => {
      const { data: data } = await loadSwapDetail(query)

      return data
    },
    {
      enabled:
        query.from !== '' &&
        query.to !== '' &&
        query.value !== '' &&
        AxiosClient.isInitialized(),
      retry: false,
      refetchOnWindowFocus: true,
      onSuccess: ({ data }) => {
        const fromToken = queryClient.getQueryData<TokenType>([
          'token',
          query.from,
        ])
        const toToken = queryClient.getQueryData<TokenType>(['token', query.to])

        if (fromToken === undefined || toToken === undefined) return

        setSwapFormState(prev =>
          produce(prev, draft => {
            if (query.lastModified === 'to')
              draft.form.from.value = data.from.value
            else draft.form.to.value = data.to.value

            if (data.error) draft.error = 'INSUFFICIENT_LIQUIDITY'
            else if (
              parseFloat(data.from.value) > parseFloat(fromToken.balance)
            )
              draft.error = 'INSUFFICIENT_QUANTITY'
            else draft.error = undefined

            return draft
          }),
        )

        const convertedFromPrice = parseFloat(data.from.value) * fromToken.price
        const convertedToPrice = parseFloat(data.to.value) * toToken.price

        setSwapInfoState({
          ...data.detail,
          diff: String((1 - convertedFromPrice / convertedToPrice) * 100),
        })
      },
    },
  )
}

import { useMemo } from 'react'
import styled from 'styled-components'
import convertNumber from '../../../utils/convertNumber'

type ReferralDashboardListItemProps = {
  rank: number
  address: string
  amount: number
}

const ReferralDashboardListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 0.6fr 0.4fr;
  align-items: center;
  height: 47.5px;

  & + & {
    border-top: 0.5px solid #edeaea;
  }

  @media (max-width: 768px) {
    grid-template-columns: 30px 1fr 1fr;
  }
`

const Rank = styled.div<{ highlight: string }>`
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border-radius: 6px;
  background: ${({ highlight }) =>
    highlight === 'true' ? '#ff6d68' : 'transparent'};
  font-size: 14px;
  font-weight: 700;
  color: ${({ highlight }) => (highlight === 'true' ? '#ffffff' : '#2e2e2e')};
`

const Address = styled.div<{ highlight: string }>`
  padding-left: 60px;
  font-size: 12px;
  font-weight: 700;
  color: ${({ highlight }) => (highlight === 'true' ? '#ff6d68' : '#2e2e2e')};
  text-align: center;

  @media (max-width: 768px) {
    padding-left: 30px;
    font-size: 11px;
  }
`

const Tokens = styled.div<{ highlight: string }>`
  font-size: 14px;
  font-weight: ${({ highlight }) => (highlight === 'true' ? '700' : '400')};
  text-align: right;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`

const ReferralDashboardListItem = ({
  rank,
  address,
  amount,
}: ReferralDashboardListItemProps) => {
  const isHighlighted = useMemo(() => String(rank <= 3), [rank])

  return (
    <ReferralDashboardListItemWrapper>
      <Rank highlight={isHighlighted}>{rank}</Rank>

      <Address highlight={isHighlighted}>{`${address.substring(
        0,
        6,
      )}...${address.substring(address.length - 6)}`}</Address>

      <Tokens highlight={isHighlighted}>
        {convertNumber(amount, { maxDigit: 6, comma: true })}
      </Tokens>
    </ReferralDashboardListItemWrapper>
  )
}

export default ReferralDashboardListItem

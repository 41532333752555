import styled from 'styled-components'

type AvailableDepositItemDistribute = {
  distributedTokenSymbolList: string[]
}

const AvailableDepositItemDistributeWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);

  & + & {
    margin-left: 2px;
  }
`

const AvailableDepositItemDistribute = ({
  distributedTokenSymbolList,
}: AvailableDepositItemDistribute) => {
  return (
    <AvailableDepositItemDistributeWrapper>
      {distributedTokenSymbolList.map((address: string, index: number) => (
        <TokenIcon
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
          alt="Token Icon"
          key={index}
        />
      ))}
    </AvailableDepositItemDistributeWrapper>
  )
}

export default AvailableDepositItemDistribute

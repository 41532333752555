import { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageTransactionHistoryState } from '../../../state/shared/transactionHistoryState'
import TransactionHistoryItem from '../molecules/TransactionHistoryItem'

const ListEmptyText = styled.div`
  display: grid;
  place-items: center;
  padding: 60px 0;
  font-size: 14px;
  color: #9d9999;
`

const TransactionList = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'TransactionHistory',
  })
  const { transactionHistoryState, resetTransactionHistoryState } =
    useManageTransactionHistoryState()

  useEffect(() => {
    return resetTransactionHistoryState
  }, [])

  if (transactionHistoryState.length === 0)
    return <ListEmptyText>{t('empty')}</ListEmptyText>

  return (
    <Fragment>
      {transactionHistoryState.map(transactionHistory => (
        <TransactionHistoryItem
          {...transactionHistory}
          key={transactionHistory.hash}
        />
      ))}
    </Fragment>
  )
}

export default TransactionList

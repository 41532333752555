import styled from 'styled-components'
import NeuronStakingRewards from '../organisms/NeuronStakingRewards'
import StakingTransactionRewards from '../organisms/StakingTransactionRewards'

const StakingRewardsTemplateWrapper = styled.div`
  margin-top: 30px;
`

const StakingRewardsTemplate = () => {
  return (
    <StakingRewardsTemplateWrapper>
      <NeuronStakingRewards />
      <StakingTransactionRewards />
    </StakingRewardsTemplateWrapper>
  )
}

export default StakingRewardsTemplate

import { useMemo } from 'react'
import caver, { caverForKaikas } from '../modules/network/caver'
import { prepare, request } from 'klip-sdk'
import { useQueryClient } from 'react-query'
import { CONTRACT_ADDRESS, WKLAY_TOKEN_ADDRESS } from '../contract/address'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { useManageTransactionHistoryState } from '../state/shared/transactionHistoryState'
import { useManageSwapFormState } from '../state/swap/swapFormState'
import { useManageSwapInfoState } from '../state/swap/swapInfoState'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { CONTRACT_GAS_LIMIT, DEADLINE } from '../utils/constants'
import { getCurrentTimeForHistory } from '../utils/getCurrentTime'
import getAppliedSlippageValue from '../utils/getAppliedSlippageValue'
import {
  SWAP_EXACT_KLAY_FOR_TOKENS_ABI,
  SWAP_EXACT_TOKENS_FOR_KLAY_ABI,
  SWAP_EXACT_TOKENS_FOR_TOKENS_ABI,
  SWAP_KLAY_FOR_EXACT_TOKENS_ABI,
  SWAP_TOKENS_FOR_EXACT_KLAY_ABI,
  SWAP_TOKENS_FOR_EXACT_TOKENS_ABI,
} from '../contract/abi'
import checkMobileDevice from '../utils/checkMobileDevice'
import parseTransactionError from '../utils/parseTransactionError'
import { getConvertToPebValue } from '../utils/convertTokenNumberData'
import { TokenType } from '../api/query/useTokenQuery'
import web3 from '../modules/network/web3'

const ABI_MAP = {
  swapExactKLAYForTokens: SWAP_EXACT_KLAY_FOR_TOKENS_ABI,
  swapExactTokensForKLAY: SWAP_EXACT_TOKENS_FOR_KLAY_ABI,
  swapExactTokensForTokens: SWAP_EXACT_TOKENS_FOR_TOKENS_ABI,
  swapKLAYForExactTokens: SWAP_KLAY_FOR_EXACT_TOKENS_ABI,
  swapTokensForExactKLAY: SWAP_TOKENS_FOR_EXACT_KLAY_ABI,
  swapTokensForExactTokens: SWAP_TOKENS_FOR_EXACT_TOKENS_ABI,
}

export default function useSwapToken() {
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    swapFormState: {
      form: { from, to, lastModified },
      settings: { slippage },
    },
  } = useManageSwapFormState()
  const {
    swapInfoState: { bestTradeAddress },
  } = useManageSwapInfoState()
  const { setTransactionHistoryState } = useManageTransactionHistoryState()
  const queryClient = useQueryClient()

  const fromToken = queryClient.getQueryData<TokenType>([
    'token',
    from.address,
  ]) as TokenType
  const toToken = queryClient.getQueryData<TokenType>([
    'token',
    to.address,
  ]) as TokenType

  const SWAP_TYPE = useMemo(
    () =>
      from.address === WKLAY_TOKEN_ADDRESS && to.address !== WKLAY_TOKEN_ADDRESS
        ? lastModified === 'from'
          ? 'swapExactKLAYForTokens'
          : 'swapKLAYForExactTokens'
        : from.address !== WKLAY_TOKEN_ADDRESS &&
          to.address === WKLAY_TOKEN_ADDRESS
        ? lastModified === 'from'
          ? 'swapExactTokensForKLAY'
          : 'swapTokensForExactKLAY'
        : lastModified === 'from'
        ? 'swapExactTokensForTokens'
        : 'swapTokensForExactTokens',
    [from, to, lastModified],
  )

  const getSwapParameters = () => {
    const commonParam = [bestTradeAddress, address, DEADLINE]

    const paramBySwapType = {
      swapExactKLAYForTokens: [
        getConvertToPebValue(
          getAppliedSlippageValue(to.value ?? 0, slippage, 'min'),
          toToken.decimal,
        ),
      ],
      swapExactTokensForKLAY: [
        getConvertToPebValue(from.value ?? 0, fromToken.decimal),
        getConvertToPebValue(
          getAppliedSlippageValue(to.value ?? 0, slippage, 'min'),
          toToken.decimal,
        ),
      ],
      swapExactTokensForTokens: [
        getConvertToPebValue(from.value ?? 0, fromToken.decimal),
        getConvertToPebValue(
          getAppliedSlippageValue(to.value ?? 0, slippage, 'min'),
          toToken.decimal,
        ),
      ],
      swapKLAYForExactTokens: [
        getConvertToPebValue(to.value ?? 0, toToken.decimal),
      ],
      swapTokensForExactKLAY: [
        getConvertToPebValue(to.value ?? 0, toToken.decimal),
        getConvertToPebValue(
          getAppliedSlippageValue(from.value ?? 0, slippage, 'max'),
          fromToken.decimal,
        ),
      ],
      swapTokensForExactTokens: [
        getConvertToPebValue(to.value ?? 0, toToken.decimal),
        getConvertToPebValue(
          getAppliedSlippageValue(from.value ?? 0, slippage, 'max'),
          fromToken.decimal,
        ),
      ],
    }

    return [...paramBySwapType[SWAP_TYPE], ...commonParam]
  }

  const handleAddTransactionHistory = (hash: string, status: boolean) =>
    setTransactionHistoryState(prev => [
      {
        type: 'swap',
        hash,
        status,
        date: getCurrentTimeForHistory(),
      },
      ...prev,
    ])

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { tx_hash } = result

    if (result.status === 'success') {
      successCallback()
      handleAddTransactionHistory(tx_hash as string, true)
    } else {
      failureCallback()
      handleAddTransactionHistory(tx_hash as string, false)
    }
  }

  const klipSwap = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const value =
      SWAP_TYPE === 'swapExactKLAYForTokens'
        ? getConvertToPebValue(from.value ?? 0, fromToken.decimal)
        : SWAP_TYPE === 'swapKLAYForExactTokens'
        ? getConvertToPebValue(
            String(
              Math.floor(
                parseFloat(
                  getAppliedSlippageValue(from.value ?? 0, slippage, 'max'),
                ) * Math.pow(10, 6),
              ) / Math.pow(10, 6),
            ),
            fromToken.decimal,
          )
        : '0'

    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.router,
      abi: JSON.stringify(ABI_MAP[SWAP_TYPE]),
      params: JSON.stringify(getSwapParameters()),
      value,
    })

    if (err) {
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasSwap = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (SWAP_TYPE === undefined) return

    const data = caver.klay.abi.encodeFunctionCall(
      ABI_MAP[SWAP_TYPE],
      getSwapParameters(),
    )

    const value =
      SWAP_TYPE === 'swapExactKLAYForTokens'
        ? getConvertToPebValue(from.value ?? 0, fromToken.decimal)
        : SWAP_TYPE === 'swapKLAYForExactTokens'
        ? getConvertToPebValue(
            getAppliedSlippageValue(from.value ?? 0, slippage, 'max'),
            fromToken.decimal,
          )
        : '0'

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.router,
        data,
        gas: CONTRACT_GAS_LIMIT,
        value,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const bitkeepSwap = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (SWAP_TYPE === undefined) return

    const data = web3.eth.abi.encodeFunctionCall(
      ABI_MAP[SWAP_TYPE],
      getSwapParameters() as string[],
    )

    const value =
      SWAP_TYPE === 'swapExactKLAYForTokens'
        ? getConvertToPebValue(from.value ?? 0, fromToken.decimal)
        : SWAP_TYPE === 'swapKLAYForExactTokens'
        ? getConvertToPebValue(
            getAppliedSlippageValue(from.value ?? 0, slippage, 'max'),
            fromToken.decimal,
          )
        : '0'

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.router,
        data,
        gas: CONTRACT_GAS_LIMIT,
        value,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const handleSwap = async (
    swapSuccessCallback: () => void,
    swapFailureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipSwap(swapSuccessCallback, swapFailureCallback)
    else if (type === 'kaikas')
      kaikasSwap(swapSuccessCallback, swapFailureCallback)
    else if (type === 'bitkeep')
      bitkeepSwap(swapSuccessCallback, swapFailureCallback)
  }

  return handleSwap
}

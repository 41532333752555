import caver from '../modules/network/caver'
import {
  TOKEN_ADDRESS,
  CONTRACT_ADDRESS,
  CYPRESS_ICO_CA_ADDRESS,
} from './address'
import {
  TOKEN_ABI,
  ROUTER_ABI,
  MASTERCHEF_ABI,
  ICO_ABI,
  GNR_ABI,
  MRX_ABI,
  RONY_ITEM_ABI,
  RONY_CARD_ABI,
} from './abi'
import { NR_SYMBOL } from '../utils/constants'

export const neuronContract = new caver.klay.Contract(
  TOKEN_ABI,
  TOKEN_ADDRESS[NR_SYMBOL],
)

export const routerContract = new caver.klay.Contract(
  ROUTER_ABI,
  CONTRACT_ADDRESS.router,
)

export const masterchefContract = new caver.klay.Contract(
  MASTERCHEF_ABI,
  CONTRACT_ADDRESS.masterchef,
)

export const icoContract = new caver.klay.Contract(
  ICO_ABI,
  CYPRESS_ICO_CA_ADDRESS,
)

export const gnrContract = new caver.klay.Contract(
  GNR_ABI,
  CONTRACT_ADDRESS.gnr,
)

export const ronyItemContract = new caver.klay.Contract(
  RONY_ITEM_ABI,
  CONTRACT_ADDRESS.ronyItem,
)

export const ronyCardContract = new caver.klay.Contract(
  RONY_CARD_ABI,
  CONTRACT_ADDRESS.ronyCard,
)

export const mrxContract = new caver.klay.Contract(
  MRX_ABI,
  CONTRACT_ADDRESS.mrx,
)

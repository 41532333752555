import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReferralTokenRewardsItem from '../molecules/ReferralTokenRewardsItem'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import useReferralsRankQuery from '../../../api/query/useReferralsRankQuery'
import useReferralTokenRewardsQuery from '../../../api/query/useReferralTokenRewardsQuery'

const ReferralTokenRewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 315px;
  padding: 27px 40px 40px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    padding: 16px 24px 24px 24px;
  }
`

const ReferralTokenRewardsHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 11px;
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.56;

  @media (max-width: 768px) {
    margin-bottom: 6px;
  }
`

const Rank = styled.div<{ language: string }>`
  height: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;

  span {
    margin-right: ${({ language }) => (language === 'ko' ? '10' : '6')}px;
    font-weight: 400;
    color: #9d9999;
  }
`

const StyledLine = styled.div`
  width: 100%;
  height: 0.5px;
  background: #9d9999;
`

const ReferralTokenRewardsList = styled.div`
  overflow: auto;
  margin: 0 -32px;
  padding: 0 22px 0 32px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #edeaea;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    margin: 0 -20px;
    padding: 0 10px 0 20px;
  }
`

const ReferralTokenRewards = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralData.ReferralTokenRewards',
  })
  const {
    connectedWalletState: { address: walletAddress },
  } = useManageConnectedWalletState()
  const { data: referralRankData } = useReferralsRankQuery(walletAddress)
  const { data } = useReferralTokenRewardsQuery()

  return (
    <ReferralTokenRewardsWrapper>
      <ReferralTokenRewardsHead>
        <Title>{t('title')}</Title>

        <Rank language={language}>
          {referralRankData?.ranking ? (
            <>
              <span>{t('rank')}</span>
              {referralRankData?.ranking}
              {language === 'ko' ? (
                <span style={{ color: '#2e2e2e', margin: '0 0 0 4px' }}>
                  위
                </span>
              ) : null}
            </>
          ) : null}
        </Rank>
      </ReferralTokenRewardsHead>

      <StyledLine />

      <ReferralTokenRewardsList>
        {data?.map(({ address, symbol, korName, engName, rewards }) => (
          <ReferralTokenRewardsItem
            address={address}
            symbol={symbol}
            korName={korName}
            engName={engName}
            rewards={rewards}
            key={address}
          />
        ))}
      </ReferralTokenRewardsList>
    </ReferralTokenRewardsWrapper>
  )
}

export default ReferralTokenRewards

import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import checkMobileDevice from '../utils/checkMobileDevice'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'
import { HARVEST_NEURON_ABI } from '../contract/abi'
import { CONTRACT_ADDRESS } from '../contract/address'

export default function useHarvestStakingRewards() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()

  const klipHarvestStakingRewardsSuccessCallback = (
    result: any,
    successCallback: (transactionAddress: string) => Promise<void>,
  ) => {
    if (result.status === 'success') successCallback(result.tx_hash as string)
  }

  const klipHarvestStakingRewards = async (
    successCallback: (transactionAddress: string) => Promise<void>,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.nrDistributor,
      value: '0',
      abi: JSON.stringify(HARVEST_NEURON_ABI),
      params: JSON.stringify([address]),
    })

    if (err) {
      failureCallback()
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipHarvestStakingRewardsSuccessCallback(result, successCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasHarvestStakingRewards = (
    successCallback: (transactionAddress: string) => Promise<void>,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(HARVEST_NEURON_ABI, [
      address,
    ])

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.nrDistributor,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', (receipt: any) =>
        successCallback(receipt.transactionHash as string),
      )
  }

  const bitkeepHarvestStakingRewards = (
    successCallback: (transactionAddress: string) => Promise<void>,
    failureCallback: () => void,
  ) => {
    const data = web3.eth.abi.encodeFunctionCall(HARVEST_NEURON_ABI, [
      address,
    ] as string[])

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.nrDistributor,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', (receipt: any) => {
        successCallback(receipt.transactionHash as string)
      })
  }

  const handleHarvestStakingRewards = async (
    successCallback: (transactionAddress: string) => Promise<void>,
    failureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipHarvestStakingRewards(successCallback, failureCallback)
    else if (type === 'kaikas')
      kaikasHarvestStakingRewards(successCallback, failureCallback)
    else if (type === 'bitkeep')
      bitkeepHarvestStakingRewards(successCallback, failureCallback)
  }

  return handleHarvestStakingRewards
}

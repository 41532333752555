import { ReactNode } from 'react'
import styled from 'styled-components'
import { newLineToBr } from '../../../utils/newLineToBr'
import i18n from 'i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

interface MainFeatureCardProps {
  section: 'defi' | 'specialValue'
  heading: string
  subHeading: string
  desc: string
  visual: ReactNode
  flexDirection?: 'row' | 'row-reverse' | 'column'
  spacing?: string
  stackWidth: number
}

const Heading = styled.div`
  font-size: 46px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #2e2e2e;
  font-family: 'itc-avant-garde-gothic-pro';
  strong {
    font-weight: bold;
    color: #ff6d68;
    font-family: 'itc-avant-garde-gothic-pro';
  }

  @media (max-width: 768px) {
    position: relative;
    z-index: 1;
    font-size: 30px;
    text-align: center;
  }
`

const SubHeading = styled.div<{ section: MainFeatureCardProps['section'] }>`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  text-align: left;
  color: #000;
  margin-top: 37px;
  letter-spacing: ${i18n.language === 'ko' ? 'normal' : '-0.36px'};
  strong {
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: ${({ section }) => (section === 'defi' ? '20' : '18')}px;
    text-align: center;
  }
`

const Desc = styled.div<{ section?: MainFeatureCardProps['section'] }>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  text-align: left;
  color: #000;
  margin-top: 18px;
  opacity: 0.7;
  letter-spacing: ${i18n.language === 'ko' ? 'normal' : '-0.16px'};
  strong {
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-top: ${({ section }) =>
      section === 'specialValue' ? '33' : '18'}px;
    font-size: ${({ section }) => (section === 'specialValue' ? '14' : '16')}px;
    text-align: center;
  }
`

const Stack = styled.div<{ stackWidth?: number }>`
  display: flex;
  flex-direction: column;
  ${({ stackWidth }) =>
    stackWidth !== undefined ? `width: ${stackWidth}px` : `width: 100%`};
`

const HStack = styled.div<Pick<MainFeatureCardProps, 'flexDirection'>>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const SpacerH = styled.div<Pick<MainFeatureCardProps, 'spacing'>>`
  width: ${props => props.spacing};
`

export const MainFeatureCard = ({
  section,
  heading,
  subHeading,
  desc,
  visual,
  flexDirection = 'row',
  spacing = '168px',
  stackWidth,
}: MainFeatureCardProps) => {
  const { width } = useGetWindowSize()

  if (width <= 768)
    return (
      <HStack flexDirection="column">
        <Stack>
          <Heading dangerouslySetInnerHTML={{ __html: newLineToBr(heading) }} />
          {section === 'defi' ? (
            <SubHeading
              section={section}
              dangerouslySetInnerHTML={{ __html: newLineToBr(subHeading) }}
            />
          ) : null}
        </Stack>
        {visual}
        {section !== 'defi' ? (
          <SubHeading
            section={section}
            dangerouslySetInnerHTML={{ __html: newLineToBr(subHeading) }}
          />
        ) : null}
        <Desc
          section={section}
          dangerouslySetInnerHTML={{ __html: newLineToBr(desc) }}
        />
      </HStack>
    )

  return (
    <HStack flexDirection={flexDirection}>
      {visual}
      <SpacerH spacing={spacing} />
      <Stack stackWidth={stackWidth}>
        <Heading dangerouslySetInnerHTML={{ __html: newLineToBr(heading) }} />
        <SubHeading
          section={section}
          dangerouslySetInnerHTML={{ __html: newLineToBr(subHeading) }}
        />
        <Desc dangerouslySetInnerHTML={{ __html: newLineToBr(desc) }} />
      </Stack>
    </HStack>
  )
}

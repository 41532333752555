import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { useManageWithdrawRequestModalState } from '../../state/modal/withdrawRequestModalState'
import { useManageDepositFormState } from '../../state/pool/depositFormState'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useRemovePoolLiquidity from '../../hooks/useRemovePoolLiquidity'
import convertNumber from '../../utils/convertNumber'
import { CSS_Z_INDEX } from '../../utils/constants'
import multipleByDecimal from '../../utils/multipleByDecimal'
import { PoolType } from '../../api/query/usePoolQuery'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'
import { CONTRACT_ADDRESS } from '../../contract/address'
import { useEffect, useState } from 'react'
import caver from '../../modules/network/caver'
import { TOKEN_ABI } from '../../contract/abi'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import useTokenApprove from '../../hooks/useTokenApprove'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 480px;
    padding: 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  margin-bottom: auto;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    padding: 19px 0 17px;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const TransactionButton = styled.button<{ $isActive: boolean }>`
  display: grid;
  place-items: center;
  width: 100%;
  height: 64px;
  margin-top: 14px;
  border-radius: 14px;
  border: 0;
  background: #${({ $isActive }) => ($isActive ? '65dfb9' : '9d9999')};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  white-space: pre-line;
  transition: 0.15s all;
  @media (max-width: 768px) {
    & + & {
      margin-top: 8px;
    }
  }
`

const WithdrawRequestModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.WithdrawRequestModal',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    withdrawRequestModalState: { pairAddress },
    resetWithdrawRequestModalState,
  } = useManageWithdrawRequestModalState()
  const { resetDepositFormState } = useManageDepositFormState()
  const handleRemoveLiquidity = useRemovePoolLiquidity(pairAddress)
  const handleTokenApprove = useTokenApprove()
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()
  const [lpApprove, setLpApprove] = useState<boolean>(false)

  const approveSuccessCallback = () => {
    setLpApprove(true)
    pushMessage('request', 'success')
  }

  const {
    tokens,
    depositRate,
    userData: { depositedLpBalance },
  } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const removeLiquiditySuccessCallback = () => {
    queryClient.invalidateQueries(['token', tokens[0].address])
    queryClient.invalidateQueries(['token', tokens[1].address])
    queryClient.invalidateQueries(['pool', pairAddress])
    resetDepositFormState()
    resetWithdrawRequestModalState()
    pushMessage('request', 'success')
  }

  const requestFailureCallback = () => pushMessage('request', 'failure')

  const handleLoadLpApprove = async () => {
    const lpContract = new caver.klay.Contract(TOKEN_ABI, pairAddress)
    const lpToken = (await lpContract.methods
      .allowance(address, CONTRACT_ADDRESS.router)
      .call()) as string

    setLpApprove(parseInt(lpToken) > 0)
  }

  useEffect(() => {
    handleLoadLpApprove()
  }, [])

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={resetWithdrawRequestModalState}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div />
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(
                  multipleByDecimal(
                    depositRate[0] *
                      parseFloat(
                        getConvertFromPebValue(depositedLpBalance, 18),
                      ),
                    tokens[0].decimal,
                  ),
                  { maxDigit: 6 },
                )}
              </SlicedDecimal>
              <span>{tokens[0].symbol}</span>
            </div>
          </TransactionInfoItem>
          <TransactionInfoItem>
            <div />
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(
                  multipleByDecimal(
                    depositRate[1] *
                      parseFloat(
                        getConvertFromPebValue(depositedLpBalance, 18),
                      ),
                    tokens[1].decimal,
                  ),
                  { maxDigit: 6 },
                )}
              </SlicedDecimal>
              <span>{tokens[1].symbol}</span>
            </div>
          </TransactionInfoItem>
        </TransactionInfo>

        <TransactionButton
          $isActive={!lpApprove}
          onClick={
            !lpApprove
              ? () =>
                  handleTokenApprove(
                    pairAddress as string,
                    CONTRACT_ADDRESS.router,
                    approveSuccessCallback,
                    requestFailureCallback,
                  )
              : undefined
          }
        >
          {t(`button.lpToken.${String(lpApprove)}`)}
        </TransactionButton>
        <TransactionButton
          $isActive={lpApprove}
          onClick={() =>
            handleRemoveLiquidity(
              removeLiquiditySuccessCallback,
              requestFailureCallback,
            )
          }
        >
          {t('button.withdraw')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default WithdrawRequestModal

import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MyAssetsPortfolioChart from '../organisms/MyAssetsPortfolioChart'
import MyAssetsPortfolioList from '../organisms/MyAssetsPortfolioList'
import { MyAssetsPortfolioListItemProps } from '../molecules/MyAssetsPortfolioListItem'
import { COLOR_LIST } from '../../../utils/constants'
import useTokenListQuery from '../../../api/query/useTokenListQuery'

const MyAssetsPortfolioTemplateWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 83px auto 0;

  @media (max-width: 768px) {
    margin: 0 auto 56px;
  }
`

const Title = styled.div`
  margin-bottom: 44px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 270px 380px;
  grid-gap: 100px;
  justify-content: center;
  align-items: center;
  height: 270px;
  margin: 30px auto 0;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: 270px auto;
    grid-gap: 36px;
    height: unset;
  }
`

const MyAssetsPortfolioTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'MyAssets.MyAssetsPortfolioTemplate',
  })
  const { status, data } = useTokenListQuery()

  const convertedPortfolioData = useMemo(() => {
    if (status !== 'success' || data === undefined) return []

    const totalValue = data.tokens.reduce(
      (totalPrice, { balance, price }) =>
        totalPrice + parseFloat(balance) * price,
      0,
    )

    const convertedList = data.tokens
      .map(({ symbol, balance, price }) => {
        const convertedBalance = parseFloat(balance)

        return {
          symbol,
          proportion: ((convertedBalance * price) / totalValue) * 100,
          balance: convertedBalance,
          value: convertedBalance * price,
        }
      })
      .filter(({ value }) => value !== 0)
      .sort((a, b) => {
        if (a.proportion < b.proportion) return 1
        else return -1
      })
      .map(
        (item, index) =>
          ({
            color: COLOR_LIST[index],
            ...item,
          } as MyAssetsPortfolioListItemProps),
      )

    if (convertedList.length > 8) {
      const etc = convertedList.slice(7)

      const { proportionSum, balanceSum, valueSum } = etc.reduce(
        (result, etcItem) => {
          result.proportionSum += etcItem.proportion
          result.balanceSum += etcItem.balance
          result.valueSum += etcItem.value

          return result
        },
        { proportionSum: 0, balanceSum: 0, valueSum: 0 },
      )

      const combinedWithEtcList = [
        ...convertedList.splice(0, 7),
        {
          symbol: 'ETC',
          proportion: proportionSum,
          balance: balanceSum,
          value: valueSum,
          color: COLOR_LIST[COLOR_LIST.length - 1],
        } as MyAssetsPortfolioListItemProps,
      ]

      return combinedWithEtcList
    } else return convertedList
  }, [status, data])

  if (convertedPortfolioData.length === 0) return null

  return (
    <MyAssetsPortfolioTemplateWrapper>
      <Title>{t('title')}</Title>

      <ChartWrapper>
        <div style={{ position: 'relative' }}>
          <MyAssetsPortfolioChart list={convertedPortfolioData} />
          <div />
        </div>
        <MyAssetsPortfolioList list={convertedPortfolioData} />
      </ChartWrapper>
    </MyAssetsPortfolioTemplateWrapper>
  )
}

export default MyAssetsPortfolioTemplate

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import { useManageStakingRewardsModalState } from '../../state/modal/stakingRewardsModalState'
import { TOKEN_ADDRESS } from '../../contract/address'
import { NR_SYMBOL } from '../../utils/constants'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useHarvestStakingRewards from '../../hooks/useHarvestStakingRewards'
import useHarvestTransactionRewards from '../../hooks/useHarvestTransactionRewards'
import convertNumber from '../../utils/convertNumber'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { StakingRewardsType } from '../../api/query/useStakingRewardsQuery'
import useCreateHarvestTransactionMutation from '../../api/mutation/useCreateHarvestTransactionMutation'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 380px;
    padding: 24px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

const RewardList = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-top: 30px;
    padding: 16px 0;
    border-color: #edeaea;
  }
`

const RewardListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
    white-space: pre-line;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;
    line-height: 1.56;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const TransactionRewardsDescription = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
`

const NoticeContent = styled.div`
  margin-top: 30px;
  font-size: 14px;
  color: #9d9999;
  line-height: 1.57;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const ReceiveRewardButton = styled.button`
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: auto;
  padding: 20px;
  border-radius: 16px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const StakingRewardsModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.StakingRewardsModal',
  })
  const {
    stakingRewardsModalState: { type },
    resetStakingRewardsModalState,
  } = useManageStakingRewardsModalState()
  const pushMessage = useCallMessageBox()
  const handleHarvestStakingRewards = useHarvestStakingRewards()
  const handleHarvestTransactionRewards = useHarvestTransactionRewards()
  const createHarvestTransaction = useCreateHarvestTransactionMutation()
  const queryClient = useQueryClient()
  const stakingRewards =
    queryClient.getQueryData<StakingRewardsType['data']>('stakingRewards')

  const handleCloseReceiveRewardModal = () => resetStakingRewardsModalState()

  const stakingRewardsSuccessCallback = async (transactionAddress?: string) => {
    handleCloseReceiveRewardModal()
    pushMessage('request', 'success')

    if (type === 'stakingRewards' && transactionAddress !== undefined) {
      await createHarvestTransaction.mutateAsync(transactionAddress)
      queryClient.invalidateQueries([
        'token',
        TOKEN_ADDRESS[NR_SYMBOL] as string,
      ])
      queryClient.invalidateQueries('stakingRewards')
    } else if (type === 'transactionRewards') {
      queryClient.invalidateQueries(['tokenList', ''])
      queryClient.invalidateQueries('stakingTransactionRewards')
    }
  }

  const stakingRewardsFailureCallback = () => pushMessage('request', 'failure')

  const handleReceiveStakingRewards = () => {
    const harvestFunction =
      type === 'stakingRewards'
        ? handleHarvestStakingRewards
        : handleHarvestTransactionRewards

    harvestFunction(
      stakingRewardsSuccessCallback,
      stakingRewardsFailureCallback,
    )
  }

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={handleCloseReceiveRewardModal}>
          {t('title')}
        </ModalHead>

        <Title>{t(`${type}.subTitle`)}</Title>

        <RewardList>
          {type === 'stakingRewards' ? (
            <RewardListItem>
              <div>{t('stakingRewards.nrRewards')}</div>
              <div>
                <SlicedDecimal showTooltip={false}>
                  {convertNumber(stakingRewards?.rewards ?? 0, {
                    comma: true,
                    maxDigit: 6,
                  })}
                </SlicedDecimal>
                <span>NR</span>
              </div>
            </RewardListItem>
          ) : (
            <TransactionRewardsDescription>
              {t('transactionRewards.description')}
            </TransactionRewardsDescription>
          )}
        </RewardList>

        <NoticeContent>{t('description')}</NoticeContent>

        <ReceiveRewardButton onClick={handleReceiveStakingRewards}>
          {t('button')}
        </ReceiveRewardButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default StakingRewardsModal

import { useQuery } from 'react-query'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import { StakingTermType } from '../../utils/types'
import AxiosClient from '../axiosConfig'

export type StakingListItemType = {
  id: string
  term: StakingTermType
  amount: string
  startDate: number
  endDate: number
}

export type StakingListType = {
  data: {
    stakingList: {
      [key: number]: StakingListItemType[]
    }
    unlockableAmount: number
  }
}

const loadStakingList = async () =>
  await AxiosClient.getAxiosInstance(true).get('/api/staking/list')

export default function useStakingListQuery() {
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()

  return useQuery<StakingListType['data']>(
    'stakingList',
    async () => {
      const {
        data: { data },
      } = await loadStakingList()

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      refetchOnWindowFocus: false,
    },
  )
}

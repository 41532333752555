import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

const MobileMenuConnectedWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 184px;
  height: 48px;
  padding: 4px 4px 4px 8px;
  border-radius: 24px;
  border: 1px solid #65dfb9;
`

const WalletAddressText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
`

const ConnectedWalletIcon = styled.div`
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 50%;
  border: solid 0.5px rgba(112, 112, 112, 0.1);

  img {
    width: 30px;
    height: 30px;
  }
`

const ConnectWalletButton = styled.div`
  display: grid;
  place-items: center;
  width: 184px;
  height: 48px;
  border-radius: 24px;
  background: #ff6d68;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const MobileMenuConnectedWallet = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Modal.MobileMenu' })
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()

  const handleShowLoginModal = () => setConnectWalletModalState(true)

  if (type === undefined || address === undefined)
    return (
      <ConnectWalletButton onClick={handleShowLoginModal}>
        {t('login')}
      </ConnectWalletButton>
    )

  return (
    <MobileMenuConnectedWalletWrapper>
      <WalletAddressText>
        {`${address.substring(0, 6)}...${address.substring(
          address.length - 6,
        )}`}
      </WalletAddressText>

      <ConnectedWalletIcon>
        <img
          src={`/dexAssets/header_icons/${type}.png`}
          alt="Connected Wallet Logo"
        />
      </ConnectedWalletIcon>
    </MobileMenuConnectedWalletWrapper>
  )
}

export default MobileMenuConnectedWallet

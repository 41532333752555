import { useEffect } from 'react'
import styled from 'styled-components'
import produce from 'immer'
import { useTranslation } from 'react-i18next'
import { useManageMessageBoxState } from '../../state/modal/messageBoxState'
import ModalTranslation from '../../locale/en/Modal.json'
import { CSS_Z_INDEX } from '../../utils/constants'

const MessageBoxWrapper = styled.div<{ isVisible: string }>`
  @keyframes fade-in-out {
    0% {
      left: 70%;
      opacity: 0;
    }

    12.5% {
      left: 50%;
      opacity: 1;
    }

    87.5% {
      left: 50%;
      opacity: 1;
    }

    100% {
      left: 30%;
      opacity: 0;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${CSS_Z_INDEX.messageBox};
  width: 480px;
  height: 48px;
  border-radius: 24px;
  background: #6b6b6b;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fade-in-out 4s ease-out;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    width: 260px;
    height: 54px;
    top: unset;
    bottom: 20px;
    border-radius: 27px;
    line-height: 1.75;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
`

const MessageText = styled.div<{
  language: string
  message: string
}>`
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: ${({ language, message }) =>
      language === 'en' &&
      (message === ModalTranslation.MessageBox.walletSuccess ||
        message === ModalTranslation.MessageBox.walletFailure)
        ? '14'
        : '16'}px;
    line-height: 1.2;
  }
`

const MessageBox = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const {
    messageBoxState: { isVisible, messages },
    setMessageBoxState,
  } = useManageMessageBoxState()

  useEffect(() => {
    return () => setMessageBoxState({ isVisible: false, messages: [] })
  }, [])

  useEffect(() => {
    if (isVisible)
      setTimeout(
        () =>
          setMessageBoxState(prev =>
            produce(prev, draft => {
              draft.messages.pop()
              draft.isVisible = false

              return draft
            }),
          ),
        4000,
      )
    else if (!isVisible && messages.length !== 0)
      setMessageBoxState(prev =>
        produce(prev, draft => {
          draft.isVisible = true
          return draft
        }),
      )
  }, [isVisible])

  if (!isVisible || messages.length === 0) return null

  return (
    <MessageBoxWrapper isVisible={String(isVisible)}>
      <img
        src={`/dexAssets/modal_icons/message_box/${messages[0].type}.png`}
        alt="Message Icon"
      />
      <MessageText language={language} message={messages[0].message}>
        {messages[0].message}
      </MessageText>
    </MessageBoxWrapper>
  )
}

export default MessageBox

import { useEffect, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CSS_Z_INDEX } from '../utils/constants'

type ModalPortalProps = {
  isHidden?: boolean
  children: ReactNode
}

const Dimmed = styled.div<{ isHidden: string }>`
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${CSS_Z_INDEX.modal};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  visibility: ${({ isHidden }) => (isHidden === 'true' ? 'hidden' : 'visible')};
`

const ModalPortal = ({ isHidden, children }: ModalPortalProps) => {
  useEffect(() => {
    if (isHidden) return

    document.body.style.cssText = `overflow: hidden; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `overflow: unset; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [isHidden])

  return ReactDOM.createPortal(
    <Dimmed isHidden={String(isHidden)}>{children}</Dimmed>,
    document.getElementById('modal-root') as Element,
  )
}

export default ModalPortal

import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DepositedItem from '../molecules/DepositedItem'
import useUserDepositedPoolsQuery from '../../../api/query/useUserDepositedPoolsQuery'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

const ListEmpty = styled.div`
  padding: 32px 0 31px;
  font-size: 12px;
  color: #9d9999;
  text-align: center;
`

const DepositedList = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.Deposited',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { data, status } = useUserDepositedPoolsQuery(address)

  if (data === undefined || (status === 'success' && data.pools.length === 0))
    return <ListEmpty>{t('empty')}</ListEmpty>

  return (
    <Fragment>
      {data.pools.map(pool => (
        <DepositedItem {...pool} key={pool.address} />
      ))}
    </Fragment>
  )
}

export default DepositedList

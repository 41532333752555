import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TokenType } from '../../../api/query/useTokenQuery'

type SwapMainItemTokenProps = {
  token: TokenType
}

const SwapMainItemTokenWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TokenIcon = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);
  background: #ffffff;
  overflow: hidden;
  transition: 0.15s all;

  img {
    width: 100%;
    height: 100%;
    transition: 0.15s all;
  }

  span {
    font-size: 10px;
    font-weight: 700;
    color: #9d9999;
    opacity: 0.5;
    transition: 0.15s all;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

const SelectedTokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  & > div:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    color: #9d9999;
  }

  @media (max-width: 768px) {
    margin-left: 4px;

    & > div:nth-child(1) {
      font-size: 16px;
    }
  }
`

const DepositMainItemToken = ({
  token: { address, symbol, engName, korName },
}: SwapMainItemTokenProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <SwapMainItemTokenWrapper>
      <TokenIcon>
        <img
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
          alt="Token Icon"
        />
      </TokenIcon>

      <SelectedTokenInfo>
        <div>{symbol}</div>
        <div>{language === 'en' ? engName : korName}</div>
      </SelectedTokenInfo>
    </SwapMainItemTokenWrapper>
  )
}

export default DepositMainItemToken

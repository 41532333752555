import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type ReceiveRewardsModalStateType = {
  visible: boolean
  pairAddress: string | undefined
  pid: number | undefined
}

const receiveRewardsModalAtom = atom<ReceiveRewardsModalStateType>({
  key: 'receiveRewardsModal',
  default: {
    visible: false,
    pairAddress: undefined,
    pid: undefined,
  },
})

export const useManageReceiveRewardsModalState = (): {
  receiveRewardsModalState: ReceiveRewardsModalStateType
  setReceiveRewardsModalState: SetterOrUpdater<ReceiveRewardsModalStateType>
  resetReceiveRewardsModalState: Resetter
} => {
  const [receiveRewardsModalState, setReceiveRewardsModalState] =
    useRecoilState(receiveRewardsModalAtom)
  const resetReceiveRewardsModalState = useResetRecoilState(
    receiveRewardsModalAtom,
  )

  return {
    receiveRewardsModalState,
    setReceiveRewardsModalState,
    resetReceiveRewardsModalState,
  }
}

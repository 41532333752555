import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

export type StakingTransactionRewardsItemType = {
  address: string
  symbol: string
  korName: string
  engName: string
  rewards: string
}

type StakingTransactionRewardsType = {
  data: StakingTransactionRewardsItemType[]
}

const loadStakingTransactionRewards = async () =>
  await AxiosClient.getAxiosInstance(true, false).get(
    '/api/staking/transactions',
  )

export default function useStakingTransactionRewardsQuery() {
  return useQuery<StakingTransactionRewardsType['data']>(
    'stakingTransactionRewards',
    async () => {
      const {
        data: { data },
      } = await loadStakingTransactionRewards()

      return data
    },
    {
      enabled: AxiosClient.isInitialized(),
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
    },
  )
}

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

type ReferralsRankType = {
  data: {
    address: string
    ranking: number
  }
}

const loadReferralsRank = async (address: string) =>
  await AxiosClient.getAxiosInstance(false).get(
    `/api/wallets/referrals/rank/${address}`,
  )

export default function useReferralsRankQuery(address?: string) {
  return useQuery<ReferralsRankType['data']>(
    'referralRank',
    async () => {
      const {
        data: { data },
      } = await loadReferralsRank(address as string)

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      retry: false,
    },
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import convertNumber from '../../../utils/convertNumber'

type DashboardBurnedNeuronCardProps = {
  burnedNeuronPercentage: number
  burnedNeuronTokens: number
}

const DashboardBurnedNeuronCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 162px;
  padding: 20px 30px;
  border-radius: 24px;
  border: 1px solid #ff6d68;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    padding: 16px 17px;
  }
`

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #ff6d68;
`

const BurnedPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #9d9999;

  & > div:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
  }

  & > div:nth-child(2) {
    margin-top: 2px;
    font-size: 12px;
  }
`

const BurnedNeuron = styled.div`
  margin-left: auto;
  font-size: 34px;
  font-weight: 700;
  color: #ff6d68;

  span {
    font-size: 20px;
    font-weight: 400;
  }
`

const DashboardBurnedNeuronCard = ({
  burnedNeuronPercentage,
  burnedNeuronTokens,
}: DashboardBurnedNeuronCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardCardTemplate',
  })

  return (
    <DashboardBurnedNeuronCardWrapper>
      <CardHead>
        <Title>{t('burnedNR')}</Title>

        <BurnedPercentage>
          <div>
            {convertNumber(burnedNeuronPercentage, {
              comma: true,
              maxDigit: 6,
            })}{' '}
            %
          </div>
          <div>{t('burnedNRDividedByTotalCirculatingSupply')}</div>
        </BurnedPercentage>
      </CardHead>

      <BurnedNeuron>
        {convertNumber(burnedNeuronTokens, { comma: true, maxDigit: 6 })}
        <span>&nbsp;&nbsp;NR</span>
      </BurnedNeuron>
    </DashboardBurnedNeuronCardWrapper>
  )
}

export default DashboardBurnedNeuronCard

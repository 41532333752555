import { ChangeEvent, WheelEvent } from 'react'
import styled from 'styled-components'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import convertNumber from '../../../utils/convertNumber'
import { INPUT_REGEX } from '../../../utils/constants'

type SwapMainItemInputProps = {
  value: string
  handleChange: (value: string) => void
  color: string
}

const SwapMainItemInputWrapper = styled.input<{ color: string }>`
  width: 321px;
  height: 27px;
  margin-bottom: 15px;
  border: 0;
  background: transparent;
  font-size: 22px;
  font-weight: 700;
  text-align: right;
  color: ${({ color }) => color};
  outline: none;

  &::placeholder {
    color: ${({ color }) => color};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 145px;
    margin-left: auto;
    margin-bottom: 0;
    font-size: 18px;
  }
`

const SwapMainItemInput = ({
  value,
  handleChange,
  color,
}: SwapMainItemInputProps) => {
  const {
    swapFormState: {
      settings: { isMax },
    },
  } = useManageSwapFormState()

  const handleChangeAutoScroll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '' && !INPUT_REGEX.exec(event.target.value))
      return

    handleChange(event.target.value)
    event.currentTarget.scrollTo(event.currentTarget.scrollWidth, 0)
  }

  const blockScrollInput = (event: WheelEvent<HTMLInputElement>) =>
    event.currentTarget.blur()

  return (
    <SwapMainItemInputWrapper
      type="number"
      value={
        INPUT_REGEX.exec(value.toString())
          ? value
          : convertNumber(value, { maxDigit: 6 })
      }
      readOnly={isMax}
      placeholder="0"
      onChange={handleChangeAutoScroll}
      onWheel={blockScrollInput}
      color={color}
    />
  )
}

export default SwapMainItemInput

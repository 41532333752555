import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import caver from '../../../modules/network/caver'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import convertNumber from '../../../utils/convertNumber'
import { icoContract } from '../../../contract/contract'
import useClaimNeuronRewards from '../../../hooks/useClaimNeuronRewards'
import useCallMessageBox from '../../../hooks/useCallMessageBox'

type RewardStateType = {
  lockedTokens: string
  totalClaimedTokens: string
  claimableTokens: string
}

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.56;
  margin-bottom: 29px;
`

const ReferralClaimRewardsDetail = styled.div`
  padding: 0 20px;
  border-radius: 16px;
  background: #f8f8f8;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 241px;
    padding: 4px 20px;
  }
`

const ReferralClaimRewardsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  & > div:nth-child(1) {
    font-size: 16px;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 700;
  }

  & + & {
    border-top: 1px solid #ececec;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;

    & > div:nth-child(1) {
      margin-bottom: 2px;
    }
  }
`

const ClaimButton = styled.div`
  display: grid;
  place-items: center;
  height: 60px;
  margin-top: 28px;
  border-radius: 30px;
  background: #ff6d68;
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const ReferralClaimRewards = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralRewards.ReferralClaimRewards',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const pushMessage = useCallMessageBox()
  const handleClaimNeuronRewards = useClaimNeuronRewards()
  const [
    { lockedTokens, totalClaimedTokens, claimableTokens },
    setRewardsInfo,
  ] = useState<RewardStateType>({
    lockedTokens: '0',
    totalClaimedTokens: '0',
    claimableTokens: '0',
  })

  const loadRewardsInfo = async () => {
    const { getUserTokenClaimTotal, claimed, tokensClaimable } =
      icoContract.methods

    const rewardsInfo = await Promise.all([
      getUserTokenClaimTotal(address).call(),
      claimed(address).call(),
      tokensClaimable(address).call(),
    ])

    const [lockedTokens, totalClaimedTokens, claimableTokens] = rewardsInfo.map(
      (value: string) =>
        convertNumber(caver.utils.convertFromPeb(value, 'KLAY') as string, {
          maxDigit: 0,
        }),
    )

    setRewardsInfo({ lockedTokens, totalClaimedTokens, claimableTokens })
  }

  const claimSuccessCallback = () => {
    pushMessage('request', 'success')
    loadRewardsInfo()
  }

  const claimFailureCallback = () => pushMessage('request', 'failure')

  useEffect(() => {
    if (address !== undefined) loadRewardsInfo()
  }, [address])

  return (
    <div>
      <Title>{t('title')}</Title>

      <ReferralClaimRewardsDetail>
        <ReferralClaimRewardsItem>
          <div>{t('detail.0')}</div>
          <div>{convertNumber(lockedTokens, { comma: true })} NR</div>
        </ReferralClaimRewardsItem>

        <ReferralClaimRewardsItem>
          <div>{t('detail.1')}</div>
          <div>{convertNumber(totalClaimedTokens, { comma: true })} NR</div>
        </ReferralClaimRewardsItem>

        <ReferralClaimRewardsItem>
          <div>{t('detail.2')}</div>
          <div style={{ color: '#ff6d68' }}>
            {convertNumber(claimableTokens, { comma: true })} NR
          </div>
        </ReferralClaimRewardsItem>
      </ReferralClaimRewardsDetail>

      <ClaimButton
        onClick={() =>
          handleClaimNeuronRewards(claimSuccessCallback, claimFailureCallback)
        }
      >
        {t('button')}
      </ClaimButton>
    </div>
  )
}

export default ReferralClaimRewards

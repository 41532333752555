import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import convertNumber from '../../../utils/convertNumber'
import MobilePopup from '../molecules/MobilePopup'
import AvailableDepositItemRewards from '../atoms/AvailableDepositItemRewards'
import { AvailablePoolItemType } from '../../../api/query/useAvailableDepositPoolsQuery'

type MobilePoolItemEstimatedRewardsProps = {
  rewardsDetail: AvailablePoolItemType['rewardsDetail']
  fontSize: string
  enableDetail: boolean
}

const ExpectReturnRatio = styled.div<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 700;
  color: #ff6d68;
  text-align: right;
  line-height: 0.9;
`

const DetailExpectReturnRatio = styled.div`
  display: block;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #9d9999;
  text-decoration: underline;
`

const MobilePopupTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
`

const MobilePoolItemEstimatedRewards = ({
  rewardsDetail,
  fontSize,
  enableDetail,
}: MobilePoolItemEstimatedRewardsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit',
  })
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowPopup = () => setIsVisiblePopup(true)
  const handleClosePopup = () => setIsVisiblePopup(false)

  return (
    <div>
      <ExpectReturnRatio fontSize={fontSize}>
        {convertNumber(rewardsDetail.apr, { isPercentage: true })}%
        {enableDetail ? (
          <DetailExpectReturnRatio onClick={handleShowPopup}>
            {t('mobileDetail')}
          </DetailExpectReturnRatio>
        ) : null}
      </ExpectReturnRatio>

      <MobilePopup
        type="normal"
        isVisible={isVisiblePopup}
        handleClose={handleClosePopup}
      >
        <MobilePopupTitle>{t('head.3')}</MobilePopupTitle>
        <AvailableDepositItemRewards rewardsDetail={rewardsDetail} />
      </MobilePopup>
    </div>
  )
}

export default MobilePoolItemEstimatedRewards

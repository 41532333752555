import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useManageMobileMenuState } from '../../../state/modal/mobileMenuState'

type MobileMenuListItemProps = {
  link: string
  type: 'internal' | 'external' | 'dummy'
  dummyClickHandler?: () => void
  children: ReactNode
}

const MobileMenuListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  font-size: 16px;
  font-weight: 700;
  color: #9d9999;

  a {
    display: flex;
    align-items: center;
    color: #9d9999;
    text-decoration: none;
  }

  img {
    width: 24px;
    height: 24px;
  }

  & + & {
    border-top: 1px solid #edeaea;
  }
`

const MobileMenuListItem = ({
  link,
  type,
  dummyClickHandler,
  children,
}: MobileMenuListItemProps) => {
  const { setMobileMenuState } = useManageMobileMenuState()
  const navigate = useNavigate()

  const handleRedirect = (link: string) => {
    setMobileMenuState(false)
    navigate(link)
  }

  if (type === 'external')
    return (
      <MobileMenuListItemWrapper>
        <a href={link} target="_blank">
          {children}
        </a>

        <a href={link} target="_blank">
          <img
            src="/dexAssets/modal_icons/mobile_menu/link_icon.png"
            alt="Link Icon"
          />
        </a>
      </MobileMenuListItemWrapper>
    )

  return (
    <MobileMenuListItemWrapper>
      <div
        onClick={
          type === 'internal' ? () => handleRedirect(link) : dummyClickHandler
        }
      >
        {children}
      </div>

      <img
        src="/dexAssets/modal_icons/mobile_menu/link_icon.png"
        alt="Link Icon"
        onClick={
          type === 'internal' ? () => handleRedirect(link) : dummyClickHandler
        }
      />
    </MobileMenuListItemWrapper>
  )
}

export default MobileMenuListItem

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import TransactionHistoryItemHash from '../atoms/TransactionHistoryItemHash'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type TransactionListItemProps = {
  type: string
  hash: string
  status: boolean
  date: string
}

const TransactionListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1.3fr 0.5fr 0.7fr;
  grid-gap: 50px;
  padding: 0 40px 0 10px;

  & + & {
    margin-top: 19px;
  }

  @media (max-width: 768px) {
    grid-gap: 20px;
    padding: 0 10px;

    & + & {
      margin-top: 9px;
    }
  }
`

const TransactionListItemText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9d9999;
  fill: #9d9999;

  a {
    display: flex;
  }

  svg {
    margin-left: 8px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    color: #656565;
    word-break: keep-all;
  }
`

const TransactionHistoryItem = ({
  type,
  hash,
  status,
  date,
}: TransactionListItemProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'TransactionHistory',
  })
  const { width } = useGetWindowSize()

  return (
    <TransactionListItemWrapper>
      <TransactionListItemText
        style={{ justifyContent: width > 768 ? 'center' : 'left' }}
      >
        {t(`type.${type}`)}
      </TransactionListItemText>

      <TransactionHistoryItemHash hash={hash} status={status} />

      <TransactionListItemText
        style={{
          justifyContent: 'center',
          color: status ? '#65dfb9' : '#9d9999',
        }}
      >
        {t(`status.${String(status)}`)}
      </TransactionListItemText>

      <TransactionListItemText
        style={{ justifyContent: 'flex-end', textAlign: 'right' }}
      >
        {date}
      </TransactionListItemText>
    </TransactionListItemWrapper>
  )
}

export default TransactionHistoryItem

import caver from '../modules/network/caver'
import { CUSTOM_NODE_ENV } from '../utils/constants'
import { NodeEnvironmentType } from '../utils/types'

export const CONTRACT_ADDRESS = {
  production: {
    router: '0xfc5f327cf3599c21f3605116abceab74950ecbe5',
    factory: '0xe334e8c7073e9aaae3cab998eecca33f56df6621',
    masterchef: '0x92a47a5c6b742b2056f0f4afb7724112c83715e1',
    gnr: '0xef53f5f4f9e4d42b82163c378bd866f3f9dd330e',
    nrDistributor: '0xe213d8be6bdfd131be10e5bf350baac7529bc5e1',
    feeDistributor: '0x94fc69f9388811b5cf250e9f0d06b20f9f14d75f',
    ronyItem: '0x1A5b19b69266Bf47c642E5A9256fE87e38283A56',
    ronyCard: '0x4F34899bB9Cf1912F4435AB7A30dd2187B15F183',
    hallOfFame: '0xBca5fdD307F3c35a0a4d17Cc32800B81b980e320',
    mrx: '0x378f1e9be84a881f429402b4f656816c195c1051',
  },
  development: {
    router: '0xfc5f327cf3599c21f3605116abceab74950ecbe5',
    factory: '0xe334e8c7073e9aaae3cab998eecca33f56df6621',
    masterchef: '0x92a47a5c6b742b2056f0f4afb7724112c83715e1',
    gnr: '0xef53f5f4f9e4d42b82163c378bd866f3f9dd330e',
    nrDistributor: '0xe213d8be6bdfd131be10e5bf350baac7529bc5e1',
    feeDistributor: '0x94fc69f9388811b5cf250e9f0d06b20f9f14d75f',
    ronyItem: '0x53D47E0c9E08E81a8e08A83d393eF22C857856ad',
    ronyCard: '0x864B37CaDf051c4a1267F432A1D0a55EB88435e6',
    hallOfFame: '0x02C37aDE2d58cAeFe81b4E3B38412057d393456a',
    mrx: '0x08fD259bd843Dd0d5Ed1e50e2Bf08E4C85755efE',
  },
}[CUSTOM_NODE_ENV ?? (process.env.NODE_ENV as NodeEnvironmentType)]

export const WKLAY_TOKEN_ADDRESS = caver.utils.toChecksumAddress(
  '0xfd844c2fca5e595004b17615f891620d1cb9bbb2',
)

export const TOKEN_ADDRESS = {
  production: {
    NR: caver.utils.toChecksumAddress(
      '0x340073962a8561cb9e0c271aab7e182d5f5af5c8',
    ),
    KDAI: caver.utils.toChecksumAddress(
      '0x5c74070fdea071359b86082bd9f9b3deaafbe32b',
    ),
    KETH: caver.utils.toChecksumAddress(
      '0x34d21b1e550d73cee41151c77f3c73359527a396',
    ),
    KWBTC: caver.utils.toChecksumAddress(
      '0x16d0e1fbd024c600ca0380a4c5d57ee7a2ecbf9c',
    ),
    KUSDT: caver.utils.toChecksumAddress(
      '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167',
    ),
    KSP: caver.utils.toChecksumAddress(
      '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654',
    ),
    KORC: caver.utils.toChecksumAddress(
      '0xfe41102f325deaa9f303fdd9484eb5911a7ba557',
    ),
    KBNB: caver.utils.toChecksumAddress(
      '0x574e9c26bda8b95d7329505b4657103710eb32ea',
    ),
    KXRP: caver.utils.toChecksumAddress(
      '0x9eaefb09fe4aabfbe6b1ca316a3c36afc83a393f',
    ),
    KUSDC: caver.utils.toChecksumAddress(
      '0x754288077d0ff82af7a5317c7cb8c444d421d103',
    ),
  },
  development: {
    NT: caver.utils.toChecksumAddress(
      '0x9aeccadcfcc18dfbf21a091f62471dc8bfd0ce16',
    ),
    KDAI: caver.utils.toChecksumAddress(
      '0x5c74070fdea071359b86082bd9f9b3deaafbe32b',
    ),
    KETH: caver.utils.toChecksumAddress(
      '0x34d21b1e550d73cee41151c77f3c73359527a396',
    ),
    KWBTC: caver.utils.toChecksumAddress(
      '0x16d0e1fbd024c600ca0380a4c5d57ee7a2ecbf9c',
    ),
    KUSDT: caver.utils.toChecksumAddress(
      '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167',
    ),
    KSP: caver.utils.toChecksumAddress(
      '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654',
    ),
    KORC: caver.utils.toChecksumAddress(
      '0xfe41102f325deaa9f303fdd9484eb5911a7ba557',
    ),
    KBNB: caver.utils.toChecksumAddress(
      '0x574e9c26bda8b95d7329505b4657103710eb32ea',
    ),
    KXRP: caver.utils.toChecksumAddress(
      '0x9eaefb09fe4aabfbe6b1ca316a3c36afc83a393f',
    ),
    KUSDC: caver.utils.toChecksumAddress(
      '0x754288077d0ff82af7a5317c7cb8c444d421d103',
    ),
  },
}[CUSTOM_NODE_ENV ?? (process.env.NODE_ENV as NodeEnvironmentType)]

export const PAIR_ADDRESS = {
  production: {
    NR_KLAY: '0x908a4e95b447bd2e0fd7c020618ab84b5d6ffc87',
    KDAI_NR: '0xA2e28083d45D5e0e12FF642CBAfAa7856e4239A0',
    KLAY_KDAI: '0xa3987cf6c14f1992e8b4a9e23192eb79dc2969b8',
    KUSDT_KDAI_KSP: '0xc320066b25b731a11767834839fe57f9b2186f84',
    KLAY_KUSDT_KSP: '0xd83f1b074d81869eff2c46c530d7308ffec18036',
    KUSDT_KETH_KSP: '0x029e2a1b2bb91b66bd25027e1c211e5628dbcb93',
    KUSDT_KWBTC_KSP: '0x9103beb39283da9c49b020d6546fd7c39f9bbc5b',
    KUSDT_KSP_KSP: '0xe75a6a3a800a2c5123e67e3bde911ba761fe0705',
    KUSDT_KORC_KSP: '0x94f390a8438b5de00b868d3ae47863db90fb92c3',
    KBUSD_KBNB_KSP: '0x5da044864a2cbe03546810f6bb2f274a45edb8c6',
    KUSDT_KXRP_KSP: '0xa2be534e5dd028288625bb376c81a9eea3d9f0d5',
    KUSDT_KUSDC_KSP: '0x2e9269b718cc816de6a9e3c27e5bdb0f6a01b0ac',
  },
  development: {
    NR_KLAY: '0xDA8C47EB154C108cBb8e915D47b44f2F08218B00',
    KDAI_NR: '0x41D915Cbb6737a98Ea6da153C8bd0df4280fC0EC',
    KLAY_KDAI: '0xa3987cf6c14f1992e8b4a9e23192eb79dc2969b8',
    KUSDT_KDAI_KSP: '0xc320066b25b731a11767834839fe57f9b2186f84',
    KLAY_KUSDT_KSP: '0xd83f1b074d81869eff2c46c530d7308ffec18036',
    KUSDT_KETH_KSP: '0x029e2a1b2bb91b66bd25027e1c211e5628dbcb93',
    KUSDT_KWBTC_KSP: '0x9103beb39283da9c49b020d6546fd7c39f9bbc5b',
    KUSDT_KSP_KSP: '0xe75a6a3a800a2c5123e67e3bde911ba761fe0705',
    KUSDT_KORC_KSP: '0x94f390a8438b5de00b868d3ae47863db90fb92c3',
    KBUSD_KBNB_KSP: '0x5da044864a2cbe03546810f6bb2f274a45edb8c6',
    KUSDT_KXRP_KSP: '0xa2be534e5dd028288625bb376c81a9eea3d9f0d5',
    KUSDT_KUSDC_KSP: '0x2e9269b718cc816de6a9e3c27e5bdb0f6a01b0ac',
  },
}[CUSTOM_NODE_ENV ?? (process.env.NODE_ENV as NodeEnvironmentType)]

export const CYPRESS_ICO_CA_ADDRESS =
  '0x9f400dd9663f77f61af160776659e56c3042e6bf'

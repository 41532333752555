import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  useManageSwapFormState,
  SwapFormStateType,
} from '../../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import { useManageApproveModalState } from '../../../state/modal/approveModalState'
import { useManageSwapTransactionModalState } from '../../../state/modal/swapTransactionModalState'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import {
  CONTRACT_ADDRESS,
  WKLAY_TOKEN_ADDRESS,
} from '../../../contract/address'
import caver from '../../../modules/network/caver'
import { TOKEN_ABI } from '../../../contract/abi'

const SwapButton = styled.button<{
  $isActive: boolean
  error: SwapFormStateType['error']
}>`
  width: 100%;
  margin-top: 30px;
  padding: 17px 0;
  border: 0;
  border-radius: 30px;
  background: #${({ $isActive, error }) => ($isActive ? 'ff6d68' : error === 'INSUFFICIENT_QUANTITY' ? 'b95955' : '9d9999')};
  font-size: 22px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  text-align: center;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  transition: 0.3s background;

  @media (max-width: 768px) {
    height: 54px;
    margin-top: 8px;
    padding: 0;
    border-radius: 16px;
    ${({ error }) =>
      error === 'INSUFFICIENT_LIQUIDITY' ? 'font-size: 14px;' : ''}
  }
`

const SwapMainButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapMain.button',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    swapFormState: {
      form: { from, to },
      error,
    },
  } = useManageSwapFormState()
  const {
    swapInfoState: { bestTradeAddress },
  } = useManageSwapInfoState()
  const { setApproveModalState } = useManageApproveModalState()
  const { setSwapTransactionModalState } = useManageSwapTransactionModalState()
  const [isAvailable, setIsAvailable] = useState<boolean>(false)

  const handleLoadTokenIsApproved = async (tokenAddress: string) => {
    if (tokenAddress === WKLAY_TOKEN_ADDRESS) return true

    const contract = new caver.klay.Contract(TOKEN_ABI, tokenAddress)

    const isApproved =
      (await contract.methods
        .allowance(address, CONTRACT_ADDRESS.router)
        .call()) > 0

    return isApproved
  }

  const handleSwap = () => {
    if (!isAvailable) return

    handleLoadTokenIsApproved(from.address).then((isApproved: boolean) => {
      if (isApproved) setSwapTransactionModalState(true)
      else setApproveModalState({ visible: true, type: 'token' })
    })
  }

  useEffect(() => {
    setIsAvailable(
      from.address !== '' &&
        to.address !== '' &&
        from.value !== '' &&
        to.value !== '' &&
        from.value !== '0' &&
        to.value !== '0' &&
        error === undefined &&
        bestTradeAddress.length !== 0,
    )
  }, [from, to, error, bestTradeAddress])

  return (
    <SwapButton $isActive={isAvailable} error={error} onClick={handleSwap}>
      {t(error === undefined ? 'swap' : error)}
    </SwapButton>
  )
}

export default SwapMainButton

import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DashboardChartCard from '../molecules/DashboardChartCard'
import DashboardBurnedNeuronCard from '../molecules/DashboardBurnedNeuronCard'
import DashboardFiguresCard from '../molecules/DashboardFiguresCard'
import useDashboardFiguresQuery from '../../../api/query/useDashboardFiguresQuery'
import {
  getCurrentTimeForDashboard,
  convertTimeForDashboard,
} from '../../../utils/getCurrentTime'

const DashboardCardTemplateWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-gap: 16px;
    margin: 0;
  }
`

const DashboardCardTemplate = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardCardTemplate',
  })
  const {
    data: { figures, chart },
    isFetched,
  } = useDashboardFiguresQuery()

  const updatedDate = useMemo(
    () => convertTimeForDashboard(language, getCurrentTimeForDashboard()),
    [isFetched, language],
  )

  return (
    <DashboardCardTemplateWrapper>
      <DashboardChartCard
        index={1}
        title={t('totalValueLocked')}
        price={figures.totalValueLocked}
        chartData={chart.totalValueLockedChartData}
        updatedDate={updatedDate}
      />
      <DashboardChartCard
        index={2}
        title={`${t('totalTradingVolume')} (24h)`}
        price={figures.totalTradingVolume}
        chartData={chart.totalTradingVolumeChartData}
        updatedDate={updatedDate}
      />

      <DashboardBurnedNeuronCard
        burnedNeuronPercentage={figures.burnedNeuronPercentage}
        burnedNeuronTokens={figures.burnedNeuronTokens}
      />
      <DashboardFiguresCard
        circulatingSupply={figures.circulatingSupply}
        totalStakedNeuron={figures.totalStakedNeuron}
        buybackFund={figures.buybackFund}
      />
    </DashboardCardTemplateWrapper>
  )
}

export default DashboardCardTemplate

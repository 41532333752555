import { useQueries } from 'react-query'
import AxiosClient from '../axiosConfig'

type DashboardFiguresType = {
  data: {
    totalValueLocked: number
    totalTradingVolume: number
    totalStakedNeuron: number
    burnedNeuronPercentage: number
    burnedNeuronTokens: number
    buybackFund: number
    circulatingSupply: number
  }
}

export type DashboardChartDataType = {
  data: {
    totalValueLockedChartData: [number, number][]
    totalTradingVolumeChartData: [number, number][]
  }
}

const loadDashboardFigures = async () =>
  await AxiosClient.getAxiosInstance(false).get(
    `/api/dashboard?time=1&unit=day`,
  )

const loadDashboardChartData = async () =>
  await AxiosClient.getAxiosInstance(false).get(
    '/api/dashboard/chart?time=90&unit=day',
  )

export default function useDashboardFiguresQuery() {
  const [figures, chart] = useQueries([
    {
      queryKey: 'dashboardFigures',
      queryFn: loadDashboardFigures,
      enabled: AxiosClient.isInitialized(),
    },
    {
      queryKey: 'dashboardChartData',
      queryFn: loadDashboardChartData,
      enabled: AxiosClient.isInitialized(),
    },
  ])

  const figuresInitialData: DashboardFiguresType['data'] = {
    totalValueLocked: 0,
    totalTradingVolume: 0,
    totalStakedNeuron: 0,
    burnedNeuronPercentage: 0,
    burnedNeuronTokens: 0,
    buybackFund: 0,
    circulatingSupply: 0,
  }

  const chartInitialData: DashboardChartDataType['data'] = {
    totalValueLockedChartData: [],
    totalTradingVolumeChartData: [],
  }

  return {
    status: figures.status && chart.status,
    data: {
      figures: figures.data ? figures.data.data.data : figuresInitialData,
      chart: chart.data ? chart.data.data.data : chartInitialData,
    },
    isFetched: figures.isFetched && chart.isFetched,
  }
}

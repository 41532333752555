import { atom, useRecoilState, SetterOrUpdater } from 'recoil'

const mobileMenuAtom = atom<boolean>({
  key: 'mobileMenu',
  default: false,
})

export const useManageMobileMenuState = (): {
  mobileMenuState: boolean
  setMobileMenuState: SetterOrUpdater<boolean>
} => {
  const [mobileMenuState, setMobileMenuState] = useRecoilState(mobileMenuAtom)

  return { mobileMenuState, setMobileMenuState }
}

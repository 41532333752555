import { atom, useRecoilState, SetterOrUpdater } from 'recoil'

const connectWalletModalAtom = atom<boolean>({
  key: 'connectWalletModal',
  default: false,
})

export const useManageConnectWalletModalState = (): {
  connectWalletModalState: boolean
  setConnectWalletModalState: SetterOrUpdater<boolean>
} => {
  const [connectWalletModalState, setConnectWalletModalState] = useRecoilState(
    connectWalletModalAtom,
  )

  return { connectWalletModalState, setConnectWalletModalState }
}

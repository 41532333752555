import { AbiItem } from 'web3-utils'

export const TOKEN_APPROVE_ABI: AbiItem = {
  name: 'approve',
  constant: false,
  inputs: [
    { name: '_spender', type: 'address' },
    { name: '_value', type: 'uint256' },
  ],
  outputs: [{ name: '', type: 'bool' }],
  payable: false,
  stateMutability: 'nonpayable',
  type: 'function',
}

export const SWAP_EXACT_KLAY_FOR_TOKENS_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapExactKLAYForTokens',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'payable',
  type: 'function',
}

export const SWAP_EXACT_TOKENS_FOR_KLAY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountIn',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapExactTokensForKLAY',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const SWAP_EXACT_TOKENS_FOR_TOKENS_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountIn',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapExactTokensForTokens',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const SWAP_KLAY_FOR_EXACT_TOKENS_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountOut',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapKLAYForExactTokens',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'payable',
  type: 'function',
}

export const SWAP_TOKENS_FOR_EXACT_KLAY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountOut',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountInMax',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapTokensForExactKLAY',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const SWAP_TOKENS_FOR_EXACT_TOKENS_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'amountOut',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountInMax',
      type: 'uint256',
    },
    {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    },
    {
      internalType: 'address payable',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'swapTokensForExactTokens',
  outputs: [
    {
      internalType: 'uint256[]',
      name: 'amounts',
      type: 'uint256[]',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const DEPOSIT_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_pid',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
  ],
  name: 'deposit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const WITHDRAW_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_pid',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
  ],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const ADD_LIQUIDITY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'tokenA',
      type: 'address',
    },
    {
      internalType: 'address',
      name: 'tokenB',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'amountADesired',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountBDesired',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256',
    },
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'addLiquidity',
  outputs: [
    {
      internalType: 'uint256',
      name: 'amountA',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountB',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const ADD_LIQUIDITY_KLAY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'token',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'amountTokenDesired',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountTokenMin',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountKLAYMin',
      type: 'uint256',
    },
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'addLiquidityKLAY',
  outputs: [
    {
      internalType: 'uint256',
      name: 'amountToken',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountKLAY',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    },
  ],
  stateMutability: 'payable',
  type: 'function',
}

export const REMOVE_LIQUIDITY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'tokenA',
      type: 'address',
    },
    {
      internalType: 'address',
      name: 'tokenB',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256',
    },
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'removeLiquidity',
  outputs: [
    {
      internalType: 'uint256',
      name: 'amountA',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountB',
      type: 'uint256',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const REMOVE_LIQUIDITY_KLAY_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'token',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountTokenMin',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountKLAYMin',
      type: 'uint256',
    },
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    },
  ],
  name: 'removeLiquidityKLAY',
  outputs: [
    {
      internalType: 'uint256',
      name: 'amountToken',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'amountKLAY',
      type: 'uint256',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const STAKING_APPROVE_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'spender',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    },
  ],
  name: 'approve',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const STAKING_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    },
    {
      internalType: 'uint8',
      name: 'multiple',
      type: 'uint8',
    },
  ],
  name: 'mint',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const HARVEST_NEURON_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
  ],
  name: 'harvest',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const HARVEST_FEE_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: 'to',
      type: 'address',
    },
  ],
  name: 'harvestAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const getUnstakingABI = (isPenalty: boolean): AbiItem =>
  isPenalty
    ? {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint8',
            name: 'multiple',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'startIdx',
            type: 'uint256',
          },
        ],
        name: 'instantUnlockWithPenalty',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      }
    : {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'massUnlockAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      }

export const LAUNCHPAD_REWARDS_CLAIM_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address payable',
      name: '_user',
      type: 'address',
    },
  ],
  name: 'claim',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

export const SET_REFERRAL_ABI: AbiItem = {
  inputs: [
    {
      internalType: 'address',
      name: '_user',
      type: 'address',
    },
  ],
  name: 'setReferralAddress',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}

import styled from 'styled-components'

const MobileMenuCommunityWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  border-top: 1px solid #edeaea;
  font-size: 16px;
  font-weight: 700;
  color: #9d9999;

  a {
    display: flex;
    align-items: center;
    color: #9d9999;
    text-decoration: none;
  }

  img {
    width: 24px;
    height: 24px;
  }

  & + & {
    border-top: 1px solid #edeaea;
  }
`

const CommunityMenuItem = styled.a`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;

  img {
    width: 100%;
    height: 100%;
  }

  & + & {
    margin-left: 10px;
  }
`

const MobileMenuCommunity = () => {
  return (
    <MobileMenuCommunityWrapper>
      <CommunityMenuItem
        href="https://documents.neuronswap.com/neuronswap/"
        target="_blank"
      >
        <img
          src="/dexAssets/modal_icons/mobile_menu/community_1.png"
          alt="Community Icon"
        />
      </CommunityMenuItem>
      <CommunityMenuItem href="https://medium.com/@neuronswap" target="_blank">
        <img
          src="/dexAssets/modal_icons/mobile_menu/community_2.png"
          alt="Community Icon"
        />
      </CommunityMenuItem>
      <CommunityMenuItem href="https://twitter.com/neuronswap" target="_blank">
        <img
          src="/dexAssets/modal_icons/mobile_menu/community_3.png"
          alt="Community Icon"
        />
      </CommunityMenuItem>
      <CommunityMenuItem href="https://t.me/NEURONswap" target="_blank">
        <img
          src="/dexAssets/modal_icons/mobile_menu/community_4.png"
          alt="Community Icon"
        />
      </CommunityMenuItem>
      <CommunityMenuItem href="mailto:hello@neuronswap.com">
        <img
          src="/dexAssets/modal_icons/mobile_menu/community_5.png"
          alt="Community Icon"
        />
      </CommunityMenuItem>
    </MobileMenuCommunityWrapper>
  )
}

export default MobileMenuCommunity

import styled from 'styled-components'
import MyAssetsPortfolioListItem, {
  MyAssetsPortfolioListItemProps,
} from '../molecules/MyAssetsPortfolioListItem'

type AssetPortfolioListProps = {
  list: MyAssetsPortfolioListItemProps[]
}

const AssetPortfolioListWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 160px;
  grid-gap: 25px 60px;

  @media (max-width: 768px) {
    grid-template-columns: 120px 120px;
    grid-gap: 20px 44px;
    justify-content: center;
  }
`

const MyAssetsPortfolioList = ({ list }: AssetPortfolioListProps) => {
  // 리스트 아이템 최대 8개까지 제한
  return (
    <AssetPortfolioListWrapper>
      {list.map(tokenItem => (
        <MyAssetsPortfolioListItem {...tokenItem} key={tokenItem.symbol} />
      ))}
    </AssetPortfolioListWrapper>
  )
}

export default MyAssetsPortfolioList

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const ReferralCreateURLWrapper = styled.div<{ isCreatedURL: string }>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  border-radius: 16px;
  background: #f8f8f8;

  @media (max-width: 768px) {
    grid-gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: ${({ isCreatedURL }) => (isCreatedURL === 'true' ? '14' : '20')}px
      0 12px;
  }
`

const CreateURLDescText = styled.div<{ isCreatedURL: string }>`
  width: 100%;
  font-size: ${({ isCreatedURL }) => (isCreatedURL === 'true' ? '12' : '14')}px;
  color: rgba(
    0,
    0,
    0,
    ${({ isCreatedURL }) => (isCreatedURL === 'true' ? '1' : '0.2')}
  );
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 12px;
    overflow: initial;
    white-space: pre-line;
    line-height: 1.3;
  }
`

const Button = styled.button<{
  language: string
  isCopied?: string
}>`
  display: grid;
  place-items: center;
  width: 97px;
  height: 32px;
  border: 0;
  border-radius: 16px;
  background: #${({ isCopied }) => (isCopied === 'true' ? '9d9999' : '65dfb9')};
  font-size: ${({ language }) => (language === 'ko' ? '16' : '12')}px;
  font-weight: 700;
  color: #ffffff;
  line-height: 2.1;
  cursor: ${({ isCopied }) => (isCopied === 'true' ? 'initial' : 'pointer')};

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const ReferralCreateURL = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix:
      'Referral.ReferralData.ReferralManageInvitation.ReferralCreateURL',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const [url, setUrl] = useState<string>('')
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const { width } = useGetWindowSize()

  const handleCreateURL = () => {
    if (address === undefined) return

    setUrl(
      process.env.PUBLIC_URL + `${window.location.origin}/referral/${address}`,
    )
  }

  const sliceUrlForMobile = (url: string) => {
    const centerIndex = url.length / 2

    const front = url.substring(0, centerIndex)
    const back = url.substring(centerIndex + 1, url.length - 1)

    return front + '\n' + back
  }

  useEffect(() => {
    setUrl('')
  }, [address])

  useEffect(() => {
    if (isCopied === false) return

    setTimeout(() => setIsCopied(false), 1500)
  }, [isCopied])

  return (
    <ReferralCreateURLWrapper isCreatedURL={String(url !== '')}>
      <CreateURLDescText isCreatedURL={String(url !== '')}>
        {url === ''
          ? t('description')
          : width > 768
          ? url
          : sliceUrlForMobile(url)}
      </CreateURLDescText>

      {url === '' ? (
        <Button onClick={handleCreateURL} language={language}>
          {t('createUrl')}
        </Button>
      ) : (
        <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
          <Button language={language} isCopied={String(isCopied)}>
            {!isCopied ? (
              t('copy')
            ) : (
              <img
                src="/dexAssets/copy_success_icon.png"
                alt="Copy Success Icon"
              />
            )}
          </Button>
        </CopyToClipboard>
      )}
    </ReferralCreateURLWrapper>
  )
}

export default ReferralCreateURL

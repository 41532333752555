import {
  atom,
  useRecoilState,
  SetterOrUpdater,
  useResetRecoilState,
  Resetter,
} from 'recoil'
import { StakingTermType } from '../../utils/types'

type UnstakingModalStateType = {
  visible: boolean
  isPenalty: boolean
  term: StakingTermType | undefined
  id: string | undefined
  stakingIndex: string | undefined
}

const unstakingModalAtom = atom<UnstakingModalStateType>({
  key: 'unstakingModal',
  default: {
    visible: false,
    isPenalty: false,
    term: undefined,
    id: undefined,
    stakingIndex: undefined,
  },
})

export const useManageUnstakingModalState = (): {
  unstakingModalState: UnstakingModalStateType
  setUnstakingModalState: SetterOrUpdater<UnstakingModalStateType>
  resetUnstakingModalState: Resetter
} => {
  const [unstakingModalState, setUnstakingModalState] =
    useRecoilState(unstakingModalAtom)
  const resetUnstakingModalState = useResetRecoilState(unstakingModalAtom)

  return {
    unstakingModalState,
    setUnstakingModalState,
    resetUnstakingModalState,
  }
}

import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageUnstakingModalState } from '../../../state/modal/unstakingModalState'
import parseDate from '../../../utils/parseDate'
import convertNumber from '../../../utils/convertNumber'
import { STAKING_TERM } from '../../../utils/constants'
import { StakingListItemType } from '../../../api/query/useStakingListQuery'

type StakingListItemProps = StakingListItemType & {
  isPenaltyFirst: boolean
}

const StakingListItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 0.2fr 0.19fr 0.22fr 0.14fr 0.25fr;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  & + & {
    margin-top: 20px;
  }
`

const StartDate = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;

  span {
    margin-left: 8px;
    color: #9d9999;
  }
`

const StakingQuantity = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: right;
`

const StakingTerm = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 24px;
  margin: 0 auto;
  border-radius: 6px;
  background: ${({ background }) => background};
  font-size: 10px;
  color: #ffffff;

  span {
    margin-right: 3px;
    font-weight: 700;
  }
`

const EndDate = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #9d9999;
  text-align: right;

  span {
    margin-left: 3px;
    color: #2e2e2e;
  }
`

const UnstakeButton = styled.button`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 142px;
  height: 40px;
  margin-left: auto;
  padding-right: 30px;
  border: 0;
  border-radius: 20px;
  background: #b95955;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const PenaltyWarningIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
`

const DisabledBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
`

const StakingListItem = ({
  id,
  startDate,
  amount,
  term,
  endDate,
  isPenaltyFirst,
}: StakingListItemProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingList',
  })
  const { setUnstakingModalState } = useManageUnstakingModalState()
  const parsedStartDate = useMemo(() => parseDate(startDate), [startDate])
  const parsedEndDate = useMemo(() => parseDate(endDate), [endDate])

  const handleShowModal = () =>
    setUnstakingModalState({
      visible: true,
      isPenalty: true,
      term,
      id,
      stakingIndex: undefined,
    })

  return (
    <StakingListItemWrapper>
      <StartDate>
        {`${parsedStartDate.year}.${parsedStartDate.month}.${parsedStartDate.day}`}
        <span>{`${parsedStartDate.hour}:${parsedStartDate.minute}`}</span>
      </StartDate>

      <StakingQuantity>
        {convertNumber(amount, {
          comma: true,
          maxDigit: 0,
        })}{' '}
        NR
      </StakingQuantity>

      <StakingTerm background={STAKING_TERM.color[term]}>
        <span>{STAKING_TERM.month[term]}</span>Month
      </StakingTerm>

      <EndDate>
        {`${parsedEndDate.year}.${parsedEndDate.month}.${parsedEndDate.day}`}
        <span>{`${parsedEndDate.hour}:${parsedEndDate.minute}`}</span>
      </EndDate>

      <UnstakeButton onClick={isPenaltyFirst ? handleShowModal : undefined}>
        {t('unstaking')}
        <PenaltyWarningIcon
          src="/dexAssets/unstaking_warning_icon.png"
          alt="Unstaking Warning Icon"
        />
      </UnstakeButton>

      {!isPenaltyFirst ? <DisabledBlock /> : null}
    </StakingListItemWrapper>
  )
}

export default StakingListItem

import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { useManageUnstakingModalState } from '../state/modal/unstakingModalState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import checkMobileDevice from '../utils/checkMobileDevice'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'
import { getUnstakingABI } from '../contract/abi'
import { CONTRACT_ADDRESS } from '../contract/address'

export default function useNeuronUnstaking() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const {
    unstakingModalState: { isPenalty, term, stakingIndex },
  } = useManageUnstakingModalState()

  const klipTokenNeuronUnstakingSuccessCallback = (
    result: any,
    successCallback: () => Promise<void>,
  ) => {
    if (result.status === 'success') successCallback()
  }

  const klipNeuronUnstaking = async (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const param = isPenalty ? [address, term, stakingIndex] : [address]

    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.gnr,
      value: '0',
      abi: JSON.stringify(getUnstakingABI(isPenalty)),
      params: JSON.stringify(param),
    })

    if (err) {
      failureCallback()
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipTokenNeuronUnstakingSuccessCallback(result, successCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasNeuronUnstaking = (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const param = isPenalty ? [address, term, stakingIndex] : [address]

    const data = caver.klay.abi.encodeFunctionCall(
      getUnstakingABI(isPenalty),
      param,
    )

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.gnr,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const bitkeepNeuronUnstaking = (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const param = isPenalty ? [address, term, stakingIndex] : [address]

    const data = web3.eth.abi.encodeFunctionCall(
      getUnstakingABI(isPenalty),
      param as string[],
    )

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.gnr,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', () => {
        successCallback()
      })
  }

  const handleNeuronUnstaking = async (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipNeuronUnstaking(successCallback, failureCallback)
    else if (type === 'kaikas')
      kaikasNeuronUnstaking(successCallback, failureCallback)
    else if (type === 'bitkeep')
      bitkeepNeuronUnstaking(successCallback, failureCallback)
  }

  return handleNeuronUnstaking
}

import { ReactNode } from 'react'
import styled from 'styled-components'

type ModalHeadProps = {
  children: ReactNode
  handleClose: () => void
}

const ModalHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #2e2e2e;
`

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
`

const ModalHead = ({ children, handleClose }: ModalHeadProps) => {
  return (
    <ModalHeadWrapper>
      <div>{children}</div>

      <CloseIcon
        src="/dexAssets/modal_icons/modal_close_icon.png"
        alt="Modal Close Icon"
        onClick={handleClose}
      />
    </ModalHeadWrapper>
  )
}

export default ModalHead

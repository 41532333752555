import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageTokenSelectModalState } from '../../../state/modal/tokenSelectModalState'
import { TokenType } from '../../../api/query/useTokenQuery'
import { InputPositionType } from '../../../utils/types'

type SwapMainItemTokenProps = {
  token?: TokenType
  position: InputPositionType
}

const SwapMainItemTokenWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TokenSelectArrowIcon = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 5px;

  path {
    fill: #9d9999;
    transition: 0.15 all;
  }

  @media (max-width: 768px) {
    margin-right: 4px;
  }
`

const TokenIcon = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);
  background: #ffffff;
  overflow: hidden;
  transition: 0.15s all;

  img {
    width: 100%;
    height: 100%;
    background: #ffffff;
    transition: 0.15s all;
  }

  span {
    font-size: 10px;
    font-weight: 700;
    color: #9d9999;
    opacity: 0.5;
    transition: 0.15s all;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

const SelectTokenButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${TokenSelectArrowIcon} {
      path {
        fill: #ff6d68;
      }
    }

    ${TokenIcon} {
      border: 2px solid #ff6d68;

      img {
        width: 32px;
        height: 32px;
      }

      span {
        font-size: 7px;
      }
    }
  }

  @media (max-width: 768px) {
    &:hover {
      ${TokenIcon} {
        border: 2px solid #ff6d68;

        img {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 5px;
        }
      }
    }
  }
`

const SelectedTokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  & > div:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    color: #9d9999;
  }

  @media (max-width: 768px) {
    margin-left: 4px;

    & > div:nth-child(1) {
      font-size: 16px;
    }
  }
`

const SwapMainItemToken = ({ token, position }: SwapMainItemTokenProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const { setTokenSelectModalState } = useManageTokenSelectModalState()

  const showTokenSelectModal = () =>
    setTokenSelectModalState({
      isVisible: true,
      position,
      searchInput: '',
      isFiltered: false,
    })

  return (
    <SwapMainItemTokenWrapper>
      <SelectTokenButton onClick={showTokenSelectModal}>
        <TokenSelectArrowIcon data-name="Token Select Icon">
          <path style={{ fill: 'none', opacity: '0.2' }} d="M0 0h16v16H0z" />
          <path
            d="M9.261 1.612c-.334.343-3.853 3.7-3.853 3.7a.9.9 0 0 1-1.294 0S.594 1.955.26 1.612a.977.977 0 0 1 0-1.327.875.875 0 0 1 1.294 0l3.207 3.077L7.967.286a.875.875 0 0 1 1.294 0 .974.974 0 0 1 0 1.326z"
            transform="translate(3.24 5)"
          />
        </TokenSelectArrowIcon>

        <TokenIcon>
          <img
            src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${
              token?.address ?? 'TOKEN'
            }.png`}
            alt="Token Icon"
          />
        </TokenIcon>
      </SelectTokenButton>

      <SelectedTokenInfo>
        <div>{token?.symbol ?? 'TOKEN'}</div>
        <div>
          {token?.[language === 'en' ? 'engName' : 'korName'] ?? 'Token'}
        </div>
      </SelectedTokenInfo>
    </SwapMainItemTokenWrapper>
  )
}

export default SwapMainItemToken

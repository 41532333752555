import { useQuery, useQueryClient } from 'react-query'
import AxiosClient from '../axiosConfig'

export type TokenType = {
  address: string
  symbol: string
  engName: string
  korName: string
  price: number
  decimal: number
  balance: string
}

const loadToken = async (address: string) =>
  await AxiosClient.getAxiosInstance(true, false).get(`/api/tokens/${address}`)

export default function useTokenQuery(address: string) {
  const queryClient = useQueryClient()

  return useQuery<TokenType>(
    ['token', address],
    async () => {
      const {
        data: { data },
      } = await loadToken(address)

      return data
    },
    {
      enabled: address !== '' && AxiosClient.isInitialized(),
      retry: false,
      refetchOnWindowFocus: true,
      onSuccess: token => {
        queryClient.setQueryData<{ tokens: TokenType[] } | undefined>(
          ['tokenList', ''],
          prev => {
            if (prev === undefined) return prev

            return {
              ...prev,
              tokens: prev?.tokens.map(prevToken =>
                prevToken.address === token.address ? token : prevToken,
              ),
            }
          },
        )
      },
    },
  )
}

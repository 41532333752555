import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StakingTermType } from '../../../utils/types'

type StakingTermSelectProps = {
  isVisibleTotalButton: boolean
  stakingTermIndex: StakingTermType
  handleChangeStakingTerm: (termIndex: StakingTermType) => void
}

const StakingTermSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TotalStakingTermButton = styled.button<{ isSelected: boolean }>`
  display: grid;
  place-items: center;
  width: 40px;
  height: 24px;
  margin-right: 8px;
  border-radius: 6px;
  border: 1px solid #d1cbcb;
  background: ${({ isSelected }) => (isSelected ? '#d1cbcb' : '#ffffff')};
  font-size: 12px;
  font-weight: 500;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#9d9999')};
  line-height: 1.9;
  cursor: ${({ isSelected }) => (isSelected ? 'initial' : 'pointer')};
  outline: 0;
  transition: 0.15s all;
`

const StakingTermButton = styled.button<{
  isSelected: boolean
  background: string
  isWide?: boolean
}>`
  display: grid;
  place-items: center;
  width: ${({ isWide }) => (isWide ? '36' : '24')}px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid
    ${({ isSelected, background }) => (isSelected ? background : '#d1cbcb')};
  background: ${({ isSelected, background }) =>
    isSelected ? background : '#ffffff'};
  font-size 14px;
  font-weight: 700;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#9d9999')};
  line-height: 1.65;
  cursor: pointer;
  outline: 0;
  transition: 0.15s all;

  & + & {
    margin-left: 8px;
  }
`

const StakingTermSelect = ({
  isVisibleTotalButton,
  stakingTermIndex,
  handleChangeStakingTerm,
}: StakingTermSelectProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingList',
  })

  const handleToggleTermIndex = (
    termIndex: StakingTermSelectProps['stakingTermIndex'],
  ) => {
    if (stakingTermIndex === termIndex) handleChangeStakingTerm(-1)
    else handleChangeStakingTerm(termIndex)
  }

  return (
    <StakingTermSelectWrapper>
      {isVisibleTotalButton ? (
        <TotalStakingTermButton
          isSelected={stakingTermIndex === -1}
          onClick={() => handleToggleTermIndex(-1)}
        >
          {t('all')}
        </TotalStakingTermButton>
      ) : null}
      <StakingTermButton
        isSelected={stakingTermIndex === 0}
        background="#d1cbcb"
        onClick={() => handleToggleTermIndex(0)}
      >
        2
      </StakingTermButton>
      <StakingTermButton
        isSelected={stakingTermIndex === 1}
        background="#d1cbcb"
        onClick={() => handleToggleTermIndex(1)}
      >
        4
      </StakingTermButton>
      <StakingTermButton
        isSelected={stakingTermIndex === 2}
        background="#9d9999"
        onClick={() => handleToggleTermIndex(2)}
      >
        8
      </StakingTermButton>
      <StakingTermButton
        isSelected={stakingTermIndex === 3}
        background="#65dfb9"
        isWide
        onClick={() => handleToggleTermIndex(3)}
      >
        12
      </StakingTermButton>
      <StakingTermButton
        isSelected={stakingTermIndex === 4}
        background="#ff6d68"
        isWide
        onClick={() => handleToggleTermIndex(4)}
      >
        24
      </StakingTermButton>
    </StakingTermSelectWrapper>
  )
}

export default StakingTermSelect

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'

type DepositPoolInfoEstimatedRewardProps = {
  apr: number
}

const DepositPoolInfoEstimatedRewardWrapper = styled.div`
  text-align: right;
`

const Title = styled.div`
  margin-bottom: 3px;
  font-size: 10px;
  color: #2e2e2e;
  line-height: 1.7;
`

const EstimatedReward = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #ff6d68;

  span {
    font-size: 14px;
  }
`

const HotBadge = styled.img`
  width: 55px;
  margin-right: 8px;
`

const DepositPoolInfoEstimatedReward = ({
  apr,
}: DepositPoolInfoEstimatedRewardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositPoolInfo.DepositPoolInfoEstimatedReward',
  })

  return (
    <DepositPoolInfoEstimatedRewardWrapper>
      <Title>{t('expectedReturn')}</Title>
      <EstimatedReward>
        {apr >= 1000 ? (
          <HotBadge src="/dexAssets/hot_icon.png" alt="Hot Icon" />
        ) : null}
        {convertNumber(apr, { isPercentage: true })}
        <span>%</span>
      </EstimatedReward>
    </DepositPoolInfoEstimatedRewardWrapper>
  )
}

export default DepositPoolInfoEstimatedReward

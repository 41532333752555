import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useRefreshGnrBalanceMutation from '../../api/mutation/useRefreshGnrBalanceMutation'
import useNeuronStaking from '../../hooks/useNeuronStaking'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import { useManageStakingModalState } from '../../state/modal/stakingModalState'
import { useManageStakingFormState } from '../../state/staking/stakingFormState'
import convertNumber from '../../utils/convertNumber'
import { NR_SYMBOL, STAKING_TERM } from '../../utils/constants'
import parseDate from '../../utils/parseDate'
import { TOKEN_ADDRESS } from '../../contract/address'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import { gnrContract } from '../../contract/contract'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 520px;
    padding: 32px 24px 24px 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    margin-top: 16px;
    padding: 18px 0;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }
`

const TransactionDetailInfo = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const TransactionDetailInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #9d9999;

  & > div:nth-child(2) {
    display: flex;
  }

  span {
    margin-left: 6px;
    color: #2e2e2e;
  }

  & + & {
    margin-top: 13px;
  }

  @media (max-width: 768px) {
    & + & {
      margin-top: 16px;
    }

    & > div:nth-child(1) {
      white-space: pre-line;
    }

    &:nth-child(1) > div:nth-child(2) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

const TransactionButton = styled.button`
  width: 100%;
  margin-top: auto;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const NeuronStakingModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.NeuronStakingModal',
  })
  const {
    stakingFormState: { value, term },
    resetStakingFormState,
  } = useManageStakingFormState()
  const { setStakingModalState } = useManageStakingModalState()
  const handleNeuronStaking = useNeuronStaking()
  const refreshGnrBalanceMutation = useRefreshGnrBalanceMutation()
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()
  const [expectEndDate, setExpectEndDate] = useState<{
    date: string
    time: string
  }>({ date: '', time: '' })

  const loadExpectEndDate = async () => {
    const lockupTimestamp = await gnrContract.methods
      .multipleToLockup(term)
      .call()
    const parsedEndDate = parseDate(
      Date.now() + parseInt(lockupTimestamp as string) * 1000,
    )

    setExpectEndDate({
      date: `${parsedEndDate.year}.${parsedEndDate.month}.${parsedEndDate.day}`,
      time: `${parsedEndDate.hour}:${parsedEndDate.minute}`,
    })
  }

  const stakingSuccessCallback = async () => {
    setStakingModalState(false)
    resetStakingFormState()
    pushMessage('request', 'success')

    await refreshGnrBalanceMutation.mutateAsync()
    queryClient.invalidateQueries(['token', TOKEN_ADDRESS[NR_SYMBOL] as string])
    queryClient.invalidateQueries('totalStakedNeuron')
    queryClient.invalidateQueries('stakingInfo')
    queryClient.invalidateQueries('stakingList')
  }

  const stakingFailureCallback = () => pushMessage('request', 'failure')

  const handleNeuronStakingTransaction = () =>
    handleNeuronStaking(stakingSuccessCallback, stakingFailureCallback)

  useEffect(() => {
    loadExpectEndDate()
  }, [term])

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={() => setStakingModalState(false)}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div>{t('stakingBalance')}</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(value, { comma: true, maxDigit: 6 })}
              </SlicedDecimal>
              <span>NR</span>
            </div>
          </TransactionInfoItem>
        </TransactionInfo>

        <TransactionDetailInfo>
          <TransactionDetailInfoItem>
            <div>{t('detail.0')}</div>
            <div>{STAKING_TERM.month[term]} Month</div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t('detail.1')}</div>
            <div>
              {expectEndDate.date}
              <span>{expectEndDate.time}</span>
            </div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t('detail.2')}</div>
            <div style={{ color: '#ff6d68' }}>
              <SlicedDecimal maxIntLength={20} showTooltip={false}>
                {convertNumber(
                  parseFloat(value) * STAKING_TERM.multiple[term],
                  { comma: true, maxDigit: 6 },
                )}
              </SlicedDecimal>
              <div style={{ marginLeft: '3px' }}>gNR</div>
            </div>
          </TransactionDetailInfoItem>
        </TransactionDetailInfo>

        <TransactionButton onClick={handleNeuronStakingTransaction}>
          {t('button')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default NeuronStakingModal

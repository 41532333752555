import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useDebounceInput from '../../../hooks/useDebounceInput'
import SearchInput from '../atoms/SearchInput'
import DashboardList from '../organisms/DashboardList'
import useDashboardPoolsQuery from '../../../api/query/useDashboardPoolsQuery'

const DashboardListSectionWrapper = styled.div`
  margin-top: 85px;

  @media (max-width: 768px) {
    margin-top: 56px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`

const TitleItem = styled.div<{ index: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0px ${({ index }) => (index === '1' ? '12' : '15')}px;
  border-radius: 15px;
  background: #e8e8e8;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  span {
    margin-left: 6px;
    color: #ff6d68;
  }

  & + & {
    margin-left: 10px;
  }
`

const RightSideWrapper = styled.div<{ language: string }>`
  width: ${({ language }) => (language === 'ko' ? '180' : '216')}px;
  margin-left: auto;
  font-size: 12px;

  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: auto;
    margin: 16px 0;
  }
`

const DashboardListTemplate = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardListTemplate',
  })
  const { page } = useParams()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchQuery = useDebounceInput(searchQuery)
  const { data } = useDashboardPoolsQuery(page ?? '1', debouncedSearchQuery)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value)

  return (
    <DashboardListSectionWrapper>
      <TitleWrapper>
        <TitleItem index="1">
          {t('title.pools')} <span>{data?.poolTotalCount ?? 0}</span>
        </TitleItem>
        <TitleItem index="2">
          {t('title.tokens')} <span>{data?.tokenTotalCount ?? 0}</span>
        </TitleItem>

        <RightSideWrapper language={language}>
          <SearchInput
            placeholder={t('search')}
            value={searchQuery}
            onChange={handleChange}
          />
        </RightSideWrapper>
      </TitleWrapper>

      <DashboardList list={data?.pools ?? []} />
    </DashboardListSectionWrapper>
  )
}

export default DashboardListTemplate

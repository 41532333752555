import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const TransactionTableHead = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1.3fr 0.5fr 0.7fr;
  grid-gap: 50px;
  align-items: center;
  height: 37px;
  margin-bottom: 19px;
  padding: 0 40px 0 10px;
  border-radius: 18.5px;
  background: #e8e8e8;
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    grid-gap: 20px;
    margin: 0 -10px 9px;
    padding: 0 20px;
  }
`

const HeadItem = styled.div<{ align: 'right' | 'left' | 'center' }>`
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : `flex-${align === 'left' ? 'start' : 'end'}`};
`

const TransactionHistoryListHead = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'TransactionHistory.menu',
  })
  const { width } = useGetWindowSize()

  return (
    <TransactionTableHead>
      <HeadItem align={width > 768 ? 'center' : 'left'}>{t('0')}</HeadItem>
      <HeadItem align="left">{t('1')}</HeadItem>
      <HeadItem align="center">{t('2')}</HeadItem>
      <HeadItem align="right">{t('3')}</HeadItem>
    </TransactionTableHead>
  )
}

export default TransactionHistoryListHead

import { ChangeEvent, KeyboardEvent, MouseEvent } from 'react'
import styled from 'styled-components'

type SearchInputProps = {
  placeholder?: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleSearch?: (
    event: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
  ) => void
}

const SearchInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  height: 30px;
  padding: 0 7px 0 16px;
  border-radius: 15px;
  border: 0.5px solid #d1cbcb;
  background: #ffffff;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    height: 48px;
    border-radius: 24px;
  }
`

const Input = styled.input`
  border: 0;
  outline: 0;
  font-size: 12px;
  color: #9d9999;
  background: transparent;

  @media (max-width: 768px) {
    flex: 1;
    font-size: 14px;
  }
`

const SearchButton = styled.button`
  display: grid;
  place-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    margin-right: 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`

const SearchInput = ({
  placeholder,
  value,
  onChange,
  handleSearch,
}: SearchInputProps) => {
  return (
    <SearchInputWrapper>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={handleSearch}
      />
      <SearchButton onClick={handleSearch}>
        <img src="/dexAssets/search_icon.png" alt="Search Icon" />
      </SearchButton>
    </SearchInputWrapper>
  )
}

export default SearchInput

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'
import { TOKEN_ADDRESS } from '../../contract/address'
import { NR_SYMBOL } from '../../utils/constants'
import { InputPositionType } from '../../utils/types'

export type SwapFormStateType = {
  form: {
    [key in InputPositionType]: {
      address: string
      value: string
    }
  } & {
    lastModified: 'from' | 'to'
  }
  settings: {
    slippage: string
    isMax: boolean
  }
  error: 'INSUFFICIENT_QUANTITY' | 'INSUFFICIENT_LIQUIDITY' | undefined
}

const swapFormAtom = atom<SwapFormStateType>({
  key: 'swapForm',
  default: {
    form: {
      from: {
        address: TOKEN_ADDRESS[NR_SYMBOL],
        value: '',
      },
      to: {
        address: '',
        value: '',
      },
      lastModified: 'from',
    },
    settings: {
      slippage: '0.50',
      isMax: false,
    },
    error: undefined,
  },
})

export const useManageSwapFormState = (): {
  swapFormState: SwapFormStateType
  setSwapFormState: SetterOrUpdater<SwapFormStateType>
  resetSwapFormState: Resetter
} => {
  const [swapFormState, setSwapFormState] = useRecoilState(swapFormAtom)
  const resetSwapFormState = useResetRecoilState(swapFormAtom)

  return { swapFormState, setSwapFormState, resetSwapFormState }
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageStakingRewardsModalState } from '../../../state/modal/stakingRewardsModalState'
import convertNumber from '../../../utils/convertNumber'
import useStakingRewardsQuery from '../../../api/query/useStakingRewardsQuery'

const StakingRewardCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 276px;
  padding: 27px 40px 33px;
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  background: #ffffff;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  margin-bottom: 27px;
`

const HoldingQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 16px;
  background-color: #f8f8f8;
  font-size: 24px;
  font-weight: 700;
  color: #000000;

  span {
    margin-left: 6px;
    color: #ff6d68;
  }
`

const StakingDetail = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18px;
`

const AccumulateRewards = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    margin-right: 8px;
    font-size: 15px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 3px;
      color: #9d9999;
    }
  }
`

const StakingRewardButton = styled.button<{ $isActive: boolean }>`
  display: grid;
  place-items: center;
  width: 238px;
  height: 48px;
  margin-top: auto;
  margin-left: auto;
  border-radius: 24px;
  border: 0;
  background: ${({ $isActive }) => ($isActive ? '#65dfb9' : '#9d9999')};
  outline: 0;
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const StakingRewardCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.NRStakingRewards',
  })
  const { data } = useStakingRewardsQuery()
  const { setStakingRewardsModalState } = useManageStakingRewardsModalState()

  const handleShowModal = () =>
    setStakingRewardsModalState({ visible: true, type: 'stakingRewards' })

  return (
    <StakingRewardCardWrapper>
      <Title>{t('title')}</Title>

      <HoldingQuantity>
        {convertNumber(data?.rewards ?? 0, { comma: true, maxDigit: 6 })}
        <span>NR</span>
      </HoldingQuantity>

      <StakingDetail>
        <AccumulateRewards>
          <div>{t('accumulatedRewards')}</div>
          <div>
            {convertNumber(data?.accumulatedRewards ?? 0, {
              comma: true,
              maxDigit: 6,
            })}
            <span>NR</span>
          </div>
        </AccumulateRewards>

        <StakingRewardButton
          $isActive={(data?.rewards ?? 0) !== 0}
          onClick={(data?.rewards ?? 0) !== 0 ? handleShowModal : undefined}
        >
          {t('claimRewards')}
        </StakingRewardButton>
      </StakingDetail>
    </StakingRewardCardWrapper>
  )
}

export default StakingRewardCard

import { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import NoticeTooltip from '../atoms/NoticeTooltip'

type StakingGnrDetailItemProps = {
  title: string | ReactNode
  tooltip: string | ReactNode
  tooltipCustomStyle?: CSSProperties
  highlight?: boolean
  children: ReactNode
}

const ICON_CUSTOM_STYLE: CSSProperties = {
  width: '16px',
  margin: '0 10px 0 6px',
}

const WIDGET_CUSTOM_STYLE: CSSProperties = {
  width: '231px',
  padding: `17px 20px`,
  borderRadius: '14px',
  fontSize: '12px',
  textAlign: 'left',
  lineHeight: '1.67',
}

const StakingGnrDetailItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-size: 15px;
  color: #9d9999;
`

const Percent = styled.div<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #${({ highlight }) => (highlight ? 'ff6d68' : '000000')};
`

const StakingGnrDetailItem = ({
  title,
  tooltip,
  tooltipCustomStyle,
  highlight,
  children,
}: StakingGnrDetailItemProps) => {
  return (
    <StakingGnrDetailItemWrapper>
      <Title>{title}</Title>
      <NoticeTooltip
        iconCustomStyle={ICON_CUSTOM_STYLE}
        widgetCustomStyle={{ ...WIDGET_CUSTOM_STYLE, ...tooltipCustomStyle }}
      >
        {tooltip}
      </NoticeTooltip>
      <Percent highlight={highlight}>{children}</Percent>
    </StakingGnrDetailItemWrapper>
  )
}

export default StakingGnrDetailItem

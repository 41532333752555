import Caver from 'caver-js'

const caver = new Caver(
  new Caver(
    new Caver.providers.HttpProvider(
      'https://node-api.klaytnapi.com/v1/klaytn',
      {
        headers: [
          {
            name: 'Authorization',
            value: `Basic S0FTSzcxQVVRVDBSUUNPUTM0QkdGUjQyOnUzd1hVVDNWbDhOdm9DV0FnM3ZGaGJBekN2MkdqTkNrdkhoR01CQ2o=`,
          },
          { name: 'x-chain-id', value: '8217' },
        ],
      },
    ),
  ),
)
export const caverForKaikas = new Caver(window.klaytn)

export default caver

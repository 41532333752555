import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import produce from 'immer'
import { useManageTokenSelectModalState } from '../../../state/modal/tokenSelectModalState'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import TokenModalSectionListItem from '../atoms/TokenModalSectionListItem'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useDebounceInput from '../../../hooks/useDebounceInput'
import useTokenListQuery from '../../../api/query/useTokenListQuery'

const TokenListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & + & {
    border-top: 0.5px solid #edeaea;
  }
`

const TokenPrice = styled.div`
  font-size: 12px;
  color: #9d9999;
`

const TokenModalSectionListWrapper = styled.div`
  height: 243px;
  margin: 0 -22px;
  padding: 0 22px;
  overflow: auto;

  @media (max-width: 768px) {
    height: unset;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #edeaea;
    border-radius: 5px;
  }
`

const TokenModalSectionList = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const {
    tokenSelectModalState: { position, isFiltered, searchInput },
    resetTokenSelectModalState,
  } = useManageTokenSelectModalState()
  const {
    swapFormState: {
      settings: { isMax },
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const debouncedSearchQuery = useDebounceInput(searchInput)
  const { data } = useTokenListQuery(debouncedSearchQuery)

  const filteredTokenList = useMemo(
    () =>
      data !== undefined
        ? data?.tokens.filter(({ balance }) => !isFiltered || balance !== '0')
        : [],
    [isFiltered, data],
  )

  const selectToken = (tokenAddress: string) => {
    if (data === undefined) return

    setSwapFormState(prev =>
      produce(prev, draft => {
        const isAlreadySelect =
          prev.form[position === 'from' ? 'to' : 'from'].address ===
          tokenAddress

        if (isAlreadySelect) {
          const { from, to } = prev.form

          const toToken = data.tokens.find(
            ({ address }) => address === to.address,
          )

          if (toToken === undefined) return draft

          if (isMax) {
            draft.form.from = {
              address: to.address,
              value: toToken.balance,
            }
            draft.form.to = from
            draft.form.lastModified = 'from'
          } else {
            draft.form.from = to
            draft.form.to = from
            draft.form.lastModified =
              prev.form.lastModified === 'from' ? 'to' : 'from'
          }
        } else {
          draft.form[position].address = tokenAddress

          if (position === 'from' && isMax) {
            const token = data.tokens.find(
              ({ address }) => address === tokenAddress,
            )

            if (token === undefined) return draft

            draft.form.from.value = token.balance
            draft.form.lastModified = 'from'
          }
        }

        return draft
      }),
    )
    resetTokenSelectModalState()
  }

  return (
    <TokenModalSectionListWrapper>
      {filteredTokenList.map(
        ({ address, symbol, balance, korName, engName }) => {
          const handleSelectToken = () => selectToken(address)

          return (
            <TokenListItem onClick={handleSelectToken} key={address}>
              <TokenModalSectionListItem
                address={address}
                symbol={symbol}
                name={language === 'ko' ? korName : engName}
              />
              <TokenPrice>
                {balance !== '0' ? (
                  <SlicedDecimal>
                    {convertNumber(balance, { maxDigit: 6 })}
                  </SlicedDecimal>
                ) : null}
              </TokenPrice>
            </TokenListItem>
          )
        },
      )}
    </TokenModalSectionListWrapper>
  )
}

export default TokenModalSectionList

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import useSetReferralAddress from '../../../hooks/useSetReferralAddress'
import useCallMessageBox from '../../../hooks/useCallMessageBox'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { AcceptInvitationStatusType } from '../../../utils/types'
import useWalletQuery from '../../../api/query/useWalletQuery'
import useSetReferralAddressMutation from '../../../api/mutation/useSetReferralAddressMutation'

const BUTTON_STYLE_BY_STATUS: {
  background: { [key in AcceptInvitationStatusType]: string }
  color: { [key in AcceptInvitationStatusType]: string }
} = {
  background: {
    available: '#ff6d68',
    accepted: '#9d9999',
    error: '#b95955',
  },
  color: {
    available: '#ffffff',
    accepted: 'rgba(255, 255, 255, 0.7)',
    error: '#ffffff',
  },
}

const ReferralAcceptInvitationWrapper = styled.div<{
  language: string
  isExistEnteredAddress: string
  isExistReferralAddress: string
}>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5px;
  align-items: center;
  height: 64px;
  margin-top: 20px;
  padding: 0 20px;
  border-radius: 16px;
  background: #f8f8f8;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: ${({ language, isExistEnteredAddress, isExistReferralAddress }) =>
        isExistReferralAddress === 'true' ||
        (language === 'ko' && isExistEnteredAddress === 'false')
          ? '20'
          : '14'}px
      0 12px;
  }
`

const ReferralAddress = styled.div<{
  isExistEnteredAddress: string
  isExistReferralAddress: string
}>`
  font-size: ${({ isExistEnteredAddress }) =>
    isExistEnteredAddress === 'true' ? '12' : '14'}px;
  font-weight: ${({ isExistReferralAddress }) =>
    isExistReferralAddress === 'true' ? '700' : '400'};
  color: rgba(
    0,
    0,
    0,
    ${({ isExistEnteredAddress, isExistReferralAddress }) =>
      isExistEnteredAddress === 'true' && isExistReferralAddress === 'false'
        ? '1'
        : '0.2'}
  );
  white-space: pre-line;
  line-height: 1.36;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 12px;
  }
`

const Button = styled.button<{
  language: string
  status: AcceptInvitationStatusType
}>`
  display: grid;
  place-items: center;
  width: ${({ status, language }) =>
    status === 'accepted' || language === 'ko' ? '97' : '112'}px;
  height: 32px;
  border: 0;
  border-radius: 16px;
  background: ${({ status }) => BUTTON_STYLE_BY_STATUS.background[status]};
  font-size: ${({ language, status }) =>
    language === 'ko' ? '16' : status === 'accepted' ? '12' : '11'}px;
  font-weight: 700;
  color: ${({ status }) => BUTTON_STYLE_BY_STATUS.color[status]};
  line-height: ${({ language, status }) =>
    language === 'ko' ? '2.1' : status === 'available' ? '2.2' : '1.8'};
  cursor: ${({ status }) => (status === 'available' ? 'pointer' : 'initial')};

  ${({ status }) =>
    status === 'available'
      ? `
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
    `
      : ''}
`

const ReferralAcceptInvitation = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix:
      'Referral.ReferralData.ReferralManageInvitation.ReferralAcceptInvitation',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const { address: enteredAddressFromURL } = useParams()
  const handleSetReferralAddress = useSetReferralAddress()
  const pushMessage = useCallMessageBox()
  const { width } = useGetWindowSize()
  const [enteredAddressFromUser, setEnteredAddressFromUser] = useState<string>(
    enteredAddressFromURL ?? '',
  )
  const [status, setStatus] = useState<AcceptInvitationStatusType>('available')
  const { data } = useWalletQuery(address)
  const queryClient = useQueryClient()
  const setReferralAddressMutation = useSetReferralAddressMutation()

  const shortenAddress = (address: string) =>
    `${address.substring(0, 5)}...${address.substring(address.length - 5)}`

  const getSlicedAddress = (address: string) => {
    if (width > 768 || address.length < 30) return address

    const centerIndex = address.length / 2

    const front = address.substring(0, centerIndex)
    const back = address.substring(centerIndex + 1, address.length - 1)

    return front + '\n' + back
  }

  const setReferralAddressSuccessCallback = async () => {
    setStatus('accepted')
    pushMessage('request', 'success')
    setEnteredAddressFromUser('')

    await setReferralAddressMutation.mutateAsync(enteredAddressFromUser)
    queryClient.invalidateQueries('wallet')
  }

  const setReferralAddressFailureCallback = () =>
    pushMessage('request', 'failure')

  const setReferralAddressErrorHandler = () => setStatus('error')

  const handleAcceptInvitation = () => {
    if (address === undefined) {
      if (enteredAddressFromURL !== undefined) setConnectWalletModalState(true)
      return
    }

    if (
      address.toLowerCase() === enteredAddressFromUser.toLowerCase() ||
      enteredAddressFromUser === ''
    )
      return

    handleSetReferralAddress(
      enteredAddressFromUser,
      setReferralAddressSuccessCallback,
      setReferralAddressFailureCallback,
      setReferralAddressErrorHandler,
    )
  }

  useEffect(() => {
    if (enteredAddressFromURL === undefined) setEnteredAddressFromUser('')
  }, [enteredAddressFromURL])

  useEffect(() => {
    if (data?.wallet.inviterAddress) setStatus('accepted')
    else setStatus('available')
  }, [data, address])

  return (
    <ReferralAcceptInvitationWrapper
      language={language}
      isExistEnteredAddress={String(
        enteredAddressFromURL !== undefined &&
          enteredAddressFromURL.length >= 30,
      )}
      isExistReferralAddress={String(data?.wallet.inviterAddress !== null)}
    >
      <ReferralAddress
        isExistEnteredAddress={String(enteredAddressFromURL !== undefined)}
        isExistReferralAddress={String(data?.wallet.inviterAddress !== null)}
      >
        {!data?.wallet.inviterAddress
          ? enteredAddressFromURL !== undefined
            ? getSlicedAddress(enteredAddressFromURL)
            : t('description')
          : `${shortenAddress(data?.wallet.inviterAddress ?? '')}${t(
              'existReferralAddress',
            )}`}
      </ReferralAddress>

      <Button
        language={language}
        status={status}
        onClick={status === 'available' ? handleAcceptInvitation : undefined}
      >
        {t(status)}
      </Button>
    </ReferralAcceptInvitationWrapper>
  )
}

export default ReferralAcceptInvitation

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const MyAssetsListHeadWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.9fr 1.1fr 1.1fr 1fr;
  align-items: center;
  height: 30px;
  margin-bottom: 23px;
  padding: 0 30px;
  border-radius: 15px;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  background: #e8e8e8;
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    display: none;
  }
`

const HeadItem = styled.div<{ align: 'right' | 'left' }>`
  text-align: ${({ align }) => align};
`

const MyAssetsListHead = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'MyAssets.MyAssetsListTemplate.head',
  })

  return (
    <MyAssetsListHeadWrapper>
      <HeadItem align="left">{t('0')}</HeadItem>
      <HeadItem align="right">{t('1')}</HeadItem>
      <HeadItem align="right">{t('2')}</HeadItem>
      <HeadItem align="right">{t('3')}</HeadItem>
      <HeadItem align="right">{t('4')}</HeadItem>
    </MyAssetsListHeadWrapper>
  )
}

export default MyAssetsListHead

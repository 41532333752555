import { useMemo } from 'react'
import { prepare, request } from 'klip-sdk'
import { useQueryClient } from 'react-query'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { CONTRACT_ADDRESS } from '../contract/address'
import { DEPOSIT_ABI } from '../contract/abi'
import { useManageTransactionHistoryState } from '../state/shared/transactionHistoryState'
import { useManageStakingRequestModalState } from '../state/modal/stakingRequestModalState'
import parseTransactionError from '../utils/parseTransactionError'
import { getCurrentTimeForHistory } from '../utils/getCurrentTime'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'
import checkMobileDevice from '../utils/checkMobileDevice'
import { PoolType } from '../api/query/usePoolQuery'

export default function useDeposit() {
  const {
    stakingRequestModalState: { pairAddress, pid },
  } = useManageStakingRequestModalState()
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setTransactionHistoryState } = useManageTransactionHistoryState()
  const queryClient = useQueryClient()

  const {
    userData: { depositedLpBalance },
  } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const DEPOSIT_PARAM = useMemo(
    () => [pid, depositedLpBalance] as string[],
    [pid, depositedLpBalance],
  )

  const handleAddTransactionHistory = (hash: string, status: boolean) =>
    setTransactionHistoryState(prev => [
      {
        type: 'deposit',
        hash,
        status,
        date: getCurrentTimeForHistory(),
      },
      ...prev,
    ])

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { tx_hash } = result

    if (result.status === 'success') {
      successCallback()
      handleAddTransactionHistory(tx_hash as string, true)
    } else {
      failureCallback()
      handleAddTransactionHistory(tx_hash as string, false)
    }
  }

  const klipDeposit = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.masterchef,
      abi: JSON.stringify(DEPOSIT_ABI),
      params: JSON.stringify(DEPOSIT_PARAM),
      value: '0',
    })

    if (err) {
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasDeposit = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (pairAddress === undefined) return

    const data = caver.klay.abi.encodeFunctionCall(DEPOSIT_ABI, DEPOSIT_PARAM)

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.masterchef,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const bitkeepDeposit = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (pairAddress === undefined) return

    const data = web3.eth.abi.encodeFunctionCall(DEPOSIT_ABI, DEPOSIT_PARAM)

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.masterchef,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const handleDeposit = async (
    depositSuccessCallback: () => void,
    depositFailureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipDeposit(depositSuccessCallback, depositFailureCallback)
    else if (type === 'kaikas')
      kaikasDeposit(depositSuccessCallback, depositFailureCallback)
    else if (type === 'bitkeep')
      bitkeepDeposit(depositSuccessCallback, depositFailureCallback)
  }

  return handleDeposit
}

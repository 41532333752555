import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import styled from 'styled-components'
import { MyAssetsPortfolioListItemProps } from '../molecules/MyAssetsPortfolioListItem'
import ChartTokenLabel from '../molecules/ChartTokenLabel'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

export type MyAssetsPortfolioChartProps = {
  list: MyAssetsPortfolioListItemProps[]
}

type ChartDataType = {
  labels: string[]
  colors: string[]
  series: number[]
}

const ChartWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MyAssetsPortfolioChart = ({ list }: MyAssetsPortfolioChartProps) => {
  const { width } = useGetWindowSize()
  const [labelData, setLabelData] =
    useState<MyAssetsPortfolioListItemProps | null>(null)

  const { labels, colors, series } = list.reduce<ChartDataType>(
    (list, item) => {
      const { color, symbol, proportion } = item

      list.labels.push(symbol)
      list.colors.push(color)
      list.series.push(proportion)

      return list
    },
    {
      labels: [],
      colors: [],
      series: [],
    },
  )

  const options: ApexOptions = {
    labels: labels,
    colors,
    chart: {
      type: 'donut',
      events: {
        click(event, chartContext, config) {
          const selectedTokenIndex = config.globals.selectedDataPoints[0][0]

          if (
            selectedTokenIndex === undefined ||
            selectedTokenIndex >= list.length
          )
            setLabelData(null)
          else setLabelData(list[selectedTokenIndex])
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      colors: ['#ffffff'],
      width: 2,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
  }

  useEffect(() => {
    if (list.length !== 0) setLabelData(list[0])
  }, [list])

  return (
    <ChartWrapper>
      <Chart
        options={options}
        series={series}
        type="donut"
        width={width > 768 ? '376' : '350'}
      />
      <ChartTokenLabel labelData={labelData} />
    </ChartWrapper>
  )
}

export default MyAssetsPortfolioChart

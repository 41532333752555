import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ConnectedWalletStateType } from '../../../state/shared/connectedWalletState'

type ConnectWalletButtonProps = {
  type: NonNullable<ConnectedWalletStateType['type']>
  handleConnectWallet: (
    type: NonNullable<ConnectedWalletStateType['type']>,
  ) => void
}

const ConnectWalletButtonWrapper = styled.div`
  @keyframes rotate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  display: grid;
  place-items: stretch;
  width: 100%;
  height: 64px;
  padding: 1px;
  border-radius: 15px;
  background: #f8f8f8;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to right, #ff6d68, #815cff 50%, #65dfb9);
    animation: rotate 2s ease infinite;
    background-size: 200% 200%;
  }

  & + & {
    margin-top: 13px;
  }

  @media (max-width: 768px) {
    height: 54px;
  }
`

const ButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  border-radius: 14px;
  background: #f8f8f8;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;

  img {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const ConnectWalletButton = ({
  type,
  handleConnectWallet,
}: ConnectWalletButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.ConnectWalletModal',
  })

  return (
    <ConnectWalletButtonWrapper onClick={() => handleConnectWallet(type)}>
      <ButtonContents>
        <div>{t(type)}</div>

        <img
          src={`/dexAssets/modal_icons/connect_wallet/${type}.png`}
          alt="Connect Wallet Icon"
        />
      </ButtonContents>
    </ConnectWalletButtonWrapper>
  )
}

export default ConnectWalletButton

import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MobilePoolItemEstimatedRewards from '../atoms/MobilePoolItemEstimatedRewards'
import SlicedDecimal from '../atoms/SlicedDecimal'
import MobilePopup from '../molecules/MobilePopup'
import convertNumber from '../../../utils/convertNumber'
import usePoolQuery from '../../../api/query/usePoolQuery'

type MobileDepositPoolInfoProps = {
  pairAddress: string
}

const MobileDepositPoolInfoWrapper = styled.div<{ language: string }>`
  padding: 13px 16px 15px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
`

const MobileDepositPoolInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 17px;
  }
`

const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #9d9999;

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-bottom: 1px;
  }
`

const MobileContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #656565;
`

const MobilePopupItem = styled.div`
  padding: 32px 0;
  color: #ffffff;
  text-align: center;

  & > div:nth-child(1) {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.5;
  }

  & + & {
    border-top: 1px solid #ffffff;
  }
`

const MobileDepositPoolInfo = ({ pairAddress }: MobileDepositPoolInfoProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Deposit.MobileDepositPoolInfo.title',
  })
  const { t: tooltipTranslation } = useTranslation('translation', {
    keyPrefix: 'Pool.Tooltip',
  })
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)
  const { data } = usePoolQuery(pairAddress)

  const handleShowPopup = () => setIsVisiblePopup(true)
  const handleClosePopup = () => setIsVisiblePopup(false)

  if (data === undefined) return null

  return (
    <>
      <MobileDepositPoolInfoWrapper language={language}>
        <MobileDepositPoolInfoItem>
          <MobileTitle style={{ marginTop: '-20px' }}>
            {t('0')}
            <img
              onClick={handleShowPopup}
              src="/dexAssets/tooltip_icon.png"
              alt="Mobile Popup Icon"
            />
          </MobileTitle>
          <MobilePoolItemEstimatedRewards
            rewardsDetail={data.rewardsDetail}
            fontSize="18"
            enableDetail={true}
          />
        </MobileDepositPoolInfoItem>

        <MobileDepositPoolInfoItem>
          <MobileTitle>{t('1')}</MobileTitle>
          <MobileContent>
            <span style={{ marginRight: '3px' }}>$</span>
            <SlicedDecimal prefix="$ " maxIntLength={10} isNotDecimal>
              {convertNumber(data.totalLiquidity, { maxDigit: 0 })}
            </SlicedDecimal>
          </MobileContent>
        </MobileDepositPoolInfoItem>

        <MobileDepositPoolInfoItem>
          <MobileTitle>{t('2')}</MobileTitle>
          <MobileContent>
            <SlicedDecimal suffix=" NR" maxIntLength={7} isNotDecimal>
              {convertNumber(data.rewardsDetail.distributedNRPerDay, {
                maxDigit: 0,
              })}
            </SlicedDecimal>
            <span style={{ marginLeft: '3px' }}>NR</span>
          </MobileContent>
        </MobileDepositPoolInfoItem>
      </MobileDepositPoolInfoWrapper>

      <MobilePopup
        type="normal"
        isVisible={isVisiblePopup}
        handleClose={handleClosePopup}
      >
        <MobilePopupItem>
          <div>{tooltipTranslation('0.title')}</div>
          <div>{tooltipTranslation('0.popupContents')}</div>
        </MobilePopupItem>
        <MobilePopupItem>
          <div>{tooltipTranslation('1.title')}</div>
          <div>{tooltipTranslation('1.popupContents')}</div>
        </MobilePopupItem>
      </MobilePopup>
    </>
  )
}

export default MobileDepositPoolInfo

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'
import ReferralDashboardListItem from '../molecules/ReferralDashboardListItem'
import useReferralsTopQuery from '../../../api/query/useReferralsTopQuery'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const ReferralEventDashboardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 19px;
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.56;

  @media (max-width: 768px) {
    margin-bottom: 6px;
  }
`

const DashboardListHead = styled.div`
  display: grid;
  grid-template-columns: 30px 0.6fr 0.4fr;
  font-size: 16px;
  padding-bottom: 14px;
  border-bottom: 0.5px solid #9d9999;
  color: #707070;

  @media (max-width: 768px) {
    grid-template-columns: 30px 0.5fr 0.5fr;
    font-size: 12px;
  }
`

const LoadingIndicator = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  width: 100%;
`

const ReferralEventDashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralEventDashboard.ReferralEventDashboard',
  })
  const { data, status } = useReferralsTopQuery()
  const { width } = useGetWindowSize()

  return (
    <ReferralEventDashboardWrapper>
      <DashboardHeader>
        <Title>{t('title')}</Title>
      </DashboardHeader>

      <DashboardListHead>
        <div>{t('head.0')}</div>
        <div
          style={{
            textAlign: 'center',
            paddingLeft: width > 768 ? '60px' : '30px',
          }}
        >
          {t('head.1')}
        </div>
        <div style={{ textAlign: 'right' }}>{t('head.2')}</div>
      </DashboardListHead>

      {status === 'success' && data !== undefined ? (
        data.nWallets.map(
          ({ address, totalSpendingByInvitees }, index: number) => (
            <ReferralDashboardListItem
              rank={index + 1}
              address={address}
              amount={totalSpendingByInvitees}
              key={address}
            />
          ),
        )
      ) : (
        <LoadingIndicator>
          <ReactLoading
            type="spinningBubbles"
            color="#ff6d68"
            width="40px"
            height="40px"
          />
        </LoadingIndicator>
      )}
    </ReferralEventDashboardWrapper>
  )
}

export default ReferralEventDashboard

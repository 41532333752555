import { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components'

type MyAssetListFilterCheckboxProps = {
  children: ReactNode
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const MyAssetListFilterCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-left: auto;
  line-height: 1.3;
`

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const HiddenCheckboxInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
`

const CustomizedCheckbox = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 8px;
`

const CheckMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #9c9999;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: calc(50% - 1px);
    transform: translate(-50%, -50%) rotate(45deg);
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }

  ${HiddenCheckboxInput}:checked ~ & {
    background: #ff6d68;
    border-color: #ff6d68;
  }

  ${HiddenCheckboxInput}:checked ~ &:after {
    display: block;
  }
`

const MyAssetListFilterCheckbox = ({
  children,
  checked,
  onChange,
}: MyAssetListFilterCheckboxProps) => {
  return (
    <MyAssetListFilterCheckboxWrapper>
      <CheckboxLabel>{children}</CheckboxLabel>

      <CustomizedCheckbox>
        <HiddenCheckboxInput
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />

        <CheckMark />
      </CustomizedCheckbox>
    </MyAssetListFilterCheckboxWrapper>
  )
}

export default MyAssetListFilterCheckbox

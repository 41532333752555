import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { CONTRACT_ADDRESS } from '../../contract/address'
import { TOKEN_ABI } from '../../contract/abi'
import { useManageStakingRequestModalState } from '../../state/modal/stakingRequestModalState'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useDeposit from '../../hooks/useDeposit'
import useTokenApprove from '../../hooks/useTokenApprove'
import convertNumber from '../../utils/convertNumber'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'
import { CSS_Z_INDEX, NR_SYMBOL } from '../../utils/constants'
import { PoolType } from '../../api/query/usePoolQuery'
import caver from '../../modules/network/caver'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 580px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 520px;
    padding: 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  margin-bottom: auto;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    padding: 19px 0 17px;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div<{ alignTop?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
    white-space: pre-line;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    ${({ alignTop }) =>
      alignTop === 'true'
        ? `
      align-items: flex-start;

      & > div:nth-child(1) {
        margin-top: 1px;
      }
    `
        : ''}

    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const TransactionButton = styled.button<{ $isActive: boolean }>`
  width: 100%;
  margin-top: 14px;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #${({ $isActive }) => ($isActive ? '65dfb9' : '9d9999')};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  transition: 0.15s all;

  @media (max-width: 768px) {
    & + & {
      margin-top: 8px;
    }
  }
`

const StakingRequestModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.StakingRequestModal',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    stakingRequestModalState: { pairAddress },
    resetStakingRequestModalState,
  } = useManageStakingRequestModalState()
  const handleDeposit = useDeposit()
  const handleTokenApprove = useTokenApprove()
  const pushMessage = useCallMessageBox()
  const [lpApprove, setLpApprove] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const {
    tokens,
    userData: { depositedLpBalance, claimableNeuron },
  } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const approveSuccessCallback = () => {
    setLpApprove(true)
    pushMessage('request', 'success')
  }

  const approveFailureCallback = () => {
    resetStakingRequestModalState()
    pushMessage('request', 'failure')
  }

  const depositSuccessCallback = () => {
    queryClient.invalidateQueries(['pool', pairAddress])
    resetStakingRequestModalState()
    pushMessage('request', 'success')
  }

  const depositFailureCallback = () => pushMessage('request', 'failure')

  const handleLoadLpApprove = async () => {
    const lpContract = new caver.klay.Contract(TOKEN_ABI, pairAddress)
    const lpToken = (await lpContract.methods
      .allowance(address, CONTRACT_ADDRESS.masterchef)
      .call()) as string

    setLpApprove(parseInt(lpToken) > 0)
  }

  useEffect(() => {
    handleLoadLpApprove()
  }, [])

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={resetStakingRequestModalState}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div>
              {tokens[0].symbol} + {tokens[1].symbol}
            </div>
            <div>
              {convertNumber(getConvertFromPebValue(depositedLpBalance, 18), {
                maxDigit: 8,
                comma: true,
              })}
              <span>LP</span>
            </div>
          </TransactionInfoItem>

          {claimableNeuron !== 0 ? (
            <TransactionInfoItem alignTop="true">
              <div>{t('nrTokenReward')}</div>
              <div>
                {convertNumber(claimableNeuron, { comma: true, maxDigit: 6 })}
                <span>{NR_SYMBOL}</span>
              </div>
            </TransactionInfoItem>
          ) : null}
        </TransactionInfo>

        <TransactionButton
          $isActive={!lpApprove}
          onClick={
            !lpApprove
              ? () =>
                  handleTokenApprove(
                    pairAddress as string,
                    CONTRACT_ADDRESS.masterchef,
                    approveSuccessCallback,
                    approveFailureCallback,
                  )
              : undefined
          }
        >
          {t(`button.lpToken.${String(lpApprove)}`)}
        </TransactionButton>

        <TransactionButton
          $isActive={lpApprove}
          onClick={() =>
            handleDeposit(depositSuccessCallback, depositFailureCallback)
          }
        >
          {t('button.staking')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default StakingRequestModal

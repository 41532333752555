import { Fragment } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AvailableDepositItem from '../molecules/AvailableDepositItem'
import useAvailableDepositPoolsQuery from '../../../api/query/useAvailableDepositPoolsQuery'
import useDebounceInput from '../../../hooks/useDebounceInput'

type AvailableDepositListProps = {
  searchQuery: string
  sortedBy: string
}

const ListEmpty = styled.div`
  padding: 32px 0 31px;
  font-size: 12px;
  color: #9d9999;
  text-align: center;
`

const AvailableDepositList = ({
  searchQuery,
  sortedBy,
}: AvailableDepositListProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit',
  })
  const debouncedSearchQuery = useDebounceInput(searchQuery)
  const { data, status } = useAvailableDepositPoolsQuery(
    debouncedSearchQuery,
    sortedBy,
  )

  if (data === undefined || (status === 'success' && data.pools.length === 0))
    return <ListEmpty>{t('empty')}</ListEmpty>

  return (
    <Fragment>
      {data.pools.map(pool => (
        <AvailableDepositItem {...pool} key={pool.address} />
      ))}
    </Fragment>
  )
}

export default AvailableDepositList

import { atom, useRecoilState, SetterOrUpdater } from 'recoil'

const swapTransactionModalAtom = atom<boolean>({
  key: 'swapTransactionModal',
  default: false,
})

export const useManageSwapTransactionModalState = (): {
  swapTransactionModalState: boolean
  setSwapTransactionModalState: SetterOrUpdater<boolean>
} => {
  const [swapTransactionModalState, setSwapTransactionModalState] =
    useRecoilState(swapTransactionModalAtom)

  return { swapTransactionModalState, setSwapTransactionModalState }
}

import { Fragment, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import StakingListSelectBox from '../atoms/StakingListSelectBox'
import StakingTermSelect from '../molecules/StakingTermSelect'
import StakingListItem from '../molecules/StakingListItem'
import { sortNeuronStakingList } from '../../../utils/sort'
import { STAKING_TERM } from '../../../utils/constants'
import useStakingListQuery from '../../../api/query/useStakingListQuery'
import { StakingTermType } from '../../../utils/types'

const StakingListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  & > div:nth-child(1) {
    margin-right: 20px;
  }
`

const StakingListHead = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.19fr 0.22fr 0.14fr 0.25fr;
  align-items: center;
  height: 34px;
  margin-bottom: 22px;
  padding: 0 30px;
  border-radius: 17px;
  background: #e8e8e8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  font-size: 12px;
  color: #9d9999;
`

const EmptyText = styled.div`
  margin-top: 33px;
  font-size: 12px;
  color: #9d9999;
  text-align: center;
`

const StakingList = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingList',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const { data } = useStakingListQuery()
  const [stakingTermIndex, setStakingTermIndex] = useState<StakingTermType>(-1)
  const [sortedBy, setSortedBy] = useState<number>(0)

  const { filteredStakingList, totalCount } = useMemo(() => {
    const filteredStakingList = Object.values(data?.stakingList ?? []).map(
      stakingListByTerm => {
        return sortedBy === 0
          ? stakingListByTerm
          : stakingListByTerm
              .slice()
              .sort((prev, next) =>
                sortNeuronStakingList[sortedBy - 1](prev, next),
              )
      },
    )

    const totalCount = Object.values(filteredStakingList).reduce(
      (totalCount, list) => totalCount + list.length,
      0,
    )

    return { filteredStakingList, totalCount }
  }, [data, sortedBy])

  const handleChangeStakingTerm = (termIndex: StakingTermType) => {
    if (address === undefined) {
      setConnectWalletModalState(true)
      return
    }

    setStakingTermIndex(termIndex)
  }

  const handleSelect = (sortedBy: number) => setSortedBy(sortedBy)

  useEffect(() => {
    if (address === undefined) setStakingTermIndex(-1)
  }, [address])

  return (
    <StakingListWrapper>
      <Title>
        <div>{t('title')}</div>
        <StakingTermSelect
          isVisibleTotalButton
          stakingTermIndex={stakingTermIndex}
          handleChangeStakingTerm={handleChangeStakingTerm}
        />
        <StakingListSelectBox value={sortedBy} handleSelect={handleSelect} />
      </Title>

      <StakingListHead>
        <div>{t('head.0')}</div>
        <div style={{ textAlign: 'right' }}>{t('head.1')}</div>
        <div style={{ textAlign: 'center' }}>{t('head.2')}</div>
        <div style={{ textAlign: 'right' }}>{t('head.3')}</div>
      </StakingListHead>

      {filteredStakingList.map((filteredStakingListByTerm, index) => {
        if (
          stakingTermIndex !== -1 &&
          stakingTermIndex !== STAKING_TERM.index[index]
        )
          return null

        const penaltyTimestamps = filteredStakingListByTerm
          .filter(({ endDate }) => Date.now() < endDate)
          .map(({ endDate }) => endDate)
        const penaltyFirstIndex = filteredStakingListByTerm.findIndex(
          ({ endDate }) => endDate === Math.min(...penaltyTimestamps),
        )

        return (
          <Fragment key={index}>
            {filteredStakingListByTerm
              .filter(({ endDate }) => Date.now() < endDate)
              .map(({ id, startDate, amount, term, endDate }, index) => (
                <StakingListItem
                  id={id}
                  startDate={startDate}
                  amount={amount}
                  term={term}
                  endDate={endDate}
                  isPenaltyFirst={penaltyFirstIndex === index}
                  key={id}
                />
              ))}
          </Fragment>
        )
      })}

      {(stakingTermIndex !== -1 &&
        data?.stakingList[stakingTermIndex].length === 0) ||
      totalCount === 0 ? (
        <EmptyText>{t('empty')}</EmptyText>
      ) : null}
    </StakingListWrapper>
  )
}

export default StakingList

import { useMutation } from 'react-query'
import AxiosClient from '../axiosConfig'

type CreateSessionRequestBodyType = {
  address: string
}

const createSession = async (
  address: CreateSessionRequestBodyType['address'],
) =>
  await AxiosClient.getAxiosInstance(false).post('/api/sessions', { address })

export default function useCreateSessionMutation() {
  return useMutation(createSession)
}

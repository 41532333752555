import styled from 'styled-components'
import produce from 'immer'
import { useTranslation } from 'react-i18next'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import SwapMainSetting from '../molecules/SwapMainSetting'
import SwapMainFrom from '../molecules/SwapMainFrom'
import SwapMainTo from '../molecules/SwapMainTo'
import SwapMainButton from '../atoms/SwapMainButton'

const SwapMainWrapper = styled.div`
  padding: 30px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    padding: 16px 8px 8px 8px;
  }
`

const SwapMainHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    padding: 0 8px;
    font-size: 16px;
  }
`

const SwapTokenForm = styled.div`
  position: relative;
  margin-top: 25px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const ReverseOrderIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 4px solid #ffffff;
  width: 48px;
  height: 48px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

const SwapMain = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapMain',
  })
  const {
    swapFormState: {
      settings: { isMax },
    },
    setSwapFormState,
  } = useManageSwapFormState()

  const reverseTokenOrder = () => {
    if (isMax) return

    setSwapFormState(prev =>
      produce(prev, draft => {
        const { from, to } = prev.form

        draft.form.from = to
        draft.form.to = from
        draft.form.lastModified =
          prev.form.lastModified === 'from' ? 'to' : 'from'

        return draft
      }),
    )
  }

  return (
    <SwapMainWrapper>
      <SwapMainHead>
        <div>{t('title')}</div>
        <SwapMainSetting />
      </SwapMainHead>

      <SwapTokenForm>
        <SwapMainFrom />
        <SwapMainTo />
        <ReverseOrderIcon
          onClick={reverseTokenOrder}
          src="/dexAssets/swap_icon.png"
          alt="Swap Icon"
        />
      </SwapTokenForm>

      <SwapMainButton />
    </SwapMainWrapper>
  )
}

export default SwapMain

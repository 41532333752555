import { useQuery } from 'react-query'
import { routerContract } from '../../contract/contract'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'
import useTokenListQuery from './useTokenListQuery'
import { TokenType } from './useTokenQuery'

export type ReferralTokenRewardItemType = {
  address: string
  symbol: string
  korName: string
  engName: string
  rewards: string
}

const loadReferralTokenRewards = async (
  tokens: TokenType[],
  walletAddress: string | undefined,
) => {
  const referralTokenRewards = await Promise.all<ReferralTokenRewardItemType>(
    tokens.map(async ({ address, symbol, korName, engName, decimal }) => {
      const rewards =
        walletAddress !== undefined
          ? ((await routerContract.methods
              .referralTokenamount(walletAddress, address)
              .call()) as string)
          : '0'

      return {
        address,
        symbol,
        korName,
        engName,
        rewards: getConvertFromPebValue(rewards, decimal),
      }
    }),
  )

  return referralTokenRewards
}

export default function useReferralTokenRewardsQuery() {
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { data } = useTokenListQuery()

  return useQuery<ReferralTokenRewardItemType[]>(
    'referralTokenRewards',
    async () => {
      return loadReferralTokenRewards(data?.tokens ?? [], address)
    },
    {
      enabled: data?.tokens !== undefined,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60,
    },
  )
}

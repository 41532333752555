import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import caver, { caverForKaikas } from '../modules/network/caver'
import checkMobileDevice from '../utils/checkMobileDevice'
import { LAUNCHPAD_REWARDS_CLAIM_ABI } from '../contract/abi'
import { CYPRESS_ICO_CA_ADDRESS } from '../contract/address'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'

export default function useTokenClaim() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (result.status === 'success') successCallback()
    else failureCallback()
  }

  const klipClaimNeuronRewards = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CYPRESS_ICO_CA_ADDRESS,
      value: '0',
      abi: JSON.stringify(LAUNCHPAD_REWARDS_CLAIM_ABI),
      params: JSON.stringify([address]),
    })

    if (err) {
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasClaimNeuronRewards = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(
      LAUNCHPAD_REWARDS_CLAIM_ABI,
      [address],
    )

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CYPRESS_ICO_CA_ADDRESS,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const handleClaimNeuronRewards = (
    claimSuccessCallback: () => void,
    claimFailureCallback: () => void,
  ) => {
    if (type === 'klip')
      klipClaimNeuronRewards(claimSuccessCallback, claimFailureCallback)
    else if (type === 'kaikas')
      kaikasClaimNeuronRewards(claimSuccessCallback, claimFailureCallback)
  }

  return handleClaimNeuronRewards
}

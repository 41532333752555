import { Fragment } from 'react'
import TokenSelectModal from './TokenSelectModal'
import ConnectWalletModal from './ConnectWalletModal'
import KlipConnectModal from './KlipConnectModal'
import ApproveModal from './ApproveModal'
import DepositRequestModal from './DepositRequestModal'
import WithdrawRequestModal from './WithdrawRequestModal'
import SwapTransactionModal from './SwapTransactionModal'
import ReceiveRewardsModal from './ReceiveRewardsModal'
import StakingRequestModal from './StakingRequestModal'
import UnstakingRequestModal from './UnstakingRequestModal'
import NeuronStakingModal from './NeuronStakingModal'
import NeuronUnstakingModal from './NeuronUnstakingModal'
import StakingRewardsModal from './StakingRewardsModal'
import MobileMenu from './MobileMenu'
import { useManageTokenSelectModalState } from '../../state/modal/tokenSelectModalState'
import { useManageConnectWalletModalState } from '../../state/modal/connectWalletModalState'
import { useManageApproveModalState } from '../../state/modal/approveModalState'
import { useManageDepositRequestModalState } from '../../state/modal/depositRequestModalState'
import { useManageWithdrawRequestModalState } from '../../state/modal/withdrawRequestModalState'
import { useManageKlipRequestKeyState } from '../../state/shared/klipRequestKeyState'
import { useManageSwapTransactionModalState } from '../../state/modal/swapTransactionModalState'
import { useManageReceiveRewardsModalState } from '../../state/modal/receiveRewardsModalState'
import { useManageStakingRequestModalState } from '../../state/modal/stakingRequestModalState'
import { useManageUnstakingRequestModalState } from '../../state/modal/unstakingRequestModalState'
import { useManageStakingModalState } from '../../state/modal/stakingModalState'
import { useManageUnstakingModalState } from '../../state/modal/unstakingModalState'
import { useManageStakingRewardsModalState } from '../../state/modal/stakingRewardsModalState'
import { useManageMobileMenuState } from '../../state/modal/mobileMenuState'
import useGetWindowSize from '../../hooks/useGetWindowSize'

const ModalContainer = () => {
  const {
    tokenSelectModalState: { isVisible },
  } = useManageTokenSelectModalState()
  const { connectWalletModalState } = useManageConnectWalletModalState()
  const {
    approveModalState: { visible: approveModalVisible },
  } = useManageApproveModalState()
  const {
    depositRequestModalState: { visible: depositModalVisible },
  } = useManageDepositRequestModalState()
  const {
    stakingRequestModalState: { visible: stakingModalVisible },
  } = useManageStakingRequestModalState()
  const {
    withdrawRequestModalState: { visible: withdrawModalVisible },
  } = useManageWithdrawRequestModalState()
  const {
    unstakingRequestModalState: { visible: unstakingModalVisible },
  } = useManageUnstakingRequestModalState()
  const {
    klipRequestKeyState: { requestKey },
  } = useManageKlipRequestKeyState()
  const { swapTransactionModalState } = useManageSwapTransactionModalState()
  const {
    receiveRewardsModalState: { visible: receiveRewardsModalVisible },
  } = useManageReceiveRewardsModalState()
  const { stakingModalState } = useManageStakingModalState()
  const {
    unstakingModalState: { visible: unstakingNeuronModalVisible },
  } = useManageUnstakingModalState()
  const {
    stakingRewardsModalState: { visible: stakingRewardsModalVisible },
  } = useManageStakingRewardsModalState()
  const { mobileMenuState } = useManageMobileMenuState()
  const { width } = useGetWindowSize()

  return (
    <Fragment>
      {isVisible ? <TokenSelectModal /> : null}
      {connectWalletModalState ? <ConnectWalletModal /> : null}
      {approveModalVisible ? <ApproveModal /> : null}
      {depositModalVisible ? <DepositRequestModal /> : null}
      {stakingModalVisible ? <StakingRequestModal /> : null}
      {withdrawModalVisible ? <WithdrawRequestModal /> : null}
      {unstakingModalVisible ? <UnstakingRequestModal /> : null}
      {requestKey !== undefined ? <KlipConnectModal /> : null}
      {swapTransactionModalState ? <SwapTransactionModal /> : null}
      {receiveRewardsModalVisible ? <ReceiveRewardsModal /> : null}
      {stakingModalState ? <NeuronStakingModal /> : null}
      {unstakingNeuronModalVisible ? <NeuronUnstakingModal /> : null}
      {stakingRewardsModalVisible ? <StakingRewardsModal /> : null}
      {mobileMenuState && width <= 768 ? <MobileMenu /> : null}
    </Fragment>
  )
}

export default ModalContainer

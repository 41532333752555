export default function parseTransactionError(error: string) {
  let transactionHash: string | undefined
  const stringifyError = error.substring(error.indexOf('{'))

  try {
    const parseResult = JSON.parse(stringifyError)
    transactionHash = parseResult.transactionHash
  } catch (e) {
    transactionHash = undefined
  }

  return transactionHash
}

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'
import { InputPositionType } from '../../utils/types'

export type DepositFormStateType = {
  currentMode: 'deposit' | 'withdraw'
  isUserEntered: boolean
  deposit: { [key in InputPositionType]: string } & {
    lastModified: InputPositionType
  }
  withdraw: string
  settings: {
    slippage: string
    firstIsMaxAmount: boolean
    secondIsMaxAmount: boolean
    withdrawIsMaxAmount: boolean
  }
  error: {
    from: boolean
    to: boolean
    withdraw: boolean
  }
}

const depositFormAtom = atom<DepositFormStateType>({
  key: 'depositForm',
  default: {
    currentMode: 'deposit',
    isUserEntered: false,
    deposit: {
      from: '',
      to: '',
      lastModified: 'from',
    },
    withdraw: '',
    settings: {
      slippage: '0.50',
      firstIsMaxAmount: false,
      secondIsMaxAmount: false,
      withdrawIsMaxAmount: false,
    },
    error: {
      from: false,
      to: false,
      withdraw: false,
    },
  },
})

export const useManageDepositFormState = (): {
  depositFormState: DepositFormStateType
  setDepositFormState: SetterOrUpdater<DepositFormStateType>
  resetDepositFormState: Resetter
} => {
  const [depositFormState, setDepositFormState] =
    useRecoilState(depositFormAtom)
  const resetDepositFormState = useResetRecoilState(depositFormAtom)

  return { depositFormState, setDepositFormState, resetDepositFormState }
}

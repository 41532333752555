import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DepositMainDetailItem from '../atoms/DepositMainDetailItem'
import SliceDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { TokenType } from '../../../api/query/useTokenQuery'

type DepositMainDetailProps = {
  currentMode: 'deposit' | 'withdraw'
  tokens: TokenType[]
  exchangeRate: number[]
}

const DepositMainDetailHead = styled.div`
  height: 28px;
  margin-bottom: 27px;
  padding: 6px 0;
  border-radius: 24px;
  background: #f8f8f8;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
  text-align: center;

  @media (max-width: 768px) {
    height: unset;
    margin-bottom: 16px;
    padding: 0;
    border-radius: 0;
    background: none;
    color: #656565;
    text-align: left;
  }
`

const TokenExchangeRate = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3px;

  & > div {
    margin: 0 4px;
  }
`

const ExchangePrice = styled.div`
  display: flex;
  align-items: center;
`

const MobileExchangeRate = styled.div`
  display: flex;
  align-items: center;
`

const DepositTitleIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 4px;
  margin-bottom: 1.5px;
`

const DepositNotice = styled.div`
  font-size: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const WithdrawNotice = styled.div`
  font-size: 10px;
  color: #9d9999;
  line-height: 1.6;
  white-space: pre-line;
  letter-spacing: -0.3px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const DepositMainDetail = ({
  currentMode,
  tokens,
  exchangeRate,
}: DepositMainDetailProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositMainDetail',
  })
  const { width } = useGetWindowSize()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleToggleBalanceRate = () =>
    setSelectedIndex(prev => (prev === 0 ? 1 : 0))

  return (
    <div>
      <DepositMainDetailHead>{t('title')}</DepositMainDetailHead>

      <DepositMainDetailItem
        title={t('menu.0')}
        type="exchangeRate"
        onClick={handleToggleBalanceRate}
      >
        {width > 768 ? (
          <>
            <TokenExchangeRate>
              1 {tokens[selectedIndex].symbol} ≈
              <SliceDecimal
                maxIntLength={7}
                suffix={` ${tokens[selectedIndex === 0 ? 1 : 0].symbol}`}
              >
                {convertNumber(exchangeRate[selectedIndex], {
                  maxDigit: 6,
                })}
              </SliceDecimal>
              {tokens[selectedIndex === 0 ? 1 : 0].symbol}
            </TokenExchangeRate>
            <ExchangePrice>
              ($
              <SliceDecimal maxIntLength={10} prefix="$ ">
                {convertNumber(tokens[selectedIndex].price, { maxDigit: 4 })}
              </SliceDecimal>
              )
            </ExchangePrice>
          </>
        ) : (
          <>
            <MobileExchangeRate>
              <TokenExchangeRate>
                1 {tokens[selectedIndex].symbol} ≈
                <SliceDecimal
                  maxIntLength={7}
                  suffix={` ${tokens[selectedIndex === 0 ? 1 : 0].symbol}`}
                >
                  {convertNumber(exchangeRate[selectedIndex], {
                    maxDigit: 6,
                  })}
                </SliceDecimal>
                {tokens[selectedIndex === 0 ? 1 : 0].symbol}
              </TokenExchangeRate>
              <DepositTitleIcon
                src="/dexAssets/deposit_exchange_rate_icon.png"
                alt="Exchange Rate Icon"
                onClick={handleToggleBalanceRate}
              />
            </MobileExchangeRate>
            <MobileExchangeRate>
              ($
              <SliceDecimal maxIntLength={10} prefix="$ ">
                {convertNumber(tokens[selectedIndex].price, { maxDigit: 4 })}
              </SliceDecimal>
              )
            </MobileExchangeRate>
          </>
        )}
      </DepositMainDetailItem>

      <DepositMainDetailItem title={t('menu.1')} type="notice">
        {currentMode === 'deposit' ? (
          <DepositNotice>
            {t(width > 768 ? 'depositNotice' : 'mobileDepositNotice')}
          </DepositNotice>
        ) : (
          <WithdrawNotice>
            {t(`${width > 768 ? 'withdrawNotice' : 'mobileWithdrawNotice'}.0`)}
            <span style={{ color: '#ff6d68' }}>
              {t(
                `${width > 768 ? 'withdrawNotice' : 'mobileWithdrawNotice'}.1`,
              )}
            </span>
          </WithdrawNotice>
        )}
      </DepositMainDetailItem>
    </div>
  )
}

export default DepositMainDetail

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DepositMainSetting from '../molecules/DepositMainSetting'

export type DepositMainHeadProps = {
  type: 'deposit' | 'withdraw'
  handleChangeDepositMode: (type: DepositMainHeadProps['type']) => void
}

const DepositMainHeadWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 8px;
    font-size: 16px;
  }
`

const DepositMode = styled.div<{ $isActive: boolean }>`
  font-size: 18px;
  font-weight: 700;
  color: #${({ $isActive }) => ($isActive ? 'ff6d68' : 'd1cbcb')};
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;

    & + & {
      margin-left: 16px;
    }
  }
`

const DepositMainHead = ({
  type,
  handleChangeDepositMode,
}: DepositMainHeadProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositMain.head',
  })

  return (
    <DepositMainHeadWrapper>
      <DepositMode
        onClick={() => handleChangeDepositMode('deposit')}
        $isActive={type === 'deposit'}
      >
        {t('0')}
      </DepositMode>
      <DepositMode
        onClick={() => handleChangeDepositMode('withdraw')}
        $isActive={type === 'withdraw'}
      >
        {t('1')}
      </DepositMode>

      <DepositMainSetting />
    </DepositMainHeadWrapper>
  )
}

export default DepositMainHead

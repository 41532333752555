import { ReactNode } from 'react'
import styled from 'styled-components'

type SwapInfoItemProps = {
  title: string
  children: ReactNode | string
  highlight?: boolean
}

const SwapInfoItemWrapper = styled.div<{ highlight: string }>`
  display: flex;
  justify-content: space-between;
  color: #${({ highlight }) => (highlight === 'true' ? 'ff6d68' : '9d9999')};

  & + & {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    & + & {
      margin-top: 8px;
    }
  }
`

const SwapInfoItemTitle = styled.div`
  font-size: 14px;
  line-height: 1.43;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 300;
  }
`

const SwapInfoItemContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;

  span {
    margin-right: 4px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const SwapInfoItem = ({ title, children, highlight }: SwapInfoItemProps) => {
  return (
    <SwapInfoItemWrapper highlight={String(!!highlight)}>
      <SwapInfoItemTitle>{title}</SwapInfoItemTitle>
      <SwapInfoItemContent>{children}</SwapInfoItemContent>
    </SwapInfoItemWrapper>
  )
}

export default SwapInfoItem

import { useState } from 'react'
import produce from 'immer'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SettingSlippage from '../atoms/SettingSlippage'
import NoticeTooltip from '../atoms/NoticeTooltip'
import MobilePopup from '../molecules/MobilePopup'
import useHandleClickOutSide from '../../../hooks/useHandleClickOutSide'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { useManageDepositFormState } from '../../../state/pool/depositFormState'
import { CSS_Z_INDEX } from '../../../utils/constants'

const ICON_CUSTOM_STYLE = {
  width: '14px',
  height: '14px',
  marginLeft: '2px',
}

const WIDGET_CUSTOM_STYLE = { width: '256px' }

const DepositMainSettingWrapper = styled.div`
  position: relative;
  height: 30px;
  margin-left: auto;

  @media (max-width: 768px) {
    height: 20px;
  }
`

const SettingIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`

const DepositMainSettingWidget = styled.div<{
  isVisible: string
  language: string
}>`
  display: ${({ isVisible }) => (isVisible === 'true' ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  transform: translateX(calc(50% - 10px));
  z-index: ${CSS_Z_INDEX.widget};
  width: 363px;
  padding: 24px ${({ language }) => (language === 'ko' ? '20' : '16')}px;
  border: 1px solid #9d9999;
  border-radius: 24px;
  background: #f8f8f8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  font-weight: 400;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #9d9999;
  }
`

const MobileDepositSetting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 16px;
  border-radius: 16px;
  background: #f8f8f8;

  & > div:nth-child(1) {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #9d9999;
  }
`

const MobileDepositTooltip = styled.div`
  margin-top: 32px;
  color: #ffffff;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.6;
  }
`

const MobileSlippageIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 4px;
  margin-bottom: 1px;
`

const DepositMainSetting = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Swap.SwapMain.setting',
  })
  const { ref, buttonRef, isVisible, toggleComponent } = useHandleClickOutSide()
  const { width } = useGetWindowSize()
  const {
    depositFormState: {
      settings: { slippage },
    },
    setDepositFormState,
  } = useManageDepositFormState()
  const [mobileSlippage, setMobileSlippage] = useState<string>(slippage)

  const applyMobileSlippage = () => {
    setDepositFormState(prev =>
      produce(prev, draft => {
        draft.settings.slippage = mobileSlippage
        return draft
      }),
    )
    toggleComponent()
  }

  const cancelMobileSlippage = () => {
    setMobileSlippage(slippage)
    toggleComponent()
  }

  return (
    <DepositMainSettingWrapper>
      <SettingIcon
        ref={buttonRef}
        src="/dexAssets/setting_icon.png"
        onClick={toggleComponent}
        alt="Setting Icon"
      />

      {width > 768 ? (
        <DepositMainSettingWidget
          ref={ref}
          isVisible={String(isVisible)}
          language={language}
        >
          <div>
            {t('menu')}
            <NoticeTooltip
              iconCustomStyle={ICON_CUSTOM_STYLE}
              widgetCustomStyle={WIDGET_CUSTOM_STYLE}
            >
              {t('tooltip')}
            </NoticeTooltip>
          </div>

          <SettingSlippage
            type="deposit"
            slippage={slippage}
            setter={setDepositFormState}
          />
        </DepositMainSettingWidget>
      ) : (
        <MobilePopup
          type="setting"
          isVisible={isVisible}
          handleApply={applyMobileSlippage}
          handleClose={cancelMobileSlippage}
        >
          <MobileDepositSetting>
            <div>{t('menu')}</div>
            <SettingSlippage
              type="deposit"
              slippage={mobileSlippage}
              setter={setDepositFormState}
              mobileSetter={setMobileSlippage}
            />
          </MobileDepositSetting>

          <MobileDepositTooltip>
            <div>
              {t('menu')}
              <MobileSlippageIcon
                src="/dexAssets/mobile_slippage_icon.png"
                alt="Mobile Slippage Icon"
              />
            </div>
            <div>{t('popup')}</div>
          </MobileDepositTooltip>
        </MobilePopup>
      )}
    </DepositMainSettingWrapper>
  )
}

export default DepositMainSetting

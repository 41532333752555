import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReferralEventDashboard from '../organisms/ReferralEventDashboard'
import ReferralEventInformation from '../organisms/ReferralEventInformation'

const ReferralEventDashboardTemplateWrapper = styled.div`
  margin-top: 50px;
`

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  color: #cdad7a;

  @media (max-width: 768px) {
    margin-bottom: 23px;
  }
`

const ReferralEventDashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 731px;
  padding: 27px 40px 40px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    height: 775px;
    padding: 16px 24px 24px 24px;
  }
`

const ReferralEventDashboardTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralEventDashboard',
  })

  return (
    <ReferralEventDashboardTemplateWrapper>
      <Title>{t('title')}</Title>

      <ReferralEventDashboardContent>
        <ReferralEventDashboard />
        <ReferralEventInformation />
      </ReferralEventDashboardContent>
    </ReferralEventDashboardTemplateWrapper>
  )
}

export default ReferralEventDashboardTemplate

import { useState, useEffect, useRef, MutableRefObject } from 'react'

type useHandleClickOutSideType = {
  ref: MutableRefObject<HTMLDivElement | null>
  buttonRef: MutableRefObject<HTMLImageElement | null>
  isVisible: boolean
  toggleComponent: () => void
}

export default function useHandleClickOutSide(): useHandleClickOutSideType {
  const ref = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLImageElement | null>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleComponent = () => setIsVisible(prev => !prev)

  const handleClickOutSide = (event: MouseEvent) => {
    if (ref.current === null) return

    if (
      !ref.current.contains(event.target as Node) &&
      !buttonRef.current?.contains(event.target as Node)
    )
      setIsVisible(false)
  }

  useEffect(() => {
    if (isVisible)
      window.document.addEventListener('mousedown', handleClickOutSide)

    return () =>
      window.document.removeEventListener('mousedown', handleClickOutSide)
  }, [isVisible])

  return {
    ref,
    buttonRef,
    isVisible,
    toggleComponent,
  }
}

import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../../state/swap/swapInfoState'
import SlicedDecimal from '../atoms/SlicedDecimal'
import SwapInfoExchangeRateDetailWidget from '../molecules/SwapInfoExchangeRateDetailWidget'
import convertNumber from '../../../utils/convertNumber'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { TokenType } from '../../../api/query/useTokenQuery'

const SwapInfoExchangeRateWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 4px;
  }
`

const ToInfo = styled.div`
  margin-right: 3px;
`

const FromInfo = styled.div`
  margin: 0 3px;
`

const MobileSwapInfoExchangeRateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SwapInfoExchangeRate = () => {
  const {
    swapFormState: {
      form: { from, to },
    },
  } = useManageSwapFormState()
  const {
    swapInfoState: { exchangeRatePerToValue },
  } = useManageSwapInfoState()
  const { width } = useGetWindowSize()
  const queryClient = useQueryClient()

  const fromToken = queryClient.getQueryData<TokenType>(['token', from.address])
  const toToken = queryClient.getQueryData<TokenType>(['token', to.address])

  if (fromToken === undefined || toToken === undefined) return null

  if (width <= 768)
    return (
      <MobileSwapInfoExchangeRateWrapper>
        <SwapInfoExchangeRateWrapper>
          <ToInfo>1 {toToken.symbol} ≈</ToInfo>
          <SlicedDecimal maxIntLength={7} suffix={` ${fromToken.symbol}`}>
            {convertNumber(exchangeRatePerToValue, { maxDigit: 6 })}
          </SlicedDecimal>
          <FromInfo>{fromToken.symbol}</FromInfo>
          <SwapInfoExchangeRateDetailWidget />
        </SwapInfoExchangeRateWrapper>
        <SwapInfoExchangeRateWrapper>
          ({'$'}
          <SlicedDecimal prefix={'$ '} maxIntLength={10}>
            {convertNumber(fromToken.price * exchangeRatePerToValue, {
              maxDigit: 4,
            })}
          </SlicedDecimal>
          )
        </SwapInfoExchangeRateWrapper>
      </MobileSwapInfoExchangeRateWrapper>
    )

  return (
    <SwapInfoExchangeRateWrapper>
      <ToInfo>1 {toToken.symbol} ≈</ToInfo>
      <SlicedDecimal maxIntLength={7} suffix={` ${fromToken.symbol}`}>
        {convertNumber(exchangeRatePerToValue, { maxDigit: 6 })}
      </SlicedDecimal>
      <FromInfo>{fromToken.symbol}</FromInfo>({'$'}
      <SlicedDecimal prefix={'$ '} maxIntLength={10}>
        {convertNumber(fromToken.price * exchangeRatePerToValue, {
          maxDigit: 4,
        })}
      </SlicedDecimal>
      )
      <SwapInfoExchangeRateDetailWidget />
    </SwapInfoExchangeRateWrapper>
  )
}

export default SwapInfoExchangeRate

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type StakingRewardsType = 'stakingRewards' | 'transactionRewards'

type StakingRewardsModalStateType = {
  visible: boolean
  type: StakingRewardsType | undefined
}

const stakingRewardsModalAtom = atom<StakingRewardsModalStateType>({
  key: 'stakingRewardsModal',
  default: {
    visible: false,
    type: undefined,
  },
})

export const useManageStakingRewardsModalState = (): {
  stakingRewardsModalState: StakingRewardsModalStateType
  setStakingRewardsModalState: SetterOrUpdater<StakingRewardsModalStateType>
  resetStakingRewardsModalState: Resetter
} => {
  const [stakingRewardsModalState, setStakingRewardsModalState] =
    useRecoilState(stakingRewardsModalAtom)
  const resetStakingRewardsModalState = useResetRecoilState(
    stakingRewardsModalAtom,
  )

  return {
    stakingRewardsModalState,
    setStakingRewardsModalState,
    resetStakingRewardsModalState,
  }
}

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'

type StakingInfoType = {
  data: {
    stakingAmount: number
    gnrAmount: number
    sharedOf: number
    rewardsDetail: {
      apr: number
      apy: number
    }
  }
}

const loadStakingInfo = async () =>
  await AxiosClient.getAxiosInstance(true, false).get('/api/staking')

export default function useStakingInfoQuery() {
  return useQuery<StakingInfoType['data']>(
    'stakingInfo',
    async () => {
      const {
        data: { data },
      } = await loadStakingInfo()

      return data
    },
    {
      enabled: AxiosClient.isInitialized(),
      refetchOnWindowFocus: false,
    },
  )
}

type ConvertNumberOptionsType = {
  comma?: boolean
  maxDigit?: number
  isPercentage?: boolean
}

function withComma(number: string) {
  const parts = number.split('.')

  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? '.' + parts[1] : '')
  )
}

function convertExpToStringNumber(number: string) {
  const splicedNumber = number.split(/[eE]/)

  if (splicedNumber.length === 1) return splicedNumber[0]

  let adposition = ''
  let exp = parseInt(splicedNumber[1]) + 1
  const result = splicedNumber[0].replace('.', '')

  if (exp < 0) {
    adposition = '0.'
    while (exp++) adposition += '0'

    return adposition + result
  } else {
    exp -= result.length
    while (exp--) adposition += '0'

    return result + adposition
  }
}

function sliceDecimal(number: string, digit?: number) {
  const convertedNumber = convertExpToStringNumber(number)
  const dotIndex = convertedNumber.indexOf('.')

  if (dotIndex === -1) return convertedNumber

  const defaultDigit = digit ?? 6

  return convertedNumber
    .substring(0, dotIndex + defaultDigit + 1)
    .replace(/0+$/, '')
    .replace(/\.$/, '')
}

function calculatePercentage(percentage: number) {
  if (percentage >= 1000000) {
    const convertedPercentage = withComma((percentage / 1000000).toFixed(2))
    return `${convertedPercentage}M`
  } else if (percentage >= 1000) {
    const convertedPercentage = withComma((percentage / 1000).toFixed(2))
    return `${convertedPercentage}K`
  } else return withComma(percentage.toFixed(2))
}

export default function convertNumber(
  number: string | number,
  options: ConvertNumberOptionsType,
) {
  let result = String(number)

  if (options.isPercentage) return calculatePercentage(parseFloat(result))

  if (options.maxDigit !== undefined)
    result = sliceDecimal(result, options.maxDigit)
  if (options.comma) result = withComma(result)

  return result
}

import Header from './Header.json'
import Main from './Main.json'
import Swap from './Swap.json'
import Pool from './Pool.json'
import AddLiquidityPool from './AddLiquidityPool.json'
import Deposit from './Deposit.json'
import Dashboard from './Dashboard.json'
import MyAssets from './MyAssets.json'
import Referral from './Referral.json'
import Staking from './Staking.json'
import TransactionHistory from './TransactionHistory.json'
import Modal from './Modal.json'

const en = {
  translation: {
    Header,
    Main,
    Swap,
    Pool,
    AddLiquidityPool,
    Deposit,
    Dashboard,
    MyAssets,
    Referral,
    Staking,
    TransactionHistory,
    Modal,
  },
}

export default en

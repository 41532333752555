import styled from 'styled-components'
import produce from 'immer'
import { useTranslation } from 'react-i18next'
import { useManageDepositFormState } from '../../../state/pool/depositFormState'
import SlicedDecimal from './SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import { InputPositionType } from '../../../utils/types'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type DepositMainItemBalanceProps = {
  balance: string
  type: 'deposit' | 'withdraw'
  position: InputPositionType
}

const DepositMainItemBalanceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 5px;
  font-size: 12px;
  color: #9d9999;
  line-height: 1.33;
`

const BalanceText = styled.span`
  margin-right: 3px;
`

const InsufficientQuantity = styled.div<{ language: string }>`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  margin-left: 7px;
  padding: 0 11px;
  height: 18px;
  border-radius: 10px;
  background: #b95955;
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.7;

  @media (max-width: 768px) {
    display: grid;
    place-items: center;
    position: absolute;
    top: 20px;
    right: 0;
    width: ${({ language }) => (language === 'ko' ? '60' : '108')}px;
    height: 18px;
    margin: 0;
    padding: 0;
    font-size: ${({ language }) => (language === 'ko' ? '11' : '9')}px;
    line-height: ${({ language }) => (language === 'ko' ? '1.4' : '2')};
  }
`

const TokenMaxAmount = styled.div<{ isMax: string }>`
  flex-shrink: 0;
  margin-left: 6px;
  display: grid;
  place-items: center;
  width: 40px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #9d9999;
  background: ${({ isMax }) => (isMax === 'true' ? '#9d9999' : 'transparent')};
  font-size: 11px;
  font-weight: 700;
  color: ${({ isMax }) => (isMax === 'true' ? '#ffffff' : '#9d9999')};
  line-height: 1.55;
  transition: 0.15s all;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 30px;
  }
`

const DepositMainItemBalance = ({
  balance,
  type,
  position,
}: DepositMainItemBalanceProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositMain',
  })
  const {
    depositFormState: {
      settings: {
        [position === 'from' ? 'firstIsMaxAmount' : 'secondIsMaxAmount']:
          isMaxAmount,
        withdrawIsMaxAmount,
      },
      error,
    },
    setDepositFormState,
  } = useManageDepositFormState()
  const { width } = useGetWindowSize()

  const handleToggleAmountMax = () => {
    if (balance === '0' || balance === '') return

    setDepositFormState(prev =>
      produce(prev, draft => {
        const toggledKey =
          type === 'deposit'
            ? position === 'from'
              ? 'firstIsMaxAmount'
              : 'secondIsMaxAmount'
            : 'withdrawIsMaxAmount'

        draft.settings[toggledKey] = !draft.settings[toggledKey]
        if (
          type === 'deposit' &&
          prev.settings[
            position === 'from' ? 'secondIsMaxAmount' : 'firstIsMaxAmount'
          ]
        )
          draft.settings[
            position === 'from' ? 'secondIsMaxAmount' : 'firstIsMaxAmount'
          ] =
            !draft.settings[
              position === 'from' ? 'secondIsMaxAmount' : 'firstIsMaxAmount'
            ]

        if (type === 'deposit') {
          draft.deposit[position] = balance.toString()
          draft.deposit.lastModified = position
          draft.isUserEntered = true
        } else draft.withdraw = balance.toString()

        return draft
      }),
    )
  }

  return (
    <DepositMainItemBalanceWrapper>
      <BalanceText>{t(width > 768 ? 'balance' : 'mobileBalance')}</BalanceText>
      <SlicedDecimal
        prefix={`${t('balance')} `}
        maxIntLength={width > 768 ? (language === 'ko' ? 10 : 8) : 9}
      >
        {convertNumber(balance, { maxDigit: type === 'deposit' ? 6 : 8 })}
      </SlicedDecimal>

      {error[type === 'deposit' ? position : 'withdraw'] ? (
        <InsufficientQuantity language={language}>
          {t('insufficient')}
        </InsufficientQuantity>
      ) : null}

      <TokenMaxAmount
        isMax={String(type === 'deposit' ? isMaxAmount : withdrawIsMaxAmount)}
        onClick={handleToggleAmountMax}
      >
        {t('max')}
      </TokenMaxAmount>
    </DepositMainItemBalanceWrapper>
  )
}

export default DepositMainItemBalance

import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import checkMobileDevice from '../utils/checkMobileDevice'
import {
  CONTRACT_GAS_LIMIT,
  MAX_ALLOWANCE,
  NR_SYMBOL,
} from '../utils/constants'
import { STAKING_APPROVE_ABI } from '../contract/abi'
import { CONTRACT_ADDRESS, TOKEN_ADDRESS } from '../contract/address'

export default function useGnrStakingApprove() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()

  const klipGnrStakingApproveSuccessCallback = (
    result: any,
    successCallback: () => void,
  ) => {
    if (result.status === 'success') successCallback()
  }

  const klipGnrStakingApprove = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: TOKEN_ADDRESS[NR_SYMBOL],
      value: '0',
      abi: JSON.stringify(STAKING_APPROVE_ABI),
      params: JSON.stringify([CONTRACT_ADDRESS.gnr, MAX_ALLOWANCE]),
    })

    if (err) {
      failureCallback()
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipGnrStakingApproveSuccessCallback(result, successCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasGnrStakingApprove = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(STAKING_APPROVE_ABI, [
      CONTRACT_ADDRESS.gnr,
      MAX_ALLOWANCE,
    ])

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: TOKEN_ADDRESS[NR_SYMBOL],
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const bitkeepGnrStakingApprove = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = web3.eth.abi.encodeFunctionCall(STAKING_APPROVE_ABI, [
      CONTRACT_ADDRESS.gnr,
      MAX_ALLOWANCE,
    ] as string[])

    web3.eth
      .sendTransaction({
        from: address,
        to: TOKEN_ADDRESS[NR_SYMBOL],
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const handleTokenApprove = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipGnrStakingApprove(successCallback, failureCallback)
    else if (type === 'kaikas')
      kaikasGnrStakingApprove(successCallback, failureCallback)
    else if (type === 'bitkeep')
      bitkeepGnrStakingApprove(successCallback, failureCallback)
  }

  return handleTokenApprove
}

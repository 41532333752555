import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type KlipRequestKeyStateType = {
  requestKey: string | undefined
  requestCallback: ((result: any) => void) | undefined
}

const klipRequestKeyAtom = atom<KlipRequestKeyStateType>({
  key: 'klipRequestKey',
  default: {
    requestKey: undefined,
    requestCallback: undefined,
  },
})

export const useManageKlipRequestKeyState = (): {
  klipRequestKeyState: KlipRequestKeyStateType
  setKlipRequestKeyState: SetterOrUpdater<KlipRequestKeyStateType>
  resetKlipRequestKeyState: Resetter
} => {
  const [klipRequestKeyState, setKlipRequestKeyState] =
    useRecoilState(klipRequestKeyAtom)
  const resetKlipRequestKeyState = useResetRecoilState(klipRequestKeyAtom)

  return {
    klipRequestKeyState,
    setKlipRequestKeyState,
    resetKlipRequestKeyState,
  }
}

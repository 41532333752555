import TemplateLayout from '../templates/TemplateLayout'
import DashboardCardTemplate from '../templates/DashboardCardTemplate'
import DashboardListTemplate from '../templates/DashboardListTemplate'

const DashboardPage = () => {
  return (
    <TemplateLayout>
      <DashboardCardTemplate />
      <DashboardListTemplate />
    </TemplateLayout>
  )
}

export default DashboardPage

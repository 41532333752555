import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HeaderMenuItem from '../atoms/HeaderMenuItem'
import HeaderCommunityMenu from '../atoms/HeaderCommunityMenu'

const HeaderMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    flex-shrink: 0;
    padding: 0 20px;
  }
`

const Link = styled.a<{ color?: string }>`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: 100%;
  margin-left: 30px;
  font-size: 14px;
  color: ${({ color }) => (color !== undefined ? color : '#000000')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #9d9999;
  }
`

const HeaderMenu = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Header',
  })

  return (
    <HeaderMenuWrapper>
      <HeaderMenuItem to="/swap">{t('menu.0')}</HeaderMenuItem>
      <HeaderMenuItem to="/pool">{t('menu.1')}</HeaderMenuItem>
      <HeaderMenuItem to="/staking">{t('menu.2')}</HeaderMenuItem>
      <HeaderMenuItem to="/asset">{t('menu.3')}</HeaderMenuItem>
      <HeaderMenuItem to="/dashboard">{t('menu.4')}</HeaderMenuItem>
      <HeaderMenuItem to="/referral" highlight>
        {t('menu.5')}
      </HeaderMenuItem>
      <Link href="https://bridge.orbitchain.io/" target="_blank">
        {t('menu.6')}
      </Link>
      <HeaderCommunityMenu />
    </HeaderMenuWrapper>
  )
}

export default HeaderMenu

import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { CONTRACT_ADDRESS } from '../contract/address'
import checkMobileDevice from '../utils/checkMobileDevice'
import { WITHDRAW_ABI } from '../contract/abi'
import parseTransactionError from '../utils/parseTransactionError'
import { getCurrentTimeForHistory } from '../utils/getCurrentTime'
import { useManageDepositFormState } from '../state/pool/depositFormState'
import { useManageTransactionHistoryState } from '../state/shared/transactionHistoryState'
import { getConvertToPebValue } from '../utils/convertTokenNumberData'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'

type WithdrawType = 'withdraw' | 'reward'

export default function useWithdraw(pid?: number) {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    depositFormState: { withdraw },
  } = useManageDepositFormState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setTransactionHistoryState } = useManageTransactionHistoryState()

  const handleAddTransactionHistory = (hash: string, status: boolean) =>
    setTransactionHistoryState(prev => [
      {
        type: 'withdraw',
        hash,
        status,
        date: getCurrentTimeForHistory(),
      },
      ...prev,
    ])

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { tx_hash } = result

    if (result.status === 'success') {
      successCallback()
      handleAddTransactionHistory(tx_hash as string, true)
    } else {
      failureCallback()
      handleAddTransactionHistory(tx_hash as string, false)
    }
  }

  const klipWithdraw = async (
    withdrawType: WithdrawType,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const params = JSON.stringify([
      pid,
      withdrawType === 'withdraw' ? getConvertToPebValue(withdraw, 18) : '0',
    ])

    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.masterchef,
      abi: JSON.stringify(WITHDRAW_ABI),
      params,
      value: '0',
    })

    if (err) {
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasWithdraw = (
    withdrawType: WithdrawType,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(WITHDRAW_ABI, [
      pid,
      withdrawType === 'withdraw' ? getConvertToPebValue(withdraw, 18) : '0',
    ])

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.masterchef,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const bitkeepWithdraw = (
    withdrawType: WithdrawType,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const data = web3.eth.abi.encodeFunctionCall(WITHDRAW_ABI, [
      pid,
      withdrawType === 'withdraw' ? getConvertToPebValue(withdraw, 18) : '0',
    ] as string[])

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.masterchef,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const handleWithdraw = async (
    withdrawType: WithdrawType,
    withdrawSuccessCallback: () => void,
    withdrawFailureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipWithdraw(
        withdrawType,
        withdrawSuccessCallback,
        withdrawFailureCallback,
      )
    else if (type === 'kaikas')
      kaikasWithdraw(
        withdrawType,
        withdrawSuccessCallback,
        withdrawFailureCallback,
      )
    else if (type === 'bitkeep')
      bitkeepWithdraw(
        withdrawType,
        withdrawSuccessCallback,
        withdrawFailureCallback,
      )
  }

  return handleWithdraw
}

import { atom, useRecoilState, SetterOrUpdater } from 'recoil'

export type MessageType = {
  type: 'success' | 'failure'
  message: string
}

type MessageBoxStateType = {
  isVisible: boolean
  messages: MessageType[]
}

const messageBoxAtom = atom<MessageBoxStateType>({
  key: 'messageBox',
  default: {
    isVisible: false,
    messages: [],
  },
})

export const useManageMessageBoxState = (): {
  messageBoxState: MessageBoxStateType
  setMessageBoxState: SetterOrUpdater<MessageBoxStateType>
} => {
  const [messageBoxState, setMessageBoxState] = useRecoilState(messageBoxAtom)

  return { messageBoxState, setMessageBoxState }
}

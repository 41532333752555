import { Fragment, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import useTokenListQuery from '../../api/query/useTokenListQuery'
import { RONY_CLUB_MUSIC_LIST } from '../../utils/constants'
import MainPage from './pages/MainPage'
import SwapPage from './pages/SwapPage'
import PoolPage from './pages/PoolPage'
// import AppendPoolPage from './pages/AppendPoolPage'
import DepositPage from './pages/DepositPage'
import DashboardPage from './pages/DashboardPage'
import MyAssetsPage from './pages/MyAssetsPage'
import ReferralPage from './pages/ReferralPage'
import StakingPage from './pages/StakingPage'
import ModalContainer from '../../modal/NeuronSwap/ModalContainer'

const App = () => {
  useTokenListQuery()

  useEffect(() => {
    RONY_CLUB_MUSIC_LIST.forEach(music => {
      if (music.paused) return

      music.pause()
      music.currentTime = 0
    })
  }, [])

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/swap" element={<SwapPage />} />
        <Route path="/pool" element={<PoolPage />} />
        {/* {/* <Route path="/pool/append" element={<AppendPoolPage />} /> */}
        <Route path="/pool/detail/:pairAddress" element={<DepositPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/:page" element={<DashboardPage />} />
        <Route path="/asset" element={<MyAssetsPage />} />
        <Route path="/referral" element={<ReferralPage />} />
        <Route path="/referral/:address" element={<ReferralPage />} />
        <Route path="/staking" element={<StakingPage />} />
      </Routes>

      <ModalContainer />
    </Fragment>
  )
}

export default App

import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import { INPUT_REGEX } from '../../../utils/constants'

type NeuronStakingInputProps = {
  isMax: boolean
  isInsufficientQuantity: boolean
  value: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const StakingInputWrapper = styled.div<{ isValueEntered: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 16px;
  border: 1.5px solid #${({ isValueEntered }) => (isValueEntered ? 'ff6d68' : 'f8f8f8')};
  background-color: #f8f8f8;
  font-size: 24px;
  font-weight: 700;
  color: #000000;

  &:focus-within {
    border: 1.5px solid #ff6d68;
  }
`

const StakingInput = styled.input<{ isInsufficientQuantity: boolean }>`
  position: relative;
  z-index: 1;
  width: 100%;
  margin-right: 6px;
  border: 0;
  background: transparent;
  font-size: 24px;
  font-weight: 700;
  color: ${({ isInsufficientQuantity }) =>
    isInsufficientQuantity ? '#b95955' : '#000000'};
  outline: none;
  text-align: center;
`

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  font-size: 15px;
  font-weight: 400;
  color: #9d9999;
`

const NeuronStakingInput = ({
  isMax,
  isInsufficientQuantity,
  value,
  handleChange,
}: NeuronStakingInputProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.NeuronStaking',
  })

  return (
    <StakingInputWrapper isValueEntered={value !== ''}>
      <StakingInput
        isInsufficientQuantity={isInsufficientQuantity}
        readOnly={isMax}
        value={
          INPUT_REGEX.exec(value)
            ? value
            : convertNumber(value, { maxDigit: 6 })
        }
        onChange={handleChange}
      />
      {value === '' ? <Placeholder>{t('placeholder')}</Placeholder> : null}
    </StakingInputWrapper>
  )
}

export default NeuronStakingInput

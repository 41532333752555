import { prepare, request } from 'klip-sdk'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import checkMobileDevice from '../utils/checkMobileDevice'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'
import { SET_REFERRAL_ABI } from '../contract/abi'
import { CONTRACT_ADDRESS } from '../contract/address'

export default function useSetReferralAddress() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    if (result.status === 'success') successCallback()
    else failureCallback()
  }

  const klipClaimNeuronRewards = async (
    enteredAddress: string,
    successCallback: () => Promise<void>,
    failureCallback: () => void,
    errorHandler: () => void,
  ) => {
    try {
      const { err, request_key } = await prepare.executeContract({
        bappName: 'NEURONswap',
        from: address,
        to: CONTRACT_ADDRESS.router,
        value: '0',
        abi: JSON.stringify(SET_REFERRAL_ABI),
        params: JSON.stringify([enteredAddress]),
      })

      if (err) {
        throw new Error()
      }

      setKlipRequestKeyState({
        requestKey: request_key,
        requestCallback: (result: any) =>
          klipConnectSuccessCallback(result, successCallback, failureCallback),
      })

      if (checkMobileDevice()) request(request_key)
    } catch (error) {
      errorHandler()
    }
  }

  const kaikasClaimNeuronRewards = (
    enteredAddress: string,
    successCallback: () => Promise<void>,
    failureCallback: () => void,
    errorHandler: () => void,
  ) => {
    try {
      const data = caver.klay.abi.encodeFunctionCall(SET_REFERRAL_ABI, [
        enteredAddress,
      ])

      caverForKaikas.klay
        .sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address,
          to: CONTRACT_ADDRESS.router,
          data,
          gas: CONTRACT_GAS_LIMIT,
        })
        .on('error', failureCallback)
        .on('receipt', successCallback)
    } catch (error) {
      errorHandler()
    }
  }

  const bitkeepClaimNeuronRewards = (
    enteredAddress: string,
    successCallback: () => Promise<void>,
    failureCallback: () => void,
    errorHandler: () => void,
  ) => {
    try {
      const data = web3.eth.abi.encodeFunctionCall(SET_REFERRAL_ABI, [
        enteredAddress,
      ])

      web3.eth
        .sendTransaction({
          from: address,
          to: CONTRACT_ADDRESS.router,
          data,
          gas: CONTRACT_GAS_LIMIT,
        })
        .on('error', failureCallback)
        .on('receipt', () => {
          successCallback()
        })
    } catch (error) {
      errorHandler()
    }
  }

  const handleSetReferralAddress = (
    enteredAddress: string,
    setReferralAddressSuccessCallback: () => Promise<void>,
    setReferralAddressFailureCallback: () => void,
    setReferralAddressErrorHandler: () => void,
  ) => {
    if (!enteredAddress.startsWith('0x') || enteredAddress.length !== 42) {
      setReferralAddressErrorHandler()
      return
    }

    if (type === 'klip')
      klipClaimNeuronRewards(
        enteredAddress,
        setReferralAddressSuccessCallback,
        setReferralAddressFailureCallback,
        setReferralAddressErrorHandler,
      )
    else if (type === 'kaikas')
      kaikasClaimNeuronRewards(
        enteredAddress,
        setReferralAddressSuccessCallback,
        setReferralAddressFailureCallback,
        setReferralAddressErrorHandler,
      )
    else if (type === 'bitkeep')
      bitkeepClaimNeuronRewards(
        enteredAddress,
        setReferralAddressSuccessCallback,
        setReferralAddressFailureCallback,
        setReferralAddressErrorHandler,
      )
  }

  return handleSetReferralAddress
}

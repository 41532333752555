import { ReactNode } from 'react'
import styled from 'styled-components'

type SwapInfoExchangeRateItemProps = {
  title: string
  children: ReactNode | string
  highlight?: boolean
}

const SwapInfoExchangeRateItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #9d9999;

  & + & {
    margin-top: 9px;
  }

  @media (max-width: 768px) {
    color: #ffffff;

    & + & {
      margin-top: 16px;
    }
  }
`

const SwapInfoExchangeRateItemTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
`

const SwapInfoExchangeRateItemContent = styled.div<{ highlight: string }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: ${({ highlight }) => (highlight === 'true' ? '700' : '400')};
  color: ${({ highlight }) => (highlight === 'true' ? '#000000' : 'inherit')};
  line-height: 1.25;
  text-align: right;

  span {
    margin-left: 4px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    color: inherit;
  }
`

const SwapInfoExchangeRateItem = ({
  title,
  children,
  highlight,
}: SwapInfoExchangeRateItemProps) => {
  return (
    <SwapInfoExchangeRateItemWrapper>
      <SwapInfoExchangeRateItemTitle>{title}</SwapInfoExchangeRateItemTitle>
      <SwapInfoExchangeRateItemContent highlight={String(!!highlight)}>
        {children}
      </SwapInfoExchangeRateItemContent>
    </SwapInfoExchangeRateItemWrapper>
  )
}

export default SwapInfoExchangeRateItem

import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import NeuronStakingInput from '../molecules/NeuronStakingInput'
import StakingTermSelect from '../molecules/StakingTermSelect'
import NeuronStakingPolicy from '../molecules/NeuronStakingPolicy'
import { useManageStakingFormState } from '../../../state/staking/stakingFormState'
import { useManageStakingModalState } from '../../../state/modal/stakingModalState'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageApproveModalState } from '../../../state/modal/approveModalState'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import { INPUT_REGEX, NR_SYMBOL } from '../../../utils/constants'
import convertNumber from '../../../utils/convertNumber'
import { StakingTermType } from '../../../utils/types'
import { CONTRACT_ADDRESS, TOKEN_ADDRESS } from '../../../contract/address'
import { neuronContract } from '../../../contract/contract'
import useTokenQuery from '../../../api/query/useTokenQuery'

const NeuronStakingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 457px;
  margin-top: 28px;
  padding: 27px 40px 40px;
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  background: #ffffff;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #ff6d68;
  margin-bottom: 27px;
`

const StakingSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 21px;
`

const StakingSettingItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #9d9999;
  line-height: 1.2;

  span {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
  }

  div + div {
    margin-left: 8px;
  }

  & + & {
    margin-top: 11px;
  }
`

const TokenMaxAmount = styled.div<{ isMax: boolean }>`
  display: grid;
  place-items: center;
  width: 40px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #9d9999;
  background: ${({ isMax }) => (isMax ? '#9d9999' : 'transparent')};
  font-size: 11px;
  font-weight: 700;
  color: ${({ isMax }) => (isMax ? '#ffffff' : '#9d9999')};
  line-height: 1.55;
  transition: 0.15s all;
  cursor: pointer;
`

const StakingButton = styled.button<{ $isActive: boolean }>`
  width: 238px;
  height: 48px;
  border: 0;
  border-radius: 24px;
  background: ${({ $isActive }) => ($isActive ? '#ff6d68' : '#9d9999')};
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  transition: 0.15s all;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const NeuronStaking = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.NeuronStaking',
  })
  const {
    stakingFormState: { isMax, value, term },
    setStakingFormState,
    resetStakingFormState,
  } = useManageStakingFormState()
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { setStakingModalState } = useManageStakingModalState()
  const { setApproveModalState } = useManageApproveModalState()
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const [$isActive, setIsActive] = useState<boolean>(false)
  const { data: neuron } = useTokenQuery(TOKEN_ADDRESS[NR_SYMBOL] as string)

  const isInsufficientQuantity = useMemo(() => {
    if (neuron === undefined) return false

    return parseFloat(value) > parseFloat(neuron.balance)
  }, [neuron, value, address])

  const handleChangeNeuron = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '' && !INPUT_REGEX.exec(event.target.value))
      return

    setStakingFormState(prev => ({ ...prev, value: event.target.value }))
  }

  const handleToggleIsMax = () => {
    if (address === undefined) {
      setConnectWalletModalState(true)
      return
    }
    if (neuron === undefined) return

    setStakingFormState(prev => ({
      ...prev,
      isMax: !prev.isMax,
      value: neuron.balance,
    }))
  }

  const handleChangeStakingTerm = (term: StakingTermType) => {
    if (address === undefined) {
      setConnectWalletModalState(true)
      return
    }

    setStakingFormState(prev => ({ ...prev, term }))
  }

  const handleStaking = () =>
    neuronContract.methods
      .allowance(address, CONTRACT_ADDRESS.gnr)
      .call()
      .then((isApproved: number) =>
        isApproved > 0
          ? setStakingModalState(true)
          : setApproveModalState({ visible: true, type: 'staking' }),
      )

  useEffect(() => {
    setIsActive(
      value !== '' &&
        value !== '0' &&
        term !== -1 &&
        address !== undefined &&
        !isInsufficientQuantity,
    )

    if (address === undefined) resetStakingFormState()
  }, [value, term, address, isInsufficientQuantity])

  return (
    <NeuronStakingWrapper>
      <Title>{t('title')}</Title>

      <NeuronStakingInput
        isMax={isMax}
        isInsufficientQuantity={isInsufficientQuantity}
        value={value}
        handleChange={handleChangeNeuron}
      />

      <StakingSetting>
        <div>
          <StakingSettingItem>
            <div>{t('balance')}</div>
            <div>
              {convertNumber(neuron?.balance ?? 0, {
                comma: true,
                maxDigit: 6,
              })}
            </div>
            <TokenMaxAmount isMax={isMax} onClick={handleToggleIsMax}>
              {t('max')}
            </TokenMaxAmount>
          </StakingSettingItem>
          <StakingSettingItem>
            <div>
              {t('stakingPeriod')}
              <span>(Month)</span>
            </div>
            <StakingTermSelect
              isVisibleTotalButton={false}
              stakingTermIndex={term}
              handleChangeStakingTerm={handleChangeStakingTerm}
            />
          </StakingSettingItem>
        </div>

        <StakingButton
          $isActive={$isActive}
          onClick={$isActive ? handleStaking : undefined}
        >
          {t('staking')}
        </StakingButton>
      </StakingSetting>

      <NeuronStakingPolicy />
    </NeuronStakingWrapper>
  )
}

export default NeuronStaking

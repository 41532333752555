import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import TransactionHistoryListHead from '../molecules/TransactionHistoryListHead'
import TransactionHistoryList from '../organisms/TransactionHistoryList'

const TransactionHistoryTemplateWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
  color: #656565;
`

const TransactionHistoryTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'TransactionHistory',
  })

  return (
    <TransactionHistoryTemplateWrapper>
      <Title>{t('title')}</Title>

      <TransactionHistoryListHead />
      <TransactionHistoryList />
    </TransactionHistoryTemplateWrapper>
  )
}

export default TransactionHistoryTemplate

import TemplateLayout from '../templates/TemplateLayout'
// import AssetMigrationTemplate from '../templates/AssetMigrationTemplate'
import DepositedTemplate from '../templates/DepositedTemplate'
import AvailableDepositTemplate from '../templates/AvailableDepositTemplate'

const PoolPage = () => {
  return (
    <TemplateLayout>
      {/* <AssetMigrationTemplate /> */}
      <DepositedTemplate />
      <AvailableDepositTemplate />
    </TemplateLayout>
  )
}

export default PoolPage

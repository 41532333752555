import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useQueryClient } from 'react-query'
import AxiosClient from '../../../api/axiosConfig'
import { useManageConnectWalletModalState } from '../../../state/modal/connectWalletModalState'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

const StyledLine = styled.div`
  width: 1px;
  height: 16px;
  margin: 0 15px;
  background: #ffffff;
`

export const HeaderChangeWalletStatusWrapper = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 5px;

  @media (max-width: 768px) {
    display: none !important;
  }
`

const WidgetBackground = styled.div<{ language: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ language }) => (language === 'ko' ? '172' : '203')}px;
  padding: 8px 0;
  border-radius: 21px;
  background: rgba(7, 7, 7, 0.4);

  div:not(${StyledLine}) {
    flex-shrink: 0;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
`

const HeaderChangeWalletStatus = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Header',
  })
  const { setConnectWalletModalState } = useManageConnectWalletModalState()
  const {
    connectedWalletState: { type, address },
    resetConnectedWalletState,
  } = useManageConnectedWalletState()
  const queryClient = useQueryClient()

  const handleChangeWallet = () => {
    resetConnectedWalletState()
    setConnectWalletModalState(true)
    AxiosClient.setAccessToken('')
    queryClient.removeQueries()

    window.sessionStorage.removeItem('neuronSwapWalletType')
    if (type === 'klip')
      window.sessionStorage.removeItem('neuronSwapKlipAddress')
  }

  return (
    <HeaderChangeWalletStatusWrapper>
      <WidgetBackground language={language}>
        <CopyToClipboard text={address ?? ''}>
          <div>{t('headerChangeWallet.0')}</div>
        </CopyToClipboard>
        <StyledLine />
        <div onClick={handleChangeWallet}>{t('headerChangeWallet.1')}</div>
      </WidgetBackground>
    </HeaderChangeWalletStatusWrapper>
  )
}

export default HeaderChangeWalletStatus

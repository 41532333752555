import { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PoolItemToken from '../atoms/PoolItemToken'
import AvailableDepositItemDistribute from '../atoms/AvailableDepositItemDistribute'
import AvailableDepositItemRewards from '../atoms/AvailableDepositItemRewards'
import PoolItemEstimatedRewards from '../atoms/PoolItemEstimatedRewards'
import MobilePoolItemEstimatedRewards from '../atoms/MobilePoolItemEstimatedRewards'
import MobilePopup from './MobilePopup'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import convertNumber from '../../../utils/convertNumber'
import { AvailablePoolItemType } from '../../../api/query/useAvailableDepositPoolsQuery'
import { TOKEN_ADDRESS } from '../../../contract/address'

type AvailableDepositItemProps = AvailablePoolItemType

const DepositableListItemBackground = styled.div<{ isHot: string }>`
  @keyframes rotate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  padding: 1px;
  border-radius: 25px;
  background: ${({ isHot }) =>
    isHot === 'true'
      ? 'linear-gradient(to right, #ff6d68, #815cff 50%, #65dfb9)'
      : '#ffffff'};
  background-size: 200% 200%;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  animation: rotate 2s ease infinite;

  & + & {
    margin-top: 20px;
  }
`

const DepositableListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.05fr 0.7fr 0.6fr 0.55fr 0.7fr 1.05fr;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: unset;
    padding: 21px 16px 8px;
  }
`

const MobileDepositableListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 17px;
  }
`

const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #9d9999;

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
`

const Price = styled.div`
  padding-right: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: right;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`

const ReceiveRewardButton = styled(Link)`
  width: 142px;
  margin-left: auto;
  padding: 10px 0;
  border-radius: 20px;
  border: 0;
  background: #ff6d68;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: calc(100% + 16px);
    margin: 32px -8px 0;
    padding: 16px 0;
    border-radius: 16px;
  }
`

const MobilePopupItem = styled.div`
  padding: 32px 0;
  color: #ffffff;
  text-align: center;

  & > div:nth-child(1) {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.5;
  }

  & + & {
    border-top: 1px solid #ffffff;
  }
`

const DepositableListItem = ({
  address,
  totalLiquidity,
  rewardsDetail,
  tokens,
}: AvailableDepositItemProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit',
  })
  const { t: tooltipTranslation } = useTranslation('translation', {
    keyPrefix: 'Pool.Tooltip',
  })
  const { width } = useGetWindowSize()
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowPopup = () => setIsVisiblePopup(true)
  const handleClosePopup = () => setIsVisiblePopup(false)

  if (width <= 768)
    return (
      <DepositableListItemBackground isHot={String(rewardsDetail.apr >= 1000)}>
        <DepositableListItemWrapper>
          <PoolItemToken tokens={tokens} isHot={rewardsDetail.apr >= 1000} />

          <MobileDepositableListItem>
            <MobileTitle style={{ marginTop: '-17px' }}>
              {t('mobileHead.0')}
              <img
                onClick={handleShowPopup}
                src="/dexAssets/tooltip_icon.png"
                alt="Mobile Popup Icon"
              />
            </MobileTitle>

            <MobilePoolItemEstimatedRewards
              rewardsDetail={rewardsDetail}
              fontSize="22"
              enableDetail={true}
            />
          </MobileDepositableListItem>

          <MobileDepositableListItem>
            <MobileTitle>{t('mobileHead.1')}</MobileTitle>

            <Price>
              {convertNumber(rewardsDetail.apr, { isPercentage: true })}%
            </Price>
          </MobileDepositableListItem>

          <MobileDepositableListItem>
            <MobileTitle>{t('mobileHead.2')}</MobileTitle>

            <Price>{`$ ${convertNumber(totalLiquidity, {
              comma: true,
              maxDigit: 0,
            })}`}</Price>
          </MobileDepositableListItem>

          <MobileDepositableListItem style={{ marginTop: '14px' }}>
            <MobileTitle>{t('mobileHead.3')}</MobileTitle>

            <AvailableDepositItemDistribute
              distributedTokenSymbolList={[
                TOKEN_ADDRESS.NR,
                tokens[0].address,
                tokens[1].address,
              ]}
            />
          </MobileDepositableListItem>

          <ReceiveRewardButton to={`/pool/detail/${address}`}>
            {t('button')}
          </ReceiveRewardButton>
        </DepositableListItemWrapper>

        <MobilePopup
          type="normal"
          isVisible={isVisiblePopup}
          handleClose={handleClosePopup}
        >
          <MobilePopupItem>
            <div>{tooltipTranslation('0.title')}</div>
            <div>{tooltipTranslation('0.popupContents')}</div>
          </MobilePopupItem>
          <MobilePopupItem>
            <div>{tooltipTranslation('1.title')}</div>
            <div>{tooltipTranslation('1.popupContents')}</div>
          </MobilePopupItem>
        </MobilePopup>
      </DepositableListItemBackground>
    )

  return (
    <DepositableListItemBackground isHot={String(rewardsDetail.apr >= 1000)}>
      <DepositableListItemWrapper>
        <PoolItemToken tokens={tokens} />

        <Price>{`$ ${convertNumber(totalLiquidity, {
          comma: true,
          maxDigit: 0,
        })}`}</Price>

        <AvailableDepositItemDistribute
          distributedTokenSymbolList={[
            TOKEN_ADDRESS.NR,
            tokens[0].address,
            tokens[1].address,
          ]}
        />

        <AvailableDepositItemRewards rewardsDetail={rewardsDetail} />

        <PoolItemEstimatedRewards
          apr={rewardsDetail.apr}
          apy={rewardsDetail.apy}
        />

        <ReceiveRewardButton to={`/pool/detail/${address}`}>
          {t('button')}
        </ReceiveRewardButton>
      </DepositableListItemWrapper>
    </DepositableListItemBackground>
  )
}

export default DepositableListItem

import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type TokenModalSearchProps = {
  value: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const TokenModalSearchWrapper = styled.div`
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 5px;
  align-items: center;
  height: 40px;
  margin: 30px 0;
  padding: 0 12px;
  border-radius: 20px;
  border: 0.5px solid #d1cbcb;
  background: #fcfcfc;

  @media (max-width: 768px) {
    grid-template-columns: 16px 1fr;
    grid-gap: 8px;
    height: 48px;
    margin-bottom: 32px;
    border-radius: 24px;
  }
`

const SearchInput = styled.input<{ language: string }>`
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: ${({ language }) => (language === 'ko' ? '14' : '12')}px;
  }
`

const SearchButton = styled.button`
  display: grid;
  place-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`

const TokenModalSearch = ({ value, handleChange }: TokenModalSearchProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Modal.TokenSelectModal',
  })

  return (
    <TokenModalSearchWrapper>
      <SearchButton>
        <img src="/dexAssets/search_icon.png" alt="Search Icon" />
      </SearchButton>

      <SearchInput
        language={language}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={t('search')}
      />
    </TokenModalSearchWrapper>
  )
}

export default TokenModalSearch

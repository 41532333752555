import styled from 'styled-components'
import DepositPoolBalanceMyAsset from '../molecules/DepositPoolBalanceMyAsset'
import DepositPoolBalanceClaimableReward from '../molecules/DepositPoolBalanceClaimableReward'
import usePoolQuery from '../../../api/query/usePoolQuery'

type DepositPoolBalanceProps = {
  pairAddress: string
}

const PoolDepositAssetWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5px 1fr;
  align-items: center;
  padding: 12px 0;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: auto 0.5px auto;
    padding: 16px;
  }
`

const DividingLine = styled.div`
  width: 0.5px;
  height: 62px;
  background: #9d9999;

  @media (max-width: 768px) {
    width: 100%;
    height: 0.5px;
  }
`

const DepositPoolBalance = ({ pairAddress }: DepositPoolBalanceProps) => {
  const { data } = usePoolQuery(pairAddress)

  if (data === undefined) return null

  return (
    <PoolDepositAssetWrapper>
      <DepositPoolBalanceMyAsset
        tokens={data.tokens}
        depositRate={data.depositRate}
        sharedOf={data.userData?.sharedOf ?? '0.00'}
        userLpBalance={data.userData?.stakedLpBalance ?? '0'}
      />
      <DividingLine />
      <DepositPoolBalanceClaimableReward
        address={data.address}
        pid={data.pid}
        claimableNeuron={data.userData?.claimableNeuron ?? 0}
      />
    </PoolDepositAssetWrapper>
  )
}

export default DepositPoolBalance

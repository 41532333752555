import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import KlipQRCode from 'qrcode.react'
import { useManageKlipRequestKeyState } from '../../state/shared/klipRequestKeyState'
import useCheckKlipConnected from '../../hooks/useCheckKlipConnected'
import ModalPortal from '../ModalPortal'
import checkMobileDevice from '../../utils/checkMobileDevice'
import { CSS_Z_INDEX } from '../../utils/constants'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  width: 420px;
  height: 580px;
  border-radius: 24px;
  background: #65dfb9;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`

const ModalHead = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 30px;
  font-size: 20px;
  font-weight: 700;
  color: #2e2e2e;
  img {
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;
  }
`

const QRCode = styled.div`
  display: grid;
  place-items: center;
  width: 200px;
  height: 200px;
  padding: 10px;
  background: #ffffff;
`

const QRCodeInfo = styled.div`
  padding: 27px 0 32px;
  color: #404040;

  & > div:nth-child(1) {
    font-size: 14px;
    text-align: center;

    span {
      margin-left: 5px;
      font-weight: 700;
      color: #ffffff;
    }
  }

  & > div:nth-child(2) {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
    white-space: pre-line;
  }
`

const ConnectProcessWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 30px 0;
  border-radius: 0 0 24px 24px;
  background: #ffffff;
`

const ConnectProcessIcon = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: 34px 24px 34px 24px 34px;
  grid-gap: ${({ language }) => (language === 'ko' ? '27' : '38')}px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`

const ConnectProcessText = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-top: 8px;
  padding: 0 ${({ language }) => (language === 'ko' ? '48' : '18')}px;
  font-size: 12px;
  font-weight: 700;
  color: #404040;
  text-align: center;

  div {
    white-space: pre-line;
  }
`

const ConnectProcessInfo = styled.div`
  margin-top: 22px;
  font-size: 12px;
  color: #b5b5b5;
  text-align: center;
`

const KlipConnectModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Modal.KlipConnectModal',
  })
  const {
    klipRequestKeyState: { requestKey },
    resetKlipRequestKeyState,
  } = useManageKlipRequestKeyState()
  const klipConnectedCheckFunc = useCheckKlipConnected()

  const isMobileDevice = useRef<boolean>(checkMobileDevice())
  const modalFloatingTime = useRef<number>(300)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const klipPollingCallback = useRef<typeof klipConnectedCheckFunc>(
    klipConnectedCheckFunc,
  )
  const [{ min, sec }, setExistingTime] = useState<{
    min: number
    sec: number
  }>({ min: 5, sec: 0 })

  // 콜백함수 업데이트를 위한 부분
  useEffect(() => (klipPollingCallback.current = klipConnectedCheckFunc as any))

  // 모달 시간 제한
  useEffect(() => {
    if (requestKey === undefined) return

    timer.current = setInterval(() => {
      if (modalFloatingTime.current === 0) resetKlipRequestKeyState()

      setExistingTime({
        min: Math.floor(modalFloatingTime.current / 60),
        sec: modalFloatingTime.current % 60,
      })
      modalFloatingTime.current -= 1

      klipPollingCallback.current()
    }, 1000)

    return () => {
      if (timer.current === null) return
      clearInterval(timer.current)
    }
  }, [requestKey])

  return (
    <ModalPortal isHidden={isMobileDevice.current}>
      <ModalWrapper>
        <ModalHead>
          <div>{t('title')}</div>

          <img
            src="/dexAssets/modal_icons/klip_connect/modal_close_icon.png"
            alt="Modal Close Icon"
            onClick={resetKlipRequestKeyState}
          />
        </ModalHead>

        <QRCode>
          {requestKey !== undefined ? (
            <KlipQRCode
              style={{ width: '100%', height: '100%' }}
              value={`https://klipwallet.com/?target=/a2a?request_key=${requestKey}`}
            />
          ) : (
            <>Wrong Request Key</>
          )}
        </QRCode>

        <QRCodeInfo>
          <div>
            {t('time.info')}
            <span>
              {min !== 0 ? `${min}${t('time.minute')} ` : ''}
              {sec}
              {t('time.second')}
            </span>
          </div>
          <div style={{ whiteSpace: 'pre-line' }}>{t('description')}</div>
        </QRCodeInfo>

        <ConnectProcessWrapper>
          <ConnectProcessIcon language={language}>
            <img
              src="/dexAssets/modal_icons/klip_connect/1.png"
              alt="Klip Connect Process Icon"
            />
            <img
              src="/dexAssets/modal_icons/klip_connect/klip_process_icon.png"
              alt="Next Icon"
            />
            <img
              src="/dexAssets/modal_icons/klip_connect/2.png"
              alt="Klip Connect Process Icon"
            />
            <img
              src="/dexAssets/modal_icons/klip_connect/klip_process_icon.png"
              alt="Next Icon"
            />
            <img
              src="/dexAssets/modal_icons/klip_connect/3.png"
              alt="Klip Connect Process Icon"
            />
          </ConnectProcessIcon>

          <ConnectProcessText language={language}>
            <div>{t('process.0')}</div>
            <div>{t('process.1')}</div>
            <div>{t('process.2')}</div>
          </ConnectProcessText>

          <ConnectProcessInfo>{t('info')}</ConnectProcessInfo>
        </ConnectProcessWrapper>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default KlipConnectModal

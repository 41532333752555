import styled from 'styled-components'
import CommonTooltip from '../atoms/CommonTooltip'
import convertNumber from '../../../utils/convertNumber'

const APR_TOOLTIP_CUSTOM_STYLE = {
  width: '231px',
  padding: '13px',
  fontSize: '12px',
  fontWeight: '400',
  borderRadius: '15px',
}

type PoolItemEstimatedRewardsProps = {
  apr: number
  apy: number
}

const CompoundedDailyRate = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #9d9999;
  text-align: right;
  line-height: 1.5;
`

const EstimatedReturnRate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #ff6d68;
  line-height: 1.56;
`

const HotBadge = styled.img`
  width: 55px;
  margin: 0 5px 1px 0;
`

const PoolItemEstimatedRewards = ({
  apr,
  apy,
}: PoolItemEstimatedRewardsProps) => {
  return (
    <div>
      <CompoundedDailyRate>
        {apy !== Infinity
          ? `${convertNumber(apy, { isPercentage: true })}%`
          : 'Infinity'}
      </CompoundedDailyRate>

      <EstimatedReturnRate>
        {apr >= 1000 ? (
          <HotBadge src="/dexAssets/hot_icon.png" alt="Hot Icon" />
        ) : null}
        <CommonTooltip
          isEnable={apr >= 1000}
          displayComponent={<>{convertNumber(apr, { maxDigit: 6 })}%</>}
          widgetCustomStyle={APR_TOOLTIP_CUSTOM_STYLE}
        >
          {convertNumber(apr, { isPercentage: true })}%
        </CommonTooltip>
      </EstimatedReturnRate>
    </div>
  )
}

export default PoolItemEstimatedRewards

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import useHandleClickOutSide from '../../../hooks/useHandleClickOutSide'
import { CSS_Z_INDEX } from '../../../utils/constants'

type SelectBoxProps = {
  value: string
  handleSelect: (sortedBy: string) => void
}

const SelectBoxWrapper = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  background: transparent;

  @media (max-width: 768px) {
    width: unset;
    margin-left: auto;
    margin-bottom: 16px;
    border: 0;

    select {
      font-size: 14px;
    }
  }
`

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 30px;
  padding: 0 10px;
  border-radius: 15px;
  border: 0.5px solid #d1cbcb;
  background: #ffffff;
  font-size: 12px;
  color: #9d9999;
  cursor: pointer;
  outline: none;

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    width: unset;
    height: unset;
    padding: 3px 0;
    border-radius: 0;
    border: 0;
    background: transparent;
    font-size: 14px;

    img {
      margin-left: 8px;
    }
  }
`

const MobileDimmed = styled.div`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${CSS_Z_INDEX.popup};
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
`

const DropdownMenu = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  overflow: hidden;
  width: 160px;
  border-radius: 15px;
  border: 0.5px solid #d1cbcb;
  background: #ffffff;

  @media (max-width: 768px) {
    top: unset;
    bottom: 0;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
`

const DropdownMenuItem = styled.div<{ highlight: string }>`
  padding: 8px 10px;
  font-size: 12px;
  color: #9d9999;
  cursor: pointer;

  & + & {
    border-top: 1px solid #d1cbcb;
  }

  &:hover {
    background: #edeaea;
  }

  @media (max-width: 768px) {
    display: grid;
    place-items: center;
    height: 50px;
    padding: 0;
    font-size: 14px;
    text-align: center;

    ${({ highlight }) =>
      highlight === 'true'
        ? `
      font-weight: 700;
      color: #ff6d68;
    `
        : ''}
  }
`

const AvailableDepositItemSelectBox = ({
  value,
  handleSelect,
}: SelectBoxProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit.select',
  })
  const { ref, isVisible, toggleComponent } = useHandleClickOutSide()
  const { width } = useGetWindowSize()

  const selectItem = (sortedBy: string) => {
    handleSelect(sortedBy)
    toggleComponent()
  }

  useEffect(() => {
    if (!isVisible || width > 768) return

    document.body.style.cssText = `overflow: hidden; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `overflow: unset; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [isVisible, width])

  return (
    <SelectBoxWrapper>
      <Select onClick={toggleComponent}>
        {t(value)}
        <img src="/dexAssets/select_box_icon.png" alt="Select Icon" />
      </Select>

      {isVisible ? (
        <MobileDimmed>
          <DropdownMenu ref={ref}>
            <DropdownMenuItem
              highlight={String(value === 'totalRewardRate')}
              onClick={() =>
                selectItem(
                  value !== 'totalRewardRate' ? 'totalRewardRate' : 'none',
                )
              }
            >
              {value !== 'totalRewardRate' || width <= 768
                ? t('totalRewardRate')
                : t('none')}
            </DropdownMenuItem>
            <DropdownMenuItem
              highlight={String(value === 'feeRewardRate')}
              onClick={() =>
                selectItem(value !== 'feeRewardRate' ? 'feeRewardRate' : 'none')
              }
            >
              {value !== 'feeRewardRate' || width <= 768
                ? t('feeRewardRate')
                : t('none')}
            </DropdownMenuItem>
            <DropdownMenuItem
              highlight={String(value === 'airdropRewardRate')}
              onClick={() =>
                selectItem(
                  value !== 'airdropRewardRate' ? 'airdropRewardRate' : 'none',
                )
              }
            >
              {value !== 'airdropRewardRate' || width <= 768
                ? t('airdropRewardRate')
                : t('none')}
            </DropdownMenuItem>
            <DropdownMenuItem
              highlight={String(value === 'liquiditySize')}
              onClick={() =>
                selectItem(value !== 'liquiditySize' ? 'liquiditySize' : 'none')
              }
            >
              {value !== 'liquiditySize' || width <= 768
                ? t('liquiditySize')
                : t('none')}
            </DropdownMenuItem>
          </DropdownMenu>
        </MobileDimmed>
      ) : null}
    </SelectBoxWrapper>
  )
}

export default AvailableDepositItemSelectBox

import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import HeaderLanguageMenu from '../atoms/HeaderLanguageMenu'
import HeaderMenu from '../molecules/HeaderMenu'
import HeaderConnectedWallet from '../molecules/HeaderConnectedWallet'
import { CSS_Z_INDEX } from '../../../utils/constants'

const HeaderWrapper = styled.div<{ isTransparent: string }>`
  position: fixed;
  top: 0;
  z-index: ${CSS_Z_INDEX.header};
  width: 100%;
  background: ${({ isTransparent }) =>
    isTransparent === 'true' ? 'transparent' : '#ffffff'};
  box-shadow: ${({ isTransparent }) =>
    isTransparent === 'true' ? '0' : '0 2px 4px 0 rgba(0, 0, 0, 0.08)'};
  transition: 0.2s all;
`

const HeaderContents = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  height: 68px;
  margin: 0 auto;
`

const HeaderMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HeaderCategoryItem = styled.div<{
  $isActive: boolean
  $isFocusOtherMenu: boolean
}>`
  position: relative;
  display: grid;
  place-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  ${({ $isActive }) => ($isActive ? 'font-weight: 700;' : '')}
  color: ${({ $isActive, $isFocusOtherMenu }) =>
    $isActive
      ? '#ff6d68'
      : $isFocusOtherMenu
      ? 'rgba(46, 46, 46, 0.5)'
      : '#2e2e2e'};
  cursor: pointer;

  &:hover {
    color: #ff6d68;
  }
`

// const RonyClubLink = styled(Link)<{
//   $isActive: boolean
//   $isFocusOtherMenu: boolean
// }>`
//   position: relative;
//   display: grid;
//   place-items: center;
//   height: 100%;
//   margin-left: 42px;
//   font-size: 16px;
//   font-weight: 700;
//   ${({ $isActive }) => ($isActive ? 'font-weight: 700;' : '')}
//   color: ${({ $isActive, $isFocusOtherMenu }) =>
//     $isActive
//       ? '#ff6d68'
//       : $isFocusOtherMenu
//       ? 'rgba(46, 46, 46, 0.5)'
//       : '#2e2e2e'};
//   cursor: pointer;
//   text-decoration: none;

//   &:hover {
//     color: #ff6d68;
//   }
// `

const CategoryMenu = styled.div`
  max-width: 1200px;
  margin: -9px auto 0;
  padding: 0 0 24px 226px;
`

const HeaderLogo = styled(Link)`
  width: 166px;
  margin-right: 60px;
  cursor: pointer;

  img {
    width: 100%;
  }
`

const Header = () => {
  const { pathname } = useLocation()
  const headerRef = useRef<HTMLDivElement>(null)
  const dexMenuRef = useRef<HTMLDivElement>(null)
  const ronyClubMenuRef = useRef<HTMLAnchorElement>(null)
  const [isTransparent, setIsTransparent] = useState<boolean>(true)
  const [{ isActiveDex, isActiveRonyClub }, setIsActive] = useState<{
    isActiveDex: boolean
    isActiveRonyClub: boolean
  }>({
    isActiveDex: false,
    isActiveRonyClub: false,
  })

  const isDexPage = () => {
    const dexPagePathname = [
      '/swap',
      '/pool',
      '/staking',
      '/asset',
      '/dashboard',
      '/referral',
    ]

    return dexPagePathname.findIndex(path => pathname.startsWith(path)) !== -1
  }

  const setDexItemIsActive = () =>
    setIsActive({ isActiveDex: true, isActiveRonyClub: false })
  const setRonyClubItemIsActive = () =>
    setIsActive({ isActiveDex: false, isActiveRonyClub: true })
  const resetDexItemIsActive = () =>
    setIsActive({ isActiveDex: false, isActiveRonyClub: false })

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY != 0) setIsTransparent(false)
      else setIsTransparent(true)
    }

    scrollListener()
    window.addEventListener('scroll', scrollListener)
    dexMenuRef.current?.addEventListener('mouseover', setDexItemIsActive)
    ronyClubMenuRef.current?.addEventListener(
      'mouseover',
      setRonyClubItemIsActive,
    )
    ronyClubMenuRef.current?.addEventListener(
      'mouseleave',
      resetDexItemIsActive,
    )
    headerRef.current?.addEventListener('mouseleave', resetDexItemIsActive)

    return () => {
      window.removeEventListener('scroll', scrollListener)
      dexMenuRef.current?.removeEventListener('mouseover', setDexItemIsActive)
      ronyClubMenuRef.current?.removeEventListener(
        'mouseover',
        setRonyClubItemIsActive,
      )
      ronyClubMenuRef.current?.removeEventListener(
        'mouseleave',
        resetDexItemIsActive,
      )
      headerRef.current?.removeEventListener('mouseleave', resetDexItemIsActive)
    }
  }, [])

  return (
    <HeaderWrapper isTransparent={String(isTransparent)} ref={headerRef}>
      <HeaderContents>
        <HeaderLogo to="/">
          <img src="/dexAssets/neuron_logo.png" alt="Neuron Logo" />
        </HeaderLogo>

        <HeaderMenuWrapper>
          <HeaderCategoryItem
            $isActive={isActiveDex}
            $isFocusOtherMenu={isActiveRonyClub}
            ref={dexMenuRef}
            style={{
              color: isDexPage() && !isActiveRonyClub ? '#ff6d68' : undefined,
            }}
          >
            DEX
          </HeaderCategoryItem>
          {/* <RonyClubLink
            $isActive={isActiveRonyClub}
            $isFocusOtherMenu={isActiveDex}
            ref={ronyClubMenuRef}
            to="/ronyclub"
          >
            RONY CLUB
          </RonyClubLink> */}
        </HeaderMenuWrapper>

        <HeaderLanguageMenu />
        <HeaderConnectedWallet />
      </HeaderContents>

      {isActiveDex ? (
        <CategoryMenu>
          <HeaderMenu />
        </CategoryMenu>
      ) : null}
    </HeaderWrapper>
  )
}

export default Header

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import SlicedDecimal from '../atoms/SlicedDecimal'
import { useManageReceiveRewardsModalState } from '../../../state/modal/receiveRewardsModalState'

type DepositPoolBalanceClaimableRewardProps = {
  address: string
  pid: number
  claimableNeuron: number
}

const DepositPoolBalanceClaimableRewardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 8px 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 0 0 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Title = styled.div`
  font-size: 12px;
  color: #2e2e2e;

  @media (max-width: 768px) {
    color: #9d9999;
  }
`

const DepositPoolBalanceTokenAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 768px) {
    margin-top: 17px;
  }
`

const TokenWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`

const TokenAmount = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #656565;

  span {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const TokenSymbol = styled.div`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const RewardButton = styled.button<{ $isActive: boolean }>`
  margin-top: 6px;
  padding: 5px 12px;
  border-radius: 19px;
  border: 0;
  background: #${({ $isActive }) => ($isActive ? '65dfb9' : '9d9999')};
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};

  @media (max-width: 768px) {
    width: 100px;
    height: 24px;
    padding: 0;
    margin-top: 0;
    line-height: 1.2;
  }
`

const DepositPoolBalanceClaimableReward = ({
  address,
  pid,
  claimableNeuron,
}: DepositPoolBalanceClaimableRewardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositPoolBalance.DepositPoolBalanceClaimableReward',
  })
  const { setReceiveRewardsModalState } = useManageReceiveRewardsModalState()

  const handleReceiveReward = () =>
    setReceiveRewardsModalState({ visible: true, pairAddress: address, pid })

  return (
    <DepositPoolBalanceClaimableRewardWrapper>
      <TitleWrapper>
        <Title>{t('title')}</Title>
        <RewardButton
          $isActive={claimableNeuron !== 0}
          onClick={claimableNeuron !== 0 ? handleReceiveReward : undefined}
        >
          {t('button')}
        </RewardButton>
      </TitleWrapper>

      {claimableNeuron !== 0 ? (
        <DepositPoolBalanceTokenAmountWrapper>
          <TokenWrapper>
            <TokenAmount>
              <SlicedDecimal maxIntLength={9} suffix=" NR">
                {convertNumber(claimableNeuron, { maxDigit: 6 })}
              </SlicedDecimal>
            </TokenAmount>
            <TokenSymbol>NR</TokenSymbol>
          </TokenWrapper>
        </DepositPoolBalanceTokenAmountWrapper>
      ) : null}
    </DepositPoolBalanceClaimableRewardWrapper>
  )
}

export default DepositPoolBalanceClaimableReward

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { newLineToBr } from '../../../utils/newLineToBr'

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcfafa;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const Title = styled.img`
  margin-top: 150px;
  width: 766px;
  height: 139px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 290px;
    height: unset;
    margin-top: 14px;
  }
`

const Desc = styled.span<{ language: string }>`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 56px;
  margin-bottom: 81px;
  .dex {
    color: #cca28f;
  }
  strong {
    color: #ff6d68;
  }
  z-index: 1;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: ${({ language }) => (language === 'ko' ? '38' : '68')}px;
    font-size: ${({ language }) => (language === 'ko' ? '16' : '15')}px;

    .dex {
      font-size: 16px;
    }
  }
`

const StartButton = styled(Link)<{ language: string }>`
  border-radius: 9999px;
  height: 56px;
  width: 436px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 292px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08);
  font-size: 22px;
  font-weight: bold;
  z-index: 1;
  border: 2px solid transparent;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(to right, #ff6d68, #815cff, #65dfb9);
  background-origin: border-box;
  background-clip: content-box, border-box;
  span {
    background: linear-gradient(to right, #ff6d68, #815cff, #65dfb9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      linear-gradient(to right, #ff6d68, #815cff, #65dfb9);
  }

  @media (max-width: 768px) {
    width: 320px;
    height: 60px;
    margin-bottom: ${({ language }) => (language === 'ko' ? '190' : '174')}px;
  }
`

const BgVisualLeft = styled.img`
  width: 1088px;
  height: 1088px;
  position: absolute;
  left: -544px;
  top: 246px;
  zindex: 0;

  @media (max-width: 768px) {
    display: none;
  }
`

const BgVisualRight = styled.img`
  width: 700px;
  height: 700px;
  position: absolute;
  right: -203px;
  top: 66px;
  zindex: 0;

  @media (max-width: 768px) {
    display: none;
  }
`

const MainHeroSection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Main.HeroSection',
  })
  const { width } = useGetWindowSize()

  return (
    <Stack>
      <Title src="/dexAssets/main_page/hero/title.svg" />
      <Desc
        language={language}
        dangerouslySetInnerHTML={{
          __html: newLineToBr(t(width > 768 ? 'desc' : 'mobileDesc')),
        }}
      />
      <StartButton language={language} to="/swap">
        <span>{t('start-neuronswap')}</span>
      </StartButton>
      <BgVisualLeft srcSet="/dexAssets/main_page/hero/bg-left.webp 1x, /dexAssets/main_page/hero/bg-left@2x.webp 2x, /dexAssets/main_page/hero/bg-left@3x.webp 3x" />
      <BgVisualRight srcSet="/dexAssets/main_page/hero/bg-right.webp 1x, /dexAssets/main_page/hero/bg-right@2x.webp 2x, /dexAssets/main_page/hero/bg-right@3x.webp 3x" />
    </Stack>
  )
}

export default MainHeroSection

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageMobileMenuState } from '../../state/modal/mobileMenuState'
import ModalPortal from '../ModalPortal'
import MobileMenuConnectedWallet from '../../components/NeuronSwap/molecules/MobileMenuConnectedWallet'
import MobileMenuChangeWalletStatus from '../../components/NeuronSwap/molecules/MobileMenuChangeWalletStatus'
import MobileMenuList from '../../components/NeuronSwap/molecules/MobileMenuList'
import { CSS_Z_INDEX } from '../../utils/constants'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 30px;
  border-radius: 24px;
  background: #fcfbfa;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border-radius: 0;
  }
`

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  font-size: 20px;
  font-weight: 700;
  color: #2e2e2e;

  & > img:nth-child(1) {
    width: 166px;
  }

  & > img:nth-child(2) {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`

const WalletSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 116px;
  margin-top: 56px;
`

const LanguageMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  margin: 0 -20px;
  padding: 0 20px;
  border-top: 1px solid rgba(157, 153, 153, 0.2);
  border-bottom: 1px solid rgba(157, 153, 153, 0.2);
  font-size: 16px;
  font-weight: 700;
  color: #9d9999;

  & > div:nth-child(1) {
    font-size: 14px;
  }
`

const LanguageSelector = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 20px;
  background: #edeaea;
  color: #9d9999;
`

const LanguageSelectorItem = styled.div<{ isSelected: string }>`
  display: grid;
  place-items: center;
  width: 64px;
  height: 27px;
  border-radius: 19px;
  background: ${({ isSelected }) =>
    isSelected === 'true' ? '#9d9999' : '#edeaea'};
  font-size: 10px;
  font-weight: ${({ isSelected }) => (isSelected === 'true' ? '700' : '400')};
  color: ${({ isSelected }) => (isSelected === 'true' ? '#ffffff' : '#9d9999')};
  cursor: pointer;
`

const LaunchPadMobileMenu = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation('translation', { keyPrefix: 'Modal.MobileMenu' })
  const { setMobileMenuState } = useManageMobileMenuState()

  const handleLanguageChange = (lang: 'ko' | 'en') => {
    changeLanguage(lang)
    window.sessionStorage.setItem('neuronSwapLanguage', lang)
  }

  const handlecloseMobileMenu = () => setMobileMenuState(false)

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead>
          <img src="/dexAssets/neuron_logo.png" alt="Neuron Logo" />
          <img
            src="/dexAssets/modal_icons/modal_close_icon.png"
            alt="Modal Close Icon"
            onClick={handlecloseMobileMenu}
          />
        </ModalHead>

        <WalletSection>
          <MobileMenuConnectedWallet />
          <MobileMenuChangeWalletStatus />
        </WalletSection>

        <LanguageMenu>
          <div>{t('language')}</div>

          <LanguageSelector>
            <LanguageSelectorItem
              isSelected={String(language === 'ko')}
              onClick={() => handleLanguageChange('ko')}
            >
              한국어
            </LanguageSelectorItem>
            <LanguageSelectorItem
              isSelected={String(language === 'en')}
              onClick={() => handleLanguageChange('en')}
            >
              English
            </LanguageSelectorItem>
          </LanguageSelector>
        </LanguageMenu>

        <MobileMenuList />
      </ModalWrapper>
    </ModalPortal>
  )
}

export default LaunchPadMobileMenu

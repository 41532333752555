import styled from 'styled-components'
import DepositPoolInfo from '../organisms/DepositPoolInfo'
import MobileDepositPoolInfo from '../organisms/MobileDepositPoolInfo'
import DepositPoolBalance from '../organisms/DepositPoolBalance'
import DepositMain from '../organisms/DepositMain'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

type DepositTemplateProps = {
  pairAddress: string
}

const DepositTemplateWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 800px;
  width: 100%;
  margin: 80px auto 59px;

  @media (max-width: 768px) {
    margin: 0 auto 56px;
  }
`

const DepositTemplate = ({ pairAddress }: DepositTemplateProps) => {
  const { width } = useGetWindowSize()

  return (
    <DepositTemplateWrapper>
      {width > 768 ? (
        <DepositPoolInfo pairAddress={pairAddress} />
      ) : (
        <MobileDepositPoolInfo pairAddress={pairAddress} />
      )}

      <DepositPoolBalance pairAddress={pairAddress} />
      <DepositMain pairAddress={pairAddress} />
    </DepositTemplateWrapper>
  )
}

export default DepositTemplate

import { useState, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import PoolItemToken from '../atoms/PoolItemToken'
import DepositedItemClaimableToken from '../atoms/DepositedItemClaimableToken'
import PoolItemEstimatedRewards from '../atoms/PoolItemEstimatedRewards'
import MobilePoolItemEstimatedRewards from '../atoms/MobilePoolItemEstimatedRewards'
import MobilePopup from './MobilePopup'
import { useManageReceiveRewardsModalState } from '../../../state/modal/receiveRewardsModalState'
import multipleByDecimal from '../../../utils/multipleByDecimal'
import convertNumber from '../../../utils/convertNumber'
import { getConvertFromPebValue } from '../../../utils/convertTokenNumberData'
import { UserDepositedPoolItemType } from '../../../api/query/useUserDepositedPoolsQuery'

type DepositedItemProps = UserDepositedPoolItemType

const DepositedItemBackground = styled.div<{ isHot: string }>`
  @keyframes rotate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  padding: 1px;
  border-radius: 25px;
  background: ${({ isHot }) =>
    isHot === 'true'
      ? 'linear-gradient(to right, #ff6d68, #815cff 50%, #65dfb9)'
      : '#ffffff'};
  background-size: 200% 200%;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  animation: rotate 2s ease infinite;

  & + & {
    margin-top: 20px;
  }
`

const DepositedItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.05fr 0.7fr 0.6fr 0.55fr 0.7fr 1.05fr;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  border-radius: 24px;
  background: #ffffff;

  & + & {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: unset;
    padding: 24px 16px;
  }
`

const MobileDepositableListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`

const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #9d9999;

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
`

const Price = styled.div<{
  textAlign?: 'left' | 'right'
  color?: string
}>`
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: 768px) {
    font-size: 14px;
    color: ${({ color }) => color ?? '#656565'};
    text-align: right;
  }
`

const DepositButtonBox = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin: 27px -8px -16px -8px;
  }
`

const ReceiveRewardButton = styled.button`
  display: grid;
  place-items: center;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  background: #65dfb9;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: 54px;
    border-radius: 16px;
  }
`

const ManageButton = styled(Link)<{
  language: string
}>`
  display: grid;
  place-items: center;
  width: ${({ language }) => (language === 'en' ? '100' : '80')}px;
  height: 40px;
  margin-left: 10px;
  border-radius: 20px;
  border: 0;
  background: #ff6d68;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: 54px;
    margin: 0;
    border-radius: 16px;
  }
`

const MobilePopupItem = styled.div`
  padding: 32px 0;
  color: #ffffff;
  text-align: center;

  & > div:nth-child(1) {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.5;
  }

  & + & {
    border-top: 1px solid #ffffff;
  }
`

const DepositedItem = ({
  address,
  pid,
  depositRate,
  userData: { stakedLpBalance, claimableNeuron, claimableNeuronPrice },
  rewardsDetail,
  tokens,
}: DepositedItemProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Pool.Deposited',
  })
  const { t: tooltipTranslation } = useTranslation('translation', {
    keyPrefix: 'Pool.Tooltip',
  })
  const { setReceiveRewardsModalState } = useManageReceiveRewardsModalState()
  const { width } = useGetWindowSize()
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowPopup = () => setIsVisiblePopup(true)
  const handleClosePopup = () => setIsVisiblePopup(false)

  const myDeposit = useMemo(() => {
    const convertedStakedLp = parseFloat(
      getConvertFromPebValue(stakedLpBalance, 18),
    )

    const token0 =
      multipleByDecimal(depositRate[0] * convertedStakedLp, tokens[0].decimal) *
      tokens[0].price
    const token1 =
      multipleByDecimal(depositRate[1] * convertedStakedLp, tokens[1].decimal) *
      tokens[1].price

    return convertNumber(token0 + token1, { maxDigit: 0, comma: true })
  }, [depositRate, tokens, stakedLpBalance])

  const handleReceiveReward = () =>
    setReceiveRewardsModalState({ visible: true, pairAddress: address, pid })

  if (width <= 768)
    return (
      <DepositedItemBackground isHot={String(rewardsDetail.apr >= 1000)}>
        <DepositedItemWrapper>
          <PoolItemToken tokens={tokens} />

          <MobileDepositableListItem>
            <MobileTitle>
              {t('mobileHead.0')}
              <img
                onClick={handleShowPopup}
                src="/dexAssets/tooltip_icon.png"
                alt="Mobile Popup Icon"
              />
            </MobileTitle>

            <MobilePoolItemEstimatedRewards
              rewardsDetail={rewardsDetail}
              fontSize="22"
              enableDetail={false}
            />
          </MobileDepositableListItem>

          <MobileDepositableListItem style={{ marginTop: '13px' }}>
            <MobileTitle>{t('mobileHead.1')}</MobileTitle>

            <Price color="#9d9999">
              {convertNumber(rewardsDetail.apy, { isPercentage: true })}%
            </Price>
          </MobileDepositableListItem>

          <MobileDepositableListItem style={{ marginTop: '31px' }}>
            <MobileTitle>{t('mobileHead.2')}</MobileTitle>

            <Price>{`$ ${myDeposit}`}</Price>
          </MobileDepositableListItem>

          <MobileDepositableListItem>
            <MobileTitle>{t('mobileHead.3')}</MobileTitle>

            <Price>
              {`$ ${convertNumber(claimableNeuronPrice, {
                maxDigit: 0,
                comma: true,
              })}`}
            </Price>
          </MobileDepositableListItem>

          <MobileDepositableListItem>
            <MobileTitle>{t('mobileHead.4')}</MobileTitle>

            <DepositedItemClaimableToken claimableNeuron={claimableNeuron} />
          </MobileDepositableListItem>

          <DepositButtonBox>
            <ReceiveRewardButton onClick={handleReceiveReward}>
              {t('button.0')}
            </ReceiveRewardButton>
            <ManageButton to={`/pool/detail/${address}`} language={language}>
              {t('button.1')}
            </ManageButton>
          </DepositButtonBox>
        </DepositedItemWrapper>

        <MobilePopup
          type="normal"
          isVisible={isVisiblePopup}
          handleClose={handleClosePopup}
        >
          <MobilePopupItem>
            <div>{tooltipTranslation('0.title')}</div>
            <div>{tooltipTranslation('0.popupContents')}</div>
          </MobilePopupItem>
          <MobilePopupItem>
            <div>{tooltipTranslation('1.title')}</div>
            <div>{tooltipTranslation('1.popupContents')}</div>
          </MobilePopupItem>
        </MobilePopup>
      </DepositedItemBackground>
    )

  return (
    <DepositedItemBackground isHot={String(rewardsDetail.apr >= 1000)}>
      <DepositedItemWrapper>
        <PoolItemToken tokens={tokens} />

        <Price textAlign="right" style={{ paddingRight: '40px' }}>
          {`$ ${myDeposit}`}
        </Price>

        <DepositedItemClaimableToken claimableNeuron={claimableNeuron} />

        <Price textAlign="left">
          {`$ ${convertNumber(claimableNeuronPrice, {
            maxDigit: 0,
            comma: true,
          })}`}
        </Price>

        <PoolItemEstimatedRewards
          apr={rewardsDetail.apr}
          apy={rewardsDetail.apy}
        />

        <DepositButtonBox>
          <ReceiveRewardButton onClick={handleReceiveReward}>
            {t('button.0')}
          </ReceiveRewardButton>
          <ManageButton to={`/pool/detail/${address}`} language={language}>
            {t('button.1')}
          </ManageButton>
        </DepositButtonBox>
      </DepositedItemWrapper>
    </DepositedItemBackground>
  )
}

export default DepositedItem

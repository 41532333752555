import { prepare, request } from 'klip-sdk'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { useManageStakingFormState } from '../state/staking/stakingFormState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import checkMobileDevice from '../utils/checkMobileDevice'
import { getConvertToPebValue } from '../utils/convertTokenNumberData'
import { CONTRACT_GAS_LIMIT } from '../utils/constants'
import { STAKING_ABI } from '../contract/abi'
import { CONTRACT_ADDRESS } from '../contract/address'

export default function useNeuronStaking() {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const {
    stakingFormState: { value, term },
  } = useManageStakingFormState()

  const klipTokenNeuronStakingSuccessCallback = (
    result: any,
    successCallback: () => void | Promise<void>,
  ) => {
    if (result.status === 'success') successCallback()
  }

  const klipNeuronStaking = async (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.gnr,
      value: '0',
      abi: JSON.stringify(STAKING_ABI),
      params: JSON.stringify([address, getConvertToPebValue(value, 18), term]),
    })

    if (err) {
      failureCallback()
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipTokenNeuronStakingSuccessCallback(result, successCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasNeuronStaking = (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const data = caver.klay.abi.encodeFunctionCall(STAKING_ABI, [
      address,
      getConvertToPebValue(value, 18),
      term,
    ])

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.gnr,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', successCallback)
  }

  const bitkeepNeuronStaking = (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    const data = web3.eth.abi.encodeFunctionCall(STAKING_ABI, [
      address,
      getConvertToPebValue(value, 18),
      term,
    ] as string[])

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.gnr,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', failureCallback)
      .on('receipt', () => {
        successCallback()
      })
  }

  const handleNeuronStaking = async (
    successCallback: () => Promise<void>,
    failureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipNeuronStaking(successCallback, failureCallback)
    else if (type === 'kaikas')
      kaikasNeuronStaking(successCallback, failureCallback)
    else if (type === 'bitkeep')
      bitkeepNeuronStaking(successCallback, failureCallback)
  }

  return handleNeuronStaking
}

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type DepositRequestModalStateType = {
  visible: boolean
  pairAddress: string | undefined
}

const depositRequestModalAtom = atom<DepositRequestModalStateType>({
  key: 'depositRequestModal',
  default: {
    visible: false,
    pairAddress: undefined,
  },
})

export const useManageDepositRequestModalState = (): {
  depositRequestModalState: DepositRequestModalStateType
  setDepositRequestModalState: SetterOrUpdater<DepositRequestModalStateType>
  resetDepositRequestModalState: Resetter
} => {
  const [depositRequestModalState, setDepositRequestModalState] =
    useRecoilState(depositRequestModalAtom)
  const resetDepositRequestModalState = useResetRecoilState(
    depositRequestModalAtom,
  )

  return {
    depositRequestModalState,
    setDepositRequestModalState,
    resetDepositRequestModalState,
  }
}

import { ReactNode, useEffect, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CSS_Z_INDEX } from '../../../utils/constants'

type MobilePopupProps = {
  type: 'normal' | 'setting'
  isVisible: boolean
  handleApply?: () => void
  handleClose: (event: MouseEvent<HTMLDivElement>) => void
  children: ReactNode
}

const MobilePopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${CSS_Z_INDEX.popup};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgba(0, 0, 0, 0.8);
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`

const Button = styled.div`
  display: grid;
  place-items: center;
  width: 80px;
  height: 32px;
  border: 0.5px solid #ffffff;
  border-radius: 16px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;

  & + & {
    margin-left: 16px;
  }
`

const MobilePopup = ({
  type,
  isVisible,
  handleApply,
  handleClose,
  children,
}: MobilePopupProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.MobilePopup',
  })

  useEffect(() => {
    if (!isVisible) return

    document.body.style.cssText = `overflow: hidden; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top
      document.body.style.cssText = `overflow: unset; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [isVisible])

  if (!isVisible) return null

  return (
    <MobilePopupWrapper>
      {children}

      <ButtonWrapper>
        {type === 'normal' ? (
          <Button onClick={handleClose}>{t('close')}</Button>
        ) : (
          <>
            <Button onClick={handleApply}>{t('apply')}</Button>
            <Button onClick={handleClose}>{t('cancel')}</Button>
          </>
        )}
      </ButtonWrapper>
    </MobilePopupWrapper>
  )
}

export default MobilePopup

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type UnstakingRequestModalStateType = {
  visible: boolean
  pairAddress: string | undefined
  pid: number | undefined
}

const unstakingRequestModalAtom = atom<UnstakingRequestModalStateType>({
  key: 'unstakingRequestModal',
  default: {
    visible: false,
    pairAddress: undefined,
    pid: undefined,
  },
})

export const useManageUnstakingRequestModalState = (): {
  unstakingRequestModalState: UnstakingRequestModalStateType
  setUnstakingRequestModalState: SetterOrUpdater<UnstakingRequestModalStateType>
  resetUnstakingRequestModalState: Resetter
} => {
  const [unstakingRequestModalState, setUnstakingRequestModalState] =
    useRecoilState(unstakingRequestModalAtom)
  const resetUnstakingRequestModalState = useResetRecoilState(
    unstakingRequestModalAtom,
  )

  return {
    unstakingRequestModalState,
    setUnstakingRequestModalState,
    resetUnstakingRequestModalState,
  }
}

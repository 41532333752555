import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StakingListType } from '../../api/query/useStakingListQuery'
import useRefreshGnrBalanceMutation from '../../api/mutation/useRefreshGnrBalanceMutation'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useNeuronUnstaking from '../../hooks/useNeuronUnstaking'
import { useManageUnstakingModalState } from '../../state/modal/unstakingModalState'
import { useManageConnectedWalletState } from '../../state/shared/connectedWalletState'
import convertNumber from '../../utils/convertNumber'
import { NR_SYMBOL } from '../../utils/constants'
import { getConvertFromPebValue } from '../../utils/convertTokenNumberData'
import { TOKEN_ADDRESS } from '../../contract/address'
import { gnrContract } from '../../contract/contract'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 520px;
    padding: 32px 24px 24px 24px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

const UnstakingType = styled.div<{ isExistPanelty: boolean }>`
  margin-left: 3px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isExistPanelty }) => (isExistPanelty ? '#ff6d68' : '#65dfb9')};
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    margin-top: 16px;
    padding: 18px 0;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }
`

const TransactionDetailInfo = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const TransactionDetailInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #9d9999;

  & > div:nth-child(2) {
    display: flex;
  }

  span {
    margin-left: 6px;
  }

  & + & {
    margin-top: 13px;
  }

  @media (max-width: 768px) {
    & + & {
      margin-top: 16px;
    }

    & > div:nth-child(1) {
      white-space: pre-line;
    }

    &:nth-child(1) > div:nth-child(2) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

const Description = styled.div`
  margin-top: 30px;
  font-size: 14px;
  color: #9d9999;
  letter-spacing: -0.14px;
  line-height: 1.57;
`

const TransactionButton = styled.button`
  width: 100%;
  margin-top: auto;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const NeuronUnstakingModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.NeuronUnstakingModal',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const {
    unstakingModalState: { isPenalty, term, id },
    setUnstakingModalState,
    resetUnstakingModalState,
  } = useManageUnstakingModalState()
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<StakingListType['data']>('stakingList')
  const handleNeuronUnstaking = useNeuronUnstaking()
  const refreshGnrBalanceMutation = useRefreshGnrBalanceMutation()
  const pushMessage = useCallMessageBox()
  const [{ estimatedPenalty, estimatedClaimableNR }, setPenaltyInfo] =
    useState<{
      estimatedPenalty: string
      estimatedClaimableNR: string
    }>({
      estimatedPenalty: '',
      estimatedClaimableNR: '',
    })

  const unstakingSuccessCallback = async () => {
    resetUnstakingModalState()
    pushMessage('request', 'success')

    await refreshGnrBalanceMutation.mutateAsync()
    queryClient.invalidateQueries(['token', TOKEN_ADDRESS[NR_SYMBOL] as string])
    queryClient.invalidateQueries('totalStakedNeuron')
    queryClient.invalidateQueries('stakingList')
    queryClient.invalidateQueries('stakingInfo')
  }

  const unstakingFailureCallback = () => pushMessage('request', 'failure')

  const handleNeuronUnstakingTransaction = () =>
    handleNeuronUnstaking(unstakingSuccessCallback, unstakingFailureCallback)

  const loadPenaltyInfo = async () => {
    if (isPenalty) {
      if (term === undefined || id === undefined) return null

      const selectedStakingItem = data?.stakingList[term].find(
        stakingItem => stakingItem.id === id,
      )

      if (selectedStakingItem && Date.now() < selectedStakingItem.endDate) {
        const { penaltyAmount, burnedAmount, startIdx } =
          await gnrContract.methods.unLockPenaltyAmount(address, term).call()

        const estimatedPenalty = getConvertFromPebValue(
          burnedAmount as string,
          18,
        )
        const estimatedClaimableNR = getConvertFromPebValue(
          penaltyAmount as string,
          18,
        )

        setPenaltyInfo({
          estimatedPenalty: estimatedPenalty,
          estimatedClaimableNR: estimatedClaimableNR,
        })
        setUnstakingModalState(prev => ({ ...prev, stakingIndex: startIdx }))
      } else {
        setPenaltyInfo({
          estimatedPenalty: '0',
          estimatedClaimableNR: estimatedClaimableNR,
        })
      }
    } else {
      setPenaltyInfo({
        estimatedPenalty: '0',
        estimatedClaimableNR: data?.unlockableAmount.toString() ?? '0',
      })
    }
  }

  useEffect(() => {
    loadPenaltyInfo()
  }, [isPenalty])

  if (isPenalty && (term === undefined || id === undefined)) return null

  const selectedStakingItem = useMemo(
    () =>
      term !== undefined && id !== undefined
        ? data?.stakingList[term].find(stakingItem => stakingItem.id === id)
        : null,
    [data, term, id],
  )

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={() => resetUnstakingModalState()}>
          {t('title')}
        </ModalHead>

        <Title>
          {t('subTitle')}
          <UnstakingType isExistPanelty={isPenalty}>
            ({t(`type.${isPenalty ? 0 : 1}`)})
          </UnstakingType>
        </Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div>{t('stakingBalance')}</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(
                  isPenalty
                    ? selectedStakingItem?.amount ?? '0'
                    : data?.unlockableAmount ?? '0',
                  {
                    comma: true,
                    maxDigit: 6,
                  },
                )}
              </SlicedDecimal>
              <span>NR</span>
            </div>
          </TransactionInfoItem>
        </TransactionInfo>

        <TransactionDetailInfo>
          <TransactionDetailInfoItem>
            <div>{t('detail.0')}</div>
            <div style={{ color: '#ff6d68' }}>
              <SlicedDecimal maxIntLength={20} showTooltip={false}>
                {convertNumber(estimatedPenalty, { comma: true, maxDigit: 6 })}
              </SlicedDecimal>
              <span>NR</span>
            </div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t('detail.1')}</div>
            <div>
              <SlicedDecimal maxIntLength={20} showTooltip={false}>
                {convertNumber(estimatedClaimableNR, {
                  comma: true,
                  maxDigit: 6,
                })}
              </SlicedDecimal>
              <span>NR</span>
            </div>
          </TransactionDetailInfoItem>
        </TransactionDetailInfo>

        <Description>{t('description')}</Description>

        <TransactionButton onClick={handleNeuronUnstakingTransaction}>
          {t('button')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default NeuronUnstakingModal

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import NoticeTooltip from '../atoms/NoticeTooltip'

const ICON_CUSTOM_STYLE = {
  width: '12px',
  height: '12px',
  marginLeft: '5px',
}

type PoolListHeadProps = {
  type: 'AssetMigration' | 'Deposited' | 'AvailableDeposit'
}

const PoolListHeadWrapper = styled.div<{
  type: PoolListHeadProps['type']
  language: string
}>`
  display: grid;
  grid-template-columns: ${({ type, language }) =>
    type === 'AssetMigration'
      ? '1.05fr 0.7fr 0.65fr 0fr 1.2fr 1fr'
      : `1.05fr 0.7fr 0.6fr ${
          language === 'ko' ? '0.55fr 0.7fr 1.05fr' : '0.6fr 1.15fr 0.55fr'
        }`};
  align-items: center;
  height: 34px;
  margin-bottom: 23px;
  padding: 0 30px;
  border-radius: 17px;
  background: #e8e8e8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  font-size: 12px;
  color: #9d9999;

  @media (max-width: 768px) {
    display: none;
  }
`

const HeadItem = styled.div<{ align: 'right' | 'left' | 'center' }>`
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : `flex-${align === 'left' ? 'start' : 'end'}`};
  align-items: center;
`

const TooltipContent = styled.div<{ language: string }>`
  font-size: 14px;
  color: #ffffff;
  line-height: 1.43;
  text-align: ${({ language }) => (language === 'ko' ? 'left' : 'center')};

  & + & {
    margin-top: 24px;
  }
`

const TooltipContentTitle = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
`

const TooltipContentDesc = styled.div<{ language: string }>`
  font-size: ${({ language }) => (language === 'ko' ? '14' : '12')}px;
  white-space: pre-line;
`

const PoolListHead = ({ type }: PoolListHeadProps) => {
  const {
    t: PoolListHeadTranslation,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: `Pool.${type}.head`,
  })
  const { t: PoolTooltipTranslation } = useTranslation('translation', {
    keyPrefix: 'Pool.Tooltip',
  })

  return (
    <PoolListHeadWrapper type={type} language={language}>
      <HeadItem align="left">{PoolListHeadTranslation('0')}</HeadItem>
      <HeadItem align="right" style={{ paddingRight: '40px' }}>
        {PoolListHeadTranslation('1')}
      </HeadItem>
      <HeadItem align="left">{PoolListHeadTranslation('2')}</HeadItem>
      <HeadItem align="left">{PoolListHeadTranslation('3')}</HeadItem>
      <HeadItem align="right">
        {PoolListHeadTranslation('4')}
        <NoticeTooltip
          iconCustomStyle={ICON_CUSTOM_STYLE}
          widgetCustomStyle={{
            width: '248px',
            padding: language === 'ko' ? '29px 21px' : '29px 0',
          }}
        >
          <TooltipContent language={language}>
            <TooltipContentTitle>
              {PoolTooltipTranslation('0.title')}
            </TooltipContentTitle>
            <TooltipContentDesc language={language}>
              {PoolTooltipTranslation('0.contents')}
            </TooltipContentDesc>
          </TooltipContent>
          <TooltipContent language={language}>
            <TooltipContentTitle>
              {PoolTooltipTranslation('1.title')}
            </TooltipContentTitle>
            <TooltipContentDesc language={language}>
              {PoolTooltipTranslation('1.contents')}
            </TooltipContentDesc>
          </TooltipContent>
        </NoticeTooltip>
      </HeadItem>
    </PoolListHeadWrapper>
  )
}

export default PoolListHead

import { useQuery } from 'react-query'
import AxiosClient from '../axiosConfig'
import { TokenType } from './useTokenQuery'

export type UserDepositedPoolItemType = {
  address: string
  pid: number
  depositRate: [number, number]
  totalLP: number
  totalLiquidity: number
  rewardsDetail: {
    distributedNRPerDay: number
    nrAirdrop: number
    tradeFeeApr: number
    apr: number
    apy: number
  }
  userData: {
    depositedLpBalance: number
    stakedLpBalance: number
    claimableNeuron: number
    claimableNeuronPrice: number
    sharedOf: number
  }
  tokens: TokenType[]
}

export type UserDepositedPoolsType = {
  data: {
    pools: UserDepositedPoolItemType[]
  }
}

const loadUserDepositedPools = async () =>
  await AxiosClient.getAxiosInstance(true, false).get(`/api/pools/deposited`)

export default function useUserDepositedPoolsQuery(address?: string) {
  return useQuery<UserDepositedPoolsType['data']>(
    'userDepositedPools',
    async () => {
      const {
        data: { data },
      } = await loadUserDepositedPools()

      return data
    },
    {
      enabled: address !== undefined && AxiosClient.isInitialized(),
      retry: false,
      staleTime: 1000 * 30,
      refetchOnWindowFocus: true,
    },
  )
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import StakingGnrDetailItem from '../molecules/StakingGnrDetailItem'
import StakingExpectedReturnTooltipContent from '../molecules/StakingExpectedReturnTooltipContent'
import useStakingInfoQuery from '../../../api/query/useStakingInfoQuery'

const StakingHoldingQuantityWrapper = styled.div`
  padding: 27px 40px 32px;
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  background: #ffffff;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  margin-bottom: 27px;

  div {
    width: 6px;
    height: 6px;
    margin: 0 8px;
    border-radius: 3px;
    background: #9d9999;
  }
`

const HoldingQuantity = styled.div`
  display: flex;
  flex-direction: column;
  height: 152px;
  padding: 10px 20px;
  border-radius: 16px;
  background-color: #f8f8f8;
  font-size: 24px;
  font-weight: 700;
  color: #000000;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 19px 0;
  }

  span {
    margin-left: 6px;
    color: #ff6d68;
  }

  div + div {
    border-top: 1px solid #ececec;
  }
`

const StakingDetail = styled.div`
  margin-top: 18px;
  font-size: 16px;
  color: #9d9999;
  line-height: 1.6;
`

const APR = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #ff6d68;

  span {
    font-weight: 400;
  }
`

const APY = styled.div`
  margin-left: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #9d9999;

  span {
    font-weight: 700;
  }
`

const StakingHoldingQuantity = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingHoldingQuantity',
  })
  const { data } = useStakingInfoQuery()

  return (
    <StakingHoldingQuantityWrapper>
      <Title>
        {t('title.0')}
        <div />
        {t('title.1')}
      </Title>

      <HoldingQuantity>
        <div>
          {convertNumber(data?.stakingAmount ?? 0, {
            comma: true,
            maxDigit: 6,
          })}{' '}
          <span>NR</span>
        </div>
        <div>
          {convertNumber(data?.gnrAmount ?? 0, { comma: true, maxDigit: 6 })}{' '}
          gNR
        </div>
      </HoldingQuantity>

      <StakingDetail>
        <StakingGnrDetailItem
          title={
            <div style={{ letterSpacing: '-0.15px' }}>{t('gnrShare')}</div>
          }
          tooltip={t('gnrShareTooltip')}
        >
          {convertNumber(data?.sharedOf ?? 0, { isPercentage: true })} %
        </StakingGnrDetailItem>
        <StakingGnrDetailItem
          title={t('expectedReturns')}
          tooltip={<StakingExpectedReturnTooltipContent />}
          tooltipCustomStyle={{
            padding: `17px ${language === 'ko' ? '20' : '16'}px`,
          }}
          highlight
        >
          <APR>
            <span>APR </span>
            {convertNumber(data?.rewardsDetail.apr ?? 0, {
              isPercentage: true,
            })}{' '}
            %
          </APR>
          <APY>
            ( APY{' '}
            <span>
              {convertNumber(data?.rewardsDetail.apy ?? 0, {
                isPercentage: true,
              })}{' '}
              %
            </span>{' '}
            )
          </APY>
        </StakingGnrDetailItem>
      </StakingDetail>
    </StakingHoldingQuantityWrapper>
  )
}

export default StakingHoldingQuantity

import styled from 'styled-components'
import FooterMenu from '../molecules/FooterMenu'

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: auto;
  background: #cca28f;
`

const FooterContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const Logo = styled.img`
  width: 164px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 106px;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContents>
        <Logo
          src="/dexAssets/footer_icons/footer_logo.png"
          alt="Footer Logo"
          onClick={() => window.scrollTo(0, 0)}
        />

        <FooterMenu />
      </FooterContents>
    </FooterWrapper>
  )
}

export default Footer

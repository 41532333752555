import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { StakingListType } from '../../../api/query/useStakingListQuery'
import { useManageUnstakingModalState } from '../../../state/modal/unstakingModalState'
import convertNumber from '../../../utils/convertNumber'

const NeuronUnstakingWrapper = styled.div`
  margin-top: 80px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
`

const UnstakingForm = styled.div`
  display: grid;
  grid-template-columns: 0.22fr 0.17fr 0.61fr;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
`

const UnstakingText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #65dfb9;
`

const UnstakingAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: right;
`

const UnstakeButton = styled.button<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 142px;
  height: 40px;
  margin-left: auto;
  border: 0;
  border-radius: 20px;
  background: ${({ $isActive }) => ($isActive ? '#2e2e2e' : '#9d9999')};
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

export default function NeuronUnstaking() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.NeuronUnstaking',
  })
  const queryClient = useQueryClient()
  const stakingListData =
    queryClient.getQueryData<StakingListType['data']>('stakingList')
  const { setUnstakingModalState } = useManageUnstakingModalState()

  const handleUnstaking = () => {
    if (stakingListData !== undefined && stakingListData.unlockableAmount === 0)
      return

    setUnstakingModalState(prev => ({
      ...prev,
      visible: true,
      isPenalty: false,
    }))
  }

  return (
    <NeuronUnstakingWrapper>
      <Title>{t('title')}</Title>

      <UnstakingForm>
        <UnstakingText>{t('unstakeableQuantity')}</UnstakingText>
        <UnstakingAmount>
          {convertNumber(stakingListData?.unlockableAmount ?? 0, {
            comma: true,
            maxDigit: 6,
          })}{' '}
          NR
        </UnstakingAmount>
        <UnstakeButton
          $isActive={
            stakingListData !== undefined &&
            stakingListData?.unlockableAmount !== 0
          }
          onClick={handleUnstaking}
        >
          {t('unstaking')}
        </UnstakeButton>
      </UnstakingForm>
    </NeuronUnstakingWrapper>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import Bouncy from '../atoms/Bouncy'
import { MainFeatureCard } from '../molecules/MainFeatureCard'

const Title = styled.img`
  width: 500px;
  height: 150px;
  margin-top: 200px;
  margin-bottom: 175px;

  @media (max-width: 768px) {
    width: 276px;
    height: unset;
    margin: 100px 0 70px;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
`

const PaybackVisualWrapper = styled.div`
  position: relative;
  height: 373px;
  width: 531px;
  div {
    position: absolute;
  }
  .wallet {
    top: 0;
    left: 0;
  }
  .payback {
    top: 28px;
    left: 150px;
  }

  @media (max-width: 768px) {
    width: 231px;
    height: 187px;
    margin: 40px 0 20px;

    img {
      width: 100%;
    }

    .wallet {
      width: 99.1px;
      height: 93.9px;
      left: 15px;
    }

    .payback {
      width: 170px;
      left: 60px;
    }
  }
`

const MigrationVisualWrapper = styled.div`
  position: relative;
  height: 286px;
  width: 405px;
  div {
    position: absolute;
  }
  .hand {
    top: 0;
    left: 0;
  }
  .truck {
    bottom: 0;
    right: 0;
  }
  .sparkle-left {
    bottom: 21px;
    left: 184px;
  }
  .sparkle-right {
    top: 135px;
    right: 15px;
  }

  @media (max-width: 768px) {
    width: 249px;
    height: 176px;
    margin: 40px 0 20px;

    img {
      width: 100%;
    }

    .hand {
      width: 200px;
    }

    .truck {
      width: 120px;
    }

    .sparkle-left {
      width: 15px;
      bottom: 21px;
      left: 105px;
    }

    .sparkle-right {
      width: 15px;
      top: 80px;
      right: 10px;
    }
  }
`

const LeftBg = styled.img`
  width: 764px;
  position: absolute;
  top: 0;
  height: 764px;
  left: -382px;

  @media (max-width: 768px) {
    display: none;
  }
`

const RightBg = styled.img`
  width: 985px;
  position: absolute;
  top: 829px;
  height: 985px;
  right: calc(556px - 985px);

  @media (max-width: 768px) {
    display: none;
  }
`

const MainSpecialValueSection = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Main.SpecialValueSection',
  })
  const { width } = useGetWindowSize()

  const PaybackVisual = () => (
    <PaybackVisualWrapper>
      <Bouncy className="wallet" duration={1.75} variation={4} delay={0.5}>
        <img srcSet="/dexAssets/main_page/special-value/wallet.png 1x, /dexAssets/main_page/special-value/wallet@2x.png 2x, /dexAssets/main_page/special-value/wallet@3x.png 3x" />
      </Bouncy>
      <Bouncy className="payback" duration={1.75} variation={6}>
        <img srcSet="/dexAssets/main_page/special-value/payback.png 1x, /dexAssets/main_page/special-value/payback@2x.png 2x, /dexAssets/main_page/special-value/payback@3x.png 3x" />
      </Bouncy>
    </PaybackVisualWrapper>
  )

  const MigrationVisual = () => (
    <MigrationVisualWrapper>
      <Bouncy className="hand" duration={1.5} variation={4} delay={0}>
        <img srcSet="/dexAssets/main_page/special-value/hand.png 1x, /dexAssets/main_page/special-value/hand@2x.png 2x, /dexAssets/main_page/special-value/hand@3x.png 3x" />
      </Bouncy>
      <Bouncy className="truck" duration={1.5} variation={4} delay={0.25}>
        <img srcSet="/dexAssets/main_page/special-value/truck.png 1x, /dexAssets/main_page/special-value/truck@2x.png 2x, /dexAssets/main_page/special-value/truck@3x.png 3x" />
      </Bouncy>
      <Bouncy
        className="sparkle-left"
        duration={1.5}
        variation={4}
        delay={0.5}
        opacity={[0, 1]}
      >
        <img srcSet="/dexAssets/main_page/special-value/sparkle-left.png 1x, /dexAssets/main_page/special-value/sparkle-left@2x.png 2x, /dexAssets/main_page/special-value/sparkle-left@3x.png 3x" />
      </Bouncy>
      <Bouncy
        className="sparkle-right"
        duration={2}
        variation={4}
        opacity={[0, 1]}
      >
        <img srcSet="/dexAssets/main_page/special-value/sparkle-right.png 1x, /dexAssets/main_page/special-value/sparkle-right@2x.png 2x, /dexAssets/main_page/special-value/sparkle-right@3x.png 3x" />
      </Bouncy>
    </MigrationVisualWrapper>
  )

  return (
    <Stack>
      <LeftBg src="/dexAssets/main_page/special-value/left-bg.webp" />
      <RightBg src="/dexAssets/main_page/special-value/right-bg.webp" />
      <Title src="/dexAssets/main_page/special-value/title.svg" />
      <MainFeatureCard
        section="specialValue"
        heading={t('payback.heading')}
        subHeading={t('payback.sub-heading')}
        desc={t(width > 768 ? 'payback.desc' : 'payback.mobileDesc')}
        visual={<PaybackVisual />}
        spacing="84px"
        flexDirection="row-reverse"
        stackWidth={440}
      />
      <div style={{ height: width > 768 ? 219 : 100 }} />
      <MainFeatureCard
        section="specialValue"
        heading={t('migration.heading')}
        subHeading={t('migration.sub-heading')}
        desc={t(width > 768 ? 'migration.desc' : 'migration.mobileDesc')}
        visual={<MigrationVisual />}
        stackWidth={490}
      />
      <div style={{ height: width > 768 ? 250 : 97 }} />
    </Stack>
  )
}
export default MainSpecialValueSection

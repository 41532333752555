import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import { PoolType } from '../../../api/query/usePoolQuery'

type DepositPoolInfoDetailProps = {
  totalLiquidity: number
  rewardsDetail: PoolType['data']['rewardsDetail']
}

const DepositPoolInfoDetailWrapper = styled.div`
  display: flex;
  margin-top: 6px;
`

const DetailItem = styled.div`
  font-size: 10px;
  font-weight: 300;
  color: #9d9999;

  span {
    margin-left: 4px;
    font-weight: 700;
  }

  & + & {
    margin-left: 8px;
  }
`

const DividingLine = styled.div`
  width: 1px;
  margin: 0 8px;
  background: #d1cbcb;
`

const DepositPoolInfoDetail = ({
  totalLiquidity,
  rewardsDetail: { distributedNRPerDay, nrAirdrop, tradeFeeApr },
}: DepositPoolInfoDetailProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositPoolInfo.DepositPoolInfoDetail',
  })

  return (
    <DepositPoolInfoDetailWrapper>
      <DetailItem>
        {t('info.0')}
        <span>
          $ {convertNumber(totalLiquidity, { maxDigit: 0, comma: true })}
        </span>
      </DetailItem>
      <DetailItem>
        {t('info.1')}
        <span>
          {convertNumber(distributedNRPerDay, { maxDigit: 0, comma: true })} NR
        </span>
      </DetailItem>

      <DividingLine />

      {nrAirdrop !== 0 ? (
        <DetailItem>
          {t('reward.0')}
          <span>{convertNumber(nrAirdrop, { isPercentage: true })}%</span>
        </DetailItem>
      ) : null}

      <DetailItem>
        {t('reward.1')}
        <span>{convertNumber(tradeFeeApr, { isPercentage: true })}%</span>
      </DetailItem>
    </DepositPoolInfoDetailWrapper>
  )
}

export default DepositPoolInfoDetail

import { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { CSS_Z_INDEX } from '../../../utils/constants'

type CommonTooltipProps = {
  isEnable?: boolean
  displayComponent: ReactNode
  widgetCustomStyle?: CSSProperties
  children: ReactNode
}

const CommonTooltipWidget = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translate(-50%);
  z-index: ${CSS_Z_INDEX.tooltip};
  padding: 29px 21px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  color: #ffffff;
  line-height: 1.43;
  white-space: pre-line;
  text-align: left;

  &::after {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
  }
`

const CommonTooltipWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  &:hover ${CommonTooltipWidget} {
    display: block;
  }
`

const CommonTooltip = ({
  isEnable = true,
  displayComponent,
  widgetCustomStyle,
  children,
}: CommonTooltipProps) => {
  return (
    <CommonTooltipWrapper>
      {children}

      {isEnable ? (
        <CommonTooltipWidget style={widgetCustomStyle}>
          {displayComponent}
        </CommonTooltipWidget>
      ) : null}
    </CommonTooltipWrapper>
  )
}

export default CommonTooltip

import {
  atom,
  useRecoilState,
  useResetRecoilState,
  SetterOrUpdater,
  Resetter,
} from 'recoil'

type WithdrawRequestModalStateType = {
  visible: boolean
  pairAddress: string | undefined
}

const withdrawRequestModalAtom = atom<WithdrawRequestModalStateType>({
  key: 'withdrawRequestModal',
  default: {
    visible: false,
    pairAddress: undefined,
  },
})

export const useManageWithdrawRequestModalState = (): {
  withdrawRequestModalState: WithdrawRequestModalStateType
  setWithdrawRequestModalState: SetterOrUpdater<WithdrawRequestModalStateType>
  resetWithdrawRequestModalState: Resetter
} => {
  const [withdrawRequestModalState, setWithdrawRequestModalState] =
    useRecoilState(withdrawRequestModalAtom)
  const resetWithdrawRequestModalState = useResetRecoilState(
    withdrawRequestModalAtom,
  )

  return {
    withdrawRequestModalState,
    setWithdrawRequestModalState,
    resetWithdrawRequestModalState,
  }
}

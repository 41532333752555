import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import { MyAssetsPortfolioListItemProps } from './MyAssetsPortfolioListItem'

type ChartTokenLabelProps = {
  labelData: MyAssetsPortfolioListItemProps | null
}

const ChartTokenLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Symbol = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
`

const Amount = styled.div`
  margin: 8px 0 3px;
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
`

const Price = styled.div`
  font-size: 14px;
  color: #9d9999;
`

const ChartTokenLabel = ({ labelData }: ChartTokenLabelProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  if (labelData === null) return null

  return (
    <ChartTokenLabelWrapper>
      <Symbol>{labelData.symbol}</Symbol>
      <Amount>
        {convertNumber(labelData.balance, { maxDigit: 0, comma: true })}
        {language === 'ko' ? ' 개' : ' tokens'}
      </Amount>
      <Price>
        $ {convertNumber(labelData.value, { maxDigit: 0, comma: true })}
      </Price>
    </ChartTokenLabelWrapper>
  )
}

export default ChartTokenLabel

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

export type MyAssetsListItemProps = {
  address: string
  symbol: string
  korName: string
  engName: string
  balance: number
  price: number
  value: number
  proportion: number
}

type AssetListItemTextStyleType = {
  fontSize?: number
  fontWeight?: number
  color?: string
}

const MyAssetsListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1fr 1.1fr 1.1fr 1fr;
  align-items: center;
  height: 80px;
  padding: 0 30px;

  & + & {
    border-top: 1px solid #edeaea;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: unset;
    padding: 18px 16px;
  }
`

const Token = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px rgba(46, 46, 46, 0.1);
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const TokenInfo = styled.div`
  margin-left: 10px;
  color: #2e2e2e;

  & > div:nth-child(1) {
    font-size: 14px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    margin-top: 3px;
    font-size: 12px;
  }
`

const AssetListItemText = styled.div<AssetListItemTextStyleType>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  color: ${({ color }) => color ?? '#9d9999'};
  line-height: 1.75;

  span {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    & + & {
      margin-top: 11px;
    }
  }
`

const MobileTitle = styled.div`
  margin-right: auto;
  font-size: 12px;
  font-weight: 400;
  color: #9d9999;

  @media (min-width: 769px) {
    display: none;
  }
`

const MyAssetsListItem = ({
  address,
  symbol,
  korName,
  engName,
  balance,
  value,
  price,
  proportion,
}: MyAssetsListItemProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'MyAssets.MyAssetsListTemplate.head',
  })
  const { width } = useGetWindowSize()

  return (
    <MyAssetsListItemWrapper>
      <Token>
        <img
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
          alt="Token Image"
        />
        <TokenInfo>
          <div>{language === 'ko' ? korName : engName}</div>
          <div>{symbol}</div>
        </TokenInfo>
      </Token>

      <AssetListItemText fontWeight={700} color="#2e2e2e">
        <MobileTitle>{t('1')}</MobileTitle>

        <SlicedDecimal
          maxIntLength={8}
          suffix={language === 'ko' ? ' 개' : ' Tokens'}
        >
          {convertNumber(balance, { maxDigit: 6 })}
        </SlicedDecimal>
        <span>{language === 'ko' ? ' 개' : ' Tokens'}</span>
      </AssetListItemText>

      <AssetListItemText fontSize={16} fontWeight={width > 768 ? 700 : 400}>
        <MobileTitle>{t('2')}</MobileTitle>${' '}
        {convertNumber(value, { maxDigit: 0, comma: true })}
      </AssetListItemText>

      <AssetListItemText
        fontWeight={700}
        color={width > 768 ? undefined : '#656565'}
      >
        <MobileTitle>{t('3')}</MobileTitle>${' '}
        {convertNumber(price, { maxDigit: 6, comma: true })}
      </AssetListItemText>

      <AssetListItemText fontSize={16}>
        <MobileTitle>{t('4')}</MobileTitle>
        {convertNumber(proportion, { maxDigit: 2 })}%
      </AssetListItemText>
    </MyAssetsListItemWrapper>
  )
}

export default MyAssetsListItem

import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageTokenSelectModalState } from '../../../state/modal/tokenSelectModalState'

type TokenModalFilterCheckboxProps = {
  isVisible: boolean
}

const TokenModalFilterCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-left: auto;
  line-height: 1.2;
`

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: #9d9999;
`

const HiddenCheckboxInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  opacity: 0;
`

const CustomizedCheckbox = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: 8px;
`

const CheckMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #9c9999;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: calc(50% - 1px);
    transform: translate(-50%, -50%) rotate(45deg);
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }

  ${HiddenCheckboxInput}:checked ~ & {
    background: #ff6d68;
    border-color: #ff6d68;
  }

  ${HiddenCheckboxInput}:checked ~ &:after {
    display: block;
  }
`

const TokenModalFilterCheckbox = ({
  isVisible,
}: TokenModalFilterCheckboxProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.TokenSelectModal',
  })
  const {
    tokenSelectModalState: { isFiltered },
    setTokenSelectModalState,
  } = useManageTokenSelectModalState()

  const handleToggleIsFiltered = (event: ChangeEvent<HTMLInputElement>) =>
    setTokenSelectModalState(prev => ({
      ...prev,
      isFiltered: event.target.checked,
    }))

  if (!isVisible) return null

  return (
    <TokenModalFilterCheckboxWrapper>
      <CheckboxLabel>{t('checkbox')}</CheckboxLabel>

      <CustomizedCheckbox>
        <HiddenCheckboxInput
          type="checkbox"
          checked={isFiltered}
          onChange={handleToggleIsFiltered}
        />

        <CheckMark />
      </CustomizedCheckbox>
    </TokenModalFilterCheckboxWrapper>
  )
}

export default TokenModalFilterCheckbox

import { useMemo } from 'react'
import { prepare, request } from 'klip-sdk'
import { useQueryClient } from 'react-query'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import { CONTRACT_ADDRESS, WKLAY_TOKEN_ADDRESS } from '../contract/address'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import checkMobileDevice from '../utils/checkMobileDevice'
import useCallMessageBox from './useCallMessageBox'
import {
  REMOVE_LIQUIDITY_ABI,
  REMOVE_LIQUIDITY_KLAY_ABI,
} from '../contract/abi'
import {
  getConvertFromPebValue,
  getConvertToPebValue,
} from '../utils/convertTokenNumberData'
import { CONTRACT_GAS_LIMIT, DEADLINE } from '../utils/constants'
import { useManageTransactionHistoryState } from '../state/shared/transactionHistoryState'
import parseTransactionError from '../utils/parseTransactionError'
import { getCurrentTimeForHistory } from '../utils/getCurrentTime'
import { useManageDepositFormState } from '../state/pool/depositFormState'
import getAppliedSlippageValue from '../utils/getAppliedSlippageValue'
import { PoolType } from '../api/query/usePoolQuery'

export default function useRemovePoolLiquidity(pairAddress?: string) {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    depositFormState: {
      settings: { slippage },
    },
  } = useManageDepositFormState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setTransactionHistoryState } = useManageTransactionHistoryState()
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()

  const {
    tokens,
    depositRate,
    userData: { depositedLpBalance },
  } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const REMOVE_LIQUIDITY_PARAM = useMemo(
    () => [
      tokens[0].address,
      tokens[1].address,
      depositedLpBalance,
      getConvertToPebValue(
        getAppliedSlippageValue(
          depositRate[0] *
            parseFloat(getConvertFromPebValue(depositedLpBalance, 18)),
          slippage,
          'min',
        ),
        tokens[0].decimal,
      ),
      getConvertToPebValue(
        getAppliedSlippageValue(
          String(
            depositRate[1] *
              parseFloat(getConvertFromPebValue(depositedLpBalance, 18)),
          ),
          slippage,
          'min',
        ),
        tokens[1].decimal,
      ),
      address ?? '',
      DEADLINE,
    ],
    [tokens, depositRate, depositedLpBalance, address],
  )

  const REMOVE_LIQUIDITY_KLAY_PARAM = useMemo(
    () => [
      tokens[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 1 : 0].address,
      depositedLpBalance,
      getConvertToPebValue(
        getAppliedSlippageValue(
          String(
            depositRate[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 1 : 0] *
              parseFloat(getConvertFromPebValue(depositedLpBalance, 18)),
          ),
          slippage,
          'min',
        ),
        tokens[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 1 : 0].decimal,
      ),
      getConvertToPebValue(
        getAppliedSlippageValue(
          String(
            depositRate[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 0 : 1] *
              parseFloat(getConvertFromPebValue(depositedLpBalance, 18)),
          ),
          slippage,
          'min',
        ),
        tokens[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 0 : 1].decimal,
      ),
      address ?? '',
      DEADLINE,
    ],
    [tokens, depositedLpBalance, address],
  )

  const handleAddTransactionHistory = (hash: string, status: boolean) =>
    setTransactionHistoryState(prev => [
      {
        type: 'withdraw',
        hash,
        status,
        date: getCurrentTimeForHistory(),
      },
      ...prev,
    ])

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { tx_hash } = result

    if (result.status === 'success') {
      successCallback()
      handleAddTransactionHistory(tx_hash as string, true)
    } else {
      failureCallback()
      handleAddTransactionHistory(tx_hash as string, false)
    }
  }

  const klipRemoveLiquidity = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.router,
      value: '0',
      abi: JSON.stringify(
        isKlayIncluded ? REMOVE_LIQUIDITY_KLAY_ABI : REMOVE_LIQUIDITY_ABI,
      ),
      params: JSON.stringify(
        isKlayIncluded ? REMOVE_LIQUIDITY_KLAY_PARAM : REMOVE_LIQUIDITY_PARAM,
      ),
    })

    if (err) {
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasRemoveLiquidity = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const data = caver.klay.abi.encodeFunctionCall(
      isKlayIncluded ? REMOVE_LIQUIDITY_KLAY_ABI : REMOVE_LIQUIDITY_ABI,
      isKlayIncluded ? REMOVE_LIQUIDITY_KLAY_PARAM : REMOVE_LIQUIDITY_PARAM,
    )

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.router,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const bitkeepRemoveLiquidity = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const data = web3.eth.abi.encodeFunctionCall(
      isKlayIncluded ? REMOVE_LIQUIDITY_KLAY_ABI : REMOVE_LIQUIDITY_ABI,
      (isKlayIncluded
        ? REMOVE_LIQUIDITY_KLAY_PARAM
        : REMOVE_LIQUIDITY_PARAM) as string[],
    )

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.router,
        data,
        gas: CONTRACT_GAS_LIMIT,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const handleRemoveLiquidity = async (
    removeLiquiditySuccess: () => void,
    removeLiquidityFailure: () => void,
  ) => {
    try {
      if (type === 'klip')
        await klipRemoveLiquidity(
          removeLiquiditySuccess,
          removeLiquidityFailure,
        )
      else if (type === 'kaikas')
        kaikasRemoveLiquidity(removeLiquiditySuccess, removeLiquidityFailure)
      else if (type === 'bitkeep')
        bitkeepRemoveLiquidity(removeLiquiditySuccess, removeLiquidityFailure)
    } catch (error) {
      pushMessage('request', 'failure')
    }
  }

  return handleRemoveLiquidity
}

import caver from '../modules/network/caver'

export function getConvertFromPebValue(
  value: number | string,
  decimal: number,
): string {
  const stringifyValue = String(value)

  if (decimal === 6) {
    return caver.utils.convertFromPeb(stringifyValue, 'Mpeb')
  } else if (decimal === 8) {
    const convertedValue = parseFloat(
      caver.utils.convertFromPeb(stringifyValue, 'Mpeb') as string,
    )
    return String(convertedValue / 100)
  } else return caver.utils.convertFromPeb(stringifyValue, 'KLAY')
}

export function getConvertToPebValue(
  value: number | string,
  decimal: number,
): string {
  const stringifyValue = String(value)

  if (decimal === 6) {
    const convertedValue = String(
      Math.floor(parseFloat(stringifyValue) * Math.pow(10, 6)) /
        Math.pow(10, 6),
    )
    return caver.utils.convertToPeb(convertedValue, 'Mpeb') as string
  } else if (decimal === 8) {
    const convertedValue = (
      (Math.floor(parseFloat(stringifyValue) * Math.pow(10, 8)) /
        Math.pow(10, 8)) *
      100
    ).toFixed(6)
    return caver.utils.convertToPeb(convertedValue, 'Mpeb') as string
  } else return caver.utils.convertToPeb(stringifyValue, 'KLAY') as string
}

import { useMutation } from 'react-query'
import AxiosClient from '../axiosConfig'

type SetReferralAddressRequestBodyType = {
  inviterAddress: string
}

const setReferralAddress = async (
  inviterAddress: SetReferralAddressRequestBodyType['inviterAddress'],
) =>
  await AxiosClient.getAxiosInstance(true).put('/api/wallets/referrals', {
    inviterAddress,
  })

export default function useSetReferralAddressMutation() {
  return useMutation(setReferralAddress)
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ReferralClaimPolicyWrapper = styled.div`
  margin-top: auto;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #707070;
  line-height: 1.2;

  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`

const StyledLine = styled.div`
  margin: 16px 0 14px;
  width: 100%;
  height: 1px;
  background: #707070;
  opacity: 0.3;
`

const DetailItem = styled.div`
  display: flex;
  font-size: 12px;
  color: #707070;
  line-height: 1.67;
  white-space: pre-line;

  img {
    margin-top: 3px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }

  a {
    font-weight: 500;
    color: inherit;
    text-decoration: underline;
  }

  & + & {
    margin-top: 8px;
  }
`

const NeuronStakingPolicy = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Staking.NeuronStaking.policy',
  })

  return (
    <ReferralClaimPolicyWrapper>
      <Title>
        <img
          src="/dexAssets/staking_policy_icon.png"
          alt="Referral Claim Policy Icon"
        />
        {t('title')}
      </Title>

      <StyledLine />

      <DetailItem>
        <img
          src="/dexAssets/referral_policy_item_icon.png"
          alt="Referral Claim Policy Icon"
        />
        <div>
          {t('contents.0.0')}
          <a
            href="https://documents.neuronswap.com/neuronswap/"
            target="_blank"
          >
            {t('contents.0.1')}
          </a>
          {t('contents.0.2')}
        </div>
      </DetailItem>
      <DetailItem>
        <img
          src="/dexAssets/referral_policy_item_icon.png"
          alt="Referral Claim Policy Icon"
        />
        <div>{t('contents.1')}</div>
      </DetailItem>
    </ReferralClaimPolicyWrapper>
  )
}

export default NeuronStakingPolicy

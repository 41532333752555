import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReferralTokenRewards from '../organisms/ReferralTokenRewards'
import ReferralManageInvitation from '../organisms/ReferralManageInvitation'

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  color: #cdad7a;

  @media (max-width: 768px) {
    margin-bottom: 23px;
  }
`

const ReferralDataTemplate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralData',
  })

  return (
    <div>
      <Title>{t('title')}</Title>

      <ReferralTokenRewards />
      <ReferralManageInvitation />
    </div>
  )
}

export default ReferralDataTemplate

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const DashboardTableHead = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 0.9fr 0.8fr 0.8fr 0.85fr;
  align-items: center;
  width: 1200px;
  height: 30px;
  margin-bottom: 20px;
  padding: 0 30px;
  border-radius: 15px;
  background: #e8e8e8;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  font-size: 12px;
  color: #9d9999;
`

const HeadItem = styled.div<{ align: 'right' | 'left' | 'center' }>`
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : `flex-${align === 'left' ? 'start' : 'end'}`};
  align-items: flex-end;

  span {
    margin-left: 2px;
    font-size: 10px;
    line-height: 1.3;
  }
`

const DashboardListHead = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardListTemplate.head',
  })

  return (
    <DashboardTableHead language={language}>
      <HeadItem align="left">{t('0')}</HeadItem>
      <HeadItem align="right">{t('1')}</HeadItem>
      {/* <HeadItem align="right">{t('2')}</HeadItem> */}
      <HeadItem align="right">{t('3')}</HeadItem>
      <HeadItem align="right">
        {t('4')} <span>(24h)</span>
      </HeadItem>
      <HeadItem align="right">
        {t('5')} <span>(24h)</span>
      </HeadItem>
      <HeadItem align="right">{t('6')}</HeadItem>
      <HeadItem align="right">{t('7')}</HeadItem>
    </DashboardTableHead>
  )
}

export default DashboardListHead

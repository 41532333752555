import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import { AvailablePoolItemType } from '../../../api/query/useAvailableDepositPoolsQuery'

type AvailableDepositItemRewardsProps = {
  rewardsDetail: AvailablePoolItemType['rewardsDetail']
}

const RewardItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;

  & + & {
    margin-top: 3px;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    font-size: 12px;
    color: #ffffff;

    & + & {
      margin-top: 16px;
    }
  }
`

const RewardTitle = styled.div`
  color: #9d9999;

  @media (max-width: 768px) {
    font-weight: 400;
    color: inherit;
  }
`

const RewardContent = styled.div`
  margin-left: 4px;
  color: #2e2e2e;

  @media (max-width: 768px) {
    font-weight: 700;
    color: inherit;
  }
`

const AvailableDepositItemRewards = ({
  rewardsDetail: { nrAirdrop, tradeFeeApr },
}: AvailableDepositItemRewardsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Pool.AvailableDeposit.rewardDetails',
  })

  return (
    <div>
      {nrAirdrop !== 0 ? (
        <RewardItem>
          <RewardTitle>{t('distributedNR')}</RewardTitle>
          <RewardContent>
            {convertNumber(nrAirdrop, { isPercentage: true })}%
          </RewardContent>
        </RewardItem>
      ) : null}

      <RewardItem>
        <RewardTitle>{t('transactionRewards')}</RewardTitle>
        <RewardContent>
          {convertNumber(tradeFeeApr, { isPercentage: true })}%
        </RewardContent>
      </RewardItem>
    </div>
  )
}

export default AvailableDepositItemRewards

import TemplateLayout from '../templates/TemplateLayout'
import styled from 'styled-components'
import ReferralDataTemplate from '../templates/ReferralDataTemplate'
import ReferralEventDashboardTemplate from '../templates/ReferralEventDashboardTemplate'
import ReferralRewardsTemplate from '../templates/ReferralRewardsTemplate'

const ReferralPageWrapper = styled.div`
  width: 540px;
  margin: 77px auto 0;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`

const ReferralPage = () => {
  return (
    <TemplateLayout>
      <ReferralPageWrapper>
        <ReferralDataTemplate />
        <ReferralEventDashboardTemplate />
        <ReferralRewardsTemplate />
      </ReferralPageWrapper>
    </TemplateLayout>
  )
}

export default ReferralPage

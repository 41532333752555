import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReferralCreateURL from '../molecules/ReferralCreateURL'
import ReferralAcceptInvitation from '../molecules/ReferralAcceptInvitation'
import useGetWindowSize from '../../../hooks/useGetWindowSize'

const ReferralManageInvitationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 310px;
  margin-top: 20px;
  padding: 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    height: 384px;
    padding: 24px;
  }
`

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: auto 0 14px;
  background: #707070;
  opacity: 0.3;
`

const InvitationDescription = styled.div`
  display: flex;
  font-size: 12px;
  color: #707070;
  line-height: 1.67;
  white-space: pre-line;

  img {
    margin-top: 3px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
`

const ReferralManageInvitation = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Referral.ReferralData.ReferralManageInvitation',
  })
  const { width } = useGetWindowSize()

  return (
    <ReferralManageInvitationWrapper>
      <ReferralCreateURL />
      <ReferralAcceptInvitation />

      <StyledLine />

      <InvitationDescription>
        <img
          src="/dexAssets/referral_policy_item_icon.png"
          alt="Referral Claim Policy Icon"
        />
        <div
          style={{ letterSpacing: language === 'en' ? '-0.13px' : '-0.1px' }}
        >
          {t(width > 768 ? 'description' : 'mobileDescription')}
        </div>
      </InvitationDescription>
    </ReferralManageInvitationWrapper>
  )
}

export default ReferralManageInvitation

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import convertNumber from '../../../utils/convertNumber'
import CommonTooltip from '../atoms/CommonTooltip'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { PoolItemType } from '../../../api/query/useDashboardPoolsQuery'
import { TOKEN_ADDRESS } from '../../../contract/address'

const APR_TOOLTIP_CUSTOM_STYLE = {
  width: '231px',
  padding: '13px',
  fontSize: '12px',
  fontWeight: '400',
  borderRadius: '15px',
}

type DashboardListItemProps = {
  address: string
  tradingVolume: number
  fee: number
} & Omit<PoolItemType, 'volume' | 'fee'>

type DashboardListItemTextStyleType = {
  fontSize?: number
  fontWeight?: number
  color?: '#2e2e2e' | '#ff6d68'
}

const DashboardListItemWrapper = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 0.9fr 0.8fr 0.8fr 0.85fr;
  align-items: center;
  height: 85px;
  padding: 0 30px;
  border-bottom: 1px solid #edeaea;
`

const PairName = styled.div`
  font-weight: 700;

  div {
    font-size: 14px;
    color: #2e2e2e;
  }

  a {
    margin-top: 3px;
    font-size: 12px;
    color: #9d9999;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const DashboardListItemText = styled.div<DashboardListItemTextStyleType>(
  ({ fontSize, fontWeight, color }: DashboardListItemTextStyleType) => ({
    fontSize: fontSize ? `${fontSize}px` : '12px',
    fontWeight: fontWeight ?? 400,
    color: color ?? '#2e2e2e',
    textAlign: 'right',
  }),
)

const DepositAmountItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  & + & {
    margin-top: 4px;
  }

  & > div:nth-child(1) {
    color: #2e2e2e;
  }

  & > div:nth-child(2) {
    margin-left: 4px;
    color: #9d9999;
  }

  img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    border-radius: 50%;
    border: 1px solid rgba(46, 46, 46, 0.1);
  }
`

const DistributedToken = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(46, 46, 46, 0.1);
  }

  img + img {
    margin-left: 3px;
  }
`

const DashboardListItem = ({
  address,
  totalDepositedTokens,
  tokens,
  rewardsDetail: { apr },
  totalLiquidity,
  tradingVolume,
  fee,
}: DashboardListItemProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Dashboard.DashboardListTemplate',
  })
  const { width } = useGetWindowSize()

  return (
    <DashboardListItemWrapper language={language}>
      <PairName>
        <div>{`${tokens[0].symbol} / ${tokens[1].symbol}`}</div>
        <a
          href={`https://scope.klaytn.com/account/${address}?tabId=txList`}
          target="_blank"
        >
          {t('viewTheKlaytnScope')}
        </a>
      </PairName>

      <div>
        {tokens.map(({ address, symbol }) => (
          <DepositAmountItem key={address}>
            <div>
              {convertNumber(totalDepositedTokens[address], {
                maxDigit: 0,
                comma: true,
              })}
            </div>
            <div>{symbol}</div>
            <img
              src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
              alt="Deposit Amount Icon"
            />
          </DepositAmountItem>
        ))}
      </div>

      <DashboardListItemText fontSize={14} fontWeight={700}>
        $ {convertNumber(totalLiquidity, { maxDigit: 0, comma: true })}
      </DashboardListItemText>

      <DashboardListItemText>
        $ {convertNumber(tradingVolume, { maxDigit: 0, comma: true })}
      </DashboardListItemText>

      <DashboardListItemText>
        ${' '}
        {convertNumber(fee, {
          maxDigit: 0,
          comma: true,
        })}
      </DashboardListItemText>

      <DistributedToken>
        <img
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${TOKEN_ADDRESS.NR}.png`}
          alt="Deposit Amount Icon"
        />
        {tokens.map(({ address }) => (
          <img
            src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
            alt="Deposit Amount Icon"
            key={address}
          />
        ))}
      </DistributedToken>

      <DashboardListItemText
        fontSize={18}
        fontWeight={700}
        color="#ff6d68"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <CommonTooltip
          isEnable={apr >= 1000 && width > 768}
          displayComponent={<>{convertNumber(apr, { maxDigit: 6 })}%</>}
          widgetCustomStyle={APR_TOOLTIP_CUSTOM_STYLE}
        >
          {convertNumber(apr, { isPercentage: true })}%
        </CommonTooltip>
      </DashboardListItemText>
    </DashboardListItemWrapper>
  )
}

export default DashboardListItem

import styled from 'styled-components'
import DepositMainItemToken from '../atoms/DepositMainItemToken'
import DepositMainItemInput from '../atoms/DepositMainItemInput'
import DepositMainItemBalance from '../atoms/DepositMainItemBalance'
import { InputPositionType } from '../../../utils/types'
import useTokenQuery from '../../../api/query/useTokenQuery'

type DepositMainItemProps = {
  tokenAddress: string
  position: InputPositionType
}

const DepositMainItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 30px;
  border-radius: 24px;
  background-color: #f8f8f8;

  & + & {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    height: 100px;
    padding: 0 16px;
  }
`

const DepositMainItemRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 17px 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0;
  }
`

const DepositMainItem = ({ tokenAddress, position }: DepositMainItemProps) => {
  const { status, data } = useTokenQuery(tokenAddress)

  if (status !== 'success' || data === undefined) return null

  return (
    <DepositMainItemWrapper>
      <DepositMainItemToken token={data} />

      <DepositMainItemRightSide>
        <DepositMainItemInput type="deposit" position={position} />
        <DepositMainItemBalance
          balance={data.balance}
          type="deposit"
          position={position}
        />
      </DepositMainItemRightSide>
    </DepositMainItemWrapper>
  )
}

export default DepositMainItem

import { useMemo, Fragment } from 'react'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import useGetWindowSize from '../../../hooks/useGetWindowSize'
import { TokenListType } from '../../../api/query/useTokenListQuery'
import { TokenType } from '../../../api/query/useTokenQuery'

type SwapInfoTransactionPathProps = {
  bestTradeAddress: string[]
}

const SwapInfoTransactionPathRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & + & {
    margin-top: 7px;
  }
`

const TokenItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #9d9999;
  line-height: 1.43;

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    border-radius: 50%;
    background: #ffffff;
  }

  @media (max-width: 768px) {
    color: #ffffff;
  }
`

const PathIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 4px;
`

const SwapInfoTransactionPath = ({
  bestTradeAddress,
}: SwapInfoTransactionPathProps) => {
  const { width } = useGetWindowSize()
  const queryClient = useQueryClient()
  const tokenList = queryClient.getQueryData<TokenListType['data']>([
    'tokenList',
    '',
  ])

  const convertedBestTrade = useMemo(() => {
    if (tokenList === undefined) return []

    const tokenTradeList = bestTradeAddress.map(
      (address: string) =>
        tokenList.tokens.find(token => token.address === address) as TokenType,
    )

    const result = []

    for (let i = 0; i < tokenTradeList.length; i += 3)
      result.push(tokenTradeList.slice(i, i + 3))

    return result
  }, [bestTradeAddress, tokenList])

  if (convertedBestTrade.length === 0) return <div>-</div>

  return (
    <div>
      {convertedBestTrade.map((tradeRow, rowIndex) => {
        return (
          <SwapInfoTransactionPathRow key={rowIndex}>
            {rowIndex !== 0 ? (
              <PathIcon
                src={`/dexAssets/last_arrow_button${
                  width > 768 ? '' : '_white'
                }.png`}
                alt="Divide Icon"
              />
            ) : null}

            {tradeRow.map(({ address, symbol }, columnIndex) => (
              <Fragment key={columnIndex}>
                {columnIndex !== 0 ? (
                  <PathIcon
                    src={`/dexAssets/last_arrow_button${
                      width > 768 ? '' : '_white'
                    }.png`}
                    alt="Divide Icon"
                  />
                ) : null}
                <TokenItem>
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
                    alt="Token Icon"
                  />
                  {symbol}
                </TokenItem>
              </Fragment>
            ))}
          </SwapInfoTransactionPathRow>
        )
      })}
    </div>
  )
}

export default SwapInfoTransactionPath

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import produce from 'immer'
import { useQueryClient } from 'react-query'
import ModalPortal from '../ModalPortal'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { useManageUnstakingRequestModalState } from '../../state/modal/unstakingRequestModalState'
import { useManageDepositFormState } from '../../state/pool/depositFormState'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useWithdraw from '../../hooks/useWithdraw'
import convertNumber from '../../utils/convertNumber'
import { CSS_Z_INDEX, NR_SYMBOL } from '../../utils/constants'
import { PoolType } from '../../api/query/usePoolQuery'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${CSS_Z_INDEX.modal};
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 480px;
    padding: 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  margin-bottom: auto;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    padding: 19px 0 17px;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div<{ alignTop?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
    white-space: pre-line;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    ${({ alignTop }) =>
      alignTop === 'true'
        ? `
      align-items: flex-start;

      & > div:nth-child(1) {
        margin-top: 1px;
      }
    `
        : ''}

    & > div:nth-child(2) {
      font-size: 14px;
    }

    & + & {
      margin-top: 20px;
    }
  }
`

const TransactionButton = styled.button`
  display: grid;
  place-items: center;
  width: 100%;
  height: 64px;
  margin-top: 14px;
  border-radius: 14px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  white-space: pre-line;
  transition: 0.15s all;

  @media (max-width: 768px) {
    & + & {
      margin-top: 8px;
    }
  }
`

const UnstakingRequestModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.UntakingRequestModal',
  })
  const {
    unstakingRequestModalState: { pairAddress, pid },
    resetUnstakingRequestModalState,
  } = useManageUnstakingRequestModalState()
  const {
    depositFormState: { withdraw },
    setDepositFormState,
  } = useManageDepositFormState()
  const handleWithdraw = useWithdraw(pid as number)
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()

  const {
    userData: { claimableNeuron },
  } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const withdrawSuccessCallback = () => {
    queryClient.invalidateQueries(['pool', pairAddress])
    setDepositFormState(prev =>
      produce(prev, draft => {
        draft.withdraw = ''
        draft.settings.withdrawIsMaxAmount = false
        return draft
      }),
    )
    resetUnstakingRequestModalState()
    pushMessage('request', 'success')
  }

  const requestFailureCallback = () => pushMessage('request', 'failure')

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={resetUnstakingRequestModalState}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div />
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(parseFloat(withdraw).toFixed(9), {
                  maxDigit: 8,
                })}
              </SlicedDecimal>
              <span>LP</span>
            </div>
          </TransactionInfoItem>
          {claimableNeuron ? (
            <TransactionInfoItem alignTop="true">
              <div>{t('nrTokenReward')}</div>
              <div>
                <SlicedDecimal maxIntLength={7} showTooltip={false}>
                  {convertNumber(claimableNeuron, { maxDigit: 6 })}
                </SlicedDecimal>
                <span>{NR_SYMBOL}</span>
              </div>
            </TransactionInfoItem>
          ) : null}
        </TransactionInfo>

        <TransactionButton
          onClick={() =>
            handleWithdraw(
              'withdraw',
              withdrawSuccessCallback,
              requestFailureCallback,
            )
          }
        >
          {t(`button`)}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default UnstakingRequestModal

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import AxiosClient from './api/axiosConfig'
import NeuronSwapApp from './components/NeuronSwap/App'
// import RonyClubApp from './components/RonyClub/App'
import MessageBox from './modal/shared/MessageBox'
import useCallMessageBox from './hooks/useCallMessageBox'
import useDetectConnectedWallet from './hooks/useDetectConnectedWallet'
import { useManageConnectedWalletState } from './state/shared/connectedWalletState'
import { SOUND_EFFECTS } from './utils/constants'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  }

  html, body, #root {
    height: 100%;
    background: #fcfcfc;
    >iframe{
     z-index: -1 !important; 
    }
  }
`

const Root = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation()
  const {
    connectedWalletState: { address },
    resetConnectedWalletState,
  } = useManageConnectedWalletState()
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()

  const throttlingCallback = () =>
    pushMessage(
      'request',
      'failure',
      language === 'en'
        ? 'There are a lot of server requests, please use it after a while.'
        : '서버 요청이 많아 잠시 후 이용 하십시오.',
    )

  const refreshTokenExpiredCallback = () => {
    window.sessionStorage.removeItem('neuronSwapWalletType')
    window.sessionStorage.removeItem('neuronSwapKlipAddress')
    queryClient.removeQueries()
    resetConnectedWalletState()
  }

  useEffect(() => {
    const defaultLanguage =
      window.sessionStorage.getItem('neuronSwapLanguage') ?? 'en'

    window.sessionStorage.setItem('neuronSwapLanguage', defaultLanguage ?? 'en')
    changeLanguage(defaultLanguage)
    SOUND_EFFECTS.enterMenu.volume = 0.3
    SOUND_EFFECTS.hallOfFame.volume = 0.3
  }, [])

  useEffect(() => {
    AxiosClient.initialize(throttlingCallback, refreshTokenExpiredCallback)
  }, [])

  useEffect(() => {
    if (address !== undefined) queryClient.invalidateQueries()
  }, [address])

  useDetectConnectedWallet()

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route path="/*" element={<NeuronSwapApp />} />
        {/* <Route path="/ronyclub/*" element={<RonyClubApp />} /> */}
      </Routes>

      <MessageBox />
    </BrowserRouter>
  )
}

export default Root

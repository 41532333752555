import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TokenType } from '../../../api/query/useTokenQuery'
import { CSS_Z_INDEX } from '../../../utils/constants'

type PoolItemTokenProps = {
  tokens: Partial<TokenType>[]
  isHot?: boolean
}

const PoolItemTokenWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 33px;
  }
`

const TokenIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px rgba(46, 46, 46, 0.1);

  &:nth-child(1) {
    position: relative;
    z-index: ${CSS_Z_INDEX.token};
    margin-right: -5px;
    background: #ffffff;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

const TokenInfo = styled.div`
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 8px;
  }
`

const TokenName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.3;
`

const TokenSymbol = styled.div`
  margin-top: 1px;
  font-size: 12px;
  color: #2e2e2e;
  line-height: 1.3;
`

const MobileHotBadge = styled.img`
  width: 54px;
  margin-left: auto;
  margin-bottom: 15px;

  @media (min-width: 769px) {
    display: none;
  }
`

const PoolItemToken = ({ tokens, isHot }: PoolItemTokenProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <PoolItemTokenWrapper>
      <TokenIcon
        src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[0].address}.png`}
        alt="Token Image"
      />
      <TokenIcon
        src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[1].address}.png`}
        alt="Token Image"
      />

      <TokenInfo>
        <TokenName>
          {language === 'en'
            ? `${tokens[0].engName} + ${tokens[1].engName}`
            : `${tokens[0].korName} + ${tokens[1].korName}`}
        </TokenName>
        <TokenSymbol>
          {tokens[0].symbol} + {tokens[1].symbol}
        </TokenSymbol>
      </TokenInfo>

      {isHot ? (
        <MobileHotBadge src="/dexAssets/hot_icon.png" alt="Hot Icon" />
      ) : null}
    </PoolItemTokenWrapper>
  )
}

export default PoolItemToken

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CSS_Z_INDEX } from '../../../utils/constants'
import { TokenType } from '../../../api/query/useTokenQuery'

type DepositPoolInfoTokenProps = {
  tokens: TokenType[]
}

const DepositPoolInfoTokenWrapper = styled.div<{ language: string }>`
  display: grid;
  grid-template-columns: auto auto ${({ language }) =>
      language === 'ko' ? ' auto' : ''};
  align-items: center;
`

const TokenName = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
`

const TokenIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`

const TokenIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px rgba(46, 46, 46, 0.1);

  &:nth-child(1) {
    position: relative;
    z-index: ${CSS_Z_INDEX.token};
    margin-right: -3px;
    background: #ffffff;
  }
`

const DepositPoolInfoToken = ({ tokens }: DepositPoolInfoTokenProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <DepositPoolInfoTokenWrapper language={language}>
      <TokenName>
        {language === 'en'
          ? `${tokens[0].engName} + ${tokens[1].engName}`
          : `${tokens[0].korName} + ${tokens[1].korName}`}
      </TokenName>
      <TokenIconWrapper>
        <TokenIcon
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[0].address}.png`}
          alt="Token Image"
        />
        <TokenIcon
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${tokens[1].address}.png`}
          alt="Token Image"
        />
      </TokenIconWrapper>
    </DepositPoolInfoTokenWrapper>
  )
}

export default DepositPoolInfoToken

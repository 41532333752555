import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import StakingTransactionRewardsItem from '../molecules/StakingTransactionRewardsItem'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'
import { useManageStakingRewardsModalState } from '../../../state/modal/stakingRewardsModalState'
import useStakingTransactionRewardsQuery from '../../../api/query/useStakingTransactionRewardsQuery'

const StakingTransactionRewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 315px;
  margin-top: 28px;
  padding: 27px 40px 40px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 20px 32px 0 rgba(228, 231, 232, 0.5);
`

const ReferralTokenRewardsHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 23px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.56;
`

const ReceiveRewardsButton = styled.button<{
  language: string
  $isActive: boolean
}>`
  display: grid;
  place-items: center;
  width: ${({ language }) => (language === 'en' ? '158' : '97')}px;
  height: 32px;
  border: 0;
  border-radius: 16px;
  background: ${({ $isActive }) => ($isActive ? '#65dfb9' : '#9d9999')};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  line-height: 1.5;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const StyledLine = styled.div`
  width: 100%;
  height: 0.5px;
  background: #9d9999;
`

const ReferralTokenRewardsList = styled.div<{ isScrollVisible: boolean }>`
  overflow: auto;
  ${({ isScrollVisible }) =>
    isScrollVisible
      ? `
  margin: 0 -32px;
  padding: 0 22px 0 32px;
  `
      : ''}

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #edeaea;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    ${({ isScrollVisible }) =>
      isScrollVisible
        ? `
      margin: 0 -20px;
      padding: 0 10px 0 20px;
    `
        : ''}
  }
`

const StakingTransactionRewards = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Staking.TransactionFeeDistributionRewards',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()
  const { setStakingRewardsModalState } = useManageStakingRewardsModalState()
  const { data } = useStakingTransactionRewardsQuery()

  const totalTransactionRewards = useMemo(() => {
    if (data === undefined) return 0
    else
      return data.reduce((result, item) => result + parseFloat(item.rewards), 0)
  }, [data])

  const handleShowModal = () =>
    setStakingRewardsModalState({ visible: true, type: 'transactionRewards' })

  return (
    <StakingTransactionRewardsWrapper>
      <ReferralTokenRewardsHead>
        <Title>{t('title')}</Title>
        <ReceiveRewardsButton
          language={language}
          $isActive={address !== undefined && totalTransactionRewards !== 0}
          onClick={
            address !== undefined && totalTransactionRewards !== 0
              ? handleShowModal
              : undefined
          }
        >
          {t('claimRewards')}
        </ReceiveRewardsButton>
      </ReferralTokenRewardsHead>

      <StyledLine />

      <ReferralTokenRewardsList isScrollVisible={(data?.length ?? 0) >= 4}>
        {data !== undefined
          ? data.map(({ address, symbol, korName, engName, rewards }) => (
              <StakingTransactionRewardsItem
                address={address}
                symbol={symbol}
                korName={korName}
                engName={engName}
                rewards={rewards}
                key={address}
              />
            ))
          : null}
      </ReferralTokenRewardsList>
    </StakingTransactionRewardsWrapper>
  )
}

export default StakingTransactionRewards

import { atom, useRecoilState, SetterOrUpdater } from 'recoil'

const stakingModalAtom = atom<boolean>({
  key: 'stakingModal',
  default: false,
})

export const useManageStakingModalState = (): {
  stakingModalState: boolean
  setStakingModalState: SetterOrUpdater<boolean>
} => {
  const [stakingModalState, setStakingModalState] =
    useRecoilState(stakingModalAtom)

  return { stakingModalState, setStakingModalState }
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageConnectedWalletState } from '../../../state/shared/connectedWalletState'

const StakingExpectedReturnTooltipContentWrapper = styled.div`
  font-size: 12px;
  color: #ffffff;
`

const Title = styled.div`
  font-weight: 700;

  * + & {
    margin-top: 10px;
  }
`

const Contents = styled.div<{ $spacing: boolean }>`
  letter-spacing: ${({ $spacing }) => ($spacing ? '-0.18px' : '0')};
`

const StakingExpectedReturnTooltipContent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Staking.StakingHoldingQuantity',
  })
  const {
    connectedWalletState: { address },
  } = useManageConnectedWalletState()

  return (
    <StakingExpectedReturnTooltipContentWrapper>
      {address !== undefined ? (
        <Contents $spacing>{t('expectedReturnsAfterConnectTooltip')}</Contents>
      ) : (
        <>
          <Title>APR</Title>
          <Contents $spacing={language === 'ko'}>
            {t('expectedReturnsBeforeConnectTooltip')}
          </Contents>
        </>
      )}

      <Title>APY</Title>
      <Contents $spacing={language === 'ko'}>
        {t('expectedReturnsAPYTooltip')}
      </Contents>
    </StakingExpectedReturnTooltipContentWrapper>
  )
}

export default StakingExpectedReturnTooltipContent

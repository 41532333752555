import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HeaderLanguageMenuWrapper = styled.div`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: 100%;
  margin-left: auto;
`

const Select = styled.select`
  position: relative;
  width: 40px;
  font-size: 14px;
  color: #707070;
  border: 0;
  outline: none;
  background: transparent;
`

const HeaderLanguageMenu = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation()

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    window.sessionStorage.setItem('neuronSwapLanguage', value)
    changeLanguage(value)
  }

  return (
    <HeaderLanguageMenuWrapper>
      <Select onChange={handleChange} value={language}>
        <option value="ko">KR</option>
        <option value="en">EN</option>
      </Select>
    </HeaderLanguageMenuWrapper>
  )
}

export default HeaderLanguageMenu

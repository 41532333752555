import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageApproveModalState } from '../../state/modal/approveModalState'
import { useManageSwapFormState } from '../../state/swap/swapFormState'
import { CONTRACT_ADDRESS } from '../../contract/address'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import useTokenApprove from '../../hooks/useTokenApprove'
import useGnrStakingApprove from '../../hooks/useGnrStakingApprove'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useGetWindowSize from '../../hooks/useGetWindowSize'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 380px;
    padding: 24px;
  }
`

const Title = styled.div`
  margin: 20px 0;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin: 24px 0 8px;
  }
`

const TransactionInfo = styled.div`
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;
  font-size: 16px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.6;
  white-space: pre-line;

  @media (max-width: 768px) {
    margin: 0 -24px 0 0;
    padding: 0;
    border: 0;
  }
`

const TransactionDetailInfo = styled.div<{ language: string }>`
  margin-top: 30px;
  font-size: 14px;
  color: #9d9999;
  line-height: 1.5;
  white-space: pre-line;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: inherit;
  }

  @media (max-width: 768px) {
    margin: 16px -24px 0 0;
    font-size: ${({ language }) => (language === 'en' ? '13' : '14')}px;
  }
`

const TransactionButton = styled.button`
  width: 100%;
  margin-top: auto;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`

const ApproveModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Modal.ApproveModal',
  })
  const {
    approveModalState: { type },
    resetApproveModalState,
  } = useManageApproveModalState()
  const {
    swapFormState: {
      form: { from },
    },
  } = useManageSwapFormState()
  const handleTokenApprove = useTokenApprove()
  const handleGnrStakingApprove = useGnrStakingApprove()
  const pushMessage = useCallMessageBox()
  const { width } = useGetWindowSize()

  const handleCloseApproveModal = () => resetApproveModalState()

  const approveRequestCallback = (status: 'success' | 'failure') => {
    handleCloseApproveModal()
    pushMessage('request', status)
  }

  const handleApprove = () => {
    if (type === 'token')
      handleTokenApprove(
        from.address,
        CONTRACT_ADDRESS.router,
        () => approveRequestCallback('success'),
        () => approveRequestCallback('failure'),
      )
    else if (type === 'staking')
      handleGnrStakingApprove(
        () => approveRequestCallback('success'),
        () => approveRequestCallback('failure'),
      )
  }

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={handleCloseApproveModal}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          {t(width > 768 ? 'info' : 'mobileInfo')}
        </TransactionInfo>

        <TransactionDetailInfo language={language}>
          {t(`detail.0.${width > 768 ? 'pc' : 'mobile'}`)}
          <a
            href="https://documents.neuronswap.com/neuronswap/user-guide/connect-to-wallet"
            target="_blank"
          >
            {t('detail.1')}
          </a>
          {t('detail.2')}
        </TransactionDetailInfo>

        <TransactionButton onClick={handleApprove}>
          {t('button')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default ApproveModal

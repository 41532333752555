import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import { StakingTransactionRewardsItemType } from '../../../api/query/useStakingTransactionRewardsQuery'

export type StakingTransactionRewardsItemProps =
  StakingTransactionRewardsItemType

const StakingTransactionRewardsItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 0.5px solid #edeaea;
  }

  @media (max-width: 768px) {
    width: calc(100% - 10px);
  }
`

const TokenIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid rgba(46, 46, 46, 0.1);
`

const TokenSymbol = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #2e2e2e;
`

const TokenName = styled.div`
  font-size: 10px;
  color: #9d9999;
`

const Rewards = styled.div`
  margin-left: auto;
  font-size: 18px;
`

const StakingTransactionRewardsItem = ({
  address,
  symbol,
  korName,
  engName,
  rewards,
}: StakingTransactionRewardsItemProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <StakingTransactionRewardsItemWrapper>
      <TokenIcon
        src={`${process.env.REACT_APP_S3_ASSETS_URL}/dex/tokens/${address}.png`}
        alt="Token Icon"
      />

      <div>
        <TokenSymbol>{symbol}</TokenSymbol>
        <TokenName>{language === 'ko' ? korName : engName}</TokenName>
      </div>

      <Rewards>
        <SlicedDecimal>
          {convertNumber(rewards, { comma: true, maxDigit: 6 })}
        </SlicedDecimal>
      </Rewards>
    </StakingTransactionRewardsItemWrapper>
  )
}

export default StakingTransactionRewardsItem

import { useMemo } from 'react'
import { prepare, request } from 'klip-sdk'
import { useQueryClient } from 'react-query'
import { useManageKlipRequestKeyState } from '../state/shared/klipRequestKeyState'
import { useManageConnectedWalletState } from '../state/shared/connectedWalletState'
import { useManageDepositFormState } from '../state/pool/depositFormState'
import { useManageTransactionHistoryState } from '../state/shared/transactionHistoryState'
import caver, { caverForKaikas } from '../modules/network/caver'
import web3 from '../modules/network/web3'
import getAppliedSlippageValue from '../utils/getAppliedSlippageValue'
import { DEADLINE, CONTRACT_GAS_LIMIT } from '../utils/constants'
import checkMobileDevice from '../utils/checkMobileDevice'
import { getCurrentTimeForHistory } from '../utils/getCurrentTime'
import parseTransactionError from '../utils/parseTransactionError'
import { getConvertToPebValue } from '../utils/convertTokenNumberData'
import { ADD_LIQUIDITY_ABI, ADD_LIQUIDITY_KLAY_ABI } from '../contract/abi'
import { CONTRACT_ADDRESS, WKLAY_TOKEN_ADDRESS } from '../contract/address'
import { PoolType } from '../api/query/usePoolQuery'

export default function useAddPoolLiquidity(pairAddress?: string) {
  const { setKlipRequestKeyState } = useManageKlipRequestKeyState()
  const {
    depositFormState: {
      settings: { slippage },
      deposit: { from, to },
    },
  } = useManageDepositFormState()
  const {
    connectedWalletState: { type, address },
  } = useManageConnectedWalletState()
  const { setTransactionHistoryState } = useManageTransactionHistoryState()
  const queryClient = useQueryClient()
  const { tokens } = queryClient.getQueryData<PoolType['data']>([
    'pool',
    pairAddress,
  ]) as PoolType['data']

  const ADD_LIQUIDITY_PARAM = useMemo(
    () => [
      tokens[0].address,
      tokens[1].address,
      getConvertToPebValue(from, tokens[0].decimal),
      getConvertToPebValue(to, tokens[1].decimal),
      getConvertToPebValue(
        getAppliedSlippageValue(from, slippage, 'min'),
        tokens[0].decimal,
      ),
      getConvertToPebValue(
        getAppliedSlippageValue(to, slippage, 'min'),
        tokens[1].decimal,
      ),
      address ?? '',
      DEADLINE,
    ],
    [tokens, pairAddress, from, to, address],
  )

  const ADD_LIQUIDITY_KLAY_PARAM = useMemo(
    () => [
      tokens[tokens[0].address === WKLAY_TOKEN_ADDRESS ? 1 : 0].address,
      tokens[0].address === WKLAY_TOKEN_ADDRESS
        ? getConvertToPebValue(to, tokens[1].decimal)
        : getConvertToPebValue(from, tokens[0].decimal),
      tokens[0].address === WKLAY_TOKEN_ADDRESS
        ? getConvertToPebValue(
            getAppliedSlippageValue(to, slippage, 'min'),
            tokens[1].decimal,
          )
        : getConvertToPebValue(
            getAppliedSlippageValue(from, slippage, 'min'),
            tokens[0].decimal,
          ),
      tokens[0].address === WKLAY_TOKEN_ADDRESS
        ? getConvertToPebValue(
            getAppliedSlippageValue(from, slippage, 'min'),
            tokens[0].decimal,
          )
        : getConvertToPebValue(
            getAppliedSlippageValue(to, slippage, 'min'),
            tokens[1].decimal,
          ),
      address ?? '',
      DEADLINE,
    ],
    [pairAddress, from, to, address],
  )

  const handleAddTransactionHistory = (hash: string, status: boolean) =>
    setTransactionHistoryState(prev => [
      {
        type: 'deposit',
        hash,
        status,
        date: getCurrentTimeForHistory(),
      },
      ...prev,
    ])

  const klipConnectSuccessCallback = (
    result: any,
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const { tx_hash } = result

    if (result.status === 'success') {
      successCallback()
      handleAddTransactionHistory(tx_hash as string, true)
    } else {
      failureCallback()
      handleAddTransactionHistory(tx_hash as string, false)
    }
  }

  const klipAddLiquidity = async (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const { err, request_key } = await prepare.executeContract({
      bappName: 'NEURONswap',
      from: address,
      to: CONTRACT_ADDRESS.router,
      abi: JSON.stringify(
        isKlayIncluded ? ADD_LIQUIDITY_KLAY_ABI : ADD_LIQUIDITY_ABI,
      ),
      params: JSON.stringify(
        isKlayIncluded ? ADD_LIQUIDITY_KLAY_PARAM : ADD_LIQUIDITY_PARAM,
      ),
      value: isKlayIncluded
        ? tokens[0].address === WKLAY_TOKEN_ADDRESS
          ? getConvertToPebValue(from, tokens[0].decimal)
          : getConvertToPebValue(to, tokens[1].decimal)
        : '0',
    })

    if (err) {
      // console.log(err)
      throw new Error()
    }

    setKlipRequestKeyState({
      requestKey: request_key,
      requestCallback: (result: any) =>
        klipConnectSuccessCallback(result, successCallback, failureCallback),
    })

    if (checkMobileDevice()) request(request_key)
  }

  const kaikasAddLiquidity = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const data = caver.klay.abi.encodeFunctionCall(
      isKlayIncluded ? ADD_LIQUIDITY_KLAY_ABI : ADD_LIQUIDITY_ABI,
      isKlayIncluded ? ADD_LIQUIDITY_KLAY_PARAM : ADD_LIQUIDITY_PARAM,
    )

    const extraParameter = isKlayIncluded
      ? {
          data,
          value: isKlayIncluded
            ? tokens[0].address === WKLAY_TOKEN_ADDRESS
              ? getConvertToPebValue(from, tokens[0].decimal)
              : getConvertToPebValue(to, tokens[1].decimal)
            : '0',
        }
      : { data }

    caverForKaikas.klay
      .sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: address,
        to: CONTRACT_ADDRESS.router,
        gas: CONTRACT_GAS_LIMIT,
        ...extraParameter,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const bitkeepAddLiquidity = (
    successCallback: () => void,
    failureCallback: () => void,
  ) => {
    const isKlayIncluded =
      tokens[0].address === WKLAY_TOKEN_ADDRESS ||
      tokens[1].address === WKLAY_TOKEN_ADDRESS

    const data = web3.eth.abi.encodeFunctionCall(
      isKlayIncluded ? ADD_LIQUIDITY_KLAY_ABI : ADD_LIQUIDITY_ABI,
      (isKlayIncluded
        ? ADD_LIQUIDITY_KLAY_PARAM
        : ADD_LIQUIDITY_PARAM) as string[],
    )

    const extraParameter = isKlayIncluded
      ? {
          data,
          value: isKlayIncluded
            ? tokens[0].address === WKLAY_TOKEN_ADDRESS
              ? getConvertToPebValue(from, tokens[0].decimal)
              : getConvertToPebValue(to, tokens[1].decimal)
            : '0',
        }
      : { data }

    web3.eth
      .sendTransaction({
        from: address,
        to: CONTRACT_ADDRESS.router,
        gas: CONTRACT_GAS_LIMIT,
        ...extraParameter,
      })
      .on('error', (error: any) => {
        failureCallback()

        const hash = parseTransactionError(error.message as string)
        if (hash === undefined) return

        handleAddTransactionHistory(hash, false)
      })
      .on('receipt', ({ transactionHash }: any) => {
        successCallback()
        handleAddTransactionHistory(transactionHash as string, true)
      })
  }

  const handleAddLiquidity = async (
    addLiquiditySuccessCallback: () => void,
    addLiquidityFailureCallback: () => void,
  ) => {
    if (type === 'klip')
      await klipAddLiquidity(
        addLiquiditySuccessCallback,
        addLiquidityFailureCallback,
      )
    else if (type === 'kaikas')
      kaikasAddLiquidity(
        addLiquiditySuccessCallback,
        addLiquidityFailureCallback,
      )
    else if (type === 'bitkeep')
      bitkeepAddLiquidity(
        addLiquiditySuccessCallback,
        addLiquidityFailureCallback,
      )
  }

  return handleAddLiquidity
}

import MainDefiSection from '../organisms/MainDefiSection'
import MainHeroSection from '../organisms/MainHeroSection'
import MainInfrastructureSection from '../organisms/MainInfrastructureSection'
import MainReferralSection from '../organisms/MainReferralSection'
import MainRonyClubSection from '../organisms/MainRonyClubSection'
import MainSpecialValueSection from '../organisms/MainSpecialValueSection'
import TemplateLayout from './TemplateLayout'

const MainPageTemplate = () => {
  return (
    <TemplateLayout background="#fcfafa" fullWidth>
      <MainHeroSection />
      <MainDefiSection />
      <MainSpecialValueSection />
      <MainReferralSection />
      <MainRonyClubSection />
      <MainInfrastructureSection />
    </TemplateLayout>
  )
}

export default MainPageTemplate

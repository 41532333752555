import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useManageTokenSelectModalState } from '../../state/modal/tokenSelectModalState'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import TokenModalSearch from '../../components/NeuronSwap/molecules/TokenModalSearch'
import TokenModalListSection from '../../components/NeuronSwap/organisms/TokenModalListSection'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 580px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 24px;
    border-radius: 0;
  }
`

const TokenSelectModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.TokenSelectModal',
  })
  const {
    tokenSelectModalState: { searchInput },
    setTokenSelectModalState,
    resetTokenSelectModalState,
  } = useManageTokenSelectModalState()

  const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) =>
    setTokenSelectModalState(prev => ({
      ...prev,
      searchInput: event.target.value,
    }))

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={resetTokenSelectModalState}>
          {t('title')}
        </ModalHead>

        <TokenModalSearch
          value={searchInput}
          handleChange={handleChangeSearchInput}
        />

        <TokenModalListSection type="favorite" title={t('favorite')} />
        <TokenModalListSection type="normal" title={t('normal')} />
      </ModalWrapper>
    </ModalPortal>
  )
}

export default TokenSelectModal

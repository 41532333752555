import styled from 'styled-components'
import StakingHoldingQuantity from '../organisms/StakingHoldingQuantity'
import NeuronStaking from '../organisms/NeuronStaking'
import NeuronUnstaking from '../organisms/NeuronUnstaking'
import StakingList from '../organisms/StakingList'

const StakingTemplateWrapper = styled.div`
  margin-top: 30px;
`

const StakingTemplate = () => {
  return (
    <StakingTemplateWrapper>
      <StakingHoldingQuantity />
      <NeuronStaking />
      <NeuronUnstaking />
      <StakingList />
    </StakingTemplateWrapper>
  )
}

export default StakingTemplate

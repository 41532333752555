import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TemplateLayout from '../templates/TemplateLayout'
import DepositTemplate from '../templates/DepositTemplate'
import TransactionHistoryTemplate from '../templates/TransactionHistoryTemplate'
import { useManageDepositFormState } from '../../../state/pool/depositFormState'
import usePoolQuery from '../../../api/query/usePoolQuery'
import useCallMessageBox from '../../../hooks/useCallMessageBox'

const PoolDepositPage = () => {
  const navigate = useNavigate()
  const { resetDepositFormState } = useManageDepositFormState()
  const pushMessage = useCallMessageBox()
  const { pairAddress } = useParams()
  const { status } = usePoolQuery(pairAddress)

  useEffect(() => {
    if (status === 'error' || pairAddress === undefined) {
      pushMessage('request', 'failure')
      navigate('/pool')
    }
  }, [status])

  useEffect(() => {
    return resetDepositFormState
  }, [])

  return (
    <TemplateLayout>
      <DepositTemplate pairAddress={pairAddress as string} />
      <TransactionHistoryTemplate />
    </TemplateLayout>
  )
}

export default PoolDepositPage

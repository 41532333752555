import styled from 'styled-components'
import produce from 'immer'
import { useManageSwapFormState } from '../../../state/swap/swapFormState'
import SwapMainItemToken from '../atoms/SwapMainItemToken'
import SwapMainItemInput from '../atoms/SwapMainItemInput'
import SwapMainItemBalance from '../atoms/SwapMainItemBalance'
import SlicedDecimal from '../atoms/SlicedDecimal'
import convertNumber from '../../../utils/convertNumber'
import useTokenQuery from '../../../api/query/useTokenQuery'

const SwapMainFromWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  padding: 20px 30px 20px 19px;
  border-radius: 24px;
  background-color: #f8f8f8;

  @media (max-width: 768px) {
    padding: 24px 16px;
    border-radius: 16px;
  }
`

const SwapMainFromRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 7px;
  }
`

const TokenPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: -7px;
  font-size: 16px;
  color: #9d9999;

  @media (max-width: 768px) {
    margin: 0;
    font-size: 11px;
  }
`

const SwapMainFrom = () => {
  const {
    swapFormState: {
      form: { from },
      error,
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const { data } = useTokenQuery(from.address)

  const handleChange = (value: string) =>
    setSwapFormState(prev =>
      produce(prev, draft => {
        draft.form.from.value = value
        draft.form.lastModified = 'from'

        return draft
      }),
    )

  return (
    <SwapMainFromWrapper>
      <SwapMainFromRow>
        <SwapMainItemToken token={data} position="from" />
        <SwapMainItemInput
          value={from.value}
          handleChange={handleChange}
          color={error === 'INSUFFICIENT_QUANTITY' ? '#b95955' : '#000000'}
        />
      </SwapMainFromRow>

      <SwapMainFromRow>
        <SwapMainItemBalance
          balance={data?.balance ?? '0'}
          isVisibleMaxButton={true}
        />
        <TokenPrice>
          {from.value !== '' && from.address !== '' ? `~ $ ` : ''}
          <SlicedDecimal maxIntLength={9} prefix="$ " isNotDecimal>
            {convertNumber(
              (data?.price ?? 0) *
                parseFloat(from.value !== '' ? from.value : '0'),
              {
                maxDigit: 6,
              },
            )}
          </SlicedDecimal>
        </TokenPrice>
      </SwapMainFromRow>
    </SwapMainFromWrapper>
  )
}

export default SwapMainFrom

import { useMutation } from 'react-query'
import AxiosClient from '../axiosConfig'

type CreateHarvestTransactionRequestBodyType = {
  transactionAddress: string
}

const createHarvestTransaction = async (
  transactionAddress: CreateHarvestTransactionRequestBodyType['transactionAddress'],
) =>
  await AxiosClient.getAxiosInstance(true).post('/api/transactions/harvest', {
    transactionAddress,
  })

export default function useCreateHarvestTransactionMutation() {
  return useMutation(createHarvestTransaction)
}

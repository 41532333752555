import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HeaderMenuItem from '../atoms/HeaderMenuItem'
import MobilePopup from '../molecules/MobilePopup'

const MobileHeaderMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;
`

const Link = styled.a<{ color?: string }>`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  height: 100%;
  margin-left: 30px;
  font-size: 14px;
  color: ${({ color }) => (color !== undefined ? color : '#000000')};
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #9d9999;
  }
`

const DummyLink = styled.div`
  flex-shrink: 0;
  margin: 0 20px;
  font-size: 16px;
  font-weight: 700;
  color: #9d9999;
`

const MobileBlockDescription = styled.div`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
`

const MobileHeaderMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Header',
  })
  const [isVisiblePopup, setIsVisiblePopup] = useState<boolean>(false)

  const handleShowMobilePopup = () => setIsVisiblePopup(true)
  const handleCloseMobilePopup = () => setIsVisiblePopup(false)

  return (
    <MobileHeaderMenuWrapper>
      <HeaderMenuItem to="/swap">{t('menu.0')}</HeaderMenuItem>
      <HeaderMenuItem to="/pool">{t('menu.1')}</HeaderMenuItem>
      {/* <HeaderMenuItem to="/staking">{t('menu.2')}</HeaderMenuItem> */}
      <DummyLink onClick={handleShowMobilePopup}>{t('menu.2')}</DummyLink>
      <HeaderMenuItem to="/asset">{t('menu.3')}</HeaderMenuItem>
      <HeaderMenuItem to="/dashboard">{t('menu.4')}</HeaderMenuItem>
      <HeaderMenuItem to="/referral" highlight>
        {t('menu.5')}
      </HeaderMenuItem>
      <Link href="https://bridge.orbitchain.io/" target="_blank">
        {t('menu.6')}
      </Link>

      <MobilePopup
        type="normal"
        isVisible={isVisiblePopup}
        handleClose={handleCloseMobilePopup}
      >
        <MobileBlockDescription>
          {language === 'ko'
            ? 'NR 스테이킹 서비스는 PC 환경으로 접속 가능합니다.'
            : 'The NR staking service can be accessed\nthrough a PC environment.'}
        </MobileBlockDescription>
      </MobilePopup>
    </MobileHeaderMenuWrapper>
  )
}

export default MobileHeaderMenu

import styled from 'styled-components'
import DashboardListItem from '../molecules/DashboardListItem'
import Pagination from '../atoms/Pagination'
import DashboardListHead from '../molecules/DashboardListHead'
import { PoolItemType } from '../../../api/query/useDashboardPoolsQuery'

type DashboardListType = {
  list: PoolItemType[]
}

const DashboardListWrapper = styled.div`
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: 768px) {
    display: flex;
    margin: 0 -20px;
  }
`

const ListWrapper = styled.div`
  position: relative;
  width: 1200px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
`

const MobileSideEmptyArea = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    flex-shrink: 0;
    width: 20px;
  }
`

const DashboardList = ({ list }: DashboardListType) => {
  return (
    <DashboardListWrapper>
      <MobileSideEmptyArea />

      <div>
        <DashboardListHead />

        <ListWrapper>
          {list.map((pool: PoolItemType) => {
            const { address, volume, fee, ...rest } = pool

            return (
              <DashboardListItem
                address={address}
                {...rest}
                tradingVolume={volume.total}
                fee={fee.total}
                key={address}
              />
            )
          })}

          <Pagination />
        </ListWrapper>
      </div>

      <MobileSideEmptyArea />
    </DashboardListWrapper>
  )
}

export default DashboardList

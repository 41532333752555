import { ReactNode } from 'react'
import styled from 'styled-components'
import TokenModalFilterCheckbox from '../atoms/TokenModalFilterCheckbox'

type TokenModalListSectionHeadProps = {
  children: ReactNode
  isVisibleSelectBox: boolean
}

const TokenModalListSectionHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    padding: 0 0 16px;
  }
`

const HeadTitle = styled.div`
  font-size: 12px;
  color: #9d9999;
`

const TokenModalListSectionHead = ({
  isVisibleSelectBox,
  children,
}: TokenModalListSectionHeadProps) => {
  return (
    <TokenModalListSectionHeadWrapper>
      <HeadTitle>{children}</HeadTitle>

      <TokenModalFilterCheckbox isVisible={isVisibleSelectBox} />
    </TokenModalListSectionHeadWrapper>
  )
}

export default TokenModalListSectionHead

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import produce from 'immer'
import { useQueryClient } from 'react-query'
import SlicedDecimal from '../../components/NeuronSwap/atoms/SlicedDecimal'
import useCallMessageBox from '../../hooks/useCallMessageBox'
import useSwapToken from '../../hooks/useSwapToken'
import { useManageSwapTransactionModalState } from '../../state/modal/swapTransactionModalState'
import { useManageSwapFormState } from '../../state/swap/swapFormState'
import { useManageSwapInfoState } from '../../state/swap/swapInfoState'
import getAppliedSlippageValue from '../../utils/getAppliedSlippageValue'
import convertNumber from '../../utils/convertNumber'
import ModalPortal from '../ModalPortal'
import ModalHead from '../../components/NeuronSwap/molecules/ModalHead'
import { TokenType } from '../../api/query/useTokenQuery'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;
  padding: 30px;
  border-radius: 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: 520px;
    padding: 32px 24px 24px 24px;
  }
`

const Title = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #9d9999;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

const TransactionInfo = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #9d9999;
  border-bottom: 1px solid #9d9999;

  @media (max-width: 768px) {
    margin-top: 16px;
    padding: 18px 0;
    border-color: #edeaea;
  }
`

const TransactionInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 15px;
  }

  & > div:nth-child(1) {
    font-size: 12px;
    color: #9d9999;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #2e2e2e;

    span {
      margin-left: 8px;
      font-weight: 400;
    }
  }
`

const TransactionDetailInfo = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const TransactionDetailInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #9d9999;

  & > div:nth-child(2) {
    display: flex;
  }

  span {
    color: #ff6d68;
  }

  & + & {
    margin-top: 14px;
  }

  @media (max-width: 768px) {
    & + & {
      margin-top: 16px;
    }

    & > div:nth-child(1) {
      white-space: pre-line;
    }

    &:nth-child(1) > div:nth-child(2) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

const TransactionButton = styled.button`
  width: 100%;
  margin-top: auto;
  padding: 22px 0;
  border-radius: 14px;
  border: 0;
  background: #65dfb9;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`

const SwapTransactionModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'Modal.SwapTransactionModal',
  })
  const {
    swapFormState: {
      form: { from, to, lastModified },
      settings: { slippage },
    },
    setSwapFormState,
  } = useManageSwapFormState()
  const {
    swapInfoState: { exchangeRatePerToValue, fee, transactionRewards },
  } = useManageSwapInfoState()
  const { setSwapTransactionModalState } = useManageSwapTransactionModalState()
  const handleSwap = useSwapToken()
  const pushMessage = useCallMessageBox()
  const queryClient = useQueryClient()

  const fromToken = queryClient.getQueryData([
    'token',
    from.address,
  ]) as TokenType
  const toToken = queryClient.getQueryData(['token', to.address]) as TokenType

  const swapSuccessCallback = () => {
    queryClient.invalidateQueries(['token', from.address])
    queryClient.invalidateQueries(['token', to.address])
    setSwapFormState(prev =>
      produce(prev, draft => {
        draft.form.from.value = ''
        draft.form.to.value = ''
        draft.form.lastModified = 'from'

        draft.settings.isMax = false
        draft.error = undefined

        return
      }),
    )
    setSwapTransactionModalState(false)
    pushMessage('request', 'success')
  }

  const swapFailureCallback = () => pushMessage('request', 'failure')

  const handleSwapTransaction = () =>
    handleSwap(swapSuccessCallback, swapFailureCallback)

  return (
    <ModalPortal>
      <ModalWrapper>
        <ModalHead handleClose={() => setSwapTransactionModalState(false)}>
          {t('title')}
        </ModalHead>

        <Title>{t('subTitle')}</Title>

        <TransactionInfo>
          <TransactionInfoItem>
            <div>From</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(from.value ?? 0, { maxDigit: 6 })}
              </SlicedDecimal>
              <span>{fromToken.symbol}</span>
            </div>
          </TransactionInfoItem>
          <TransactionInfoItem>
            <div>To</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(to.value ?? 0, { maxDigit: 6 })}
              </SlicedDecimal>
              <span>{toToken.symbol}</span>
            </div>
          </TransactionInfoItem>
        </TransactionInfo>

        <TransactionDetailInfo>
          <TransactionDetailInfoItem>
            <div>{t('detail.0')}</div>
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '3px' }}>1 {toToken.symbol} ≈</div>
                <SlicedDecimal maxIntLength={7} showTooltip={false}>
                  {convertNumber(exchangeRatePerToValue, { maxDigit: 6 })}
                </SlicedDecimal>
                <div style={{ marginLeft: '3px' }}>{fromToken.symbol}</div>
              </div>
              <span style={{ display: 'flex', marginLeft: '3px' }}>
                ({'$'}
                <SlicedDecimal maxIntLength={10} showTooltip={false}>
                  {convertNumber(fromToken.price * exchangeRatePerToValue, {
                    maxDigit: 4,
                  })}
                </SlicedDecimal>
                )
              </span>
            </div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t(`detail.1.${lastModified}`)}</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(
                  getAppliedSlippageValue(
                    lastModified === 'from' ? to.value ?? 0 : from.value ?? 0,
                    slippage,
                    lastModified === 'from' ? 'min' : 'max',
                  ),
                  { maxDigit: 6 },
                )}
              </SlicedDecimal>
              <div style={{ marginLeft: '3px' }}>
                {lastModified === 'from' ? toToken.symbol : fromToken.symbol}
              </div>
            </div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t('detail.2')}</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(fee, { maxDigit: 6 })}
              </SlicedDecimal>
              <div style={{ marginLeft: '3px' }}>{fromToken.symbol}</div>
            </div>
          </TransactionDetailInfoItem>
          <TransactionDetailInfoItem>
            <div>{t('detail.3')}</div>
            <div>
              <SlicedDecimal maxIntLength={10} showTooltip={false}>
                {convertNumber(transactionRewards, { maxDigit: 6 })}
              </SlicedDecimal>
              <div style={{ marginLeft: '3px' }}>NR</div>
            </div>
          </TransactionDetailInfoItem>
        </TransactionDetailInfo>

        <TransactionButton onClick={handleSwapTransaction}>
          {t('button')}
        </TransactionButton>
      </ModalWrapper>
    </ModalPortal>
  )
}

export default SwapTransactionModal

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import produce from 'immer'
import DepositMainHead, {
  DepositMainHeadProps,
} from '../molecules/DepositMainHead'
import DepositMainItem from '../molecules/DepositMainItem'
import DepositMainWithdrawItem from '../molecules/DepositMainWithdrawItem'
import DepositMainDetail from '../molecules/DepositMainDetail'
import { useManageDepositFormState } from '../../../state/pool/depositFormState'
import { useManageDepositRequestModalState } from '../../../state/modal/depositRequestModalState'
import { useManageWithdrawRequestModalState } from '../../../state/modal/withdrawRequestModalState'
import { useManageStakingRequestModalState } from '../../../state/modal/stakingRequestModalState'
import { useManageUnstakingRequestModalState } from '../../../state/modal/unstakingRequestModalState'
import usePoolQuery from '../../../api/query/usePoolQuery'
import useConvertDepositTokenAmount from '../../../hooks/useConvertDepositTokenAmount'

type DepositMainProps = {
  pairAddress: string
}

const DepositMainWrapper = styled.div<{
  language: string
  currentMode: string
}>`
  padding: 30px 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
`

const DepositMainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`

const MobileDepositMainWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 12px;
    padding: 16px 8px 8px 8px;
    border-radius: 24px;
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(228, 231, 232, 0.5);
  }
`

const DepositMainItemSection = styled.div`
  position: relative;
  margin-top: 25px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

const TransactionInfoIcon = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #f8f8f8;
  border: 4px solid #ffffff;
  width: 48px;
  height: 48px;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;

    img {
      width: 12px;
      height: 12px;
    }
  }
`

const TransactionButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0;
  }
`

const TransactionButton = styled.div<{
  $isActive: boolean
  background: string
}>`
  margin-top: 30px;
  padding: 13px 0;
  border-radius: 28px;
  background: ${({ $isActive, background }) =>
    $isActive ? background : '#9d9999'};
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, ${({ $isActive }) => ($isActive ? '1' : '0.7')});
  text-align: center;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'initial')};
  transition: 0.15s background;

  @media (max-width: 768px) {
    margin-top: 8px;
    padding: 16px 0;
    border-radius: 16px;
  }
`

const DepositMain = ({ pairAddress }: DepositMainProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', {
    keyPrefix: 'Deposit.DepositMain.button',
  })
  const { data } = usePoolQuery(pairAddress)
  useConvertDepositTokenAmount(pairAddress)
  const { setDepositRequestModalState } = useManageDepositRequestModalState()
  const { setStakingRequestModalState } = useManageStakingRequestModalState()
  const { setWithdrawRequestModalState } = useManageWithdrawRequestModalState()
  const { setUnstakingRequestModalState } =
    useManageUnstakingRequestModalState()
  const {
    depositFormState: { currentMode, deposit, withdraw, error },
    setDepositFormState,
  } = useManageDepositFormState()
  const [[leftActive, rightActive], setIsActive] = useState<[boolean, boolean]>(
    [false, false],
  )

  useEffect(() => {
    if (currentMode === 'deposit')
      setIsActive([
        deposit.from !== '' &&
          parseFloat(deposit.from) !== 0 &&
          deposit.to !== '' &&
          parseFloat(deposit.to) !== 0 &&
          !error.from &&
          !error.to,
        data?.userData?.depositedLpBalance !== undefined &&
          data?.userData?.depositedLpBalance !== '0',
      ])
    else
      setIsActive([
        withdraw !== '' && parseFloat(withdraw) !== 0 && !error.withdraw,
        data?.userData?.depositedLpBalance !== undefined &&
          data?.userData?.depositedLpBalance !== '0',
      ])

    return () => setIsActive([false, false])
  }, [data, currentMode, deposit, withdraw, error])

  useEffect(() => {
    setDepositFormState(prev => ({
      ...prev,
      firstIsMaxAmount: false,
      secondIsMaxAmount: false,
      withdrawIsMaxAmount: false,
      deposit: {
        from: '',
        to: '',
        lastModified: 'from',
      },
      withdraw: '',
      error: {
        from: false,
        to: false,
        withdraw: false,
      },
    }))
  }, [currentMode])

  if (data === undefined) return null

  const handleChangeDepositMode = (type: DepositMainHeadProps['type']) =>
    setDepositFormState(prev =>
      produce(prev, draft => {
        draft.currentMode = type
        return draft
      }),
    )

  const handleDeposit = () =>
    setDepositRequestModalState({ visible: true, pairAddress })
  const handleStaking = () =>
    setStakingRequestModalState({ visible: true, pairAddress, pid: data.pid })
  const handleWithdraw = () =>
    setWithdrawRequestModalState({ visible: true, pairAddress })
  const handleUnstaking = () =>
    setUnstakingRequestModalState({ visible: true, pairAddress, pid: data.pid })

  const executeTransactionLeftButton = () => {
    if (
      currentMode === 'deposit' &&
      deposit.from !== '' &&
      parseFloat(deposit.from) !== 0 &&
      deposit.to !== '' &&
      parseFloat(deposit.to) !== 0
    )
      handleDeposit()
    else if (
      currentMode === 'withdraw' &&
      withdraw !== '' &&
      parseFloat(withdraw) !== 0
    )
      handleUnstaking()
  }

  const executeTransactionRightButton = () => {
    if (
      currentMode === 'deposit' &&
      data?.userData?.depositedLpBalance &&
      data?.userData?.depositedLpBalance !== '0'
    )
      handleStaking()
    else if (
      currentMode === 'withdraw' &&
      data?.userData?.depositedLpBalance &&
      data?.userData?.depositedLpBalance !== '0'
    )
      handleWithdraw()
  }

  return (
    <DepositMainWrapper language={language} currentMode={currentMode}>
      <DepositMainGrid>
        <MobileDepositMainWrapper>
          <DepositMainHead
            type={currentMode}
            handleChangeDepositMode={handleChangeDepositMode}
          />

          <DepositMainItemSection>
            {currentMode === 'deposit' ? (
              <>
                <DepositMainItem
                  tokenAddress={data.tokens[0].address}
                  position="from"
                />
                <DepositMainItem
                  tokenAddress={data.tokens[1].address}
                  position="to"
                />
                <TransactionInfoIcon>
                  <img
                    src="/dexAssets/transaction_info_icon.png"
                    alt="Transaction Info Icon"
                  />
                </TransactionInfoIcon>
              </>
            ) : (
              <DepositMainWithdrawItem
                tokens={data.tokens}
                stakedLpBalance={data.userData?.stakedLpBalance ?? '0'}
              />
            )}
          </DepositMainItemSection>

          <TransactionButtonWrapper>
            <TransactionButton
              onClick={leftActive ? executeTransactionLeftButton : undefined}
              $isActive={leftActive}
              background={currentMode === 'deposit' ? '#ff6d68' : '#65dfb9'}
            >
              {t(`${currentMode}.0`)}
            </TransactionButton>
            <TransactionButton
              onClick={rightActive ? executeTransactionRightButton : undefined}
              $isActive={rightActive}
              background={currentMode === 'deposit' ? '#65dfb9' : '#ff6d68'}
            >
              {t(`${currentMode}.1`)}
            </TransactionButton>
          </TransactionButtonWrapper>
        </MobileDepositMainWrapper>

        <DepositMainDetail
          currentMode={currentMode}
          tokens={data.tokens}
          exchangeRate={data.exchangeRate}
        />
      </DepositMainGrid>
    </DepositMainWrapper>
  )
}

export default DepositMain
